import { axios } from '../axios';

// TYPES
import { AxiosRequestConfig } from 'axios';
import { GETAccountById } from '../../types';
import { GETUserAccountDetails } from '../../types/acount-details-summary';

// FETCH ACCOUNT BY ID
export const getAccountById = async (id: string | number) => {
  const url = `/account/get/${id}`;

  const { data } = await axios.get<GETAccountById>(url);

  return data.account_details;
};

export const getUserAccountDetails = async (token?: string) => {
  const url = '/users/welcomepagedetails';
  const options: AxiosRequestConfig = {};

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const { data } = await axios.get<GETUserAccountDetails>(url, { ...options });
  return data;
};
