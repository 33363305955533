import './View-Alert.scss';

import { useEffect, useState } from 'react';
import { useActions, useAsyncWrapper } from '../../../hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';

// API REQUESTS
import {
    getAlertById,
    getStudyById,
    getStudyList,
    updateAlertById,
} from '../../../api';

// CONSTANTS
import { ROUTES } from '../../../router';

// TYPES
import {
    StudyDetails,
    AlertFormState,
    FetchedAlertDetails,
    StudyListItem,
} from '../../../state';

// UI COMPONENT IMPORTS
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';
import AlertForm from '../../../components/Alert-Form/Alert-Form';
import PageContainer from '../../../components/Page-Container/Page-Container';

// COMPONENT PROPS
interface EditAlertProps {
    isEditing?: boolean;
}

// COMPONENT STATE
interface ComponentState {
    studies: StudyListItem[];
    alertData: FetchedAlertDetails | null;
    alertStudy: StudyDetails | null;
}

// FUNCTIONAL COMPONENT (EDIT ALERT)
function ViewAlert({ isEditing = true }: EditAlertProps) {
    // HOOKS
    const navigate = useNavigate();
    const { alert_id } = useParams();
    const wrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    const [state, setState] = useState<ComponentState>({
        studies: [],
        alertData: null,
        alertStudy: null,
    });

    // FETCH AND STORE ALERT DETAILS
    const loadAlertDetails = async () => {
        if (!alert_id) {
            return [null, null];
        }
        // FETCH ALERT DATA
        const data = await getAlertById(alert_id);

        // THROW ERROR AND REDIRECT IF ALERT NOT FOUND
        if (!data) {
            navigate(ROUTES.BRAND_MONITORING);
            throw new Error('Unable to find alert with id: ' + alert_id);
        }

        // FETCH ALERT STUDY DATA
        const study = await getStudyById(data.study_id);

        return [data, study] as const;
    };

    // FETCH ALERT DETAILS ON PAGE LOAD
    useEffect(() => {
        wrapper(async () => {
            await Promise.all([getStudyList(), loadAlertDetails()]).then(
                ([studies, [alertData, alertStudy]]) => {
                    setState({
                        studies,
                        alertData,
                        alertStudy,
                    });
                }
            );
        })();
    }, []);

    // HANDLE EDIT MONITOR/ALERT SUBMIT (NO WRAPPER - IMPLEMENTED IN ALERT_FORM)
    const onUpdateAlert = async (formState: AlertFormState) => {
        if (!alert_id) {
            return;
        }

        const data = await updateAlertById(alert_id, formState);

        if (data.message) {
            alertSuccess(data.message);
            navigate(`/alerts/view/${alert_id}`);
        }
    };

    // JSX
    return (
        <PageContainer>
            <div className="view-alert-container">
                <div className="view-alert-header">
                    {!!state.alertData?.status && (
                        <div className="scheduled-report-status">
                            <Status
                                type={
                                    state.alertData.status === 'Active'
                                        ? STATUS_TYPE.SUCCESS
                                        : STATUS_TYPE.NOTELIGIBLETOSYNC
                                }
                                title={state.alertData.status}
                            />
                        </div>
                    )}
                    <h1 className="scheduled-report-title">
                        Alert ID: {alert_id}
                        {/* --- DISPLAY EDIT ICON ONLY FOR CREATOR --- */}
                        {!isEditing && (
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={`/alerts/edit/${alert_id}`}
                                state={{ alertData: state.alertData }}
                            >
                                <i className="icon-edit" />
                            </Link>
                        )}
                    </h1>
                </div>
                {!!state.alertData && (
                    <AlertForm
                        data={state.alertData}
                        study={state.alertStudy}
                        studyList={state.studies}
                        onSubmit={onUpdateAlert}
                        readOnly={!isEditing}
                    />
                )}
            </div>
        </PageContainer>
    );
}

export default ViewAlert;
