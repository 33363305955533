import './Warning-Modal.scss';

import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';

interface WarningModalProps {
    header: string;
    content: string | React.ReactNode;
    onClose: () => void;
    onConfirm: (() => Promise<void>) | (() => void);
}

/**
 *
 * @onConfirm Implement **useAsyncWrapper** for asynchronous functions.
 */
function WarningModal({
    header,
    content,
    onClose,
    onConfirm,
}: WarningModalProps) {
    return (
        <Modal
            userClosable={false}
            alertType="error"
            onClose={onClose}
            header={header}
            content={<div>{content}</div>}
            footer={
                <div className="form-buttons">
                    <Button
                        className="btn-cancel"
                        color="red"
                        onClick={onClose}
                        style={{ color: 'white' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        form="form-add-locale"
                        onClick={async () => {
                            await onConfirm();
                            onClose();
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            }
        />
    );
}

export default WarningModal;
