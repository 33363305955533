import { DropdownOption, StudyDetails } from '../../models';
import { ScheduledReport } from '../../../types';

export const REPORT_FOCUS = ['words', 'brands'] as const;
export const REPORT_METRICS = ['coverage', 'rank', 'creative_count'] as const;

export type ReportMetric = typeof REPORT_METRICS[number][];
export type ReportFocus = typeof REPORT_FOCUS[number];

export enum ReportFilterProps {
    KEYWORDS = 'keywords',
    KEYWORD_GROUPS = 'keyword_groups',
    COMPETITORS = 'competitors',
    COMPETITOR_GROUP = 'competitor_group',
    TARGETS = 'targets',
    METRICS = 'metrics',
    TYPE = 'type',
    TIME_ZONE = 'time_zone',
    ZERO_COVERAGE = 'zero_coverage',
    AUTO_COMPETITOR_GROUP = 'auto_competitor_group',
    COMPARE_PREVIOUS = 'compare_previous',
    COMPETITOR_LIMIT = 'competitor_limit',
    DAILY = 'daily',
    HOURLY = 'hourly',
    TOP_WORDS = 'top_words',
    FOCUS = 'focus',
}

export enum REPORT_FILTER_ACTION_TYPES {
    SET_KEYWORDS = 'set_keywords',
    SET_KEYWORD_GROUPS = 'set_keyword_groups',
    SET_COMPETITORS = 'set_competitors',
    SET_COMPETITOR_GROUP = 'set_competitor_groups',
    SET_TARGETS = 'set_targets',
    SET_METRICS = 'set_metrics',
    SET_TYPE = 'set_type',
    SET_TIME_ZONE = 'set_time_zone',
    TOGGLE_ZERO_COVERAGE = 'set_zero_coverage',
    TOGGLE_AUTO_COMPETITOR_GROUP = 'set_auto_competitor_group',
    TOGGLE_COMPARE_PREVIOUS = 'set_compare_previous',
    TOGGLE_DAILY = 'set_daily',
    TOGGLE_HOURLY = 'set_hourly',
    SET_COMPETITOR_LIMIT = 'set_competitor_limit',
    SET_TOP_WORDS = 'set_top_words',
    SET_FOCUS = 'set_focus',
    RESET_FILTERS = 'reset_filters',
    LOAD_STUDY_OPTIONS = 'load_study_options',
    LOAD_REPORT_FILTERS = 'load_filters',
}

export const CHECKBOX_FILTERS: CheckboxFilters[] = [
    {
        filter: ReportFilterProps.ZERO_COVERAGE,
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_ZERO_COVERAGE,
    },
    {
        filter: ReportFilterProps.COMPARE_PREVIOUS,
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_COMPARE_PREVIOUS,
    },
    {
        filter: ReportFilterProps.AUTO_COMPETITOR_GROUP,
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_AUTO_COMPETITOR_GROUP,
    },
    {
        filter: ReportFilterProps.DAILY,
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY,
    },
    {
        filter: ReportFilterProps.HOURLY,
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY,
    },
];

export interface FocusDropdownOption {
    label: ReportFocus;
    value: ReportFocus;
}

export const DROPDOWN_FILTERS_MULTI = [
    ReportFilterProps.KEYWORDS,
    ReportFilterProps.KEYWORD_GROUPS,
    ReportFilterProps.COMPETITORS,
    ReportFilterProps.COMPETITOR_GROUP,
    ReportFilterProps.TARGETS,
    ReportFilterProps.METRICS,
    ReportFilterProps.TYPE,
    ReportFilterProps.TIME_ZONE,
] as const;

export type CheckboxFilters = {
    filter:
        | ReportFilterProps.ZERO_COVERAGE
        | ReportFilterProps.COMPARE_PREVIOUS
        | ReportFilterProps.AUTO_COMPETITOR_GROUP
        | ReportFilterProps.DAILY
        | ReportFilterProps.HOURLY;

    type:
        | REPORT_FILTER_ACTION_TYPES.TOGGLE_ZERO_COVERAGE
        | REPORT_FILTER_ACTION_TYPES.TOGGLE_COMPARE_PREVIOUS
        | REPORT_FILTER_ACTION_TYPES.TOGGLE_AUTO_COMPETITOR_GROUP
        | REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY
        | REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY;
};

export interface ReportFiltersState {
    keywordOptions: DropdownOption[];
    keywords?: DropdownOption[];
    keywordGroupOptions: DropdownOption[];
    keyword_groups?: DropdownOption[];
    competitorOptions: DropdownOption[];
    competitors?: DropdownOption[];
    competitorGroupOptions: DropdownOption[];
    competitor_group?: DropdownOption[];
    targetOptions: DropdownOption[];
    targets?: DropdownOption[];
    metrics?: DropdownOption[];
    type?: DropdownOption[];
    compare_previous?: boolean;
    zero_coverage?: boolean;
    auto_competitor_group?: boolean;
    competitor_limit?: number | null; // ONLY WHEN AUTO COMPETITOR GROUP IS SELECTED (1-100)
    daily?: boolean;
    hourly?: boolean;
    time_zone?: string | null;
    top_words?: number | null;
    focus?: ReportFocus | null;
}
export interface ReportFilters {
    keywords?: DropdownOption[];
    keyword_groups?: DropdownOption[];
    competitors?: DropdownOption[];
    competitor_group?: DropdownOption[];
    targets?: DropdownOption[];
    metrics?: DropdownOption[];
    type?: DropdownOption[];
    compare_previous?: boolean;
    zero_coverage?: boolean;
    auto_competitor_group?: boolean;
    competitor_limit?: number | null; // ONLY WHEN AUTO COMPETITOR GROUP IS SELECTED (1-100)
    daily?: boolean;
    hourly?: boolean;
    time_zone?: string | null;
    top_words?: number | null;
    focus?: ReportFocus | null;
}

export const DEFAULT_REPORT_FILTERS_STATE: ReportFiltersState = {
    keywordOptions: [],
    keywords: [],
    keywordGroupOptions: [],
    keyword_groups: [],
    competitorOptions: [],
    competitors: [],
    competitorGroupOptions: [],
    competitor_group: [],
    targetOptions: [],
    targets: [],
    metrics: [],
    type: [],
    time_zone: null,
    compare_previous: false,
    zero_coverage: false,
    auto_competitor_group: false,
    daily: false,
    hourly: false,
};

// REPORT FILTER ACTIONS
export interface SetKeywordsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_KEYWORDS;
    value: ReportFiltersState[ReportFilterProps.KEYWORDS];
}

export interface SetKeywordGroupsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_KEYWORD_GROUPS;
    value: ReportFiltersState[ReportFilterProps.KEYWORD_GROUPS];
}

export interface SetCompetitorsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITORS;
    value: ReportFiltersState[ReportFilterProps.COMPETITORS];
}

export interface SetCompetitorGroupsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_GROUP;
    value: ReportFiltersState[ReportFilterProps.COMPETITOR_GROUP];
}

export interface SetTargetsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_TARGETS;
    value: ReportFiltersState[ReportFilterProps.TARGETS];
}

export interface SetMetricsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_METRICS;
    value: ReportFiltersState[ReportFilterProps.METRICS];
}

export interface SetTypeAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_TYPE;
    value: ReportFiltersState[ReportFilterProps.TYPE];
}

export interface SetTimezoneAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_TIME_ZONE;
    value: ReportFiltersState[ReportFilterProps.TIME_ZONE];
}

export interface ToggleZeroCoverageAction {
    type: REPORT_FILTER_ACTION_TYPES.TOGGLE_ZERO_COVERAGE;
}

export interface ToggleAutoCompetitorGroupAction {
    type: REPORT_FILTER_ACTION_TYPES.TOGGLE_AUTO_COMPETITOR_GROUP;
}

export interface ToggleComparePreviousAction {
    type: REPORT_FILTER_ACTION_TYPES.TOGGLE_COMPARE_PREVIOUS;
}

export interface ToggleDailyAction {
    type: REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY;
}

export interface ToggleHourlyAction {
    type: REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY;
}

// USED ONLY WHEN AUTO COMPETITOR GROUP IS SELECTED
export interface SetCompetitorLimitAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_LIMIT;
    value: ReportFiltersState[ReportFilterProps.COMPETITOR_LIMIT];
}

export interface SetTopWordsAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_TOP_WORDS;
    value: ReportFiltersState[ReportFilterProps.TOP_WORDS];
}

export interface SetWordFocusAction {
    type: REPORT_FILTER_ACTION_TYPES.SET_FOCUS;
    value: ReportFiltersState[ReportFilterProps.FOCUS];
}

export interface ResetFiltersAction {
    type: REPORT_FILTER_ACTION_TYPES.RESET_FILTERS;
}

export interface LoadStudyOptionsAction {
    type: REPORT_FILTER_ACTION_TYPES.LOAD_STUDY_OPTIONS;
    value: StudyDetails;
}

export interface LoadReportFiltersAction {
    type: REPORT_FILTER_ACTION_TYPES.LOAD_REPORT_FILTERS;
    value: { report: ScheduledReport; study: StudyDetails };
}

export type ReportFiltersAction =
    | SetKeywordsAction
    | SetKeywordGroupsAction
    | SetCompetitorsAction
    | SetCompetitorGroupsAction
    | SetTargetsAction
    | SetMetricsAction
    | SetTypeAction
    | SetTimezoneAction
    | ToggleZeroCoverageAction
    | ToggleAutoCompetitorGroupAction
    | ToggleComparePreviousAction
    | ToggleDailyAction
    | ToggleHourlyAction
    | SetCompetitorLimitAction
    | SetTopWordsAction
    | SetWordFocusAction
    | ResetFiltersAction
    | LoadStudyOptionsAction
    | LoadReportFiltersAction;
