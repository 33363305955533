import './Pmds-Text-List.scss';

import React, { useMemo, useState } from 'react';

// UI COMPONENTS
import Pill from '../Pmds-Pill/Pmds-Pill';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// TEXT LIST COMPONENT PROPS
interface TextListProps {
    type?: string;
    label?: string | React.ReactNode;
    values: string[];
    onAdd: (text: string) => void;
    onRemove: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    invert?: boolean;
}

function TextList({
    type = 'text',
    label = '',
    values,
    onAdd,
    onRemove,
    required,
    disabled,
    placeholder = '',
    invert = true,
}: TextListProps) {
    // TEXT INPUT VALUE STATE
    const [text, setText] = useState('');

    // TRIGGER DUPLICATE VALUE ERROR MESSAGE
    const [duplicate, setDuplicate] = useState(false);

    // HANDLE ADD TEXT TO LIST
    const onAddItem = (evt: React.MouseEvent) => {
        evt.preventDefault();
        if (!text) {
            return;
        }

        // TRIM CURRENT TEXT INPUT VALUE
        const newValue = text.trim();

        // CHECK IF VALUE ALREADY EXISTS
        const isDuplicate =
            values.findIndex(
                (value) => value.toLowerCase() === newValue.toLowerCase()
            ) >= 0;

        // DISPLAY DUPLICATE ERROR MESSAGE IF TRUE
        if (isDuplicate) {
            setDuplicate(true);
            return;
        }

        // CLEAR TEXT INPUT VALUE
        setText('');

        // ADD VALUE
        onAdd(newValue);
    };

    // HANDLE REMOVE TEXT FROM LIST
    const onRemoveItem = (index: number) => {
        onRemove(values[index]);
    };

    // CREATE VALUES LIST ELEMENTS (ONLY UPDATE IF VALUES HAS CHANGED)
    const renderedPills = useMemo(() => {
        return values.map((value, index) => (
            <Pill
                key={index}
                text={value}
                onClick={() => onRemoveItem(index)}
                disabled={disabled}
                invert={invert}
            />
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, disabled]);

    return (
        <div className="text-list-container">
            <div className="text-list-input">
                <Textbox
                    className="text-input"
                    type={type}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        if (duplicate) {
                            setDuplicate(false);
                        }
                        setText(evt.target.value);
                    }}
                    value={text}
                    placeholder={placeholder}
                    required={required && values.length < 1}
                    disabled={disabled}
                    invert={invert}
                >
                    {label}
                </Textbox>
                <Button onClick={onAddItem} disabled={disabled}>
                    Add
                </Button>
            </div>
            {!!text && (
                <p
                    style={{
                        marginTop: '.5rem',
                        color: 'red',
                        fontSize: '.8rem',
                    }}
                >
                    Click "Add" to enter value
                </p>
            )}
            {duplicate && (
                <p className="duplicate-error-message">Value already exists</p>
            )}
            <div className="text-list-content">
                <ul>{renderedPills}</ul>
            </div>
        </div>
    );
}

export default TextList;
