import { DropdownOption, StatusType } from '../../state';

export const REPORT_FILE_TYPES = ['json', 'xlsx', 'csv'] as const;

export const FILE_TYPE_OPTIONS = [
    { label: 'EXCEL', value: 'xlsx' },
    { label: 'CSV', value: 'csv' },
    { label: 'JSON', value: 'json' },
];

export const STATUS_OPTIONS = [
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Active', value: 'Active' },
];

export const REPORT_TYPES = [
    'Battlefield',
    'Brands',
    'Content',
    'Keywords',
    'SERPVisibility',
    'WordAnalysis',
] as const;

export const REPORT_TYPE_OPTIONS: DropdownOption[] = [
    { label: 'Battlefield', value: 'Battlefield' },
    { label: 'Brands', value: 'Brands' },
    { label: 'Content', value: 'Content' },
    { label: 'Keywords', value: 'Keywords' },
    { label: 'SERP Visibility', value: 'SERPVisibility' },
    { label: 'Word Analysis', value: 'WordAnalysis' },
];

export type ReportType = typeof REPORT_TYPES[number];

export interface ReportTypeChoice {
    label: StatusType;
    value: StatusType;
}

export type ReportFileType = typeof REPORT_FILE_TYPES[number];

export interface ReportDownloadType {
    label: 'JSON' | 'EXCEL' | 'CSV';
    value: 'json' | 'xlsx' | 'csv';
}

export interface ReportSelectionState {
    report_type: StatusType;
    study_id: number | null;
    study_type: 'search';
    start_date: Date;
    end_date: Date;
    file_type: ReportDownloadType['value'];
}

export enum ReportSelectionProps {
    REPORT_TYPE = 'report_type',
    STUDY_ID = 'study_id',
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    FILE_TYPE = 'file_type',
}

export enum ReportSelectionActionTypes {
    UPDATE_REPORT_TYPE = 'update_report_type',
    UPDATE_STUDY_ID = 'update_study_id',
    UPDATE_START_DATE = 'update_start_date',
    UPDATE_END_DATE = 'update_end_date',
    UPDATE_FILE_TYPE = 'update_file_type',
}
