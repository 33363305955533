import React, { useEffect, useState } from 'react';
import './Chart-Menu.scss';

// COMPONENT PROPS
interface ChartMenuProps {
    onExportPNG?: () => void;
}

// TOOLBAR MENU
const OPEN_MENU_CLASS = ' apexcharts-menu-open';

// FUNCTIONAL COMPONENT (CHART MENU)
function ChartMenu({ onExportPNG }: ChartMenuProps) {
    const [isOpen, setIsOpen] = useState(false);

    // TOOLBAR MENU DISPLAY
    useEffect(() => {
        const handleDisplayMenu = (evt: any) => {
            const isMenuIcon =
                evt.target.classList.contains('word-cloud-export');
            if (!isMenuIcon) {
                setIsOpen(false);
            }
        };
        window.addEventListener('click', handleDisplayMenu);

        return () => {
            window.removeEventListener('click', handleDisplayMenu);
        };
    }, []);

    return (
        <div className="apexcharts-toolbar">
            <div
                className="apexcharts-menu-icon word-cloud-menu-icon"
                title="Menu"
            >
                <div
                    className="pmds-chart-apex__icon"
                    style={{ cursor: 'pointer' }}
                >
                    <i
                        className="icon-export word-cloud-export"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsOpen((prev) => !prev);
                        }}
                    >
                        <i></i>
                    </i>
                </div>
            </div>
            <div className={`apexcharts-menu${isOpen ? OPEN_MENU_CLASS : ''}`}>
                <div
                    className="apexcharts-menu-item exportPNG"
                    title="Download PNG"
                    onClick={onExportPNG}
                >
                    Download PNG
                </div>
            </div>
        </div>
    );
}

export default ChartMenu;
