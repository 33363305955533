import './Group-Details.scss';

// UI COMPONENT IMPORTS
import {
    AccordionControl,
    AccordionContent,
} from '@publicismedia-ds/ui-accordion';
import { STUDY_TOOLTIPS } from '../../tooltips';
import Button from '@publicismedia-ds/ui-button';
import { GroupData } from '../Group-Content/Group-Content';
import AccordionGroup from '../Accordion-Group/Accordion-Group';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface GroupDetailsProps {
    newGroups?: boolean;
    type: 'keyword' | 'competitor';
    data: GroupData[];
    onDelete?: (id: string | number) => void;
    onEdit?: (id: string | number) => void;
    onAdd?: () => void;
}

// FUNCTIONAL COMPONENT (GROUP DETAILS - KEYWORDS/COMPETITORS)
function GroupDetails({
    type,
    data,
    onAdd,
    onEdit,
    onDelete,
}: GroupDetailsProps) {
    return (
        <AccordionGroup>
            <AccordionControl targetId={type + '-groups'}>
                {onAdd ? (
                    <CustomTooltip
                        content={
                            type === 'keyword' ? (
                                <div className="tooltip-max-width">
                                    {STUDY_TOOLTIPS.KEYWORD_GROUPS}
                                </div>
                            ) : (
                                STUDY_TOOLTIPS.COMPETITOR_GROUPS
                            )
                        }
                        align={TOOLTIP_ALIGN.LEFT}
                    >
                        <h3>{type} Groups</h3>
                    </CustomTooltip>
                ) : (
                    <h3>{type} Groups</h3>
                )}
            </AccordionControl>
            <AccordionContent id={type + '-groups'}>
                <div className="accordion-group-items">
                    {!!onAdd && (
                        <div className="button-add">
                            <Button onClick={onAdd}>Add {type} group</Button>
                        </div>
                    )}
                    {data.length ? (
                        data.map((group) => {
                            return (
                                <div key={group.id}>
                                    <AccordionControl
                                        targetId={type + '-group-' + group.id}
                                    >
                                        <h4>{group.name}</h4>
                                    </AccordionControl>
                                    <AccordionContent
                                        id={type + '-group-' + group.id}
                                    >
                                        <div className="accordion-group-item accordion-content column">
                                            <h4 className="details-header">
                                                {type}s:
                                                <div className="action-buttons">
                                                    {!!onEdit && (
                                                        <i
                                                            className="icon-edit"
                                                            onClick={() =>
                                                                onEdit(group.id)
                                                            }
                                                            title="Edit"
                                                        />
                                                    )}
                                                    {!!onDelete && (
                                                        <i
                                                            className="icon-delete"
                                                            onClick={() =>
                                                                onDelete(
                                                                    group.id
                                                                )
                                                            }
                                                            title="Delete"
                                                        />
                                                    )}
                                                </div>
                                            </h4>
                                            {Object.keys(group.details).map(
                                                (detail) => (
                                                    <p
                                                        key={`${group.id}-${detail}`}
                                                    >
                                                        {detail}
                                                    </p>
                                                )
                                            )}
                                        </div>
                                    </AccordionContent>
                                </div>
                            );
                        })
                    ) : (
                        <p style={{ marginLeft: '2rem' }}>No data available.</p>
                    )}
                </div>
            </AccordionContent>
        </AccordionGroup>
    );
}

export default GroupDetails;
