import { DropdownOption } from '../../state';

export const LOCALE_OPTIONS = {
  ENGINES: [
    { value: 'Google', label: 'Google' },
    { value: 'Yahoo', label: 'Yahoo' },
    { value: 'Bing', label: 'Bing' },
  ],

  DEVICES: [
    { value: 'Desktop', label: 'Desktop' },
    { value: 'Smartphone', label: 'Smartphone' },
  ],

  COUNTRIES: [
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'New Caledonia', label: 'New Caledonia' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Guadeloupe', label: 'Guadeloupe' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Chile', label: 'Chile' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'India', label: 'India' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Finland', label: 'Finland' },
    { value: 'Taiwan', label: 'Taiwan' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Hong Kong', label: 'Hong Kong' },
    { value: 'United States', label: 'United States' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Bosnia', label: 'Bosnia' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'French Polynesia', label: 'French Polynesia' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Macedonia', label: 'Macedonia' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'French Guiana', label: 'French Guiana' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'China', label: 'China' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Martinique', label: 'Martinique' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'France', label: 'France' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Korea', label: 'Korea' },
    { value: 'Reunion', label: 'Reunion' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Philippines', label: 'Philippines' },
    { value: "Cote d'Ivoire", label: "Cote d'Ivoire" },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
    { value: 'Indonesia', label: 'Indonesia' },
    // ADDED OCT 04, 2023
    { value: 'Iceland', label: 'Iceland' },
    { value: 'Kenya', label: 'Kenya' },
  ],

  LANGUAGES: [
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Armenian', label: 'Armenian' },
    { value: 'Azerbaijani', label: 'Azerbaijani' },
    { value: 'Bulgarian', label: 'Bulgarian' },
    { value: 'Catalan', label: 'Catalan' },
    { value: 'Chinese (simplified)', label: 'Chinese (simplified)' },
    { value: 'Chinese (traditional)', label: 'Chinese (traditional)' },
    { value: 'Czech', label: 'Czech' },
    { value: 'Danish', label: 'Danish' },
    { value: 'Dutch', label: 'Dutch' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Hebrew', label: 'Hebrew' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Hungarian', label: 'Hungarian' },
    { value: 'Indonesian', label: 'Indonesian' },
    { value: 'Italian', label: 'Italian' },
    { value: 'Japanese', label: 'Japanese' },
    { value: 'Kazakh', label: 'Kazakh' },
    { value: 'Korean', label: 'Korean' },
    { value: 'Malay', label: 'Malay' },
    { value: 'Norwegian', label: 'Norwegian' },
    { value: 'Polish', label: 'Polish' },
    { value: 'Portuguese', label: 'Portuguese' },
    { value: 'Romanian', label: 'Romanian' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Slovene', label: 'Slovene' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Swedish', label: 'Swedish' },
    { value: 'Thai', label: 'Thai' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Ukrainian', label: 'Ukrainian' },
    { value: 'Urdu', label: 'Urdu' },
    { value: 'Uzbek', label: 'Uzbek' },
    { value: 'Vietnamese', label: 'Vietnamese' },
  ],
};

LOCALE_OPTIONS.COUNTRIES.sort(sortOptions);

LOCALE_OPTIONS.LANGUAGES.sort(sortOptions);

function sortOptions(a: DropdownOption, b: DropdownOption) {
  if (a.value < b.value) {
    return -1;
  }
  return 1;
}
