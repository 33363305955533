import { convertDateToString } from '..';
import { PUTEditReportBody, ScheduledReport } from '../../types';
import {
    POSTCreateReportState,
    ReportFormState,
    StatusType,
} from '../../state';

export const generateCreateReportBody = (
    reportState: ReportFormState,
    report_request_params: string
) => {
    const studyId = reportState.study?.id;

    if (!studyId || !reportState.report_type || !reportState.start_date) {
        return;
    }

    const study_id = typeof studyId === 'number' ? studyId : parseInt(studyId);
    const body: POSTCreateReportState = {
        study_id,
        study_type: reportState.study_type,
        report_type: reportState.report_type,
        file_type: reportState.file_type,
        csv_email_report_recipients: reportState.email_recipients.join(','),
        report_request_params,
        schedule: {
            start_date: convertDateToString(reportState.start_date),
            end_date: reportState.end_date
                ? convertDateToString(reportState.end_date)
                : '',
            occurrence: reportState.occurrence,
        },
    };

    return body;
};

export const generateEditReportBody = (
    reportState: ReportFormState,
    report_request_params: string,
    originalReport: ScheduledReport
) => {
    const studyId = reportState.study?.id;

    if (!studyId || !reportState.report_type || !reportState.start_date) {
        return;
    }

    const study_id = typeof studyId === 'number' ? studyId : parseInt(studyId);
    const body: PUTEditReportBody = {
        account_id: originalReport.account_id,
        report_id: originalReport.report_id,
        email: originalReport.email,
        study_id,
        study_type: reportState.study_type,
        report_type: reportState.report_type,
        file_type: reportState.file_type,
        csv_email_report_recipients: reportState.email_recipients.join(','),
        report_request_params,
        schedule: {
            start_date: convertDateToString(reportState.start_date),
            end_date: reportState.end_date
                ? convertDateToString(reportState.end_date)
                : '',
            occurence: reportState.occurrence,
        },
        status: originalReport.status as StatusType,
    };

    return body;
};
