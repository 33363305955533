// CONSTANTS
import { REPORT_TOOLTIPS } from '../../tooltips';

// TYPES
import {
  ReportFilters,
  ReportFormState,
  ReportFiltersState,
  ReportFiltersAction,
  REPORT_FILTER_ACTION_TYPES,
} from '../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-grid';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';
import { useEffect } from 'react';

// COMPONENT PROPS
interface FilterCheckboxesProps {
  onDemand?: boolean;
  state: ReportFiltersState;
  baseState: ReportFormState;
  enabledFilters: ReportFilters;
  dispatch: React.Dispatch<ReportFiltersAction>;
  disabled?: boolean;
}

const msPerDay = 24 * 60 * 60 * 1000;

// FUNCTIONAL COMPONENT (REPORT FILTER CHECKBOXES)
function ReportFilterCheckboxes({
  enabledFilters,
  state,
  dispatch,
  onDemand,
  baseState,
  disabled = false,
}: FilterCheckboxesProps) {
  // CONVERT START/END DATES
  let startTime = baseState.start_date
    ? baseState.start_date.getTime()
    : new Date().getTime();

  let endTime = baseState.end_date
    ? baseState.end_date.getTime()
    : new Date().getTime();

  // CALC NUMBER OF DAYS FOR START/END DATES
  startTime = Math.floor(startTime / msPerDay);
  endTime = Math.floor(endTime / msPerDay);

  const isMoreThanDay =
    (!onDemand && baseState.occurrence !== 'daily') || endTime - startTime >= 1;

  const isMoreThanMonth = onDemand && endTime - startTime >= 31;

  // CLEAR DAILY/HOURLY IF MORE THAN THRESHOLDS
  useEffect(() => {
    if ((isMoreThanDay || isMoreThanMonth) && state.hourly) {
      dispatch({
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY,
      });
    }

    if (isMoreThanMonth && state.daily) {
      dispatch({
        type: REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY,
      });
    }
  }, [isMoreThanDay, isMoreThanMonth, state.hourly, state.daily, dispatch]);

  return (
    <>
      {/* --- ZERO COVERAGE --- */}
      {enabledFilters.zero_coverage !== undefined && (
        <Column>
          <Checkbox
            className={'filter-checkbox'}
            checked={state.zero_coverage}
            onChange={() =>
              dispatch({
                type: REPORT_FILTER_ACTION_TYPES.TOGGLE_ZERO_COVERAGE,
              })
            }
            disabled={state.auto_competitor_group || disabled}
            value={state.zero_coverage}
            invert
          >
            <CustomTooltip
              content={REPORT_TOOLTIPS.ZERO_COVERAGE || ''}
              align={TOOLTIP_ALIGN.LEFT}
            >
              Zero Coverage
            </CustomTooltip>
          </Checkbox>
        </Column>
      )}

      {/* --- COMPARE PREVIOUS --- */}
      {enabledFilters.compare_previous !== undefined && (
        <Column>
          <Checkbox
            className={'filter-checkbox'}
            checked={state.compare_previous}
            onChange={() =>
              dispatch({
                type: REPORT_FILTER_ACTION_TYPES.TOGGLE_COMPARE_PREVIOUS,
              })
            }
            disabled={state.auto_competitor_group || disabled}
            value={state.compare_previous}
            invert
          >
            <CustomTooltip
              content={REPORT_TOOLTIPS.COMPARE_PREVIOUS || ''}
              align={TOOLTIP_ALIGN.LEFT}
            >
              Compare Previous
            </CustomTooltip>
          </Checkbox>
        </Column>
      )}

      {/* --- AUTO COMPETITOR GROUP --- */}
      {enabledFilters.auto_competitor_group !== undefined && (
        <Column>
          <Checkbox
            className={'filter-checkbox'}
            checked={state.auto_competitor_group}
            onChange={() =>
              dispatch({
                type: REPORT_FILTER_ACTION_TYPES.TOGGLE_AUTO_COMPETITOR_GROUP,
              })
            }
            value={state.auto_competitor_group}
            disabled={
              disabled ||
              state.daily ||
              state.hourly ||
              state.zero_coverage ||
              state.compare_previous ||
              !!state.competitors?.length ||
              !!state.competitor_group?.length
            }
            invert
          >
            <CustomTooltip
              content={REPORT_TOOLTIPS.AUTO_COMPETITOR_GROUP || ''}
              align={TOOLTIP_ALIGN.LEFT}
            >
              Auto Competitor Group
            </CustomTooltip>
          </Checkbox>
        </Column>
      )}

      {/* --- DAILY REPORTING --- */}
      {enabledFilters.daily !== undefined && (
        <Column>
          <Checkbox
            className={'filter-checkbox'}
            checked={state.daily}
            onChange={() =>
              dispatch({
                type: REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY,
              })
            }
            disabled={
              disabled || state.auto_competitor_group || isMoreThanMonth
            }
            value={state.daily}
            invert
          >
            <CustomTooltip
              content={REPORT_TOOLTIPS.DAILY || ''}
              align={TOOLTIP_ALIGN.LEFT}
            >
              Daily Reporting
            </CustomTooltip>
          </Checkbox>
        </Column>
      )}

      {/* --- HOURLY REPORTING --- */}
      {enabledFilters.hourly !== undefined && (
        <Column>
          <Checkbox
            className={'filter-checkbox'}
            checked={state.hourly}
            onChange={() =>
              dispatch({
                type: REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY,
              })
            }
            disabled={disabled || state.auto_competitor_group || isMoreThanDay}
            value={state.hourly}
            invert
          >
            <CustomTooltip
              content={REPORT_TOOLTIPS.HOURLY || ''}
              align={TOOLTIP_ALIGN.LEFT}
            >
              Hourly Reporting
            </CustomTooltip>
          </Checkbox>
        </Column>
      )}
    </>
  );
}

export default ReportFilterCheckboxes;
