import './Login.scss';

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useActions, useAppSelector } from '../../hooks';

// CONSTANTS
import { ROUTES } from '../../router';
import logo from '../../assets/img/benchtools-logo.png';
import { BRAND_NAME, isProduction } from '../../constants';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';

// FUNCTIONAL COMPONENT
function Login() {
  // HOOKS
  const navigate = useNavigate();

  // REDUX STATE
  const { isAuthenticated } = useAppSelector(({ auth }) => auth);

  // REDUX ACTIONS
  const { loginUser } = useActions();

  // STATE
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.HOME);
    }
  }, [isAuthenticated, navigate]);

  // HANDLE LOGIN SUBMIT
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    loginUser(email, password);
  };

  const appEnv = process.env.REACT_APP_ENV;
  console.log('APP_ENV:', appEnv);

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="login-logo-container">
          {isProduction ? (
            <img src={logo} alt="Benchtools logo" className="login-logo" />
          ) : (
            <h1 className="brand-name-title">{BRAND_NAME}</h1>
          )}
        </div>
        <p className="login-content-text">
          A tool that gives agencies the marketplace visibility they need to
          make smart, data-informed decisions for their clients.
        </p>
        <form className="login-content-form" onSubmit={handleSubmit}>
          <div className="login-inputs">
            <div className="login-input">
              <Textbox
                type="email"
                id="login-email"
                placeholder="user@email.com"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                required
              >
                Email:
              </Textbox>
            </div>
            <div className="login-input">
              <Textbox
                type="password"
                id="login-password"
                placeholder="password"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                required
              >
                Password:
              </Textbox>
              <div className="forgot-password">
                <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <Button display="primary" type="submit">
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
