import produce from 'immer';
import { sortDropdownOptions } from '../../../utils';
import { DropdownOption } from '../../models';

import {
    UserOnboardingState,
    UserOnboardingAction,
    USER_ONBOARDING_ACTION_TYPES,
} from '../models';

export const DEFAULT_USER_FORM_STATE: UserOnboardingState = {
    accountOptions: [],
    accounts: [],
    clientOptions: [],
    clients: [],
    role: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: '',
    clientOptionsByAccountId: {},
};

export const userOnboardingReducer = produce(
    (
        state: UserOnboardingState,
        action: UserOnboardingAction
    ): UserOnboardingState => {
        switch (action.type) {
            case USER_ONBOARDING_ACTION_TYPES.LOAD_ACCOUNT_OPTIONS: {
                state.clientOptionsByAccountId = action.payload.reduce(
                    (clients: { [key: string]: DropdownOption[] }, account) => {
                        clients[account.id.toString()] = account?.clients?.map(
                            (client) => ({
                                label: client.name,
                                value: client.id,
                            })
                        );
                        return clients;
                    },
                    {}
                );

                const accountOptions = action.payload.map((account) => ({
                    label: account.name + ` (ID: ${account.id})`,
                    value: account.id,
                }));

                state.accountOptions = sortDropdownOptions(accountOptions);

                return state;
            }

            case USER_ONBOARDING_ACTION_TYPES.SET_FIRST_NAME:
                state.first_name = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_LAST_NAME:
                state.last_name = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_EMAIL:
                state.email = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_PASSWORD:
                state.password = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_CONFIRM_PASSWORD:
                state.confirmPassword = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_ROLE:
                state.role = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.SET_ACCOUNTS: {
                // GET AVAILABLE CLIENTS BASED ON SELECTED ACCOUNT (UNIQUE CLIENTS ONLY)
                const clientsObj: { [key: string]: string } = {};
                for (const { value: accountId } of action.payload) {
                    if (state.clientOptionsByAccountId[accountId]) {
                        for (const { label, value } of state
                            .clientOptionsByAccountId[accountId] || []) {
                            if (!clientsObj[label]) {
                                clientsObj[label] = value.toString();
                            }
                        }
                    }
                }

                // CREATE & STORE CLIENT DROPDOWN OPTIONS (SORT: DESC)
                state.clientOptions = sortDropdownOptions(
                    Object.entries(clientsObj).map(([label, value]) => ({
                        label,
                        value,
                    }))
                );

                // STORE SELECTED ACCOUNTS
                state.accounts = action.payload;
                return state;
            }

            case USER_ONBOARDING_ACTION_TYPES.SET_CLIENTS:
                state.clients = action.payload;
                return state;

            case USER_ONBOARDING_ACTION_TYPES.RESET_FORM:
                state = { ...DEFAULT_USER_FORM_STATE };
                return state;

            default:
                return state;
        }
    },
    DEFAULT_USER_FORM_STATE
);
