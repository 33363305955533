import {
    AlertErrorAction,
    AlertSuccessAction,
    ClearAllAlertsAction,
    ClearErrorAlertAction,
    ClearSuccessAlertAction,
} from '../actions';
import { ALERTS_ACTION_TYPES } from '../action-types';

export const alertSuccess = (message: string): AlertSuccessAction => {
    return {
        type: ALERTS_ACTION_TYPES.ALERT_SUCCESS,
        payload: {
            message,
        },
    };
};

export const alertError = (message: string): AlertErrorAction => {
    return {
        type: ALERTS_ACTION_TYPES.ALERT_ERROR,
        payload: {
            message,
        },
    };
};

export const clearSuccessAlert = (): ClearSuccessAlertAction => {
    return {
        type: ALERTS_ACTION_TYPES.CLEAR_SUCCESS_ALERT,
    };
};

export const clearErrorAlert = (): ClearErrorAlertAction => {
    return {
        type: ALERTS_ACTION_TYPES.CLEAR_ERROR_ALERT,
    };
};

export const clearAllAlerts = (): ClearAllAlertsAction => {
    return {
        type: ALERTS_ACTION_TYPES.CLEAR_ALL_ALERTS,
    };
};
