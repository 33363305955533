import './Forgot-Password.scss';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useActions, useAsyncWrapper } from '../../hooks';

// CONSTANTS
import { ROUTES } from '../../router';

// API REQUESTS
import { submitForgotPassword } from '../../api';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import PageContainer from '../../components/Page-Container/Page-Container';

// FUNCTIONAL COMPONENT (FORGOT PASSWORD)
function ForgotPassword() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // FORM STATE
    const [emailInput, setEmailInput] = useState('');

    const onEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setEmailInput(evt.target.value);
    };

    const onSubmit = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        const successMessage = await submitForgotPassword(emailInput);

        alertSuccess(successMessage);
    });

    return (
        <PageContainer title="forgot password">
            <div className="forgot-password-container">
                <div className="forgot-password-content">
                    <p>
                        A link will be sent to the email address associated with
                        your account.
                    </p>
                    <form className="forgot-password-form" onSubmit={onSubmit}>
                        <Textbox
                            type="email"
                            value={emailInput}
                            onChange={onEmailChange}
                            placeholder={'user@email.com'}
                            error="Please enter a valid email"
                            required
                        >
                            Enter Email:
                        </Textbox>
                        <div className="form-buttons">
                            <Link
                                to={ROUTES.LOGIN}
                                className="form-button-login"
                            >
                                Back to login
                            </Link>
                            <Button
                                type="submit"
                                className="form-button-submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </PageContainer>
    );
}

export default ForgotPassword;
