export enum INSIGHTS_ACTION_TYPES {
    LOAD_OPTIONS = 'load_options',
    SET_START_DATE = 'set_start_date',
    SET_END_DATE = 'set_end_date',
    SET_COMPETITORS_GROUP = 'set_competitors_group',
    SET_COMPETITORS = 'set_competitors',
    SET_KEYWORDS_GROUP = 'set_keywords_group',
    SET_KEYWORDS = 'set_keywords',
    SET_AD_TYPE = 'set_ad_type',
    SET_TARGETS = 'set_targets',
    RESET_INSIGHTS = 'reset_insights',
}
