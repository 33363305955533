import { useNavigate } from 'react-router-dom';
import { useActions, useAsyncWrapper } from '../../hooks';
import React, { useEffect, useReducer, useState } from 'react';

// FORM STATE
import { initialNewStudyState } from './create-study-state/model';
import { createStudyReducer } from './create-study-state/reducers';

// UI COMPONENT IMPORTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import GroupDetails from '../../components/Group-Details/Group-Details';
import LoadingModal from '../../components/Loading-Modal/Loading-Modal';
import WarningModal from '../../components/Warning-Modal/Warning-Modal';
import { GroupData } from '../../components/Group-Content/Group-Content';
import CustomTooltip from '../../components/Custom-Tooltip/Custom-Tooltip';
import LocaleDetails from '../../components/Locale-Details/Locale-Details';
import PageContainer from '../../components/Page-Container/Page-Container';
import NewGroupModal from '../../components/New-Group-Modal/New-Group-Modal';
import NewLocaleModal from '../../components/New-Locale-Modal/New-Locale-Modal';
import EditGroupModal from '../../components/Edit-Group-Modal/Edit-Group-Modal';

// // TYPES
import {
    GroupUpdates,
    NewLocaleState,
    AccountListItem,
    FREQUENCY_OPTIONS,
    defaultNewGroupState,
    CreateStudyActionTypes,
    defaultLocaleModalState,
    defaultWarningModalState,
    defaultEditGroupModalState,
} from '../../types';
import { DropdownOption } from '../../state';

// // UTILIS IMPORTS
import {
    swapGroupKeyValues,
    swapGroupKeyValuesBack,
    formatNewLocaleDetails,
    generateCreateStudyBody,
    sortDropdownOptions,
} from '../../utils';

// CONSTANTS
import { ROUTES } from '../../router';
import { STUDY_TOOLTIPS } from '../../tooltips';

// API REQUESTS
import { createStudy, getAccountList } from '../../api';

// FUNCTION COMPONENT
function CreateStudy() {
    // HOOKS
    const navigate = useNavigate();
    const wrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { alertError, alertSuccess } = useActions();

    // FORM STATE
    const [newStudyState, dispatchNewStudy] = useReducer(
        createStudyReducer,
        initialNewStudyState
    );

    // USER'S LIST OF ASSOCIATED ACCOUNTS
    const [accountsList, setAccountsList] = useState<AccountListItem[]>([]);

    // MODAL STATES
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [addLocaleModal, setAddLocaleModal] = useState(
        defaultLocaleModalState
    );
    const [showNewGroupModal, setShowNewGroupModal] =
        useState(defaultNewGroupState);
    const [editGroupModal, setEditGroupModal] = useState(
        defaultEditGroupModalState
    );
    const [warningModal, setWarningModal] = useState(defaultWarningModalState);

    // ACCOUNT DROPDOWN OPTIONS
    const accountOptions: DropdownOption[] = accountsList.map((account) => {
        return {
            label: account.name,
            value: account.id,
        };
    });

    // CLIENT DROPDOWN OPTIONS
    const clientOptions: DropdownOption[] =
        accountsList
            .find((account) => account.id === newStudyState.account_id?.value)
            ?.clients?.map((client) => {
                return {
                    label: client.name,
                    value: client.id,
                };
            }) || [];

    // DISABLE CREATE BUTTON
    const createBtnIsDisabled =
        !newStudyState.name.trim() ||
        !newStudyState.frequency ||
        !newStudyState.account_id ||
        !newStudyState.client_id ||
        !newStudyState.locale.length ||
        !newStudyState.keyword_group.length;

    // FETCH AND STORE USER'S ACCOUNTS LIST
    const loadAccounts = wrapper(async function () {
        const accounts = await getAccountList();
        setAccountsList(accounts);
    });

    // FETCH USER ACCOUNTS
    useEffect(() => {
        loadAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // HANDLE NEW LOCALE CLICK
    const onNewLocale = () => {
        setAddLocaleModal((prev) => {
            return { ...prev, display: true };
        });
    };

    // HANDLE SELECT ACCOUNT ID
    const onSelectAccountID = (selected: { label: string; value: number }) => {
        const account_id = selected.value;

        if (!account_id) {
            return;
        }
        dispatchNewStudy({
            type: CreateStudyActionTypes.UPDATE_ACCOUNT_ID,
            value: selected,
        });
    };

    // HANDLE ADD NEW LOCALE
    const onAddNewLocale = (locale: NewLocaleState) => {
        dispatchNewStudy({
            type: CreateStudyActionTypes.ADD_LOCALE,
            value: { ...locale, id: Math.floor(Math.random() * 100000) },
        });
    };

    // HANDLE DUPLICATE EXISTING LOCALE
    const onDuplicateExistingLocale = (id: number | string) => {
        const locale =
            newStudyState.locale.find((locale) => locale.id === id) || null;
        setAddLocaleModal({ display: true, details: locale || null });
    };

    // HANDLE DELETE LOCALE
    const onDeleteLocale = (id: number | string) => {
        const onConfirm = () => {
            dispatchNewStudy({
                type: CreateStudyActionTypes.DELETE_LOCALE,
                value: id,
            });
        };
        setWarningModal({
            display: true,
            header: 'Delete Local',
            content: 'Are you sure you want to delete this locale?',
            onConfirm,
        });
    };

    // HANDLE NEW KEYWORD GROUP
    const onAddKeywordGroup = (groupName: string, keywords: string[]) => {
        const newGroupName = groupName.trim();
        const existingGroup = newStudyState.keyword_group.find(
            (group) => group.name === newGroupName
        );
        if (existingGroup) {
            alertError('Duplicate group name not allowed.');
            return;
        }
        const id = Math.floor(Math.random() * 100000);
        const newGroup: GroupData = {
            id,
            name: newGroupName,
            details: keywords.reduce((prev, word) => {
                return { ...prev, [word]: Math.floor(Math.random() * 1000000) };
            }, {}),
        };
        dispatchNewStudy({
            type: CreateStudyActionTypes.ADD_KEYWORD_GROUP,
            value: newGroup,
        });
    };

    // HANDLE EDIT KEYWORD GROUP CLICK
    const onEditKeywordGroup = (id: string | number) => {
        const foundGroup = newStudyState.keyword_group.find(
            (group) => group.id === id
        );
        if (!foundGroup) {
            return;
        }

        setEditGroupModal({
            display: true,
            groupType: 'keyword',
            groupName: foundGroup.name,
            details: foundGroup.details,
            onSubmit: onUpdateKeywordGroup,
        });
    };

    // HANDLE UPDATE KEYWORD GROUP
    const onUpdateKeywordGroup = (groupName: string, updates: GroupUpdates) => {
        const groupIndex = newStudyState.keyword_group.findIndex(
            (group) => group.name === groupName
        );
        if (groupIndex < 0) {
            return;
        }
        const updatedGroup = { ...newStudyState.keyword_group[groupIndex] };

        // HANDLE DELETED KEYWORDS
        if (updates.deleted?.length) {
            const swappedDetails = swapGroupKeyValues(updatedGroup.details);
            updates.deleted.forEach((keyword_id) => {
                delete swappedDetails[keyword_id as number];
            });
            updatedGroup.details = swapGroupKeyValuesBack(swappedDetails);
        }
        // HANDLE ADDED KEYWORDS
        if (updates.added?.length) {
            const updatedKeywords = [
                ...Object.keys(updatedGroup.details),
                ...updates.added,
            ];
            updatedGroup.details = updatedKeywords.reduce(
                (prev, keyword, index) => {
                    return { ...prev, [keyword]: index };
                },
                {}
            );
        }
        // UPDATE KEYWORD GROUPS
        dispatchNewStudy({
            type: CreateStudyActionTypes.UPDATE_KEYWORD_GROUP,
            value: updatedGroup,
        });
    };

    // HANDLE DELETE KEYWORD GROUP
    const onDeleteKeywordGroup = (id: string | number) => {
        const onConfirm = () => {
            dispatchNewStudy({
                type: CreateStudyActionTypes.DELETE_KEYWORD_GROUP,
                value: id,
            });
        };
        setWarningModal({
            display: true,
            header: 'Delete Keyword Group:',
            content: 'Are you sure you want to delete this?',
            onConfirm,
        });
    };

    // HANDLE NEW COMPETITOR GROUP
    const onAddCompetitorGroup = (groupName: string, competitors: string[]) => {
        const newGroupName = groupName.trim();
        const existingGroup = newStudyState.competitor_group.find(
            (group) => group.name === newGroupName
        );
        if (existingGroup) {
            alertError('Duplicate group name not allowed.');
            return;
        }
        const id = Math.floor(Math.random() * 1000000);
        const newGroup: GroupData = {
            id,
            name: newGroupName,
            details: competitors.reduce((prev, word, index) => {
                return { ...prev, [word]: index };
            }, {}),
        };
        dispatchNewStudy({
            type: CreateStudyActionTypes.ADD_COMPETITOR_GROUP,
            value: newGroup,
        });
    };

    // HANDLE EDIT COMPETITOR GROUP CLICK
    const onEditCompetitorGroup = (id: string | number) => {
        const foundGroup = newStudyState.competitor_group.find(
            (group) => group.id === id
        );
        if (!foundGroup) {
            return;
        }
        setEditGroupModal({
            display: true,
            groupType: 'competitor',
            groupName: foundGroup.name,
            details: foundGroup.details,
            onSubmit: onUpdateCompetitorGroup,
        });
    };

    // HANDLE UPDATE COMPETITOR GROUP
    const onUpdateCompetitorGroup = (
        groupName: string,
        updates: GroupUpdates
    ) => {
        const groupIndex = newStudyState.competitor_group.findIndex(
            (group) => group.name === groupName
        );

        if (groupIndex < 0) {
            return;
        }
        const updatedGroup = { ...newStudyState.competitor_group[groupIndex] };

        // HANDLE DELETED COMPETITORS
        if (updates.deleted) {
            const swappedDetails = swapGroupKeyValues(updatedGroup.details);
            updates.deleted.forEach((competitor_id) => {
                delete swappedDetails[competitor_id as number];
            });
            updatedGroup.details = swapGroupKeyValuesBack(swappedDetails);
        }
        // HANDLE ADDED COMPETITORS
        if (updates.added) {
            const updatedCompetitors = [
                ...Object.keys(updatedGroup.details),
                ...updates.added,
            ];
            updatedGroup.details = updatedCompetitors.reduce(
                (prev, competitor, index) => {
                    return { ...prev, [competitor]: index };
                },
                {}
            );
        }
        // UPDATE COMPETITOR GROUPS
        dispatchNewStudy({
            type: CreateStudyActionTypes.UPDATE_COMPETITOR_GROUP,
            value: updatedGroup,
        });
    };

    // HANDLE DELETE COMPETITOR GROUP
    const onDeleteCompetitorGroup = (id: string | number) => {
        const onConfirm = () => {
            dispatchNewStudy({
                type: CreateStudyActionTypes.DELETE_COMPETITOR_GROUP,
                value: id,
            });
        };
        setWarningModal({
            display: true,
            header: 'Delete Competitor Group:',
            content: 'Are you sure you want to delete this?',
            onConfirm,
        });
    };

    // HANDLE SUBMIT STUDY UPDATE FORM
    const onSubmitUpdate = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (createBtnIsDisabled) {
            alertError('Please enter values for all required fields.');
            return;
        }

        const body = generateCreateStudyBody(newStudyState);

        const { study_id, message } = await createStudy(body);

        if (!study_id) {
            return;
        }

        alertSuccess(message);

        navigate('/studies/view/' + study_id);
    });

    // JSX
    return (
        <PageContainer title="create study">
            <form onSubmit={onSubmitUpdate} className="form-update-study">
                <Row>
                    <Column>
                        {/* --- STUDY NAME --- */}
                        <Textbox
                            placeholder="Enter study name"
                            value={newStudyState.name}
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                dispatchNewStudy({
                                    type: CreateStudyActionTypes.UPDATE_NAME,
                                    value: evt.target.value,
                                });
                            }}
                            required
                        >
                            Study Name:
                        </Textbox>
                    </Column>

                    <Column>
                        {/* --- FREQUENCY --- */}
                        <Dropdown
                            placeholder="Select frequency"
                            options={FREQUENCY_OPTIONS}
                            defaultValue={newStudyState.frequency}
                            value={newStudyState.frequency}
                            onChange={(
                                selected: (typeof FREQUENCY_OPTIONS)[0]
                            ) => {
                                dispatchNewStudy({
                                    type: CreateStudyActionTypes.UPDATE_FREQUENCY,
                                    value: selected,
                                });
                            }}
                            required
                        >
                            <CustomTooltip content={STUDY_TOOLTIPS.FREQUENCY}>
                                Frequency:
                            </CustomTooltip>
                        </Dropdown>
                    </Column>

                    <Column>
                        {/* --- ACCOUNT ID --- */}
                        <Dropdown
                            placeholder="Select account ID"
                            options={sortDropdownOptions(accountOptions)}
                            onChange={onSelectAccountID}
                            value={newStudyState.account_id || ''}
                            required
                        >
                            Account ID:
                        </Dropdown>
                    </Column>

                    <Column>
                        {/* --- CLIENT ID --- */}
                        <Dropdown
                            placeholder="Select client ID"
                            options={sortDropdownOptions(clientOptions)}
                            onChange={(selected: {
                                label: string;
                                value: number;
                            }) => {
                                dispatchNewStudy({
                                    type: CreateStudyActionTypes.UPDATE_CLIENT_ID,
                                    value: selected,
                                });
                            }}
                            value={newStudyState.client_id || ''}
                            required
                        >
                            Client ID:
                        </Dropdown>
                    </Column>
                </Row>

                <div style={{ marginTop: '2rem' }}>
                    <LocaleDetails
                        locales={newStudyState.locale.map((locale, index) =>
                            formatNewLocaleDetails(locale, index)
                        )}
                        onDelete={onDeleteLocale}
                        onDuplicate={onDuplicateExistingLocale}
                        onAdd={onNewLocale}
                    />
                </div>
                {!newStudyState.locale.length && (
                    <p
                        className="text-small text-muted"
                        style={{ marginLeft: '1rem', color: 'red' }}
                    >
                        (At least one locale is required)
                    </p>
                )}

                <div style={{ marginTop: '2rem' }}>
                    <GroupDetails
                        type="keyword"
                        data={newStudyState.keyword_group}
                        onDelete={onDeleteKeywordGroup}
                        onEdit={onEditKeywordGroup}
                        onAdd={() =>
                            setShowNewGroupModal({
                                groupType: 'keyword',
                                onSubmit: onAddKeywordGroup,
                            })
                        }
                    />
                </div>
                {!newStudyState.keyword_group.length && (
                    <p
                        className="text-small text-muted"
                        style={{ marginLeft: '1rem', color: 'red' }}
                    >
                        (At least one keyword group is required)
                    </p>
                )}

                <div style={{ marginTop: '2rem' }}>
                    <GroupDetails
                        type="competitor"
                        data={newStudyState.competitor_group}
                        onDelete={onDeleteCompetitorGroup}
                        onEdit={onEditCompetitorGroup}
                        onAdd={() =>
                            setShowNewGroupModal({
                                groupType: 'competitor',
                                onSubmit: onAddCompetitorGroup,
                            })
                        }
                    />
                </div>

                <div className="form-buttons">
                    <Button
                        className="btn-cancel"
                        color="red"
                        onClick={() => navigate(ROUTES.STUDIES)}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" disabled={createBtnIsDisabled}>
                        Create Study
                    </Button>
                </div>
            </form>

            {/* === MODALS === */}
            {showLoadingModal && (
                <LoadingModal
                    header="creating study..."
                    onClose={() => setShowLoadingModal(false)}
                />
            )}
            {addLocaleModal.display && (
                <NewLocaleModal
                    onClose={() => setAddLocaleModal(defaultLocaleModalState)}
                    onSubmit={onAddNewLocale}
                    details={addLocaleModal.details || null}
                />
            )}
            {warningModal.display && (
                <WarningModal
                    header={warningModal.header}
                    content={warningModal.content}
                    onConfirm={warningModal.onConfirm}
                    onClose={() => setWarningModal(defaultWarningModalState)}
                />
            )}
            {editGroupModal.display && editGroupModal.onSubmit && (
                <EditGroupModal
                    groupType={editGroupModal.groupType || 'keyword'}
                    groupName={editGroupModal.groupName || ''}
                    details={editGroupModal.details || {}}
                    onClose={() =>
                        setEditGroupModal(defaultEditGroupModalState)
                    }
                    onSubmit={editGroupModal.onSubmit}
                />
            )}
            {showNewGroupModal.groupType && showNewGroupModal.onSubmit && (
                <NewGroupModal
                    groupType={showNewGroupModal.groupType}
                    onClose={() => setShowNewGroupModal(defaultNewGroupState)}
                    onSubmit={showNewGroupModal.onSubmit}
                />
            )}
        </PageContainer>
    );
}

export default CreateStudy;
