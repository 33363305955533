import './List-Scheduled-Reports.scss';

import { useState, useEffect } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../../hooks';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../../../constants';

// TYPES
import {
    ScheduledReport,
    ScheduledReportRow,
    DEFAULT_STATUS_MODAL_STATE,
} from '../../../types';
import { StatusType, StudyListItem } from '../../../state';

// UI COMPONENT IMPORTS
import WarningModal from '../../../components/Warning-Modal/Warning-Modal';
import PageContainer from '../../../components/Page-Container/Page-Container';
import ScheduledReportsTable from '../Scheduled-Reports-Table/Scheduled-Reports-Table';

// API REQUESTS
import {
    getStudyList,
    updateReportStatus,
    getScheduledReports,
} from '../../../api';

// FUNCTION COMPONENT (SCHEDULED REPORTS PAGE)
function ListScheduledReports() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // REDUX STATE
    const email = useAppSelector(({ auth }) => auth.user?.email);

    // REDUX ACTIONS
    const { alertSuccess, alertError } = useActions();

    // STUDY LIST
    const [studyList, setStudyList] = useState<StudyListItem[] | null>(null);

    // SCHEDULED REPORTS STATES
    const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>(
        []
    );

    // STATUS UPDATE MODAL
    const [showStatusWarningModal, setShowStatusWarningModal] = useState(
        DEFAULT_STATUS_MODAL_STATE
    );

    const loadPageData = wrapper(async () => {
        if (!email) {
            return;
        }

        await Promise.all([getStudyList(), getScheduledReports(email)]).then(
            ([studies, { report_list, error_message }]) => {
                setStudyList(studies);

                if (report_list) {
                    setScheduledReports(report_list);
                } else {
                    throw new Error(error_message);
                }
            }
        );
    });

    // FETCH SCHEDULED REPORTS AND STUDY LIST ON INITIAL RENDER
    useEffect(() => {
        loadPageData();
    }, []);

    // HANDLE STATUS UPDATE
    const onUpdateStatus = ({
        status,
        end_date,
        report_id,
    }: ScheduledReportRow) => {
        const endDate =
            end_date && end_date !== 'NA' ? new Date(end_date) : null;

        if (endDate && endDate < new Date()) {
            alertError(
                `Please remove or update the scheduled report's "End Date" before proceeding.`
            );
            return;
        }

        const updatedStatus: StatusType =
            status === 'Active' ? 'Suspended' : 'Active';

        const statusAction =
            updatedStatus === 'Active' ? 'Activate' : 'Suspend';

        const onConfirm = wrapper(async () => {
            if (!email || !studyList) {
                return;
            }

            const { message } = await updateReportStatus(
                report_id,
                updatedStatus
            );

            if (!message) {
                throw new Error('Unable to update report status.');
            }

            alertSuccess(message);

            const { report_list, error_message } = await getScheduledReports(
                email
            );

            if (report_list) {
                setScheduledReports(report_list);
            } else {
                throw new Error(error_message || DEFAULT_API_ERROR);
            }
        });

        // UPDATE REPORT STATUS AND RELOAD REPORTS LIST
        setShowStatusWarningModal({
            display: true,
            action: statusAction,
            onConfirm,
        });
    };

    // JSX
    return (
        <PageContainer title="scheduled reports">
            {/* --- SCHEDULED REPORTS TABLE --- */}
            <ScheduledReportsTable
                data={scheduledReports}
                studies={studyList || []}
                onUpdateStatus={onUpdateStatus}
            />

            {/* --- UPDATE REPORT STATUS MODAL --- */}
            {showStatusWarningModal.display &&
                showStatusWarningModal.onConfirm && (
                    <WarningModal
                        header={`${showStatusWarningModal.action} report:`}
                        content="Are you sure want to continue?"
                        onClose={() =>
                            setShowStatusWarningModal(
                                DEFAULT_STATUS_MODAL_STATE
                            )
                        }
                        onConfirm={showStatusWarningModal.onConfirm}
                    />
                )}
        </PageContainer>
    );
}

export default ListScheduledReports;
