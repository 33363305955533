import './App.scss';

import {
  userRoutes,
  adminRoutes,
  publicRoutes,
  privlagedRoutes,
} from './router';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useActions, useAppSelector } from './hooks';

import Navbar from './components/NavBar/Navbar';
import AdminRoutes from './components/AdminRoutes';
import PrivateRoutes from './components/PrivateRoutes';
import PrivlagedRoutes from './components/PrivlagedRoutes';
import LoadingModal from './components/Loading-Modal/Loading-Modal';
import AlertMessages from './components/Alert-Messages/Alert-Messages';
import TooltipsButton from './components/Tooltips-Button/Tooltips-Button';

// FUNCTIONAL COMPONENT (MAIN APP)
function App() {
  const user = useAppSelector(({ auth }) => auth.user);
  // REDUX ACTIONS
  const { loadUserAgencies } = useActions();

  // LOAD STORED USER (IF APPLICABLE)
  useEffect(() => {
    if (user && user?.agenciesInfo === undefined) {
      loadUserAgencies(user);
    }
  }, [user]);

  // JSX
  return (
    <main>
      <Navbar />
      <TooltipsButton />
      <AlertMessages />
      <Routes>
        <Route element={<PrivateRoutes />}>
          {userRoutes.map((route, i) => {
            return <Route {...route} key={i} />;
          })}

          <Route element={<PrivlagedRoutes />}>
            {privlagedRoutes.map((route, i) => {
              return <Route {...route} key={i} />;
            })}
          </Route>

          <Route element={<AdminRoutes />}>
            {adminRoutes.map((route, i) => {
              return <Route {...route} key={i} />;
            })}
          </Route>
        </Route>
        {publicRoutes.map((route, i) => {
          return <Route {...route} key={i} />;
        })}
        ;
      </Routes>
      <LoadingModal />
    </main>
  );
}

export default App;
