import React from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { KeywordsReportData } from '../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';

// UTILS
import { formatLocationAnalysis } from '../../utils';

// COMPONENT PROPS
interface LocationAnalysisProps {
    data: KeywordsReportData;
    studyName: string;
}

// FUNCTIONAL COMPONENT
function LocationAnalysis({ data, studyName }: LocationAnalysisProps) {
    const [formatted, brands] = formatLocationAnalysis(data);

    const chartData = Object.keys(formatted).reduce(
        (acc: { [key: string]: number[] }, location) => {
            for (const brand of brands) {
                let brandAverage = 0;

                if (formatted[location][brand]) {
                    const { totalValues, numValues } =
                        formatted[location][brand];
                    brandAverage = Math.round(totalValues / numValues);
                }

                // SET BRAND TO EMPTY ARRAY IF UNDEFINED IN CHART DATA
                if (!acc[brand]) {
                    acc[brand] = [];
                }

                acc[brand].push(brandAverage);
            }

            return acc;
        },
        {}
    );

    const series = Object.entries(chartData).map(([name, data]) => {
        return {
            name,
            data,
        };
    });

    // CHART OPTIONS
    const options: ApexOptions = {
        chart: {
            offsetX: 0,
            offsetY: 0,
            toolbar: {
                tools: {
                    download: true,
                },
                export: {
                    svg: {
                        filename: `${studyName}__location-analysis`,
                    },
                    png: {
                        filename: `${studyName}__location-analysis`,
                    },
                },
            },
        },
        title: {
            text: 'Location Analysis',
        },
        noData: {
            text: 'No data found',
            style: {
                fontSize: '1.5rem',
            },
        },
        yaxis: {
            max: 100,
            title: {
                text: 'Coverage (%)',
            },
        },
        xaxis: {
            categories: Object.keys(formatted),
        },
        tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                return `
                     <div class="pmds-chart-apex__tooltip">
                        <p>
                        <strong>
                        ${w.config.series[seriesIndex].name}
                        </strong>
                        </p>
                        <p>Coverage:
                        <strong>
                        ${w.config.series[seriesIndex].data[dataPointIndex]}%
                        </strong>
                        </p>   
                     </div>
                     `;
            },
        },
    };

    // FORMAT DATA FOR CHART

    return (
        <div className="location-analysis-chart-containter">
            <div className="location-analysis-chart-content">
                <Column
                    data={series}
                    options={options}
                    width="100%"
                    id="location-analysis"
                />
            </div>
        </div>
    );
}

export default React.memo(LocationAnalysis);
