export enum ADHOC_REPORT_ACTION_TYPES {
  SET_STUDY = 'set_study',
  SET_START_DATE = 'set_start_date',
  SET_END_DATE = 'set_end_date',
  SET_PRIOR_START_DATE = 'set_prior_start_date',
  SET_PRIOR_END_DATE = 'set_prior_end_date',
  SET_ZERO_COVERAGE = 'set_zero_coverage',
  SET_COMPARE_PREVIOUS = 'set_compare_previous',
  SET_AUTO_COMPETITOR_GROUP = 'set_auto_competitor_group',
  SET_DAILY_REPORTING = 'set_daily_reporting',
  SET_HOURLY_REPORTING = 'set_hourly_reporting',
  SET_COMPETITOR_GROUPS = 'set_competitor_groups',
  SET_COMPETITORS = 'set_competitors',
  SET_KEYWORD_GROUPS = 'set_keyword_groups',
  SET_KEYWORDS = 'set_keywords',
  SET_TARGETS = 'set_targets',
  SET_METRICS = 'set_metrics',
  SET_AD_TYPES = 'set_ad_types',
  SET_TIME_ZONE = 'set_zime_zone',
  SET_FOCUS = 'set_focus',
  SET_AUTO_COMPETITOR_LIMIT = 'set_auto_competitor_limit',
  SET_TOP_WORDS = 'set_top_words',
  LOAD_STATE = 'load_state',
  RESET_STATE = 'reset_state',
}
