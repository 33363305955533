import './Competitive-Insights.scss';

import { useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
    getKeywordsReport,
    getWordAnalysisBrands,
    getSERPVisibilityReport,
} from '../../api';

// TYPES
import {
    StudyDetails,
    InsightsState,
    KeywordsReportData,
    SERPVisibilityDataItem,
    WordAnalysisBrandsData,
} from '../../state';

// UI COMPONENTS
import WordCloud from '../Charts/Word-Cloud/Word-Cloud';
import InsightsFilter from '../Insights-Filter/Insights-Filter';
import BrandPresenceChart from '../Charts/Brand-Presence-Chart';
import LocationAnalysis from '../Charts/Location-Analysis-Chart';
import SERPVisibilityChart from '../Charts/SERP-Visibility-Chart';
import ExportChartsButton from '../Charts/Export-Charts-Button/Export-Charts-Button';

// COMPONENT PROPS
interface CompetitiveInsightsProps {
    study: StudyDetails;
    active: boolean;
}

// FUNCTIONAL COMPONENT
function CompetitiveInsights({ study, active }: CompetitiveInsightsProps) {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // STUDY NAME
    const studyName = study.name.split(' ').join('_').toLowerCase();

    // BRAND PRESENCE CHART DATA (KEYWORDS REPORT)
    const [keywordsData, setKeywordsData] = useState<KeywordsReportData>();

    // SERP VISIBILITY CHART DATA (SERP VISIBILITY REPORT)
    const [visibilityData, setVisibilityData] =
        useState<SERPVisibilityDataItem[]>();

    // WORD CLOUD DATA (WORD ANALYSIS REPORT)
    const [wordAnalysisData, setWordAnalysisData] =
        useState<WordAnalysisBrandsData>();

    // MOST RECENT FILTERS SELECTIONS
    const [recentFilters, setRecentFilters] = useState<InsightsState | null>(
        null
    );

    // HANDLE FETCHING REPORTS DATA
    const onGetReports = wrapper(async (filters: InsightsState) => {
        const studyId =
            typeof study.id === 'number' ? study.id : parseInt(study.id);

        await Promise.all([
            // FETCH KEYWORDS REPORT DATA
            getKeywordsReport(studyId, filters),

            // FETCH SERP VISIBILITY REPORT
            getSERPVisibilityReport(studyId, filters),

            // FETCH WORD ANALYSIS REPORT
            getWordAnalysisBrands(studyId, filters),
        ])
            .then(([keywordsReport, visibilityReport, wordAnalysisReport]) => {
                if (keywordsReport) {
                    setKeywordsData(keywordsReport.data || {});
                }

                if (visibilityReport) {
                    setVisibilityData(visibilityReport);
                }

                if (wordAnalysisReport) {
                    setWordAnalysisData(wordAnalysisReport.data || {});
                }

                setRecentFilters(filters);
            })
            .catch((error) => {
                setRecentFilters(null);
                throw error;
            });
    });

    return (
        <div className="competitive-insights-container">
            <InsightsFilter study={study} onSubmit={onGetReports} />
            <div className="competitive-insights-content">
                {keywordsData || visibilityData || wordAnalysisData ? (
                    <ExportChartsButton
                        studyName={studyName}
                        filters={recentFilters}
                    />
                ) : null}
                {!!keywordsData && (
                    <>
                        <BrandPresenceChart
                            data={keywordsData}
                            studyName={studyName}
                        />
                        <LocationAnalysis
                            data={keywordsData}
                            studyName={studyName}
                        />
                    </>
                )}
                {!!visibilityData && (
                    <SERPVisibilityChart
                        data={visibilityData}
                        studyName={studyName}
                    />
                )}
                {!!wordAnalysisData && active && (
                    <WordCloud data={wordAnalysisData} studyName={studyName} />
                )}
            </div>
        </div>
    );
}

export default CompetitiveInsights;
