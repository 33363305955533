import React from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { SERPVisibilityDataItem } from '../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';

// UTILS
import { formatDailySERPVisibilityByBrand } from '../../utils';

interface SERPVisibilityChartProps {
    data: SERPVisibilityDataItem[];
    studyName: string;
}

function SERPVisibilityChart({ data, studyName }: SERPVisibilityChartProps) {
    const [formattedData, brands] = formatDailySERPVisibilityByBrand(data);

    const dates = Object.keys(formattedData);

    const chartData = dates.reduce(
        (allData: { [key: string]: number[] }, date) => {
            for (const brand of brands) {
                let brandAverage = 0;

                if (formattedData[date][brand]) {
                    const { totalValues, numValues } =
                        formattedData[date][brand];
                    brandAverage = Math.round(totalValues / numValues);
                }

                // SET BRAND TO EMPTY ARRAY IF UNDEFINED IN CHART DATA
                if (!allData[brand]) {
                    allData[brand] = [];
                }

                allData[brand].push(brandAverage);
            }

            return allData;
        },
        {}
    );

    const series = Object.entries(chartData).map(([name, data]) => {
        return {
            name,
            data,
        };
    });

    const options: ApexOptions = {
        chart: {
            offsetX: 0,
            offsetY: 0,
            toolbar: {
                tools: {
                    download: true,
                },
                export: {
                    svg: {
                        filename: `${studyName}__serp-visibility`,
                    },
                    png: {
                        filename: `${studyName}__serp-visibility`,
                    },
                },
            },
        },
        dataLabels: {
            enabled: dates.length < 5 || series.length < 5,
        },
        title: {
            text: 'Average SERP Visibility (Daily)',
        },
        noData: {
            text: 'No data found',
            style: {
                fontSize: '1.5rem',
            },
        },
        yaxis: {
            labels: {},
            title: {
                text: 'Visibility (%)',
            },
        },
        xaxis: {
            categories: dates,
        },
        tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                return `
                     <div class="pmds-chart-apex__tooltip">
                        <p>
                        <strong>
                        ${w.config.series[seriesIndex].name}
                        </strong>
                        </p>
                        <p>Visibility:
                        <strong>
                        ${w.config.series[seriesIndex].data[dataPointIndex]}%
                        </strong>
                        </p>   
                     </div>
                     `;
            },
        },
    };

    return (
        <div className="serp-visibility-chart-containter">
            <div className="serp-visibility-chart-content">
                <Column
                    data={series}
                    options={options}
                    width="100%"
                    id="serp-visibility"
                />
            </div>
        </div>
    );
}

export default React.memo(SERPVisibilityChart);
