import React, { useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// TYPES
import {
    STATUS_OPTIONS,
    AccountFormState,
    StatusOptionsType,
} from '../../state';

// UITILS
import { onPreventEnterKeySubmit } from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// COMPONENT PROPS
interface AccountOnboardingFormProps {
    onSubmit: (formState: AccountFormState) => Promise<void>;
}

const DEFAULT_STATE: AccountFormState = {
    name: '',
    volume: '',
    status: STATUS_OPTIONS[0],
};

// FUNCTIONAL COMPONENT
function AccountOnboardingForm({ onSubmit }: AccountOnboardingFormProps) {
    const wrapper = useAsyncWrapper();

    // FORM STATE
    const [state, setState] = useState(DEFAULT_STATE);

    // ENABLE/DISABLE SUBMIT BUTTON
    const isSubmitDisabled = !state.name || !state.volume;

    // HANDLE ACCOUNT NAME INPUT
    const onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setState((prev) => ({ ...prev, name: value }));
    };

    // HANDLE SEARCH VOLUME INPUT
    const onChangeVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = evt.target;
        setState((prev) => ({ ...prev, volume: value }));
    };

    // HANDLE STATUS SELECTION
    const onSetStatus = (selection: StatusOptionsType) => {
        setState((prev) => ({ ...prev, status: selection }));
    };

    // HANDLE FORM SUBMISSION
    const onSubmitForm = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();

        await onSubmit(state);
        setState({ ...DEFAULT_STATE });
    });

    return (
        <div className="user-onboarding-form-container">
            <form onSubmit={onSubmitForm} onKeyDown={onPreventEnterKeySubmit}>
                <Row>
                    {/* FIRST NAME */}
                    <Column>
                        <Textbox
                            onChange={onChangeName}
                            value={state.name}
                            required
                        >
                            Account Name:
                        </Textbox>
                    </Column>

                    {/* LAST NAME */}
                    <Column>
                        <Textbox
                            onChange={onChangeVolume}
                            value={state.volume}
                            type="number"
                            required
                        >
                            Search Volume:
                        </Textbox>
                    </Column>

                    {/* ROLE */}
                    <Column>
                        <Dropdown
                            options={STATUS_OPTIONS}
                            onChange={onSetStatus}
                            value={state.status}
                            defaultValue={STATUS_OPTIONS[0]}
                            isSearchable={false}
                            required
                        >
                            Status:
                        </Dropdown>
                    </Column>
                </Row>

                {/* SUBMIT BUTTON */}
                <div className="form-buttons">
                    <Button type="submit" disabled={isSubmitDisabled}>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default AccountOnboardingForm;
