import './Modal-Footer-Buttons.scss';
import Button from '@publicismedia-ds/ui-button';

interface ModalFooterButtonsProps {
    cancelText?: string;
    confirmText?: string;
    onCancel?: () => void;
    onConfirm: () => void;
    disabled?: boolean;
}

function ModalFooterButtons({
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    onCancel,
    onConfirm,
    disabled,
}: ModalFooterButtonsProps) {
    return (
        <div className="custom-modal-footer">
            <Button
                className="btn-cancel"
                color="red"
                onClick={onCancel}
                style={{ color: 'white' }}
            >
                {cancelText}
            </Button>
            <Button onClick={onConfirm} disabled={disabled}>
                {confirmText}
            </Button>
        </div>
    );
}

export default ModalFooterButtons;
