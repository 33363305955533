import { LocaleDetailsState } from '../../state';
import { NewLocaleState } from '../../types/edit-study-types';

export const formatExistingLocaleDetails = (
    locale: LocaleDetailsState
): NewLocaleState => {
    return {
        device: locale.device,
        engine: locale.engine,
        language: locale.language,
        location: {
            city: locale.city,
            country: locale.location,
            county: locale.county,
            latitude: locale.latitude,
            longitude: locale.longitude,
            radius: locale.radius,
            region: locale.region,
            zip: locale.zip,
        },
    };
};

export const formatNewLocaleDetails = (
    locale: NewLocaleState,
    index: number
): LocaleDetailsState => {
    return {
        id: locale.id ? Number(locale.id) : index,
        device: locale.device,
        engine: locale.engine,
        language: locale.language,
        location: locale.location.country,
        city: locale.location.city,
        county: locale.location.county,
        latitude: locale.location.latitude,
        longitude: locale.location.longitude,
        radius: locale.location.radius,
        region: locale.location.region,
        zip: locale.location.zip,
    };
};
