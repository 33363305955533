import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';

// TYPES
import { AlertsTableItem, StatusType } from '../../state';

// CONSTANTS
import { ROUTES } from '../../router';

// UI COMPONENTS IMPORTS
import DataTable, {
    TD_TYPE,
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Tooltip from '@publicismedia-ds/ui-tooltip';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';

// API REQUESTS
import { onDownloadAlertDetails } from '../../api';

// COMPONENT PROPS
interface AlertsTableProps {
    data: AlertsTableItem[];
    onUpdateStatus?: (id: number, status: StatusType) => void;
    showButtons?: boolean;
    isEditable?: boolean;
    isViewable?: boolean;
}

// FUNCTIONAL COMPONENT (ALERTS TABLE)
function AlertsTable({
    data,
    onUpdateStatus,
    showButtons = true,
    isEditable = true,
    isViewable = true,
}: AlertsTableProps) {
    const [provider, setProvider] = useState<any>();

    // REDUX STATE
    const { user } = useAppSelector(({ auth }) => auth);

    // UPDATE DATA TABLE PROVIDER ON ALERTS LIST CHANGE
    useEffect(() => {
        const newProvider = new DataProvider({
            data: data.map((alertData) => {
                return {
                    ...alertData,
                    alertId: alertData.id.toString(),
                    studyId: alertData.study_id.toString(),
                    client_name: alertData.client_name || 'N/A',
                };
            }),
        });
        setProvider(newProvider);
    }, [data]);

    // HANDLE SEARCH STUDIES TABLE CHANGE
    const onSearchChange = (text: string) => {
        provider.search(text);
    };
    return (
        <DataTable
            data={provider}
            paginationProps={{
                value: { label: 1, value: 1 },
            }}
            bindKey="id"
            emptyState={<h2>No alerts found.</h2>}
            above={
                <div className="alerts-data-table-above">
                    {showButtons && (
                        <div className="data-table-action-buttons">
                            <Link to={ROUTES.CREATE_ALERT}>
                                <Button>Create Alert</Button>
                            </Link>
                            {/* --- EXPORT EXCEL SHEET OF ALERT DETAILS (ADMIN ONLY) --- */}
                            {user?.role === 'Admin' && (
                                <Button
                                    onClick={() => onDownloadAlertDetails()}
                                    color="brand-2"
                                >
                                    Download
                                </Button>
                            )}
                        </div>
                    )}
                    <SearchFilter onSearchChange={onSearchChange} />
                </div>
            }
            hasVirtualScrolling={false}
        >
            {/* --- ALERT ID --- */}
            <ColumnTemplate
                bind="id"
                isSortable
                onRender={(text: string) => (
                    <>
                        <span>{text}</span>
                    </>
                )}
                minWidth
            >
                Alert ID
            </ColumnTemplate>

            {/* --- STUDY NAME --- */}
            <ColumnTemplate
                bind="study_name"
                onRender={(text: string, rowData: AlertsTableItem) => {
                    return (
                        <Link
                            className="actions-link"
                            to={`/studies/view/${rowData.study_id}`}
                        >
                            <span>{text}</span>
                        </Link>
                    );
                }}
                type="actions"
                isSortable
                minWidth
            >
                Study
            </ColumnTemplate>

            {/* --- CLIENT NAME --- */}
            <ColumnTemplate bind="client_name" isSortable minWidth>
                Client
            </ColumnTemplate>

            {/* --- AD TYPE --- */}
            {!!data[0]?.ad_type && (
                <ColumnTemplate
                    bind="ad_type"
                    isSortable
                    onRender={(adTypes: string[] | string) => {
                        const text = Array.isArray(adTypes)
                            ? adTypes.join(',')
                            : adTypes;
                        return (
                            <>
                                <div className="data-table-account-name">
                                    <span>{text.toUpperCase()}</span>
                                </div>
                            </>
                        );
                    }}
                    minWidth
                >
                    Ad Type
                </ColumnTemplate>
            )}

            {/* --- ALERT TYPE --- */}
            <ColumnTemplate
                bind="report_type"
                onRender={(report_type: string) => {
                    const report =
                        report_type === 'Content' ? 'Trademark' : report_type;

                    return <span>{report} Monitor</span>;
                }}
                isSortable
                minWidth
            >
                Alert Type
            </ColumnTemplate>

            {/* --- START DATE --- */}
            <ColumnTemplate
                bind="start_date"
                align="center"
                onRender={(text: string) => (
                    <>
                        <span>{text}</span>
                    </>
                )}
                isSortable
                minWidth
            >
                Start Date
            </ColumnTemplate>

            {/* --- ALERT STATUS */}
            <ColumnTemplate
                align={TD_ALIGN.CENTER}
                bind="status"
                className="status-column"
                onRender={(status: StatusType, data: AlertsTableItem) => {
                    const isAlertActive = status === 'Active';
                    const isStudyActive = data.study_status === 'Active';
                    return (
                        <>
                            {isStudyActive ? (
                                <>
                                    {/* --- ACTIVE --- */}
                                    {isAlertActive && (
                                        <Status
                                            type={STATUS_TYPE.SUCCESS}
                                            title={status}
                                            displayOnFocus={false}
                                        />
                                    )}

                                    {/* --- ALERT/STUDY SUSPENDED --- */}
                                    {!isAlertActive &&
                                        data.study_status === 'Active' && (
                                            <Status
                                                type={
                                                    STATUS_TYPE.NOTELIGIBLETOSYNC
                                                }
                                                title={status}
                                                displayOnFocus={false}
                                            />
                                        )}
                                </>
                            ) : (
                                <Status
                                    type={STATUS_TYPE.FAILED}
                                    title="Study Suspended"
                                    displayOnFocus={false}
                                />
                            )}
                        </>
                    );
                }}
                isSortable
                minWidth
            >
                Status
            </ColumnTemplate>

            {/* --- ACTIONS */}
            <ColumnTemplate
                bind="id"
                type={TD_TYPE.ACTIONS}
                align="center"
                onRender={(id: number, data: AlertsTableItem) => {
                    const isAlertActive = data.status === 'Active';
                    const isStudyActive = data.study_status === 'Active';

                    return (
                        <>
                            {/* --- VIEW --- */}
                            {isViewable && (
                                <Link to={`/alerts/view/${id}`}>
                                    <Tooltip title="View">
                                        <i className="icon-view" />
                                    </Tooltip>
                                </Link>
                            )}

                            {/* --- EDIT --- */}
                            {isEditable && (
                                <Link to={`/alerts/edit/${id}`}>
                                    <Tooltip title="Edit">
                                        <i className="icon-edit" />
                                    </Tooltip>
                                </Link>
                            )}

                            {/* --- UPDATE STATUS --- */}
                            {!!onUpdateStatus && (
                                <>
                                    {/* --- SUSPEND --- */}
                                    {isStudyActive && isAlertActive && (
                                        <Tooltip title="Suspend">
                                            <i
                                                className="icon-cancel-20"
                                                onClick={() =>
                                                    onUpdateStatus(
                                                        id,
                                                        data.status
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    )}

                                    {/* --- ACTIVATE --- */}
                                    {isStudyActive && !isAlertActive && (
                                        <Tooltip title="Activate">
                                            <i
                                                className="icon-progress"
                                                onClick={() =>
                                                    onUpdateStatus(
                                                        id,
                                                        data.status
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </>
                    );
                }}
                minWidth
            >
                Actions
            </ColumnTemplate>
        </DataTable>
    );
}

export default React.memo(AlertsTable);
