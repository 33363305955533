import './Edit-Group-Modal.scss';

import { useActions } from '../../hooks';
import React, { useState, useMemo } from 'react';

// TYPES
import { EditGroupModalProps, GroupDetails } from '../../types';

// UTILS
import { isValidRootDomain } from '../../utils';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import ModalFooterButtons from '../Modal-Footer-Buttons/Modal-Footer-Buttons';

// FUNCTIONAL COMPONENT (EDIT KEYWORD/COMPETITOR GROUPS MODAL)
function EditGroupModal({
    groupType,
    groupName,
    details,
    onClose,
    onSubmit,
}: EditGroupModalProps) {
    // PAGE STATE
    const [newGroupItem, setNewGroupItem] = useState('');
    const [addedGroupItems, setAddedGroupItems] = useState<string[]>([]);
    const [deletedGroupItems, setDeletedGroupItems] = useState<GroupDetails>(
        {}
    );

    // REDUX ACTIONS
    const { alertError } = useActions();

    // HANDLE GROUP ITEM INPUT CHANGE
    const onTextboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setNewGroupItem(evt.target.value);
    };

    // HANDLE ADD NEW GROUP ITEM
    const addGroupItem = () => {
        const trimmedItem = newGroupItem.trim();
        if (trimmedItem === '') {
            return;
        }

        const foundDuplicateNew = addedGroupItems.indexOf(trimmedItem) > -1;

        const foundDuplicateExisting = details[trimmedItem] !== undefined;

        if (foundDuplicateExisting || foundDuplicateNew) {
            alertError(`Duplicate ${groupType}s are not allowed.`);
            return;
        }

        if (groupType === 'competitor') {
            const isValid = isValidRootDomain(trimmedItem);
            if (!isValid) {
                alertError(
                    'Please enter the root domain for all competitors (ex: Chewy.com, harvard.edu)'
                );
                return;
            }
        }

        setAddedGroupItems((prev) => [...prev, trimmedItem]);
        setNewGroupItem('');
    };

    // HANDLE SELECT GROUP ITEM TO DELETE
    const onSelectItem = (
        evt: React.ChangeEvent<HTMLInputElement>,
        isChecked: boolean
    ) => {
        const { value } = evt.target;
        const key = evt.currentTarget.getAttribute('data-item-key') || '';
        if (!isChecked) {
            setDeletedGroupItems((prev) => {
                delete prev[key];
                return { ...prev };
            });
        } else {
            setDeletedGroupItems((prev) => {
                const updated = { ...prev, [key]: value };
                return updated as GroupDetails;
            });
        }
    };

    const renderedExistingItems = useMemo(() => {
        return Object.entries(details).map(([key, value]) => {
            return (
                <Checkbox
                    data-item-key={key}
                    checked={!!deletedGroupItems[key]}
                    invert={true}
                    value={value}
                    onChange={onSelectItem}
                    key={value}
                >
                    {key}
                </Checkbox>
            );
        });
    }, [deletedGroupItems, details]);

    // RENDERS NEW GROUP ITEMS
    const renderedAddedGroupItems = useMemo(() => {
        return addedGroupItems.map((item, index) => {
            return (
                <p key={index} className="new-group-item">
                    <span
                        data-index={index}
                        // REMOVE NEW GROUP ITEM
                        className="remove-new-group-item"
                        onClick={() => {
                            setAddedGroupItems((prev) =>
                                prev.filter((kw, i) => i !== index)
                            );
                        }}
                    >
                        X
                    </span>
                    {item}
                </p>
            );
        });
    }, [addedGroupItems]);

    // JSX
    return (
        <Modal onClose={onClose} userClosable={false}>
            <div className="edit-group-container custom-modal-container">
                <div className="edit-group-header custom-modal-header">
                    <h2>Edit {groupType} Group:</h2>
                    <h3>{groupName}</h3>
                </div>
                <div className="edit-group-content custom-modal-content">
                    <form
                        onSubmit={(evt: React.FormEvent) =>
                            evt.preventDefault()
                        }
                    >
                        <div className="edit-group-item-container">
                            <h3>Remove {groupType}s:</h3>
                            <div className="edit-group-item-field">
                                <Fieldset>{renderedExistingItems}</Fieldset>
                            </div>
                        </div>
                        <div className="edit-group-item-container">
                            <h3>Add {groupType}s:</h3>
                            <div className="edit-group-item-field">
                                <div className="added-group-items-content">
                                    {renderedAddedGroupItems}
                                </div>
                            </div>
                            <div className="add-new-group-item-inputs">
                                <Textbox
                                    className="new-group-item-input"
                                    hideLabel={true}
                                    value={newGroupItem}
                                    onChange={onTextboxChange}
                                    onKeyUp={(evt: React.KeyboardEvent) => {
                                        if (
                                            evt.key === 'Enter' &&
                                            newGroupItem
                                        ) {
                                            addGroupItem();
                                        }
                                    }}
                                >
                                    ''
                                </Textbox>
                                <Button onClick={addGroupItem}>Add</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="edit-group-footer custom-modal-footer">
                    <ModalFooterButtons
                        onCancel={onClose}
                        onConfirm={async () => {
                            await onSubmit(groupName, {
                                added: addedGroupItems,
                                deleted: Object.values(deletedGroupItems),
                            });
                            onClose();
                        }}
                        disabled={
                            !Object.keys(deletedGroupItems).length &&
                            !addedGroupItems.length
                        }
                        confirmText="Update"
                    />
                </div>
            </div>
        </Modal>
    );
}

export default EditGroupModal;
