import './Pmds-Pill.scss';

interface PillProps {
    text?: string;
    invert?: boolean;
    children?: string;
    onClick?: () => void;
    disabled?: boolean;
}

function Pill({ text, invert, onClick, children, disabled }: PillProps) {
    return (
        <li className="pmds-pill">
            <button
                type="button"
                className={`${invert ? 'pmds-dd__pillInvert' : ''}`}
                onClick={onClick}
                disabled={disabled}
            >
                <span>{text || children}</span>
                <abbr title="Remove">X</abbr>
            </button>
        </li>
    );
}

export default Pill;
