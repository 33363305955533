import {
    StudyDetails,
    DropdownOption,
    FetchedAlertDetails,
} from '../../models';

import { AdsType } from '../../../constants';

export const ALERT_OPTIONS = [
    { label: 'Trademark Monitor', value: 'Content' },
    { label: 'Keywords Monitor', value: 'Keywords' },
];

export const ALERT_TYPES = ['Content', 'Keywords'] as const;

export type AlertType = (typeof ALERT_TYPES)[number];

// KEYWORDS ALERT OPERATORS
export const KEYWORDS_ALERT_OPERATORS = [
    'is at or higher than',
    'is lower than',
    'increased by',
    'decreased by',
] as const;

export type KeywordsAlertOperator = (typeof KEYWORDS_ALERT_OPERATORS)[number];

//PHASES OPERATORS
export const PHRASES_OPERATOR_OPTIONS = [
    { label: 'Contains', value: 'contains' },
    { label: 'Not Equal', value: 'notequal' },
    { label: 'Equal', value: 'equal' },
] as const;

export const PHRASES_OPERATORS = ['contains', 'notequal', 'equal'] as const;

export type PhrasesOperator = (typeof PHRASES_OPERATORS)[number];

// COMPETITORS OPERATORS
export const COMPETITORS_OPERATOR_OPTIONS = [
    { label: 'Equal', value: 'equal' },
    { label: 'Exclude', value: 'exclude' },
] as const;

export const COMPETITORS_OPERATORS = ['equal', 'exclude'] as const;

export type CompetitorsOperator = (typeof COMPETITORS_OPERATORS)[number];

// FIELD OPTIONS
export const FIELD_OPTIONS = [
    { label: 'Title', value: 'title' },
    { label: 'Description', value: 'description' },
    { label: 'URL', value: 'url' },
] as const;

export const CONTENT_ALERT_FIELDS = ['title', 'description', 'url'] as const;

export type ContentAlertField = (typeof CONTENT_ALERT_FIELDS)[number];

export const ALERT_RECURRENCE = [
    'daily',
    'weekly',
    'monthly',
    'quarterly',
] as const;

export type AlertRecurrence = (typeof ALERT_RECURRENCE)[number];

export interface AlertFormState {
    study: StudyDetails | null;
    alertType: AlertType | null;
    adsType: DropdownOption[];
    phrases: string[];
    recurrence: AlertRecurrence;
    operator: PhrasesOperator | null;
    keywords: DropdownOption[];
    competitors: DropdownOption[];
    competitorOperator: CompetitorsOperator;
    recipients: string[];
    fields: ContentAlertField[];
    savePng: 0 | 1;
    coverage_operator: KeywordsAlertOperator | null;
    coverage_base_value: number | null;
    coverage_change: number | null;
    coverage_threshold: number | null;
    rank_operator: KeywordsAlertOperator | null;
    rank_base_value: number | null;
    rank_change: number | null;
    rank_threshold: number | null;
}

export enum ALERT_FORM_ACTION_TYPES {
    SET_STUDY = 'update_study',
    SET_ALERT_TYPE = 'set_alert_type',
    SET_ALERT_RECURRENCE = 'set_alert_recurrence',
    ADD_ADS_TYPE = 'add_ads_type',
    REMOVE_ADS_TYPE = 'remove_ads_type',
    SET_ADS_TYPE = 'set_ads_type',
    SET_KEYWORDS = 'set_keywords',
    SET_COMPETITORS = 'set_competitors',
    SET_COMPETITOR_OPERATOR = 'SET_competitor_operator',
    ADD_RECIPIENT = 'add_recipient',
    REMOVE_RECIPIENT = 'remove_recipient',
    RESET_ALERT_FORM = 'reset_alert_form',
    LOAD_EXISTING_ALERT = 'load_existing_alert',

    // CONTENT ALERT ONLY
    ADD_PHRASE = 'add_phrase',
    REMOVE_PHRASE = 'remove_phase',
    SET_PHRASES_OPERATOR = 'set_phrases_operator',
    ADD_FIELD = 'add_field',
    REMOVE_FIELD = 'remove_field',
    SET_SAVE_PNG = 'set_save_png',

    // KEYWORDS ALERT ONLY
    SET_COVERAGE_OPERATOR = 'set_coverage_operator',
    SET_COVERAGE_BASE_VALUE = 'set_coverage_base_value',
    SET_COVERAGE_CHANGE = 'set_coverage_change',
    SET_COVERAGE_THRESHOLD = 'set_coverage_threshold',
    SET_RANK_OPERATOR = 'set_rank_operator',
    SET_RANK_BASE_VALUE = 'set_rank_base_value',
    SET_RANK_CHANGE = 'set_rank_change',
    SET_RANK_THRESHOLD = 'set_rank_threshold',
}

interface SetAlertTypeAction {
    type: ALERT_FORM_ACTION_TYPES.SET_ALERT_TYPE;
    payload: AlertType;
}

interface SetAlertStudyAction {
    type: ALERT_FORM_ACTION_TYPES.SET_STUDY;
    payload: StudyDetails;
}

interface AddAlertAdsTypeAction {
    type: ALERT_FORM_ACTION_TYPES.ADD_ADS_TYPE;
    payload: AdsType;
}

interface SetAlertRecurrenceAction {
    type: ALERT_FORM_ACTION_TYPES.SET_ALERT_RECURRENCE;
    payload: AlertRecurrence;
}

interface RemoveAlertAdsTypeActon {
    type: ALERT_FORM_ACTION_TYPES.REMOVE_ADS_TYPE;
    payload: AdsType;
}

interface SetAlertAdsAction {
    type: ALERT_FORM_ACTION_TYPES.SET_ADS_TYPE;
    payload: DropdownOption[];
}

interface SetAlertKeywordsAction {
    type: ALERT_FORM_ACTION_TYPES.SET_KEYWORDS;
    payload: DropdownOption[];
}

interface SetAlertCompetitorsAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COMPETITORS;
    payload: DropdownOption[];
}

interface SetAlertCompetitorOperatorAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COMPETITOR_OPERATOR;
    payload: CompetitorsOperator;
}

interface AddAlertRecipientAction {
    type: ALERT_FORM_ACTION_TYPES.ADD_RECIPIENT;
    payload: string;
}

interface RemoveAlertRecipientAction {
    type: ALERT_FORM_ACTION_TYPES.REMOVE_RECIPIENT;
    payload: string;
}

interface ResetAlertFormAction {
    type: ALERT_FORM_ACTION_TYPES.RESET_ALERT_FORM;
}

interface LoadExistingAlertAction {
    type: ALERT_FORM_ACTION_TYPES.LOAD_EXISTING_ALERT;
    payload: {
        alert: FetchedAlertDetails;
        study: StudyDetails;
    };
}

// CONTENT ALERT ACTIONS
interface AddAlertFieldAction {
    type: ALERT_FORM_ACTION_TYPES.ADD_FIELD;
    payload: ContentAlertField;
}

interface RemoveAlertFieldAction {
    type: ALERT_FORM_ACTION_TYPES.REMOVE_FIELD;
    payload: ContentAlertField;
}

interface SetAlertSavePngAction {
    type: ALERT_FORM_ACTION_TYPES.SET_SAVE_PNG;
    payload: 0 | 1;
}

interface AddAlertPhraseAction {
    type: ALERT_FORM_ACTION_TYPES.ADD_PHRASE;
    payload: string;
}

interface RemoveAlertPhraseAction {
    type: ALERT_FORM_ACTION_TYPES.REMOVE_PHRASE;
    payload: string;
}

interface SetAlertOperatorAction {
    type: ALERT_FORM_ACTION_TYPES.SET_PHRASES_OPERATOR;
    payload: PhrasesOperator;
}

// KEYWORDS ALERT ACTIONS
interface SetCoverageOperatorAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_OPERATOR;
    payload: KeywordsAlertOperator;
}

interface SetCoverageBaseValueAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_BASE_VALUE;
    payload: number | null;
}

interface SetCoverageChangeAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_CHANGE;
    payload: number | null;
}

interface SetCoverageThresholdAction {
    type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_THRESHOLD;
    payload: number | null;
}

interface SetRankOperatorAction {
    type: ALERT_FORM_ACTION_TYPES.SET_RANK_OPERATOR;
    payload: KeywordsAlertOperator;
}

interface SetRankBaseValueAction {
    type: ALERT_FORM_ACTION_TYPES.SET_RANK_BASE_VALUE;
    payload: number | null;
}

interface SetRankChangeAction {
    type: ALERT_FORM_ACTION_TYPES.SET_RANK_CHANGE;
    payload: number | null;
}

interface SetRankThresholdAction {
    type: ALERT_FORM_ACTION_TYPES.SET_RANK_THRESHOLD;
    payload: number | null;
}

export type AlertFormAction =
    | SetAlertTypeAction
    | SetAlertStudyAction
    | AddAlertAdsTypeAction
    | SetAlertRecurrenceAction
    | RemoveAlertAdsTypeActon
    | SetAlertAdsAction
    | AddAlertPhraseAction
    | RemoveAlertPhraseAction
    | SetAlertOperatorAction
    | SetAlertKeywordsAction
    | SetAlertCompetitorsAction
    | SetAlertCompetitorOperatorAction
    | AddAlertRecipientAction
    | RemoveAlertRecipientAction
    | AddAlertFieldAction
    | RemoveAlertFieldAction
    | SetAlertSavePngAction
    | ResetAlertFormAction
    | LoadExistingAlertAction
    | SetCoverageOperatorAction
    | SetCoverageBaseValueAction
    | SetCoverageChangeAction
    | SetCoverageThresholdAction
    | SetRankOperatorAction
    | SetRankBaseValueAction
    | SetRankChangeAction
    | SetRankThresholdAction;
