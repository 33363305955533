import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

// UI COMPONENT IMPORTS
import DataTable, {
    TD_TYPE,
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Tooltip from '@publicismedia-ds/ui-tooltip';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';

// TYPES
import { ScheduledReport } from '../../types';
import { ScheduledReportListItem, StatusType } from '../../state';

// COMPONENT PROPS
interface AccountReportsTableProps {
    data: ScheduledReportListItem[];
}

// FUNCTION COMPONENT (SCHEDULED REPORTS TABLE)
function AccountReportsTable({ data }: AccountReportsTableProps) {
    // SCHEDULED REPORTS TABLE DATA PROVIDER
    const [provider, setProvider] = useState<any>(null);

    // UPDATE TABLE DATA PROVIDER FOR FETCHED SCHEDULED REPORTS
    useEffect(() => {
        // CREATE & STORE NEW TABLE PROVIDER
        setProvider(
            new DataProvider({
                // FORMAT ROW DATA
                data: data.map((report) => {
                    return {
                        id: report.scheduleId,
                        scheduleId: report.scheduleId.toString(),
                        studyId: report.studyId.toString(),
                        studyName: report.studyName,
                        startDate: report.params.startDate,
                        reportType: report.params.reportType,
                        occurrence: report.params.type,
                        lastRun: report.params.lastRun ?? 'N/A',
                        status: report.status,
                    };
                }),
            })
        );
    }, [data]);

    // HANDLE SEARCH SCHEDULED REPORTS
    const onSearchChange = (text: string) => {
        provider?.search(text);
    };

    // FORMAT DATA TO BE PASSED TO VIEW PAGE
    const formatReportData = (
        report: ScheduledReportListItem
    ): ScheduledReport => {
        // RETURN FORMATTED REPORT DATA
        return {
            account_id: report.accountId,
            report_id: report.scheduleId,
            csv_email_report_recipients: report.additinalEmails || '',
            email: report.email,
            report_request_params: report.params.reportRequestParams,
            report_type: report.params.reportType,
            file_type: report.params.fileType,
            schedule: {
                start_date: report.params.startDate,
                end_date: report.params.endDate,
                occurence: report.params.type,
                occurrence: report.params.type,
            },
            study_id: report.studyId,
            status: report.status,
        };
    };

    // JSX
    return (
        <DataTable
            data={provider ?? []}
            bindKey="id"
            emptyState={<h2>No scheduled reports.</h2>}
            above={
                <div>
                    <SearchFilter onSearchChange={onSearchChange} />
                </div>
            }
        >
            {/* --- REPORT ID --- */}
            <ColumnTemplate bind="scheduleId" isSortable minWidth>
                Report ID
            </ColumnTemplate>

            {/* --- STUDY ID --- */}
            <ColumnTemplate bind="studyId" isSortable minWidth>
                Study ID
            </ColumnTemplate>

            {/* --- STUDY NAME --- */}
            <ColumnTemplate bind="studyName" isSortable minWidth>
                Study Name
            </ColumnTemplate>

            {/* --- REPORT TYPE --- */}
            <ColumnTemplate bind="reportType" isSortable minWidth>
                Report Type
            </ColumnTemplate>

            {/* --- REPORT OCCURRENCE --- */}
            <ColumnTemplate bind="occurrence" isSortable minWidth>
                Occurrence
            </ColumnTemplate>

            {/* --- LAST RUN --- */}
            <ColumnTemplate
                bind="lastRun"
                align={TD_ALIGN.LEFT}
                isSortable
                minWidth
            >
                Last Run
            </ColumnTemplate>

            {/* --- START DATE --- */}
            <ColumnTemplate
                bind="startDate"
                align={TD_ALIGN.LEFT}
                isSortable
                minWidth
            >
                Start Date
            </ColumnTemplate>

            {/* --- REPORT STATUS --- */}
            <ColumnTemplate
                align={TD_ALIGN.CENTER}
                className="status-column"
                bind="status"
                onRender={(status: StatusType) => {
                    return (
                        <Status
                            type={
                                status === 'Active'
                                    ? STATUS_TYPE.SUCCESS
                                    : STATUS_TYPE.NOTELIGIBLETOSYNC
                            }
                            title={status}
                        />
                    );
                }}
                isSortable
                minWidth
            >
                Status
            </ColumnTemplate>

            <ColumnTemplate
                bind="scheduleId"
                type={TD_TYPE.ACTIONS}
                align="center"
                onRender={(id: string) => {
                    const report = data.find(
                        (report) => report.scheduleId === parseInt(id)
                    );
                    return (
                        <Link
                            to={`/scheduled-reports/view/${id}`}
                            state={{
                                isEditing: false,
                                reportData: report
                                    ? formatReportData(report)
                                    : null,
                            }}
                        >
                            <Tooltip title="View">
                                <i className="icon-view" />
                            </Tooltip>
                        </Link>
                    );
                }}
                minWidth
            >
                Actions
            </ColumnTemplate>
        </DataTable>
    );
}

export default AccountReportsTable;
