import { useAsyncWrapper } from '../../hooks';
import React, { useMemo, useState } from 'react';

// TYPES
import {
  AccountDetails,
  DropdownOption,
  STATUS_OPTIONS,
  ClientFormState,
  StatusOptionsType,
} from '../../state';

// UITILS
import { onPreventEnterKeySubmit, sortDropdownOptions } from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import InputError from '../Input-Error-Message/Input-Error-Message';

// COMPONENT PROPS
interface ClientOnboardingFormProps {
  accounts: AccountDetails[];
  onSubmit: (formState: ClientFormState) => Promise<void>;
}

// DEFAULT CLIENT FORM STATE
const DEFAULT_STATE: ClientFormState = {
  account: '',
  name: '',
  volume: '',
  status: STATUS_OPTIONS[0],
  isNewBusiness: false,
};

// MAX SEARCH VOLUME (NEW BUSINESS)
const MAX_SEARCH_VOLUME = 100000;

// FUNCTIONAL COMPONENT
function ClientOnboardingForm({
  accounts,
  onSubmit,
}: ClientOnboardingFormProps) {
  const wrapper = useAsyncWrapper();

  // FORM STATE
  const [state, setState] = useState(DEFAULT_STATE);

  // ACCOUNT OPTIONS
  const accountOptions = useMemo(() => {
    const options = accounts.map(
      ({ name, id }): DropdownOption => ({
        label: `${name} (ID: ${id})`,
        value: id,
      })
    );

    return sortDropdownOptions(options);
  }, [accounts]);

  const excedesMaxVolume =
    state.isNewBusiness && parseInt(state.volume) > MAX_SEARCH_VOLUME;

  // ENABLE/DISABLE SUBMIT BUTTON
  const isSubmitDisabled =
    !state.account || !state.name || !state.volume || excedesMaxVolume;

  // HANDLE ACCOUNT SELECTION
  const onSetAccount = (selection: DropdownOption) => {
    setState((prev) => ({ ...prev, account: selection }));
  };

  // HANDLE ACCOUNT NAME INPUT
  const onChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setState((prev) => ({ ...prev, name: value }));
  };

  // HANDLE SEARCH VOLUME CHANGE
  const onChangeVolume = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setState((prev) => ({ ...prev, volume: value }));
  };

  // HANDLE STATUS SELECTION
  const onSetStatus = (selection: StatusOptionsType) => {
    setState((prev) => ({ ...prev, status: selection }));
  };

  // HANDLE SET CLIENT AS "NEW BUSINESS"
  const onSetNewBusiness = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = evt.target.checked;
    setState((prev) => ({ ...prev, isNewBusiness: isChecked }));
  };

  // HANDLE FORM SUBMISSION
  const onSubmitForm = wrapper(async (evt: React.FormEvent) => {
    evt.preventDefault();

    await onSubmit(state);
    setState({ ...DEFAULT_STATE });
  });

  // JSX
  return (
    <div className="user-onboarding-form-container">
      <form onSubmit={onSubmitForm} onKeyDown={onPreventEnterKeySubmit}>
        <Row>
          {/* --- ACCOUNT --- */}
          <Column>
            <Dropdown
              options={accountOptions}
              onChange={onSetAccount}
              value={state.account}
              required
            >
              Account:
            </Dropdown>
          </Column>

          {/* --- NAME --- */}
          <Column>
            <Textbox onChange={onChangeName} value={state.name} required>
              Client Name:
            </Textbox>
          </Column>

          {/* --- SEARCH VOLUME --- */}
          <Column>
            <Textbox
              onChange={onChangeVolume}
              value={state.volume}
              max={state.isNewBusiness ? MAX_SEARCH_VOLUME : undefined}
              type="number"
              required
            >
              Search Volume:
            </Textbox>
            <InputError display={excedesMaxVolume}>
              Max volume of {MAX_SEARCH_VOLUME.toLocaleString()} for new
              business
            </InputError>
          </Column>

          {/* --- STATUS --- */}
          <Column>
            <Dropdown
              options={STATUS_OPTIONS}
              onChange={onSetStatus}
              value={state.status}
              defaultValue={STATUS_OPTIONS[0]}
              isSearchable={false}
              required
            >
              Status:
            </Dropdown>
          </Column>
        </Row>
        <Row>
          <Column>
            <Checkbox
              checked={state.isNewBusiness}
              onChange={onSetNewBusiness}
              toggle
              invert
            >
              New business
            </Checkbox>
          </Column>
        </Row>

        {/* SUBMIT BUTTON */}
        <div className="form-buttons">
          <Button type="submit" disabled={isSubmitDisabled}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ClientOnboardingForm;
