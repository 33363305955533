import './Dashboard-Filter.scss';

import { useAsyncWrapper } from '../../hooks';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

// API REQUESTS
import { getStudyById } from '../../api';

// CONSTANTS
import { YESTERDAY } from '../../constants';

// FILTERS REDUCER ACTIONS
import {
  setAdhocStudy,
  loadAdhocState,
  setAdhocEndDate,
  setAdhocKeywords,
  setAdhocStartDate,
  setAdhocCompetitors,
  setAdhocPriorEndDate,
  setAdhocKeywordGroups,
  setAdhocPriorStartDate,
  setAdhocCompetitorGroups,
} from '../../state/action-creators';
// FILTERS REDUCER & DEFAULT STATE
import {
  adhocReportReducer,
  DEFAULT_ADHOC_STATE,
} from '../../state/reducers/adhoc-report-reducer';

// TYPES
import { AdhocReportState, DropdownOption, StudyListItem } from '../../state';

// UI COMPONENTS
import Card from '@publicismedia-ds/ui-cards';
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Datepicker from '@publicismedia-ds/ui-datepicker';
import Grid, { Row, Column } from '@publicismedia-ds/ui-grid';

// UTILS
import {
  parseKeywordGroups,
  sortDropdownOptions,
  parseCompetitorGroups,
  onPreventEnterKeySubmit,
  getDateDiff,
} from '../../utils';

// COMPONENT PROPS
interface DashboardFilterProps {
  studies: StudyListItem[];
  onApply: (state: AdhocReportState) => Promise<void>;
  defaultState?: AdhocReportState | null;
}

// FUNCTIONAL COMPONENT (DASHBOARD FILTERS)
function DashboardFilter({
  studies,
  onApply,
  defaultState,
}: DashboardFilterProps) {
  const ref = useRef<HTMLElement>(null);
  const wrapper = useAsyncWrapper();

  // FILTERS DATA
  const [displayFilters, setDisplayFilters] = useState(false);

  // FILTERS STATE
  const [filters, dispatch] = useReducer(
    adhocReportReducer,
    DEFAULT_ADHOC_STATE
  );

  // TOGGLE FILTERS DISPLAY
  const onFiltersClick = () => setDisplayFilters((prev) => !prev);

  // CLOSE WHEN CLICKING OUTIDE OF FILTERS CARD
  useEffect(() => {
    function handleClickOutside(evt: any) {
      if (
        displayFilters &&
        ref.current &&
        !ref.current.contains(evt.target) &&
        !evt.target.classList.contains('filter-btn')
      ) {
        setDisplayFilters(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  // LOAD OPTIONAL DEFAULT STATE ON INITIAL RENDER
  useEffect(() => {
    if (defaultState) {
      dispatch(loadAdhocState(defaultState));
    }
  }, [defaultState]);

  // CREATE STUDY OPTIONS
  const studyOptions = useMemo(() => {
    const studyOptions = studies.map(
      ({ name, id }): DropdownOption => ({ label: name, value: id })
    );
    return sortDropdownOptions(studyOptions);
  }, [studies]);

  // GET OPTIONS (MEMOIZE FOR PERFORMANCE)
  const {
    keywordOptions,
    competitorOptions,
    keywordGroupOptions,
    competitorGroupOptions,
  } = useMemo(() => {
    const [competitorGroupOptions, competitorOptions] = parseCompetitorGroups(
      filters.study?.competitor_group_details || []
    );
    const [keywordGroupOptions, keywordOptions] = parseKeywordGroups(
      filters.study?.keyword_group_details || []
    );
    return {
      keywordOptions,
      competitorOptions,
      keywordGroupOptions,
      competitorGroupOptions,
    };
  }, [filters.study]);

  // START DATE
  const startDate = useMemo(() => {
    return filters.start_date ? new Date(filters.start_date) : '';
  }, [filters.start_date]);

  // END DATE
  const endDate = useMemo(() => {
    return filters.end_date ? new Date(filters.end_date) : '';
  }, [filters.end_date]);

  // PRIOR START DATE
  const priorStartDate = useMemo(() => {
    return filters.prior_start_date ? new Date(filters.prior_start_date) : '';
  }, [filters.prior_start_date]);

  // PRIOR END DATE
  const priorEndDate = useMemo(() => {
    return filters.prior_end_date ? new Date(filters.prior_end_date) : '';
  }, [filters.prior_end_date]);

  // MIN START DATE
  const minStartDate = filters.study ? new Date(filters.study.created) : null;
  const minEndDate = filters.start_date
    ? new Date(filters.start_date)
    : minStartDate;

  // MAX PRIOR END DATE
  const maxPriorStartDate = new Date(startDate || YESTERDAY);
  maxPriorStartDate.setDate(
    maxPriorStartDate.getDate() - getDateDiff(startDate, endDate) - 1
  );

  // HANDLE STUDY SELECTION
  const onSelectStudy = wrapper(async (selected: DropdownOption) => {
    const study = await getStudyById(selected.value);
    dispatch(setAdhocStudy(study));
  });

  // HANDLE SELECT START DATE
  const onSelectStartDate = (startDate: Date) => {
    dispatch(setAdhocStartDate(startDate));
  };

  // HANDLE SELECT END DATE
  const onSelectEndDate = (endDate: Date) => {
    dispatch(setAdhocEndDate(endDate));
  };

  // HANDLE SELECT PRIOR START DATE
  const onSelectPriorStartDate = (startDate: Date) => {
    dispatch(setAdhocPriorStartDate(startDate));
  };

  // HANDLE SELECT PRIOR END DATE
  const onSelectPriorEndDate = (endDate: Date) => {
    dispatch(setAdhocPriorEndDate(endDate));
  };

  // HANDLE SELECT COMPETITOR GROUP(S)
  const onSelectCompetitorGroups = (selections: DropdownOption[]) => {
    dispatch(setAdhocCompetitorGroups(selections));
  };

  // HANDLE SELECT COMPETITOR(S)
  const onSelectCompetitors = (selections: DropdownOption[]) => {
    dispatch(setAdhocCompetitors(selections));
  };

  // HANDLE SELECT KEYWORD GROUP(S)
  const onSelectKeywordGroups = (selections: DropdownOption[]) => {
    dispatch(setAdhocKeywordGroups(selections));
  };

  // HANDLE SELECT KEYWORD(S)
  const onSelectKeywords = (selections: DropdownOption[]) => {
    dispatch(setAdhocKeywords(selections));
  };

  // HANDLE FILTER SUBMISSION
  const onApplyFilters = wrapper(async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (onApply) {
      await onApply(filters);
    }
    setDisplayFilters(false);
  });

  // Current date range string
  const renderedCurrent = useMemo(() => {
    let output = 'N/A';
    const start = filters.start_date ? new Date(filters.start_date) : null;
    const end = filters.end_date ? new Date(filters.end_date) : null;

    if (start && end) {
      output = `${start.toLocaleString('en-US').split(',')[0]} - ${
        end.toLocaleString('en-US').split(',')[0]
      }`;
    }
    return output;
  }, [filters.start_date, filters.end_date]);

  // Prior date range string
  const renderedPrior = useMemo(() => {
    let output = 'N/A';
    const start = filters.prior_start_date
      ? new Date(filters.prior_start_date)
      : null;
    const end = filters.prior_end_date
      ? new Date(filters.prior_end_date)
      : null;

    if (start && end) {
      output = `${start.toLocaleString('en-US').split(',')[0]} - ${
        end.toLocaleString('en-US').split(',')[0]
      }`;
    }
    return output;
  }, [filters.prior_start_date, filters.prior_end_date]);

  return (
    <div className="dashboard-filters-container">
      <div className="dashboard-filters-header">
        <Button onClick={onFiltersClick} className="filter-btn">
          Filters
        </Button>
        <div className="filters-preview">
          <p>
            <strong>Study:</strong> {filters.study?.name || 'N/A'}
          </p>
          <div>
            <span>
              <strong>Current:</strong> {renderedCurrent}
            </span>
            <span style={{ marginLeft: '1rem' }}>
              <strong>Prior:</strong> {renderedPrior}
            </span>
          </div>
        </div>
      </div>
      <div className="dashboard-filters-content">
        {displayFilters ? (
          <Card
            header=""
            className="dashboard-filters-card"
            isOpen={displayFilters}
            onClose={() => setDisplayFilters(false)}
            ref={ref}
          >
            <div className="card-caret">
              <i className="icon-chevron-up" />
            </div>
            <div className="dashboard-filters-form">
              <form
                onSubmit={onApplyFilters}
                onKeyDown={onPreventEnterKeySubmit}
              >
                <Grid>
                  <Row cols={3} style={{ marginTop: '-1rem' }}>
                    <Column>
                      <Dropdown
                        options={studyOptions}
                        onChange={onSelectStudy}
                        value={{ label: '', value: filters.study?.id || '' }}
                        required
                      >
                        Study:
                      </Dropdown>
                    </Column>
                  </Row>
                  <Row className="cols-4">
                    <Column>
                      <Datepicker
                        onChange={onSelectStartDate}
                        value={startDate}
                        defaultValue={startDate}
                        minDate={minStartDate}
                        maxDate={
                          filters.end_date
                            ? new Date(filters.end_date)
                            : YESTERDAY
                        }
                        required
                      >
                        Current start:
                      </Datepicker>
                    </Column>
                    <Column>
                      <Datepicker
                        onChange={onSelectEndDate}
                        value={endDate}
                        defaultValue={endDate}
                        minDate={minEndDate}
                        maxDate={YESTERDAY}
                        required
                      >
                        Current end:
                      </Datepicker>
                    </Column>
                    <Column>
                      <Datepicker
                        onChange={onSelectPriorStartDate}
                        value={priorStartDate}
                        defaultValue={priorStartDate}
                        minDate={minStartDate}
                        maxDate={maxPriorStartDate}
                        required
                      >
                        Prior start:
                      </Datepicker>
                    </Column>
                    <Column>
                      <Datepicker
                        onChange={onSelectPriorEndDate}
                        value={priorEndDate}
                        defaultValue={priorEndDate}
                        disabled
                        invert
                        required
                      >
                        Prior end:
                      </Datepicker>
                    </Column>
                  </Row>
                  <Row className="cols-4">
                    <Column>
                      <Dropdown
                        options={competitorGroupOptions}
                        value={filters.competitor_group}
                        onChange={onSelectCompetitorGroups}
                        disabled={!!filters.competitors?.length}
                        display="selectionInline"
                        multiple
                      >
                        Competitor Groups:
                      </Dropdown>
                    </Column>
                    <Column>
                      <Dropdown
                        options={competitorOptions}
                        value={filters.competitors}
                        onChange={onSelectCompetitors}
                        disabled={!!filters.competitor_group?.length}
                        display="selectionInline"
                        multiple
                      >
                        Competitors:
                      </Dropdown>
                    </Column>
                    <Column>
                      <Dropdown
                        options={keywordGroupOptions}
                        value={filters.keyword_groups}
                        onChange={onSelectKeywordGroups}
                        disabled={!!filters.keywords?.length}
                        display="selectionInline"
                        multiple
                      >
                        Keyword Groups:
                      </Dropdown>
                    </Column>
                    <Column>
                      <Dropdown
                        options={keywordOptions}
                        value={filters.keywords}
                        onChange={onSelectKeywords}
                        disabled={!!filters.keyword_groups?.length}
                        display="selectionInline"
                        multiple
                      >
                        Keywords:
                      </Dropdown>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <div className="form-buttons">
                        <Button type="submit">Apply</Button>
                      </div>
                    </Column>
                  </Row>
                </Grid>
              </form>
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
}

export default DashboardFilter;
