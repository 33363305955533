import React from 'react';
import { AxiosResponse } from 'axios';
import { DropdownOption } from '../state';

export const camelCaseToSpaces = (str: string) => {
  let output = '';

  for (let i = 0; i < str.length; i++) {
    if (i === 0) {
      output += str[0].toUpperCase();
      continue;
    } else if (str[i].toLowerCase() !== str[i]) {
      output += ` ${str[i]}`;
    } else {
      output += str[i];
    }
  }
  return output;
};

export const snakeCaseToSpaces = (str: string) => {
  const output = [];
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '_') {
      output.push(' ');
      output.push(str[i + 1].toUpperCase());
      i++;
    } else {
      output.push(str[i]);
    }
  }
  output[0] = output[0].toUpperCase();
  return output.join('').trim();
};

export const convertDateToString = (date: Date) => {
  return date.toLocaleDateString('en-CA');
};

type DateFormatOption =
  | 'YYYY-MM-DD'
  | 'YYYY/MM/DD'
  | 'MM-DD-YYYY'
  | 'MM/DD/YYYY'
  | 'DD/MM/YYYY';
export const formatDateString = (date: Date, format: DateFormatOption) => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };
  let locale = 'en-CA';
  switch (format) {
    case 'YYYY-MM-DD':
      locale = 'en-CA';
      break;

    case 'YYYY/MM/DD':
      locale = 'en-ZA';
      break;

    case 'MM-DD-YYYY':
      locale = 'en-US';
      break;

    case 'DD/MM/YYYY':
      locale = 'en-GB';
      break;

    default:
      break;
  }
  return date.toLocaleDateString(locale, options);
};

export const parseNumberInputValue = (
  evt: React.ChangeEvent<HTMLInputElement>
) => {
  const { value } = evt.target;
  const min = evt.target.getAttribute('min');
  const max = evt.target.getAttribute('max');
  const intValue = parseInt(value);

  if (isNaN(intValue)) {
    return null;
  }
  let actualValue = intValue;

  if (min) {
    actualValue = Math.max(actualValue, parseInt(min));
  }
  if (max) {
    actualValue = Math.min(actualValue, parseInt(max));
  }

  return actualValue;
};

// CREATES TEMP LINK FOR DOWNLOADING REPORT
export const saveDownloadFile = (response: AxiosResponse, fileName: string) => {
  const href = URL.createObjectURL(response.data);
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const getFileNameDate = () => {
  const now = new Date();
  const dateArr = [];
  dateArr.push((now.getMonth() + 1).toString());
  dateArr.push(now.getDate().toString());
  dateArr.push(now.getFullYear().toString());
  dateArr.push(now.getHours().toString());
  dateArr.push(now.getMinutes().toString());
  dateArr.push(now.getSeconds().toString());
  dateArr.push(now.getHours() >= 12 ? 'PM' : 'AM');

  dateArr.forEach((item, index) => {
    if (item.length < 2) {
      dateArr[index] = '0' + item;
    }
  });
  return dateArr.slice(0, 3).join('-') + '__' + dateArr.slice(3).join('_');
};

export const isValidRootDomain = (domain: string) => {
  const regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(?!www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i
  );
  return regexp.test(domain);
};

// FIND DE-SELECTED OPTION FROM MULTI DROPDOWN SELECTIONS
export const findUnselectedOption = (
  selections: DropdownOption[],
  selectionsState: DropdownOption[]
) => {
  // IF NO SELECTIONS REMAIN RETURN ONLY SELECTION
  if (!selections.length) {
    return selectionsState[0];
  }

  for (let i = 0; i < selectionsState.length; i++) {
    if (selections[i]?.value !== selectionsState[i].value) {
      return selectionsState[i];
    }
  }
  return;
};

// PREVENT ENTER KEY FROM SUBMITTING FORM
export const onPreventEnterKeySubmit = (evt: React.KeyboardEvent) => {
  if (evt.key === 'Enter') {
    evt.preventDefault();
  }
};

// CONVERT STRING TO FILENAME
export const convertToFileName = (str: string) => {
  const copy = str;
  copy.replaceAll('-', ' ');
  copy.replaceAll('*', ' ');
  copy.replaceAll('.', ' ');

  const arr = copy.split(' ');
  return arr.join('_');
};

// DROPDOWN OPTIONS SORTER
export const sortDropdownOptions = (
  options: DropdownOption[],
  order: 'asc' | 'desc' = 'asc'
) => {
  const output = [...options];

  return order === 'asc'
    ? output.sort(sortDropDowns.ascending)
    : output.sort(sortDropDowns.descending);
};

// SORT DROPDOWN OPTIONS ARRAY
export const sortDropDowns = {
  ascending: (a: DropdownOption, b: DropdownOption) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  },
  descending: (a: DropdownOption, b: DropdownOption) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();

    if (labelA < labelB) {
      return 1;
    }
    if (labelA > labelB) {
      return -1;
    }
    return 0;
  },
};

export const dropdownsToString = (options: DropdownOption[]) => {
  return options.map(({ value }) => value).join(',');
};

export const getDateDiff = (dateOne: string | Date, dateTwo: string | Date) => {
  return (
    Math.abs(new Date(dateOne).getTime() - new Date(dateTwo).getTime()) /
    (24 * 60 * 60 * 1000)
  );
};
