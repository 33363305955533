export interface DropdownOption {
    label: string;
    value: number | string;
}

export type StatusType = 'Active' | 'Suspended';

export const STATUS_OPTIONS = [
    { label: 'Active', value: 'Active' },
    { label: 'Suspended', value: 'Suspended' },
] as const;

export type StatusOptionsType = typeof STATUS_OPTIONS[number];

export type UserRoleType = 'Admin' | 'Account Manager' | 'User';

export interface RoleDetails {
    role: UserRoleType;
    account_id: string | number;
}
