import './Reports.scss';

import { axios } from '../../api';
import { useAsyncWrapper } from '../../hooks';

// UI COMPONENT IMPORTS
import ReportForm from '../../components/Report-Form/Report-Form';
import PageContainer from '../../components/Page-Container/Page-Container';

// UTILITIES
import {
    saveDownloadFile,
    generateFileName,
    getDownloadUrlAndParams,
} from '../../utils';

// TYPES
import { ReportFormState, ReportFiltersState } from '../../state';

// FUNCTIONAL COMPONENT (REPORTS - ON DEMAND)
function Reports() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // HANDLE REPORT DOWNLOAD
    const onDownloadReport = wrapper(
        async (
            reportState: ReportFormState,
            filtersState: ReportFiltersState
        ) => {
            const [url, params] = getDownloadUrlAndParams(
                reportState,
                filtersState
            );

            if (!url || !params) {
                return;
            }

            try {
                const fileName = generateFileName(reportState);
                const response = await axios.get(url, {
                    params,
                    responseType: 'blob',
                });

                saveDownloadFile(response, fileName);
            } catch (error: any) {
                const errResponseString = await error.response.data.text();
                const { errorMessage } = JSON.parse(errResponseString);
                throw new Error(errorMessage);
            }
        }
    );

    // JSX
    return (
        <PageContainer>
            <ReportForm onSubmit={onDownloadReport} onDemand />
        </PageContainer>
    );
}

export default Reports;
