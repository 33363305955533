import { ReportFileType, ReportType, ScheduledReport } from '../../../types';
import { StudyDetails } from '../../models';

export const REPORT_OCURRENCE = ['daily', 'weekly', 'monthly'] as const;
export type ReportOccurrence = typeof REPORT_OCURRENCE[number];

export interface ReportFormState {
    study: StudyDetails | null;
    study_type: 'search';
    report_type: ReportType | '';
    file_type: ReportFileType;
    email_recipients: string[];
    start_date: Date | '';
    end_date: Date | '';
    occurrence: ReportOccurrence;
}

export const DEFAULT_REPORT_FORM_STATE: ReportFormState = {
    study: null,
    report_type: 'Battlefield',
    study_type: 'search',
    start_date: '',
    end_date: '',
    email_recipients: [],
    file_type: 'xlsx',
    occurrence: 'daily',
};

export enum REPORT_FORM_STATE_PROPS {
    STUDY = 'study',
    STUDY_TYPE = 'study_type',
    REPORT_TYPE = 'report_type',
    FILE_TYPE = 'file_type',
    EMAIL_RECPIENTS = 'email_recipients',
    START_DATE = 'start_date',
    END_DATE = 'end_date',
    OCURRENCE = 'occurrence',
}

export enum REPORT_FORM_ACTION_TYPES {
    SET_STUDY = 'set_study',
    SET_REPORT_TYPE = 'set_report_type',
    SET_FILE_TYPE = 'set_file_type',
    ADD_EMAIL_RECIPIENT = 'add_email_recipient',
    REMOVE_EMAIL_RECIPIENT = 'remove_email_recipient',
    SET_START_DATE = 'set_start_date',
    SET_END_DATE = 'set_end_date',
    SET_OCCURRENCE = 'set_occurrence',
    LOAD_EXISTING_REPORT = 'load_existing_report',
}

export interface POSTCreateReportState {
    study_id: number;
    study_type: 'search';
    report_type: ReportType;
    file_type: ReportFileType;
    csv_email_report_recipients: string;
    report_request_params: string;
    schedule: {
        start_date: string;
        end_date?: string;
        occurrence: ReportOccurrence;
    };
}

export interface SetReportTypeAction {
    type: REPORT_FORM_ACTION_TYPES.SET_REPORT_TYPE;
    value: ReportType;
}

export interface SetStudyAction {
    type: REPORT_FORM_ACTION_TYPES.SET_STUDY;
    value: StudyDetails;
}

export interface SetFileTypeAction {
    type: REPORT_FORM_ACTION_TYPES.SET_FILE_TYPE;
    value: ReportFileType;
}

export interface AddEmailRecipientAction {
    type: REPORT_FORM_ACTION_TYPES.ADD_EMAIL_RECIPIENT;
    value: string;
}

export interface RemoveEmailRecipientAction {
    type: REPORT_FORM_ACTION_TYPES.REMOVE_EMAIL_RECIPIENT;
    value: string;
}

export interface SetStartDateAction {
    type: REPORT_FORM_ACTION_TYPES.SET_START_DATE;
    value: Date;
}

export interface SetEndDateAction {
    type: REPORT_FORM_ACTION_TYPES.SET_END_DATE;
    value: Date;
}

export interface SetOccurrenceAction {
    type: REPORT_FORM_ACTION_TYPES.SET_OCCURRENCE;
    value: ReportOccurrence;
}

export interface LoadExistingReportAction {
    type: REPORT_FORM_ACTION_TYPES.LOAD_EXISTING_REPORT;
    value: { report: ScheduledReport; study: StudyDetails };
}

export type ReportFormAction =
    | SetStudyAction
    | SetReportTypeAction
    | SetFileTypeAction
    | AddEmailRecipientAction
    | RemoveEmailRecipientAction
    | SetStartDateAction
    | SetEndDateAction
    | SetOccurrenceAction
    | LoadExistingReportAction;
