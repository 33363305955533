import './View-Scheduled-Report.scss';

import { useEffect, useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../../hooks';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';

// CONSTANTS
import { ROUTES } from '../../../router';

// TYPES
import { ScheduledReport } from '../../../types';
import { ReportFormState, ReportFiltersState } from '../../../state';

// API REQUESTS
import { editScheduledReport, getScheduledReports } from '../../../api';

// UTILS
import {
    generateEditReportBody,
    generateRequestParamsString,
} from '../../../utils';

// UI COMPONENTS
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';
import ReportForm from '../../../components/Report-Form/Report-Form';
import PageContainer from '../../../components/Page-Container/Page-Container';

// COMPONENT PROPS
interface ViewScheduledReportProps {
    isEditing?: boolean;
}

// FUNCTIONAL COMPONENT (VIEW SCHEDULED REPORT)
function ViewScheduledReport({ isEditing = false }: ViewScheduledReportProps) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { report_id } = useParams();
    const wrapper = useAsyncWrapper();

    // REDUX STATE
    const userEmail = useAppSelector(({ auth }) => auth.user?.email);

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // PAGE DATA STATE
    const [reportData, setReportData] = useState<ScheduledReport>();

    // FETCH REPORTS AND FIND MATCHING REPORT ID
    const loadReportData = wrapper(async (email: string, reportId: string) => {
        // FETCH REPORTS LIST
        const { report_list, error_message } = await getScheduledReports(email);

        if (!report_list || error_message) {
            throw new Error(error_message || 'Unable to fetch reports list.');
        }

        // FIND REPORT BY ID
        const reportIndex = report_list.findIndex(
            (report) => report.report_id.toString() === report_id
        );

        // THROW ERROR IF REPORT NOT FOUND
        if (reportIndex < 0) {
            navigate(ROUTES.SCHEDULED_REPORTS);
            throw new Error(
                `Unable to find scheduled report with id: ${reportId}`
            );
        }

        // STORE REPORT DATA
        setReportData({ ...report_list[reportIndex] });
    });

    // LOAD REPORT DATA ON INITIAL RENDER & REPORT_ID CHANGES
    useEffect(() => {
        if (state?.reportData) {
            setReportData({ ...state.reportData });
        } else if (userEmail && report_id) {
            loadReportData(userEmail, report_id);
        }
    }, [userEmail, report_id, isEditing]);

    // HANDLE EDIT REPORT SUBMISSION
    const onSubmitEditReport = wrapper(
        async (report: ReportFormState, filters: ReportFiltersState) => {
            if (!reportData) {
                return;
            }

            const requestParams = generateRequestParamsString(filters);
            const body = generateEditReportBody(
                report,
                requestParams,
                reportData
            );

            if (!body) {
                return;
            }

            // RETURNS REPORT DATA OR ERROR MESSAGE
            const response = await editScheduledReport(body);

            if (response.error_message) {
                throw new Error(response.error_message);
            }

            // DISPLAY SUCCESS MESSAGE
            alertSuccess('Successfully updated scheduled report.');

            // UPDATE REPORT DATA AND REDICT TO VIEW
            setReportData(response.report_details);
            navigate('/scheduled-reports/view/' + report_id);
        }
    );

    // JSX
    return (
        <PageContainer>
            <div className="scheduled-report-container">
                <div className="scheduled-report-header">
                    {!!reportData?.status && (
                        <div className="scheduled-report-status">
                            <Status
                                type={
                                    reportData.status === 'Active'
                                        ? STATUS_TYPE.SUCCESS
                                        : STATUS_TYPE.NOTELIGIBLETOSYNC
                                }
                                title={reportData.status}
                            />
                        </div>
                    )}
                    <h1 className="scheduled-report-title">
                        Report ID: {report_id}
                        {/* --- DISPLAY EDIT ICON ONLY FOR CREATOR --- */}
                        {!isEditing && reportData?.email === userEmail && (
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={`/scheduled-reports/edit/${report_id}`}
                                state={{ reportData }}
                            >
                                <i className="icon-edit" />
                            </Link>
                        )}
                    </h1>
                </div>
                <div className="scheduled-report-content">
                    <ReportForm
                        onSubmit={onSubmitEditReport}
                        onCancel={() => navigate(-1)}
                        report={reportData}
                        readOnly={!isEditing}
                    />
                </div>
            </div>
        </PageContainer>
    );
}

export default ViewScheduledReport;
