import { ReportType, ReportFileType, ScheduledReport } from '../../types';
import { ADS, AdsType } from '../../constants';
import {
    ReportFocus,
    StudyDetails,
    DropdownOption,
    ReportOccurrence,
    DEFAULT_REPORT_FORM_STATE,
    DEFAULT_REPORT_FILTERS_STATE,
} from '../../state';
import {
    parseTargets,
    parseKeywordGroups,
    parseCompetitorGroups,
} from './parse-study-details';

// PARSE REQUEST PARAMS STRING
export const parseReportParams = (reportParams: string) => {
    const filtersData = reportParams
        .split('&')
        .reduce((data: { [key: string]: string }, filter) => {
            const [filterName, filterValue] = filter.split('=');
            data[filterName] = filterValue;
            return data;
        }, {});

    return filtersData;
};

// CONVERTS REPORT START/END DATE FROM YYYY-MM-DD TO MM-DD-YYYY
export const formatReportDate = (dateString: string | null | undefined) => {
    if (!dateString) {
        return '';
    }
    const [year, month, day] = dateString.split('-');
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
};

export const formatReportFormData = (report: ScheduledReport) => {
    const { report_type, schedule, csv_email_report_recipients } = report;
    const state = { ...DEFAULT_REPORT_FORM_STATE };

    const start_date = formatReportDate(schedule.start_date);
    const end_date =
        schedule.end_date !== 'NA' ? formatReportDate(schedule.end_date) : '';

    state.report_type = report_type as ReportType;

    if (csv_email_report_recipients) {
        state.email_recipients = csv_email_report_recipients.split(',');
    }

    state.file_type = report.file_type as ReportFileType;

    state.start_date = new Date(start_date);

    state.end_date = end_date ? new Date(end_date) : '';

    state.occurrence = schedule.occurence as ReportOccurrence;

    return { ...state };
};

export const formatReportFilters = (
    report: ScheduledReport,
    study: StudyDetails
) => {
    // SET STATE TO DEFAULT
    const state = { ...DEFAULT_REPORT_FILTERS_STATE };

    // CREATE KEYWORD & KEYWORD GROUP DROPDOWN OPTIONS
    const [keywordGroupOptions, keywordOptions] = parseKeywordGroups(
        study.keyword_group_details
    );

    // CREATE COMPETITOR & COMPETITOR GROUP DROPDOWN OPTIONS
    const [competitorGroupOptions, competitorOptions] = parseCompetitorGroups(
        study.competitor_group_details
    );

    // CREATE TARGET DROPDOWN OPTIONS
    const [targetOptions] = parseTargets(study.locale_details);

    state.keywordGroupOptions = keywordGroupOptions;
    state.keywordOptions = keywordOptions;

    state.competitorGroupOptions = competitorGroupOptions;
    state.competitorOptions = competitorOptions;

    state.targetOptions = targetOptions;

    // PARSE SELECTED REPORT FILTERS FROM PARAMS STRING
    const filters = parseReportParams(report.report_request_params);

    // SET CHECKBOX VALUES
    state.zero_coverage = filters?.zero_coverage?.toString() === 'true';
    state.auto_competitor_group =
        filters?.auto_competitor_group?.toString() === 'true';
    state.compare_previous = filters?.compare_previous?.toString() === 'true';
    state.hourly = filters?.hourly?.toString() === 'true';
    state.daily = filters?.daily?.toString() === 'true';

    // SET SELECTED KEYWORD GROUPS
    if (filters?.keyword_groups) {
        // CREATE OBJECT OF ELIGIBLE KEYWORD GROUPS
        const optionsObj = keywordGroupOptions.reduce(
            (data: { [key: string]: string }, { label, value }) => {
                // value === group_id / label === group_name
                data[value] = label;
                return data;
            },
            {}
        );

        const selectedKeywordGroups: DropdownOption[] = [];

        // ADD SELECTED KEYWORD GROUPS TO STATE
        filters.keyword_groups.split(',').forEach((id) => {
            // CHECK IF KEYWORD GROUP IS STILL PRESENT IN STUDY
            if (optionsObj[id]) {
                // ADD KEYWORD GROUP DROPDOWN SELECTION
                selectedKeywordGroups.push({
                    label: optionsObj[id],
                    value: parseInt(id),
                });
            }
        });

        state.keyword_groups = [...selectedKeywordGroups];
    }

    // SET SELECTED KEYWORDS
    if (filters?.keywords) {
        // CREATE OBJECT OF ELIGIBLE KEYWORDS
        const optionsObj = keywordOptions.reduce(
            (data: { [key: string]: string }, { label, value }) => {
                // value === keyword_id /label === keyword_name
                data[value] = label;
                return data;
            },
            {}
        );

        const selectedKeywords: DropdownOption[] = [];

        filters.keywords.split(',').forEach((id) => {
            // CHECK IF KEYWORD IS STILL PRESET IN STUDY
            if (optionsObj[id]) {
                // ADD KEYWORD DROPDOWN SELECTION TO STATE
                selectedKeywords.push({
                    label: optionsObj[id],
                    value: parseInt(id),
                });
            }
        });
        state.keywords = [...selectedKeywords];
    }

    // SET SELECTED COMPETITOR GROUPS
    if (filters?.competitor_group) {
        // CREATE OBJECT OF COMPETITOR GROUPS
        const optionsObj = competitorGroupOptions.reduce(
            (data: { [key: string]: string }, { label, value }) => {
                // value === group_id /label === name_name
                data[value] = label;
                return data;
            },
            {}
        );

        const selectedCompetitorGroups: DropdownOption[] = [];

        // ADD SELECTED COMPETITOR GROUPS TO STATE
        filters.competitor_group.split(',').forEach((id) => {
            // CHECK IF COMPETITOR GROUP IS STILL PRESET IN STUDY
            if (optionsObj[id]) {
                // ADD COMPETITOR GROUP DROPDOWN SELECTION TO STATE
                selectedCompetitorGroups.push({
                    label: optionsObj[id],
                    value: parseInt(id),
                });
            }
        });

        state.competitor_group = [...selectedCompetitorGroups];
    }

    // SET SELECTED COMPETITORS
    if (filters.competitors) {
        // CREATE OBJECT OF ELIGIBLE COMPETITORS
        const optionsObj = competitorOptions.reduce(
            (data: { [key: string]: string }, { label, value }) => {
                // value === competitor_id /label === competitor_root_domain
                data[value] = label;
                return data;
            },
            {}
        );

        const selectedCompetitors: DropdownOption[] = [];

        filters.competitors.split(',').forEach((id) => {
            // CHECK IF COMPETITOR IS STILL PRESENT IN STUDY
            if (optionsObj[id]) {
                // ADD COMPETITOR DROPDOWN SELECTION TO STATE
                selectedCompetitors.push({
                    label: optionsObj[id],
                    value: parseInt(id),
                });
            }
        });

        state.competitors = [...selectedCompetitors];
    }

    // SET SELECTED TARGETS
    if (filters.targets) {
        // CREATE OBJECT OF ELIGIBLE TARGETS
        const optionsObj = targetOptions.reduce(
            (data: { [key: string]: string }, { label, value }) => {
                // value === competitor_id /label === competitor_root_domain
                data[value] = label;
                return data;
            },
            {}
        );

        const selectedTargets: DropdownOption[] = [];

        filters.targets.split(',').forEach((id) => {
            // CHECK IF TARGET IS STILL PRESENT IN STUDY
            if (optionsObj[id]) {
                // ADD TARGET DROPDOWN SELECTION TO STATE
                selectedTargets.push({
                    label: optionsObj[id],
                    value: parseInt(id),
                });
            }
        });

        state.targets = [...selectedTargets];
    }

    // SET SELECTED AD TYPES
    if (filters.type) {
        const adTypes = filters.type
            .split(',')
            .filter((adType) => ADS.includes(adType as AdsType))
            .map((adType) => ({ label: adType, value: adType }));
        state.type = [...adTypes];
    }

    // SET SELECTED METRICS
    if (filters.metrics) {
        state.metrics = filters.metrics.split(',').map((metric) => ({
            label: metric,
            value: metric,
        }));
    }

    // SET SELCTED TIMEZONE
    if (filters.time_zone) {
        state.time_zone = filters.time_zone;
    }

    // SET COMPETITOR LIMIT
    if (filters.competitor_limit) {
        state.competitor_limit = parseInt(filters.competitor_limit);
    }

    // SET TOP WORDS
    if (filters.top_words) {
        state.top_words = parseInt(filters.top_words);
    }

    // SET FOCOUS
    if (filters.focus) {
        state.focus = filters.focus as ReportFocus;
    }

    return { ...state };
};
