import { axios } from '../axios';

// TYPES
import {
    StatusType,
    StudyDetails,
    GETStudyResponse,
    GETStudiesActivity,
    GETStudyListResponse,
} from '../../state';
import {
    PUTStudyBody,
    POSTCreateStudy,
    GETUserAccountsList,
} from '../../types';

// CONSTANTS
import { API_ROUTES, PROVIDER_IDS } from '../../constants';

// UTILS
import { saveDownloadFile } from '../../utils';

// FETCH STUDY LIST
export const getStudyList = async () => {
    const url = API_ROUTES.GET_STUDIES;

    const { data } = await axios.get<GETStudyListResponse>(url);

    return data.studies;
};

// FETCH STUDY BY ID
export const getStudyById = async (id: number | string) => {
    const url = API_ROUTES.GET_STUDY_BY_ID + `/${id}`;

    const { data: study } = await axios.get<GETStudyResponse>(url);
    const details: StudyDetails = { ...study.study_details, id };

    return details;
};

// FETCH USER'S LIST OF ELIGIBLE ACCOUNTS
export const getAccountList = async () => {
    const url = '/users/accounts/accountlist';

    const {
        data: { accounts },
    } = await axios.get<GETUserAccountsList>(url);
    return accounts || [];
};

// EXPORT ACTIVE STUDIES (ADMIN ONLY)
export const exportActiveStudies = async () => {
    const url = API_ROUTES.EXPORT_STUDIES;
    const fileName = 'Export_Active_Studies-' + new Date().toDateString();

    const blob = await axios.get(url, { responseType: 'blob' });

    saveDownloadFile(blob, fileName);
};

// CREATE NEW STUDY
export const createStudy = async (body: POSTCreateStudy) => {
    const url = '/studies/add';

    const {
        data: { message },
    } = await axios.post<{ message: string }>(url, {
        ...body,
    });
    const study_id = message.split(' ').pop();

    return { study_id, message };
};

// UPDATE STUDY DETAILS
export const updateStudy = async (
    study_id: number | string,
    body: PUTStudyBody
) => {
    const url = `/studies/update/${study_id}`;

    const { data } = await axios.put<{ message: string }>(url, { ...body });

    return data;
};

// UPDATE STUDY STATUS
export const updateStudyStatus = async (
    study_id: string | number,
    status: StatusType
) => {
    const url = `/studies/update/${study_id}`;

    const { data } = await axios.put<{ message: string }>(url, { status });

    return data;
};

// DELETE STUDY LOCALE
export const deleteStudyLocale = async (
    study_id: number | string,
    locale_id: number | string
) => {
    const url = `/studies/locale/${study_id}?locale_id=${locale_id}`;

    const { data } = await axios.delete<{ message: string }>(url);

    return data;
};

// DELETE STUDY KEYWORD GROUP
export const deleteStudyKeywordGroup = async (
    study_id: number | string,
    keyword_group_id: number | string
) => {
    const url = `/studies/keyword-groups/${study_id}?keyword_groups=${keyword_group_id}`;

    const { data } = await axios.delete<{ message: string }>(url);

    return data;
};

// DELETE STUDY KEYWORDS
export const deleteStudyKeywords = async (
    study_id: string | number,
    keyword_ids: (string | number)[]
) => {
    if (!keyword_ids.length) {
        return { message: null };
    }
    const url = `/studies/keywords/${study_id}?keywords=${keyword_ids
        .join(',')
        .trim()}`;

    const { data } = await axios.delete<{ message: string }>(url);

    return data;
};

// DELETE STUDY COMPETITOR GROUP
export const deleteStudyCompetitorGroup = async (
    study_id: number | string,
    competitor_group_id: number | string
) => {
    const url = `/studies/competitor-groups/${study_id}?competitor_groups=${competitor_group_id}`;

    const { data } = await axios.delete<{ message: string }>(url);

    return data;
};

// DELETE STUDY COMPETITORS
export const deleteStudyCompetitors = async (
    study_id: string | number,
    competitor_ids: (string | number)[]
) => {
    if (!competitor_ids.length) {
        return { message: null };
    }
    const url = `/studies/competitors/${study_id}?competitors=${competitor_ids
        .join(',')
        .trim()}`;

    const { data } = await axios.delete<{ message: string }>(url);

    return data;
};

// UPDATE STUDY PROVIDER
export const updateStudyProvider = async (
    studyId: string,
    providerId: PROVIDER_IDS
) => {
    const url = API_ROUTES.UPDATE_STUDY_PROVIDER + `/${studyId}`;

    const { data } = await axios.put(url, { provider_id: providerId });

    return data;
};

// FETCH STUDIES ACTIVITY LOGS
export const getStudiesActivity = async () => {
    const { data } = await axios.get<GETStudiesActivity>(
        API_ROUTES.GET_STUDIES_ACTIVITY
    );

    return data;
};
