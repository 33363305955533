import './Competitor-Radials.scss';

import { useMemo } from 'react';

// TYPES
import {
  DropdownOption,
  AveragePositionData,
  ShareOfImpressionsData,
} from '../../../state';

// UI COMPONENTS
import ChartWrapper from '../Chart-Wrapper/Chart-Wrapper';
import RadialBarGroup, {
  RadialBarGroupData,
} from '../Radial-Bars/Radial-Bar-Group/Radial-Bar-Group';

// COMPONENT PROPS
interface CompetitorRadialsProps {
  dateRange: string;
  impressionsData?: ShareOfImpressionsData | null;
  value: DropdownOption | null;
  competitors: DropdownOption[];
  positionData?: AveragePositionData | null;
  onChange: (selection: DropdownOption) => void;
  // TODO
  // spendData?: any;
  // clicksData?: any;
}

interface PositionDetails {
  current: number | null;
  prior: number | null;
}

interface BrandData {
  Desktop: PositionDetails;
  Smartphone: PositionDetails;
}

const defaultBrandData: BrandData = {
  Desktop: { current: null, prior: null },
  Smartphone: { current: null, prior: null },
};

// FUNCTIONAL COMPONENT (COMPETITOR RADIALS)
function CompetitorRadials({
  value,
  onChange,
  dateRange,
  competitors,
  positionData,
  impressionsData,
}: // TODO
// spendData,
// clicksData,
CompetitorRadialsProps) {
  const defaultData: RadialBarGroupData[] = [
    {
      name: 'Desktop',
      current: null,
    },
    { name: 'Mobile', current: null },
  ];

  // const clicks = useMemo(() => {
  //   if (!clicksData) return defaultData;
  //   // TODO
  //   return defaultData;
  // }, [clicksData, value]);

  // const spend = useMemo(() => {
  //   if (!spendData) return defaultData;
  //   // TODO
  //   return defaultData;
  // }, [spendData, value]);

  const impressions = useMemo(() => {
    if (!impressionsData) return defaultData;

    // FIND SELECTED COMPETITOR DATA
    const brandData = value ? impressionsData[value.label] : null;

    if (!brandData) return defaultData;

    // FORMAT SELECTED COMPETITOR DATA
    const brandImps = brandData.reduce(
      (acc, details) => {
        if (acc[details.device]) {
          acc[details.device] = {
            current:
              Math.round(parseFloat(details.current_impressions || '')) || null,
            prior:
              Math.round(parseFloat(details.prior_impressions || '')) || null,
          };
        }
        return acc;
      },
      { ...defaultBrandData }
    );

    return [
      {
        name: 'Desktop',
        current: brandImps.Desktop.current,
        prior: brandImps.Desktop.prior,
      },
      {
        name: 'Mobile',
        current: brandImps.Smartphone.current,
        prior: brandImps.Smartphone.prior,
      },
    ];
  }, [impressionsData, value]);

  const position = useMemo(() => {
    if (!positionData) return defaultData;

    // FIND SELECTED COMPETITOR DATA
    const brandData = value ? positionData[value.label] : null;

    if (!brandData) return defaultData;

    // FORMAT SELECTED COMPETITOR DATA
    const brand = brandData.reduce(
      (acc, details) => {
        if (acc[details.device]) {
          acc[details.device] = {
            current:
              Math.round(parseFloat(details.current_average_position || '')) ||
              null,
            prior:
              Math.round(parseFloat(details.prior_average_position || '')) ||
              null,
          };
        }
        return acc;
      },
      { ...defaultBrandData }
    );

    return [
      {
        name: 'Desktop',
        current: brand.Desktop.current,
        prior: brand.Desktop.prior,
      },
      {
        name: 'Mobile',
        current: brand.Smartphone.current,
        prior: brand.Smartphone.prior,
      },
    ];
  }, [positionData, value]);

  // JSX
  return (
    <ChartWrapper
      options={competitors}
      title={'Brand Ownership'}
      value={value}
      onChange={onChange}
      dateRange={dateRange}
    >
      <div className="competitor-radials-container">
        {/* --- SHARE OF CLICKS --- */}
        {/* <RadialBarGroup title="My Share of Clicks" data={clicks} /> */}

        {/* --- SHARE OF IMPRESSIONS --- */}
        <RadialBarGroup title="Share of Impressions" data={impressions} />

        {/* --- SHARE OF SPEND --- */}
        {/* <RadialBarGroup title="My Share of Spend" data={spend} /> */}

        {/* --- AVERAGE POSIITION --- */}
        <RadialBarGroup title="Average Position" data={position} min={10} />
      </div>
    </ChartWrapper>
  );
}

export default CompetitorRadials;
