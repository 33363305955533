import { StudyDetails } from '../../state';
import {
    NewLocaleLocationProps,
    NewLocaleState,
    PUTStudyBody,
} from '../../types/edit-study-types';

interface UpdatedStudyDetails {
    name?: string;
    frequency?: string | number;
    status?: string;
    newLocale?: NewLocaleState;
    keyword_group?: { [key: string]: string[] };
    competitor_group?: { [key: string]: string[] };
}

export const generateUpdateStudyBody = (
    study: StudyDetails,
    updatedDetails: UpdatedStudyDetails
): PUTStudyBody => {
    const { newLocale } = updatedDetails;
    const localeCopy = newLocale ? { ...newLocale } : null;
    if (localeCopy) {
        const locationCopy = { ...localeCopy.location };
        for (const [key, value] of Object.entries(locationCopy)) {
            locationCopy[key as NewLocaleLocationProps] = value.trim();
        }
        localeCopy.location = locationCopy;
    }
    return {
        name: updatedDetails.name?.trim() || study.name,
        type: study.type,
        frequency: updatedDetails.frequency || study.frequency,
        status: updatedDetails.status || study.status,
        locale: localeCopy ? [localeCopy] : [],
        keyword_group: updatedDetails.keyword_group || {},
        competitor_group: updatedDetails.competitor_group || {},
    };
};
