import produce from 'immer';
import { AlertAction } from '../actions';
import { ALERTS_ACTION_TYPES } from '../action-types';

interface AlertState {
    successMessages: string[];
    errorMessages: string[];
}

const DEFAULT_ALERTS_STATE: AlertState = {
    successMessages: [],
    errorMessages: [],
};

export const alertsReducer = produce(
    (
        state: AlertState = DEFAULT_ALERTS_STATE,
        action: AlertAction
    ): AlertState => {
        switch (action.type) {
            case ALERTS_ACTION_TYPES.ALERT_SUCCESS:
                state.successMessages.push(action.payload.message);
                return state;

            case ALERTS_ACTION_TYPES.ALERT_ERROR:
                state.errorMessages.push(action.payload.message);
                return state;

            case ALERTS_ACTION_TYPES.CLEAR_SUCCESS_ALERT:
                state.successMessages = [];
                return state;

            case ALERTS_ACTION_TYPES.CLEAR_ERROR_ALERT:
                state.errorMessages = [];
                return state;

            case ALERTS_ACTION_TYPES.CLEAR_ALL_ALERTS:
                state.errorMessages = [];
                state.successMessages = [];
                return state;

            default:
                return state;
        }
    },
    DEFAULT_ALERTS_STATE
);
