import { CreateStudyState } from './create-study-state';

export enum CreateStudyActionTypes {
    UPDATE_NAME = 'update_name',
    UPDATE_FREQUENCY = 'update_frequency',
    UPDATE_ACCOUNT_ID = 'update_account_id',
    UPDATE_CLIENT_ID = 'update_client_id',
    ADD_LOCALE = 'add_locale',
    DELETE_LOCALE = 'delete_locale',
    ADD_COMPETITOR_GROUP = 'add_competitor_group',
    UPDATE_COMPETITOR_GROUP = 'update_competitor_group',
    DELETE_COMPETITOR_GROUP = 'delete_competitor_group',
    ADD_KEYWORD_GROUP = 'add_keyword_group',
    UPDATE_KEYWORD_GROUP = 'update_keyword_group',
    DELETE_KEYWORD_GROUP = 'delete_keyword_group',
}

export enum CreateStudyStateProps {
    NAME = 'name',
    FREQUENCY = 'frequency',
    ACCOUNT_ID = 'account_id',
    CLIENT_ID = 'client_id',
    LOCALE = 'locale',
    COMPETITOR_GROUP = 'competitor_group',
    KEYWORD_GROUP = 'keyword_group',
}

export interface UpdateStudyNameAction {
    type: CreateStudyActionTypes.UPDATE_NAME;
    value: CreateStudyState[CreateStudyStateProps.NAME];
}
export interface UpdateStudyFrequencyAction {
    type: CreateStudyActionTypes.UPDATE_FREQUENCY;
    value: CreateStudyState[CreateStudyStateProps.FREQUENCY];
}
export interface UpdateStudyAccountIdAction {
    type: CreateStudyActionTypes.UPDATE_ACCOUNT_ID;
    value: CreateStudyState[CreateStudyStateProps.ACCOUNT_ID];
}
export interface UpdateStudyClientIdAction {
    type: CreateStudyActionTypes.UPDATE_CLIENT_ID;
    value: CreateStudyState[CreateStudyStateProps.CLIENT_ID];
}
export interface AddStudyLocaleAction {
    type: CreateStudyActionTypes.ADD_LOCALE;
    value: CreateStudyState[CreateStudyStateProps.LOCALE][0];
}
export interface DeleteStudyLocaleAction {
    type: CreateStudyActionTypes.DELETE_LOCALE;
    value: string | number;
}
export interface AddStudyCompetitorGroupAction {
    type: CreateStudyActionTypes.ADD_COMPETITOR_GROUP;
    value: CreateStudyState[CreateStudyStateProps.COMPETITOR_GROUP][0];
}
export interface UpdateStudyCompetitorGroupAction {
    type: CreateStudyActionTypes.UPDATE_COMPETITOR_GROUP;
    value: CreateStudyState[CreateStudyStateProps.COMPETITOR_GROUP][0];
}
export interface DeleteStudyCompetitorGroupAction {
    type: CreateStudyActionTypes.DELETE_COMPETITOR_GROUP;
    value: string | number;
}
export interface AddStudyKeywordGroupAction {
    type: CreateStudyActionTypes.ADD_KEYWORD_GROUP;
    value: CreateStudyState[CreateStudyStateProps.KEYWORD_GROUP][0];
}
export interface UpdateStudyKeywordGroupAction {
    type: CreateStudyActionTypes.UPDATE_KEYWORD_GROUP;
    value: CreateStudyState[CreateStudyStateProps.KEYWORD_GROUP][0];
}
export interface DeleteStudyKeywordGroupAction {
    type: CreateStudyActionTypes.DELETE_KEYWORD_GROUP;
    value: string | number;
}

export type CreateStudyActions =
    | UpdateStudyNameAction
    | UpdateStudyFrequencyAction
    | UpdateStudyAccountIdAction
    | UpdateStudyClientIdAction
    | AddStudyLocaleAction
    | DeleteStudyLocaleAction
    | AddStudyCompetitorGroupAction
    | UpdateStudyCompetitorGroupAction
    | DeleteStudyCompetitorGroupAction
    | AddStudyKeywordGroupAction
    | UpdateStudyKeywordGroupAction
    | DeleteStudyKeywordGroupAction;
