import produce from 'immer';
import { initialNewStudyState } from './model';
import { CreateStudyState } from '../../../types/create-study-types/create-study-state';
import {
    CreateStudyActions,
    CreateStudyActionTypes,
} from '../../../types/create-study-types/create-study-actions';
import { NewLocaleLocationProps } from '../../../types/edit-study-types';

export const createStudyReducer = produce(
    (state: CreateStudyState, action: CreateStudyActions) => {
        switch (action.type) {
            case CreateStudyActionTypes.UPDATE_NAME:
                state.name = action.value;
                return state;

            case CreateStudyActionTypes.UPDATE_FREQUENCY:
                state.frequency = action.value;
                return state;

            case CreateStudyActionTypes.UPDATE_ACCOUNT_ID:
                state.account_id = action.value;
                return state;

            case CreateStudyActionTypes.UPDATE_CLIENT_ID:
                state.client_id = action.value;
                return state;

            case CreateStudyActionTypes.ADD_LOCALE: {
                const localeCopy = { ...action.value };
                const locationCopy = { ...localeCopy.location };
                for (const [key, value] of Object.entries(locationCopy)) {
                    locationCopy[key as NewLocaleLocationProps] = value.trim();
                }
                localeCopy.location = locationCopy;
                state.locale.push(localeCopy);
                return state;
            }

            case CreateStudyActionTypes.DELETE_LOCALE:
                state.locale = state.locale.filter(
                    (locale) => locale.id !== action.value
                );
                return state;

            case CreateStudyActionTypes.ADD_COMPETITOR_GROUP:
                state.competitor_group.push(action.value);
                return state;

            case CreateStudyActionTypes.UPDATE_COMPETITOR_GROUP: {
                const competitorIndex = state.competitor_group.findIndex(
                    (group) =>
                        group.id.toString() === action.value.id.toString()
                );
                if (competitorIndex > -1) {
                    state.competitor_group[competitorIndex] = action.value;
                }
                return state;
            }

            case CreateStudyActionTypes.DELETE_COMPETITOR_GROUP:
                state.competitor_group = state.competitor_group.filter(
                    (group) => group.id.toString() !== action.value.toString()
                );
                return state;

            case CreateStudyActionTypes.ADD_KEYWORD_GROUP:
                state.keyword_group.push(action.value);
                return state;

            case CreateStudyActionTypes.UPDATE_KEYWORD_GROUP: {
                const keywordIndex = state.keyword_group.findIndex(
                    (group) =>
                        group.id.toString() === action.value.id.toString()
                );
                if (keywordIndex > -1) {
                    state.keyword_group[keywordIndex] = action.value;
                }
                return state;
            }

            case CreateStudyActionTypes.DELETE_KEYWORD_GROUP:
                state.keyword_group = state.keyword_group.filter(
                    (group) => group.id.toString() !== action.value.toString()
                );
                return state;

            default:
                return state;
        }
    },
    initialNewStudyState
);
