import React from 'react';
import { ApexOptions } from 'apexcharts';

// TYPES
import { KeywordsReportData } from '../../state';

// UI COMPONENTS
import { Bubble } from '@publicismedia-ds/ui-charts';

// UTILS
import { calcBrandPresence } from '../../utils';

// COMPONENT PROPS
interface BrandPresenceChartProps {
  data: KeywordsReportData;
  studyName: string;
}

// FUNCTIONAL COMPONENT
function BrandPresenceChart({ data, studyName }: BrandPresenceChartProps) {
  // FORMAT BRAND PRESENCE CHART DATA
  const brandPresence = calcBrandPresence(data);

  const chartData = Object.entries(brandPresence).reduce(
    (
      data: { [key: string]: any[] },
      [brand, { rank, coverage, creativeCount }]
    ) => {
      if (!data[brand]) {
        data[brand] = [];
      }
      data[brand].push([coverage, rank, creativeCount]);
      return data;
    },
    {}
  );

  const options: ApexOptions = {
    chart: {
      type: 'bubble',
      offsetX: 0,
      offsetY: 0,
      toolbar: {
        tools: {
          download: true,
        },
        export: {
          svg: {
            filename: `${studyName}__brand-presence`,
          },
          png: {
            filename: `${studyName}__brand-presence`,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient', // 3d bubble
    },
    title: {
      text: 'Brand Presence',
    },
    noData: {
      text: 'No data found',
      style: {
        fontSize: '1.5rem',
      },
    },
    plotOptions: {
      bubble: {
        minBubbleRadius: 5,
      },
    },
    xaxis: {
      type: 'category',
      tickAmount: 10,
      min: 0,
      max: 100,
      title: {
        text: 'Coverage (%)',
        offsetY: 85,
      },
    },
    yaxis: {
      reversed: true,
      min: 0,
      max: 10,
      decimalsInFloat: 0,
      title: {
        text: 'Rank',
      },
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }) => {
        return `
                     <div class="pmds-chart-apex__tooltip">
                        <p>
                        <strong>
                        ${w.config.series[seriesIndex].name}
                        </strong>
                        </p>
                        <p>Coverage:
                        <strong>
                        ${w.config.series[seriesIndex].data[dataPointIndex][0]}
                        </strong>
                        </p>
                        <p>Rank:
                        <strong>
                        ${w.config.series[seriesIndex].data[dataPointIndex][1]}
                        </strong>
                        </p>
                        <p>Creatives:
                        <strong>
                        ${w.config.series[seriesIndex].data[dataPointIndex][2]}
                        </strong>
                        </p> 
                        
                     </div>
                     `;
      },
      fixed: {
        enabled: true,
        offsetX: -200,
      },
    },
  };

  const series = Object.entries(chartData).map(([name, data]) => {
    return {
      name,
      data,
    };
  });

  return (
    <div className="rank-coverage-chart-containter">
      <div className="rank-coverage-chart-content">
        <Bubble
          data={series}
          options={options}
          width="100%"
          id="brand-presence"
        />
      </div>
    </div>
  );
}

export default React.memo(BrandPresenceChart);
