import { axios } from '../axios';

// CONSTANTS
import { API_ROUTES } from '../../constants';

// TYPES
import {
  GETTopAds,
  DarwinFilters,
  GETBrandsReport,
  AdhocReportState,
  GETAgencyDetails,
  GETShareOfClicks,
  GETAveragePosition,
  GETNewAdsLast7Days,
  GETShareOfImpressions,
  GETDailyBattlefieldData,
} from '../../state';

// UTILS
import { getAdhocParams } from '../../utils';

// FETCH AVERAGE POSITION CHART DATA
export const getAveragePosition = async (filters: AdhocReportState) => {
  const url = API_ROUTES.GET_AVERAGE_POSITION + `/${filters.study?.id}`;
  const params = getAdhocParams(filters, { includePrior: true });
  const { data } = await axios.get<GETAveragePosition>(url, { params });
  return data.data;
};

// FETCH NEW ADS SEEN LAST 7 DAYS
export const getNewAdsLast7Days = async (study_id: string | number) => {
  const url = API_ROUTES.GET_NEW_ADS_SEEN + `/${study_id}`;
  const { data } = await axios.get<GETNewAdsLast7Days>(url);
  return data.data;
};

// FETCH PAID VS ORGANIC DATA
export const getPaidVsOrganic = async (filters: AdhocReportState) => {
  if (!filters.study) {
    throw new Error('Please select an active study');
  }
  const params = getAdhocParams(filters);
  const { data } = await axios.get(
    API_ROUTES.GET_PAID_VS_ORGANIC + `/${filters.study.id}`,
    { params }
  );

  return data.data;
};

// FETCH BRANDS REPORT
export const getBrandsReport = async (filters: AdhocReportState) => {
  if (!filters.study) {
    throw new Error('Please select an active study');
  }
  const params = getAdhocParams({
    ...filters,
    type: [
      { label: 'sem', value: 'sem' },
      { label: 'pla', value: 'pla' },
    ],
  });
  const { data } = await axios.get<GETBrandsReport>(
    API_ROUTES.GET_BRANDS_JSON + `/${filters.study.id}`,
    { params }
  );
  return data.data || null;
};

// FETCH TOP ADS DATA
export const getTopAdsData = async (filters: AdhocReportState) => {
  if (!filters.study) {
    throw new Error('Please select an active study');
  }
  const params = getAdhocParams(filters);
  const { data } = await axios.get<GETTopAds>(
    API_ROUTES.GET_TOP_ADS + `/${filters.study.id}`,
    { params }
  );
  return data.data || null;
};

export const getDarwinParams = (filters: DarwinFilters) => {
  return {
    start_date: filters.start_date,
    end_date: filters.end_date,
    agency_ids: filters.agency_ids.map(({ value }) => value).join(','),
    engine_ids: filters.engine_ids.map(({ value }) => value).join(','),
  };
};

// FETCH SHARE OF CLICKS
export const getShareOfClicks = async (filters: DarwinFilters) => {
  const params = getDarwinParams(filters);
  const { data } = await axios.get<GETShareOfClicks>(
    API_ROUTES.GET_SHARE_OF_CLICKS,
    { params }
  );
  return data.data;
};

// FETCH AGENCY DETAILS BY ID
export const getAgencyDetails = async (
  agencyId: string | number,
  userId: string | number
) => {
  const { data } = await axios.get<GETAgencyDetails>('', {
    params: {
      agencyId,
      userId,
    },
    baseURL: API_ROUTES.GET_AGENCY_DETAILS,
  });
  if (data.status === 'failure') {
    throw new Error(data.message);
  }
  return data;
};

// FETCH SHARE OF IMPRESSIONS
export const getShareOfImpressions = async (filters: AdhocReportState) => {
  const adhocParams = getAdhocParams(filters, { includePrior: true });
  const params = {
    current_start_date: adhocParams.start_date,
    current_end_date: adhocParams.end_date,
    prior_start_date: adhocParams.prior_start_date,
    prior_end_date: adhocParams.prior_end_date,
  };
  const { data } = await axios.get<GETShareOfImpressions>(
    API_ROUTES.GET_SHARE_OF_IMPRESSIONS + `/${filters.study?.id}`,
    { params }
  );
  return data.impression_data || null;
};

// FETCH BATTLEFIELD REPORT
export const getDailyBattlefieldData = async (filters: AdhocReportState) => {
  const params = getAdhocParams(filters);
  params.daily = true;
  const { data } = await axios.get<GETDailyBattlefieldData>(
    API_ROUTES.GET_BATTLEFIELD_JSON + `/${filters.study?.id}`,
    { params }
  );

  return data.data;
};
