import { useMemo, useState } from 'react';

// CONSTANTS
import { DATA_VIZ_COLORS } from '../../constants';

// TYPES
import { DropdownOption, DailyBattlefieldData } from '../../state';
import { ApexOptions } from 'apexcharts';

// UI COMPONENTS
import ReactApexChart from 'react-apexcharts';
import ChartWrapper from './Chart-Wrapper/Chart-Wrapper';
import CustomApexLegend from './Custom-Apex-Legend/Custom-Apex-Legend';

// COMPONENT PROPS
interface DailyBattlefieldChartProps {
  data: DailyBattlefieldData | null;
  dateRange: string | null;
  competitors: DropdownOption[];
  onChange: (selected: DropdownOption) => void;
  value: DropdownOption | null;
}

// FUNCTIONAL COMPONENT (DAILY BATTLEFIELD DATA BY BRAND)
function DailyBattlefieldChart({
  data,
  competitors,
  onChange,
  value,
  dateRange,
}: DailyBattlefieldChartProps) {
  const AD_TYPES = ['SEM', 'SEO', 'PLA'];
  const [adType, setAdtype] = useState(AD_TYPES[0]);
  const { categories, series } = useMemo(() => {
    const categories = Object.keys(data || {}).map((dateStr) => dateStr);

    const series: ApexAxisChartSeries = [];
    const brandName = value?.label;

    if (data && brandName) {
      /* 
          In order for the chart to display correctly data must be formatted as follow:
            {
              name: "Position 1",
              data: [day1TimeSeen, day2TimeSeen, day3TimeSeen,etc...]
            },
            {
              name: "Position 2",
              data: [day1TimeSeen, day2TimeSeen, dayTimeSeen, etc...]
            }
          Each data array must contain the same number of items with the value for each
          "day" always at the same index (ie day1 at index 0, day2 at index 1, etc..)
          
          However, not all positions are present for each day.  This leads to arrays of
          varying lengths causing the data to be improply displayed.
          
          Solution:
          1. Check if data is already present for each position.
             a. Create an array and fill with N 0s as default. (N === categories.length)
          3. Replace with value (time_seen) - if present - at each index
          */
      const tempSeries: { [key: string]: number[] } = {};
      const cutoff = 9; // display > values a single sum

      // LOOP THROUGH EACH CATEGORY (DAY)
      for (let i = 0; i < categories.length; i++) {
        let total = 0;
        // Get ad for day -> brand -> adType
        const adData = data[categories[i]][brandName][adType];

        if (adData) {
          // Format ad data for use in chart
          for (const { position, time_seen } of adData) {
            const positionName =
              adType === 'PLA' && position > cutoff
                ? `position ${cutoff + 1}+`
                : `position ${position}`;

            // Set default values if position data doesn't exist
            if (!tempSeries[positionName]) {
              tempSeries[positionName] = new Array(categories.length).fill(0);
            }

            // Set position value for a given day based on index
            tempSeries[positionName][i] =
              position > cutoff
                ? Math.round((100 - total) * 1000) / 1000
                : Math.round(time_seen);

            // Stop if cutoff has been reached
            if (position > cutoff) break;

            // Keep track of total if cutoff is reached
            total += Math.round(time_seen);
          }
        }
      }

      // Sort data by postioin value
      const positionNames = Object.keys(tempSeries).sort((a, b) => {
        const postionA = parseInt(a.split(' ')[1]);
        const postionB = parseInt(b.split(' ')[1]);
        if (postionA < postionB) return -1;
        if (postionA > postionB) return 1;
        return 0;
      });

      // Ad formatted data to series for chart
      for (const name of positionNames) {
        series.push({
          name,
          data: tempSeries[name],
        });
      }
    }

    return { categories, series };
  }, [data, value, adType]);

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    dataLabels: {
      enabled: true,
      formatter: (val: number) =>
        Math.round(val) < 2 ? '' : Math.round(val) + '%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2rem',
      },
    },
    xaxis: {
      categories,
      decimalsInFloat: 0,
    },
    fill: {
      opacity: 1,
    },
    colors: DATA_VIZ_COLORS,
    legend: {
      show: true,
    },
  };

  const chartHeight = Math.max(categories.length * 70, 350);

  return (
    <div className="battlefield-chart-container">
      <ChartWrapper
        title="Battlefield"
        tooltipText="Illustrates the percentage of time a selected brand was seen in each position, segmented by day and ad type (SEM/SEO/PLA)."
        dateRange={dateRange || 'N/A'}
        options={competitors}
        value={value}
        onChange={onChange}
      >
        <CustomApexLegend
          options={AD_TYPES}
          value={adType}
          colors={['#7f7f7f']}
          onClick={(value) => setAdtype(value)}
        />
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={chartHeight}
        />
      </ChartWrapper>
    </div>
  );
}

export default DailyBattlefieldChart;
