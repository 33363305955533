import ReactDOM           from 'react-dom';
import { store }          from './state';
import { Provider }       from 'react-redux';
import { BrowserRouter }  from 'react-router-dom';

import App                from './App';
import reportWebVitals    from './reportWebVitals';

const root = document.getElementById('app');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    root
);

reportWebVitals();
