// UTILS
import { Word } from 'react-wordcloud';
import { convertDateToString } from '.';
import { REPORT_TYPE } from '../constants';

// TYPES
import {
  InsightsState,
  InsightsParams,
  BrandPresenceData,
  KeywordsReportData,
  SERPVisibilityDataItem,
  WordAnalysisBrandsData,
  WordAnalysisBrandDetails,
  DailySERPVisibilityByBrand,
} from '../state';

// FORMAT REPORT PARAMS (KEYWORD, SERP VISIBILITY, WORD ANALYSIS)
export const getInsightsParams = (
  filters: InsightsState,
  reportType?: REPORT_TYPE
) => {
  const isKeywordsMetrics = reportType === REPORT_TYPE.KEYWORDS_METRICS;

  const params: InsightsParams = {
    start_date: convertDateToString(new Date(filters.startDate)),
    end_date: convertDateToString(
      new Date(isKeywordsMetrics ? filters.startDate : filters.endDate)
    ),
    type: isKeywordsMetrics ? 'sem' : filters.adType,
  };

  if (!isKeywordsMetrics && filters.competitorsGroup) {
    params.competitor_group = filters.competitorsGroup.toString();
  }

  if (filters.competitors.length) {
    params.competitors = filters.competitors
      .map(({ value }) => value)
      .join(',');
  }

  if (!isKeywordsMetrics && filters.keywordsGroup) {
    params.keyword_groups = filters.keywordsGroup.toString();
  }

  if (filters.keywords.length) {
    params.keywords = filters.keywords.map(({ value }) => value).join(',');
  }

  if (filters.targets.length) {
    params.targets = filters.targets.map(({ value }) => value).join(',');
  }

  return params;
};

// CALCULATE BRAND PRESENCE AVERAGE FOR EACH BRAND (BRAND PRESENCE CHART)
export const calcBrandPresence = (data: KeywordsReportData) => {
  const result = Object.keys(data).reduce(
    (brandAverages: BrandPresenceData, brandName) => {
      const numTargets = data[brandName].length;
      let totalCoverage = 0;
      let totalRank = 0;
      let totalCreativeCount = 0;

      data[brandName].forEach((targetData) => {
        totalCoverage += targetData.coverage;
        totalRank += targetData.rank;
        totalCreativeCount += targetData.creativeCount;
      });

      brandAverages[brandName] = {
        rank: parseFloat((totalRank / numTargets).toFixed(1)),
        coverage: Math.round((totalCoverage / numTargets) * 100),
        creativeCount: parseFloat((totalCreativeCount / numTargets).toFixed(1)),
      };

      return brandAverages;
    },
    {}
  );

  return result;
};

// FORMAT DATA FOR DAILY SERP VISIBILITY CHART
export const formatDailySERPVisibilityByBrand = (
  data: SERPVisibilityDataItem[]
) => {
  // UNIQUE BRANDS OBJECT
  const brandsObj: { [key: string]: string } = {};

  // CREATE OBJECT OF UNIQUE DATES
  const formattedSerpData = data.reduce(
    (serpData: DailySERPVisibilityByBrand, rowData) => {
      const { Date, Brand, Visibility } = rowData;

      brandsObj[Brand] = Brand;

      // CREATE NEW DATE PROPERTY IF UNDEFINED
      if (!serpData[Date]) {
        serpData[Date] = {};
      }

      // SETP DATE -> BRAND -> VISIBILITY AVERAGE TO 0
      if (!serpData[Date][Brand]) {
        serpData[Date][Brand] = {
          numValues: 0,
          totalValues: 0,
        };
      }

      // INCREMENT NUM VALUES
      serpData[Date][Brand].numValues++;

      // ADD VISIBILITY TO TOTAL
      serpData[Date][Brand].totalValues += Visibility;

      return serpData;
    },
    {}
  );

  const brands = Object.keys(brandsObj).reduce((allBrands: string[], brand) => {
    allBrands.push(brand);
    return allBrands;
  }, []);

  return [formattedSerpData, brands] as const;
};

interface DataByLocation {
  // location
  [key: string]: {
    // brand
    [key: string]: {
      numValues: number;
      totalValues: number;
    };
  };
}

// FORMAT LOCATION ANAYSIS CHART DATA FORM KEYWORDS REPORT AND TARGETS(LOCALES)
export const formatLocationAnalysis = (data: KeywordsReportData) => {
  const brands = Object.keys(data);

  // CREATE LOCATIONS DATA OBJECT - { LOCATON: COVERAGE[] }
  const output = Object.entries(data).reduce(
    (acc: DataByLocation, [brandName, keywordItems]) => {
      for (const keywordItem of keywordItems) {
        const { location, coverage } = keywordItem;
        if (!acc[location]) {
          acc[location] = {};
        }

        if (!acc[location][brandName]) {
          acc[location][brandName] = {
            numValues: 0,
            totalValues: 0,
          };
        }

        acc[location][brandName].numValues++;
        acc[location][brandName].totalValues += Math.round(coverage * 100);
      }
      return acc;
    },
    {}
  );
  return [output, brands] as const;
};

// TRIGGER CHART DOWNLOAD
export const triggerDownload = (
  href: string,
  fileName: string,
  ext = '.png'
) => {
  const evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  });

  const fileExt = ext[0] === '.' ? ext : '.' + ext;

  const a = document.createElement('a');
  a.setAttribute('download', fileName + fileExt);
  a.setAttribute('href', href);
  a.setAttribute('target', '_blank');

  a.dispatchEvent(evt);
};

// CREATE CANVAS FROM SVG
const createCanvasFromSVG = (svg: SVGElement) => {
  const canvas = document.createElement('canvas');

  canvas.width = parseInt(svg.getAttribute('width') || '0');
  canvas.height = parseInt(svg.getAttribute('height') || '0');

  return canvas;
};

// CREATE URL FROM SVG
const createURLFromSVG = (svg: SVGElement) => {
  const data = new XMLSerializer().serializeToString(svg);

  data.replace(/&nbsp;/g, '&#160;');

  return 'data:image/svg+xml,' + encodeURIComponent(data);
};

// CONVERT SVG TO PNG
export const convertSvgToPng = (svg: SVGElement) =>
  new Promise<{ imgURI: string; blob: Blob | null }>((resolve, reject) => {
    const DOMURL = window.URL || window.webkitURL || window;

    // SET BACKGROUND COLOR
    const svgClone = setBackgroundColorSVG(svg, '#fff');

    // SVG TO CANVAS
    const canvas = createCanvasFromSVG(svgClone);
    const context = canvas.getContext('2d');

    if (!context) {
      reject('Unable to create canvas from SVG.');
      return;
    }

    // CREATE URL
    const url = createURLFromSVG(svgClone);

    // CREATE IMG
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      context.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      const imgURI = canvas.toDataURL('image/png');

      canvas.toBlob((blob) => resolve({ imgURI, blob }));
    };

    img.src = url;

    canvas.remove();
  });

// APPLY BACKGROUND COLOR TO SVG
/**
 *
 * @param svgElement SVG Element
 * @param bgColor Any valid css color
 * @returns Clone of orginial svg element with specified background color
 */
export const setBackgroundColorSVG = (
  svgElement: SVGElement,
  bgColor: string
) => {
  // CLONE SVG ELEMENT
  const clone = svgElement.cloneNode(true) as SVGElement;

  const newRect = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'rect'
  );

  const rectHeight = clone.getAttribute('height') || '0';
  const rectWidth = clone.getAttribute('width') || '0';

  newRect.setAttribute('height', rectHeight);
  newRect.setAttribute('width', rectWidth);
  newRect.setAttribute('fill', bgColor);

  clone.insertBefore(newRect, clone.firstChild);

  return clone;
};

// ADD LABEL TO SVG (WORD CLOUD)
export const addLabelToWordCloudSVG = (svg: SVGElement, text: string) => {
  const clone = svg.cloneNode(true) as SVGElement;
  const label = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  const height = parseInt(svg.getAttribute('height') || '0') + 40;
  const width = parseInt(svg.getAttribute('width') || '0');

  const transform = `translate(${width / 2}, ${height / 2 + 25})`;

  clone.setAttribute('height', height.toString());
  clone.setAttribute('width', width.toString());
  clone.firstElementChild?.setAttribute('transform', transform);

  label.setAttribute(
    'font-family',
    '&quot;Roboto&quot;, Arial, Helvetica, sans-serif'
  );
  label.setAttribute('x', '10');
  label.setAttribute('y', '27.5');
  label.setAttribute('text-anchor', 'start');
  label.setAttribute('dominant-baseline', 'auto');
  label.setAttribute('font-size', '1.25rem');
  label.setAttribute('font-weight', '600');
  label.setAttribute('fill', '#000000');
  label.classList.add('apexcharts-title-text');
  label.textContent = text;

  clone.insertBefore(label, clone.firstChild);

  return clone;
};

// CREATE SELECTION DETAILS FILE FOR EXPORTED ZIP
export const getSelectionDetails = (
  filters: InsightsState | null,
  studyName: string
) => {
  return filters
    ? `
Study: ${studyName}

Start Date: ${filters.startDate}

End Date: ${filters.endDate}

Competitors: ${
        filters.competitors.length
          ? `
${filters.competitors.map(({ label }) => '\t' + label).join('\n')}
`
          : 'n/a'
      }

Competitor Group: ${
        filters.competitorsGroupOptions.find(
          ({ value }) => value === filters.competitorsGroup
        )?.label || 'n/a'
      }

Keywords: ${
        filters.keywords.length
          ? `
${filters.keywords.map(({ label }) => '\t' + label).join('\n')}
`
          : 'n/a'
      }

Keyword Group: ${
        filters.keywordsGroupOptions.find(
          ({ value }) => value === filters.keywordsGroup
        )?.label || 'n/a'
      }

AdType: ${filters.adType}

Targets: ${
        filters.targets.length
          ? `
${filters.targets.map(({ label }) => '\t' + label).join('\n') || 'n/a'}
`
          : 'n/a'
      } 
`
    : '';
};

// FORMAT WORD CLOUD DATA (WORD ANALYSIS BRANDS REPORT)
export const getTotalCountByWord = (data: WordAnalysisBrandsData | null) => {
  if (!data) return [];
  // CREATE SINGLE ARRAY WITH ALL WORD DETAILS FOR ALL BRANDS
  const allWords = Object.values(data).reduce(
    (wordsData: WordAnalysisBrandDetails[], brandData) => {
      wordsData.push(...brandData);
      return wordsData;
    },
    []
  );

  // CREATE OBJECT OF TOTAL COUNT FOR EACH UNIQUE WORD
  const wordCountObj = allWords.reduce(
    (counter: { [key: string]: number }, { word, count }) => {
      if (!counter[word]) {
        counter[word] = 0;
      }

      counter[word] += count;
      return counter;
    },
    {}
  );

  // RETURN WORD[] -- {[WORD]: COUNT}[]
  const output: Word[] = Object.entries(wordCountObj).map(([text, value]) => {
    return {
      text,
      value,
    };
  });

  return output.sort((a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    return 0;
  });
};
