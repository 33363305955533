import './Alert-Form.scss';

import { useNavigate } from 'react-router-dom';
import { useActions, useAsyncWrapper } from '../../hooks';
import React, { useMemo, useEffect, useReducer } from 'react';

// CONSTANTS
import { ALERTS_TOOLTIPS } from '../../tooltips';
import { AD_TYPE_OPTIONS } from '../../constants';

// UI COMPONENT IMPORTS
import ContentAlert from './Content-Alert';
import KeywordsAlert from './Keywords-Alert';
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import TextList from '../../components/Pmds-Text-List/Pmds-Text-List';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// TYPES
import {
  AlertType,
  StudyDetails,
  ALERT_OPTIONS,
  StudyListItem,
  DropdownOption,
  AlertFormState,
  alertFormReducer,
  FetchedAlertDetails,
  CompetitorsOperator,
  ALERT_FORM_ACTION_TYPES,
  DEFAULT_ALERT_FORM_STATE,
  COMPETITORS_OPERATOR_OPTIONS,
} from '../../state';

// UTILS IMPORTS
import {
  parseKeywordGroups,
  parseCompetitorGroups,
  onPreventEnterKeySubmit,
} from '../../utils';

// API REQUESTS
import { getStudyById } from '../../api';

// COMPONENT PROPS
interface AlertFormProps {
  studyList: StudyListItem[];
  study?: StudyDetails | null;
  data?: FetchedAlertDetails | null;
  readOnly?: boolean;
  onSubmit: (state: AlertFormState) => Promise<void>;
}

// FUNCTIONAL COMPONENT (ALERT FORM)
/**
 *
 * @onSubmit **useAsyncWrapper** implemented within component. Do not include with asychronous function.
 */
function AlertForm({
  data,
  studyList,
  study,
  onSubmit,
  readOnly = false,
}: AlertFormProps) {
  // HOOKS
  const navigate = useNavigate();
  const wrapper = useAsyncWrapper();

  // REDUX ACTIONS
  const { alertError } = useActions();

  // ALERT FORM STATE
  const [state, dispatch] = useReducer(
    alertFormReducer,
    DEFAULT_ALERT_FORM_STATE
  );

  useEffect(() => {
    if (data && study) {
      dispatch({
        type: ALERT_FORM_ACTION_TYPES.LOAD_EXISTING_ALERT,
        payload: { alert: data, study },
      });
    }
  }, [data, study]);

  // FORMAT STUDY KEYWORD OPTIONS (MEMOIZE FOR PERFORMANCE)
  const keywordOptions = useMemo(() => {
    if (!state.study) {
      return [];
    }

    // RETURNS DROPDOWN OPTIONS WITH {label: keyword, value: keywordId}
    const parsedKeywords = parseKeywordGroups(
      state.study?.keyword_group_details
    )[1];

    // RETURNS DROPDOWN OPTIONS WITH {label: keyword, value: keyword}
    return parsedKeywords.map(({ label }) => ({ label, value: label }));
  }, [state.study]);

  // FORMAT STUDY COMPETITOR OPTIONS (MEMOIZE FOR PERFORMANCE)
  const competitorOptions = useMemo(() => {
    if (!state.study) {
      return [];
    }

    // RETURNS DROPDOWN OPTIONS WITH {label: competitor, value: competitorId}
    const parsedCompetitors = parseCompetitorGroups(
      state.study.competitor_group_details
    )[1];

    // RETURNS DROPDOWN OPTIONS WITH {label: competitor, value: competitor}
    return parsedCompetitors.map(({ label }) => ({ label, value: label }));
  }, [state.study]);

  // DISABLE CREATE BUTTON CONDITIONS
  const isKeywordsRequired =
    state.alertType === 'Keywords' && state.keywords.length < 1;

  const isPhrasesRequired =
    state.alertType !== 'Keywords' &&
    (state.phrases.length < 1 || !state.operator);

  // ENABLED/DISABLED SUBMIT BUTTON
  const isCreateBtnDisabled =
    !state.study ||
    !state.alertType ||
    !state.adsType.length ||
    isKeywordsRequired ||
    isPhrasesRequired;

  // STUDY LIST OPTIONS
  const studyListOptions = useMemo(() => {
    // ALL STUDIES WHEN EDITING OR VIEWING (DISABLED), OTHERWISE ACTIVE STUDIES ONLY
    const studies =
      data || readOnly
        ? [...studyList]
        : studyList.filter((study) => study.status === 'Active');

    // RETURN DROPDOWN OPTIONS
    return studies.map((activeStudy) => {
      return {
        label: `${activeStudy.id} - ${activeStudy.name}`,
        value: activeStudy.id,
      };
    });
  }, [studyList]);

  // HANDLE SELECTING ALERT TYPE
  const onSelectAlertType = (selected: DropdownOption) =>
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.SET_ALERT_TYPE,
      payload: selected.value as AlertType,
    });

  // HANDLE SELECT STUDY
  const onSelectStudy = wrapper(
    async (selected: { label: string; value: number }) => {
      // FETCH STUDY
      const foundStudy = await getStudyById(selected.value);
      if (!foundStudy) {
        return;
      }

      // STORE STUDY DETAILS
      dispatch({
        type: ALERT_FORM_ACTION_TYPES.SET_STUDY,
        payload: foundStudy,
      });
    }
  );

  // HANDLE SELECT AD TYPES
  const onSelectAdType = (selected: DropdownOption | DropdownOption[]) => {
    // SELECTED WILL BE IN ARRAY IF MULTIPLE IS ALLOWED
    const value = Array.isArray(selected) ? selected : [selected];
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.SET_ADS_TYPE,
      payload: value,
    });
  };

  // HANDLE SELECTING KEYWORDS
  const onSelectKeywords = (selected: DropdownOption[]) => {
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.SET_KEYWORDS,
      payload: selected,
    });
  };

  // HANDLE SELECTING COMPETITORS
  const onSelectCompetitors = (selected: DropdownOption[]) => {
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.SET_COMPETITORS,
      payload: selected,
    });
  };

  // HANDLE SELECTING COMPETITOR OPERATOR
  const onSelectCompetitorOperator = (selected: DropdownOption) => {
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.SET_COMPETITOR_OPERATOR,
      payload: selected.value as CompetitorsOperator,
    });
  };

  // HANDLE ADD ADDITIONAL RECIPIENT
  const onAddRecipient = (value: string) => {
    const isDuplicate = state.recipients.indexOf(value) >= 0;

    if (isDuplicate) {
      alertError(`Duplicate recipient is not allowed.`);
    } else {
      dispatch({
        type: ALERT_FORM_ACTION_TYPES.ADD_RECIPIENT,
        payload: value,
      });
    }
  };

  // HANDLE REMOVE ADDITIONAL RECIPIENT
  const onRemoveRecipient = (email: string) => {
    dispatch({
      type: ALERT_FORM_ACTION_TYPES.REMOVE_RECIPIENT,
      payload: email,
    });
  };

  // HANDLE FORM SUBMIT
  const onSubmitAlert = wrapper(async (evt: React.FormEvent) => {
    evt.preventDefault();

    if (isCreateBtnDisabled) {
      return;
    }

    await onSubmit(state);
  });

  // JSX
  return (
    <>
      <form
        onSubmit={onSubmitAlert}
        onKeyDown={onPreventEnterKeySubmit}
        className="alert-form-container"
      >
        <Row>
          <Column>
            {/* === SELECT ALERT TYPE === */}
            <Dropdown
              className="form-row-item"
              options={ALERT_OPTIONS}
              value={{ label: '', value: state.alertType }}
              onChange={onSelectAlertType}
              disabled={!!data || readOnly}
              isSearchable={false}
              required
              invert
            >
              <CustomTooltip
                content={ALERTS_TOOLTIPS.ALERT_TYPE}
                align={TOOLTIP_ALIGN.LEFT}
                maxWidth="400px"
              >
                Alert Type:
              </CustomTooltip>
            </Dropdown>
          </Column>

          <Column>
            {/* === SELECT STUDY === */}
            <Dropdown
              className="form-row-item"
              placeholder="Select Study"
              options={studyListOptions}
              onChange={onSelectStudy}
              value={state.study ? { label: '', value: state.study.id } : ''}
              disabled={!!data || readOnly}
              required
              invert
            >
              Study:
            </Dropdown>
          </Column>

          <Column>
            {/* === SELECT ADS TYPE === */}
            <Dropdown
              className="form-row-item"
              placeholder={readOnly ? '0 Selected' : 'Select ads type(s)'}
              display={readOnly ? 'pillBelow' : 'selectionInline'}
              multiple={state.alertType === 'Content'}
              options={AD_TYPE_OPTIONS}
              onChange={onSelectAdType}
              value={state.adsType}
              isSearchable={false}
              disabled={readOnly}
              required
              invert
            >
              Ads Type:
            </Dropdown>
          </Column>
        </Row>

        {/* === ADD/REMOVE KEYWORDS === */}
        <Row className="border-bottom">
          <Column>
            <Dropdown
              className="form-row-item"
              placeholder={readOnly ? '0 Selected' : 'Select keywords'}
              required={state.alertType === 'Keywords'}
              display={readOnly ? 'pillBelow' : 'selectionInline'}
              onChange={onSelectKeywords}
              options={keywordOptions}
              value={state.keywords}
              disabled={readOnly}
              multiple
              invert
            >
              Keywords:
            </Dropdown>
          </Column>

          <Column>
            {/* === ADD/REMOVE COMPETITORS === */}
            <Dropdown
              className="form-row-item"
              placeholder="Select competitors"
              display={readOnly ? 'pillBelow' : 'selectionInline'}
              options={competitorOptions}
              value={state.competitors}
              multiple
              onChange={onSelectCompetitors}
              disabled={readOnly}
              invert
            >
              Competitors:
            </Dropdown>
          </Column>

          <Column>
            {/* === COMPETITORS OPERATOR === */}
            <Dropdown
              className="form-row-item"
              options={COMPETITORS_OPERATOR_OPTIONS}
              onChange={onSelectCompetitorOperator}
              value={
                state.competitorOperator
                  ? {
                      label: '',
                      value: state.competitorOperator,
                    }
                  : ''
              }
              isSearchable={false}
              disabled={readOnly}
              invert
            >
              Competitors Operator:
            </Dropdown>
          </Column>
        </Row>

        {/* === CONTENT ALERT INPUTS === */}
        {state.alertType === 'Content' && (
          <ContentAlert state={state} dispatch={dispatch} readOnly={readOnly} />
        )}

        {/* === KEYWORDS ALERT INPUTS === */}
        {state.alertType === 'Keywords' && (
          <KeywordsAlert
            state={state}
            dispatch={dispatch}
            readOnly={readOnly}
          />
        )}

        {/* === ADD/REMOVE RECIPIENTS === */}
        <Row cols={3}>
          <Column>
            <TextList
              type="email"
              placeholder={readOnly ? '' : 'user@email.com'}
              label="Additional Email Recipients:"
              values={state.recipients}
              onAdd={onAddRecipient}
              onRemove={onRemoveRecipient}
              disabled={readOnly}
            />
          </Column>
        </Row>

        <div className="form-buttons">
          {readOnly ? (
            <Button display="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          ) : (
            <>
              <Button
                color="red"
                onClick={() => navigate(-1)}
                style={{ color: 'white' }}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isCreateBtnDisabled}>
                {data ? 'Update' : 'Create'}
              </Button>
            </>
          )}
        </div>
      </form>
    </>
  );
}

export default AlertForm;
