import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// CONSTANTS
import { ROUTES } from '../../router';

// TYPE IMPORTS
import { StudyListItem } from '../../state';

// UI COMPONENTS
import DataTable, {
    TD_TYPE,
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Tooltip from '@publicismedia-ds/ui-tooltip';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';

// API REQUESTS
import { exportActiveStudies } from '../../api';

// COMPONENT PROPS
interface StudiesTableProps {
    data: StudyListItem[];
    onUpdateStatus?: (studyId: string, isActive: boolean) => void;
    isPrivileged?: boolean;
    isAdmin?: boolean;
    actions?: boolean;
}

// FUNCTIONAL COMPONENT (STUDIES TABLE)
function StudiesTable({
    data,
    onUpdateStatus,
    actions = true,
    isAdmin = false,
    isPrivileged = false,
}: StudiesTableProps) {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // TABLE STATE
    const [studiesDataProvider, setStudiesDataProvider] = useState<any>([]);

    // DATA PROVIDER FROM STUDYLIST DATA
    useEffect(() => {
        // CREATE DATA PROVIDER
        const provider = new DataProvider({
            data: data.map((study) => ({
                ...study,
                study_id: study.id.toString(),
                client_name: study.client_name || 'N/A',
            })),
        });

        // STORE DATA PROVIDER
        setStudiesDataProvider(provider);
    }, [data]);

    // HANDLE SEARCH STUDIES TABLE CHANGE
    const onSearchChange = (text: string) => {
        studiesDataProvider.search(text, ['id', 'account_id', 'client_id']);
    };

    // HANDLE DACTIVE STUDIES (ADMIN ONLY)
    const onExportStudies = wrapper(async () => {
        if (!isAdmin) {
            return;
        }

        await exportActiveStudies();
    });

    // JSX COMPONENT
    return (
        <>
            <DataTable
                data={studiesDataProvider}
                bindKey="id"
                stickyHead={true}
                emptyState={
                    <>
                        <h2>No studies found.</h2>
                    </>
                }
                above={
                    <div
                        className={`studies-data-table-above ${
                            !isPrivileged && 'search-studies-only'
                        }`}
                    >
                        {isPrivileged && (
                            <div className="data-table-action-buttons">
                                <Link to={ROUTES.CREATE_STUDY}>
                                    <Button className="btn-create-study">
                                        Create New Study
                                    </Button>
                                </Link>

                                {isAdmin && (
                                    <Button
                                        color="brand-2"
                                        onClick={onExportStudies}
                                    >
                                        Export
                                    </Button>
                                )}
                            </div>
                        )}
                        <SearchFilter
                            className="search-studies-input"
                            onSearchChange={onSearchChange}
                        />
                    </div>
                }
                hasVirtualScrolling={false}
            >
                <ColumnTemplate bind="study_id" isSortable minWidth>
                    Study ID
                </ColumnTemplate>
                <ColumnTemplate bind="name" isSortable minWidth>
                    Study Name
                </ColumnTemplate>
                <ColumnTemplate bind="account_name" isSortable minWidth>
                    Account
                </ColumnTemplate>
                <ColumnTemplate bind="client_name" isSortable minWidth>
                    Client
                </ColumnTemplate>
                <ColumnTemplate bind="start_date" isSortable minWidth>
                    Start Date
                </ColumnTemplate>
                <ColumnTemplate
                    bind="status"
                    className="status-column"
                    isSortable
                    align={TD_ALIGN.CENTER}
                    onRender={(status: string) => (
                        <>
                            {status === 'Active' ? (
                                <Status
                                    type={STATUS_TYPE.SUCCESS}
                                    title={status}
                                    displayOnFocus={false}
                                />
                            ) : (
                                <Status
                                    type={STATUS_TYPE.NOTELIGIBLETOSYNC}
                                    title={status}
                                    displayOnFocus={false}
                                />
                            )}
                        </>
                    )}
                    hasRenderCache={false}
                    minWidth
                >
                    Status
                </ColumnTemplate>

                {actions && (
                    <ColumnTemplate
                        bind="id"
                        type={TD_TYPE.ACTIONS}
                        align={TD_ALIGN.CENTER}
                        onRender={(id: string, rowData: StudyListItem) => {
                            const isActive = rowData.status === 'Active';
                            return (
                                <>
                                    {/* --- VIEW STUDY (ALL ROLES) --- */}
                                    <Link to={`/studies/view/${id}`}>
                                        <Tooltip title="View">
                                            <i className="icon-view" />
                                        </Tooltip>
                                    </Link>

                                    {/* --- ACCT. MANAGERS || ADMINS ONLY --- */}
                                    {isPrivileged && (
                                        <>
                                            {/* --- EDIT STUDY --- */}
                                            <Link to={`/studies/edit/${id}`}>
                                                <Tooltip title="Edit">
                                                    <i className="icon-edit" />
                                                </Tooltip>
                                            </Link>

                                            {/* --- UPDATE STATUS --- */}
                                            {!!onUpdateStatus && (
                                                <Tooltip
                                                    title={
                                                        rowData.status ===
                                                        'Active'
                                                            ? 'Suspend'
                                                            : 'Activate'
                                                    }
                                                >
                                                    <i
                                                        className={
                                                            isActive
                                                                ? 'icon-cancel-20'
                                                                : 'icon-progress'
                                                        }
                                                        onClick={() =>
                                                            onUpdateStatus(
                                                                id,
                                                                isActive
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </>
                            );
                        }}
                        minWidth
                    >
                        Actions
                    </ColumnTemplate>
                )}
            </DataTable>
        </>
    );
}

export default StudiesTable;
