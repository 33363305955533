import reducer from './reducers';
import { configureStore, PreloadedState } from '@reduxjs/toolkit';

export const store = configureStore({
    reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer,
        preloadedState,
    });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
