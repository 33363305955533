import { UserRoleType } from '../../state';
import { UserAgencyData } from '../../types';

export const STORED_USER = 'bt-user';

export const USER_ROLES = ['Admin', 'Account Manager', 'User'] as const;

export type UserRole = (typeof USER_ROLES)[number];

export interface UserDetails {
  email: string;
  first_name: string;
  last_name: string;
  status: string;
  role: UserRole;
  account_id: string;
  token: string;
  expires: number;
  agenciesInfo?: UserAgencyData | null;
}

export interface AuthState {
  isAuthenticated: boolean;
  user: UserDetails | null;
  authenticationFailed: boolean;
}

export interface LoginResponse {
  accessToken?: string;
  tokenType?: 'Bearer';
  user_details?: LoginUserDetails;
  timestamp?: '2023-01-31T16:47:45.098+00:00';
  status?: number;
  error?: 'Unauthorized';
  path?: '/authentication/signin';
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface AccountRoleDetails {
  role: UserRoleType;
  account_id: string;
}

export interface LoginUserDetails {
  email: string;
  status: string;
  first_name: string;
  last_name: string;
  account_role_details: AccountRoleDetails[];
}

export interface UserResponse {
  user_details: {
    email: string;
    status: string;
    first_name: string;
    last_name: string;
    account_role_details: [
      {
        role: UserRoleType;
        account_id: string;
      }
    ];
    client_id: number[];
  };
}
