import produce from 'immer';

import { TooltipsState } from '../models';
import { TooltipsAction } from '../actions';
import { TOOLTIPS_ACTION_TYPES } from '../action-types';

const DEFAULT_TOOLTIPS_STATE: TooltipsState = {
    enabled: false,
};

export const tooltipsReducer = produce(
    (state: TooltipsState, action: TooltipsAction) => {
        switch (action.type) {
            case TOOLTIPS_ACTION_TYPES.TOGGLE_TOOLIPS:
                state.enabled =
                    action.payload !== undefined
                        ? action.payload
                        : !state.enabled;
                return state;

            default:
                return state;
        }
    },
    DEFAULT_TOOLTIPS_STATE
);
