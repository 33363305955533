import { useActions } from '../../hooks';

// TYPES
import {
    FIELD_OPTIONS,
    DropdownOption,
    AlertFormState,
    AlertFormAction,
    PhrasesOperator,
    AlertRecurrence,
    ALERT_RECURRENCE,
    ALERT_FORM_ACTION_TYPES,
    PHRASES_OPERATOR_OPTIONS,
} from '../../state';

// UI COMPONENTS
import Checkbox from '@publicismedia-ds/ui-checkbox';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import TextList from '../Pmds-Text-List/Pmds-Text-List';

// COMPONENT PROPS
interface ContentAlertProps {
    readOnly?: boolean;
    state: AlertFormState;
    dispatch: React.Dispatch<AlertFormAction>;
}

// RECURRENCE DROPDOWN OPTIONS
const contentRecurrenceOptions = ALERT_RECURRENCE.filter(
    (recurrence) => recurrence !== 'quarterly'
).map((recurrence) => ({
    label: recurrence[0].toUpperCase() + recurrence.slice(1),
    value: recurrence,
}));

// FUNCTIONAL COMPONENT (CONTENT ALERT)
function ContentAlert({
    state,
    dispatch,
    readOnly = false,
}: ContentAlertProps) {
    // REDUX ACTIONS
    const { alertError } = useActions();

    const renderedFieldOptions = FIELD_OPTIONS.map((option) => {
        return (
            <Checkbox
                checked={state.fields.includes(option.value)}
                onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                    isChecked: boolean
                ) => {
                    if (isChecked) {
                        dispatch({
                            type: ALERT_FORM_ACTION_TYPES.ADD_FIELD,
                            payload: option.value,
                        });
                    }
                    if (!isChecked) {
                        dispatch({
                            type: ALERT_FORM_ACTION_TYPES.REMOVE_FIELD,
                            payload: option.value,
                        });
                    }
                }}
                disabled={readOnly}
                key={option.value}
                value={option}
                invert
            >
                {option.label}
            </Checkbox>
        );
    });

    // HANDLE SELECTING ALERT RECURRENCE
    const onSelectRecurrence = (selected: DropdownOption) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_ALERT_RECURRENCE,
            payload: selected.value as AlertRecurrence,
        });
    };

    // RENDER PHRASES OPERATOR OPTIONS
    const onSelectPhrasesOperator = (selected: DropdownOption) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_PHRASES_OPERATOR,
            payload: selected.value as PhrasesOperator,
        });
    };

    // HANDLE ADD PHRASE
    const onAddPhrase = (value: string) => {
        const isDuplicate = state.phrases.indexOf(value) >= 0;

        if (isDuplicate) {
            alertError(`Duplicate phrase is not allowed.`);
        } else {
            dispatch({
                type: ALERT_FORM_ACTION_TYPES.ADD_PHRASE,
                payload: value,
            });
        }
    };

    // HANDLE REMOVE PHRASE
    const onRemovePhrase = (phrase: string) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.REMOVE_PHRASE,
            payload: phrase,
        });
    };

    return (
        <>
            <Row>
                <Column>
                    <Dropdown
                        value={
                            state.recurrence
                                ? { label: '', value: state.recurrence }
                                : ''
                        }
                        options={contentRecurrenceOptions}
                        onChange={onSelectRecurrence}
                        isSearchable={false}
                        disabled={readOnly}
                        required
                        invert
                    >
                        Alert Recurrence:
                    </Dropdown>
                </Column>

                <Column>
                    {/* === ADD/REMOVE PHRASES === */}
                    <TextList
                        placeholder={readOnly ? '' : 'Enter phrases'}
                        values={state.phrases}
                        label={'Phrases:*'}
                        onAdd={onAddPhrase}
                        onRemove={onRemovePhrase}
                        disabled={readOnly}
                        invert
                    />
                </Column>

                {/* === SELECT PHRASES OPERTAOR === */}
                <Column>
                    <Dropdown
                        options={PHRASES_OPERATOR_OPTIONS}
                        onChange={onSelectPhrasesOperator}
                        value={
                            state.operator
                                ? { label: '', value: state.operator }
                                : ''
                        }
                        isSearchable={false}
                        disabled={readOnly}
                        invert
                    >
                        Phrases Operator:
                    </Dropdown>
                </Column>
            </Row>

            {/* === SELECT FIELD OPTIONS === */}
            <Row className="border-bottom">
                <Column>
                    <Fieldset legend="Fields:" layout="horizontal" invert>
                        {renderedFieldOptions}
                    </Fieldset>
                </Column>

                {/* === SELECT SCREENSHOT OPTION */}
                <Column>
                    <Fieldset
                        legend="Include screenshot:"
                        layout="horizontal"
                        invert
                    >
                        <Checkbox
                            onChange={(evt: any, isChecked: boolean) => {
                                dispatch({
                                    type: ALERT_FORM_ACTION_TYPES.SET_SAVE_PNG,
                                    payload: isChecked ? 1 : 0,
                                });
                            }}
                            checked={Boolean(state.savePng)}
                            value={state.savePng}
                            disabled={readOnly}
                            invert
                        >
                            Screenshot
                        </Checkbox>
                    </Fieldset>
                </Column>
            </Row>
        </>
    );
}

export default ContentAlert;
