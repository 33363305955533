import './Top-Ads-Chart.scss';

import { useEffect, useMemo, useState } from 'react';

// TYPES
import { DropdownOption, TopAdsData } from '../../../state';

// UI COMPONENTS
import TopAd from './Top-Ad-Item/Top-Ad-Item';
import ChartWrapper from '../Chart-Wrapper/Chart-Wrapper';

// COMPONENT PROPS
interface TopAdsContainerProps {
  data: TopAdsData | null;
  competitors: DropdownOption[];
  dateRange: string;
  onChange?: (selection: DropdownOption) => void;
  value?: DropdownOption | null;
}

// FUNCTIONAL COMPONENT (TOP ADS)
function TopAdsChart({
  data,
  competitors,
  dateRange,
  onChange,
  value,
}: TopAdsContainerProps) {
  const [selectedBrand, setSelectedBrand] = useState<DropdownOption | null>(
    null
  );

  // SELECT A DEFAULT BRAND SELECTION
  useEffect(() => {
    if (!data || !competitors) return;

    if (!value) {
      setSelectedBrand(competitors[0]);
    }
  }, [data, competitors]);

  // RENDER TOP AD COMPONENTS
  const renderedAds = useMemo(() => {
    if (data && value && data[value.label]) {
      return data[value.label].map((ad, index) => {
        return (
          <TopAd
            title={ad.title}
            displayURL={ad.display_url}
            description={ad.description}
            brand={value.label}
            key={index}
          />
        );
      });
    }
    if (data && selectedBrand && data[selectedBrand.label]) {
      return data[selectedBrand.label].map((ad, index) => {
        return (
          <TopAd
            title={ad.title}
            displayURL={ad.display_url}
            description={ad.description}
            brand={selectedBrand.label}
            key={index}
          />
        );
      });
    }
    return [];
  }, [data, selectedBrand, value]);

  const TOOLTIP_TEXT =
    'Displays the top 10 ads (if applicable) that were seen over the given date range.';

  // JSX
  return (
    <div className="top-ads-container">
      <ChartWrapper
        title="Top Ads"
        tooltipText={TOOLTIP_TEXT}
        dateRange={dateRange || 'N/A'}
        options={competitors}
        onChange={onChange}
        value={value}
      >
        <div className="top-ads-content">
          {renderedAds.length ? (
            renderedAds
          ) : (
            <h1 className="top-ads-no-data">No data</h1>
          )}
        </div>
      </ChartWrapper>
    </div>
  );
}

export default TopAdsChart;
