import { ReportFiltersState } from '../../state';

// GENERATE PARAMS STRING FOR SCHEDULED REPORTS
export const generateRequestParamsString = (
    filterSelections: ReportFiltersState
) => {
    const requestParams: string[] = [];
    for (const [filterName, filterValue] of Object.entries(filterSelections)) {
        if (filterName.includes('Options')) {
            continue;
        }

        const value = Array.isArray(filterValue)
            ? filterValue.map((opt) => opt.value).join(',')
            : filterValue;

        if (value) {
            requestParams.push(`${filterName}=${value}`);
        }
    }
    if (!requestParams.length) {
        return '';
    }

    return requestParams.length ? '&' + requestParams.join('&') : '';
};
