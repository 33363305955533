import './Insights-Filter.scss';

import React, { useEffect, useMemo } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// CONSTANTS
import { AD_TYPE_OPTIONS, REPORT_TYPE } from '../../constants';

// TYPES
import { StudyDetails, DropdownOption, InsightsState } from '../../state';

// UTILS
import {
  parseTargets,
  parseKeywordGroups,
  parseCompetitorGroups,
  onPreventEnterKeySubmit,
} from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Datepicker from '@publicismedia-ds/ui-datepicker';
import Grid, { Row, Column } from '@publicismedia-ds/ui-grid';
import InputError from '../Input-Error-Message/Input-Error-Message';

// COMPONENT PROPS
interface InsightsFilterProps {
  study: StudyDetails;
  onSubmit: (filterState: InsightsState) => void;
  reportType?: REPORT_TYPE;
}

const MAX_TARGETS = 666666;

// FUNCTIONAL COMPONENT
function InsightsFilter({ study, onSubmit, reportType }: InsightsFilterProps) {
  // REDUX STATE
  const state = useAppSelector((state) => state.insights);

  // REDUX ACTIONS
  const {
    alertError,
    loadInsightsOptions,
    onSetInsightsEndDate,
    onSetInsightsKeywords,
    onSelectInsightsAdType,
    onSetInsightsStartDate,
    onSelectInsightsTargets,
    onSetInsightsCompetitors,
    onSetInsightsKeywordsGroup,
    onSetInsightsCompetitorsGroup,
  } = useActions();

  const isKeywordsMetrics = reportType === REPORT_TYPE.KEYWORDS_METRICS;

  // CREATE FILTER DROPDOWN OPTIONS ON INITIAL PAGE LOAD
  useEffect(() => {
    // GET COMPETITOR AND COMPETITOR GROUP DROPDOWN OPTIONS
    const [competitorsGroupOptions, competitorOptions] = parseCompetitorGroups(
      study.competitor_group_details
    );

    // GET KEYWORD AND KEYWORD GROUP DROPDOWN OPTIONS
    const [keywordsGroupOptions, keywordOptions] = parseKeywordGroups(
      study.keyword_group_details
    );

    const [targetOptions] = parseTargets(study.locale_details);

    // SET FILTER OPTIONS
    loadInsightsOptions({
      competitorsGroupOptions,
      competitorOptions,
      keywordsGroupOptions,
      keywordOptions,
      targetOptions,
    });
  }, [study]);

  // SET SELECTED TARGETS
  const onSelectTargets = (selected: DropdownOption[]) => {
    if (selected.length < MAX_TARGETS) {
      onSelectInsightsTargets(selected);
    } else {
      alertError('Maximum of 5 targets allowed.');
    }
  };

  // HANDLE FETCHING REPORTS TO GENERATE CHARTS
  const onFetchReports = (evt: React.FormEvent) => {
    evt.preventDefault();

    onSubmit(state);
  };

  // MIN DATE
  const minDate = new Date(study.created);

  // MAX START DATE
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // MAX END DATE
  const maxDate = useMemo(() => {
    if (state.startDate) {
      const currentMax = new Date(state.startDate);
      currentMax.setDate(currentMax.getDate() + 14);
      return currentMax < yesterday ? currentMax : yesterday;
    } else {
      return yesterday;
    }
  }, [state.startDate]);

  // CHECK IF END DATE IS VALID
  const isValidEndDate =
    new Date(state.endDate) <= maxDate &&
    new Date(state.endDate) >= new Date(state.startDate);

  // ENABLE/DISABLE APPLY BUTTON
  const isSubmitDisabled = isKeywordsMetrics
    ? !state.startDate ||
      state.competitors.length < 2 ||
      state.competitors.length > 3
    : !state.startDate ||
      !state.endDate ||
      !isValidEndDate ||
      (state.competitorsGroup === null && !state.competitors.length) ||
      (state.keywordsGroup === null && !state.keywords.length) ||
      !state.adType;

  return (
    <div className="insights-filter-container">
      {!isKeywordsMetrics && (
        <div className="insights-filter-header">
          <p>
            Please select at least one competitor or competitors group and at
            least one keyword or keywords group.
          </p>
        </div>
      )}

      <form onSubmit={onFetchReports} onKeyDown={onPreventEnterKeySubmit}>
        <Grid>
          <Row cols={4}>
            {/* --- START DATE --- */}
            <Column>
              <Datepicker
                onChange={onSetInsightsStartDate}
                value={new Date(state.startDate)}
                minDate={minDate}
                maxDate={yesterday}
                required
              >
                {isKeywordsMetrics ? 'Select' : 'Start'} Date:
              </Datepicker>
            </Column>

            {/* --- END DATE --- */}
            {!isKeywordsMetrics && (
              <Column>
                <Datepicker
                  onChange={onSetInsightsEndDate}
                  value={new Date(state.endDate)}
                  minDate={new Date(state.startDate)}
                  maxDate={maxDate}
                  required
                >
                  End Date:
                </Datepicker>
                {!isValidEndDate && (
                  <InputError>
                    Please select a date within 14 days of "Start Date".
                  </InputError>
                )}
              </Column>
            )}

            {/* --- AD TYPE DROPDOWN --- */}
            {!isKeywordsMetrics && (
              <Column>
                <Dropdown
                  options={AD_TYPE_OPTIONS}
                  value={state.adType ? { label: '', value: state.adType } : ''}
                  onChange={onSelectInsightsAdType}
                  isSearchable={false}
                  required
                >
                  Ad Type:
                </Dropdown>
              </Column>
            )}

            {/* --- TARGETS DROPDOWN --- */}
            <Column>
              <Dropdown
                options={state.targetOptions}
                value={state.targets}
                onChange={onSelectTargets}
                display="selectionInline"
                isSearchable={false}
                multiple
              >
                Targets:
              </Dropdown>
            </Column>

            {/* --- COMPETITOR GROUPS DROPDOWN --- */}
            {!isKeywordsMetrics && (
              <Column>
                <Dropdown
                  options={state.competitorsGroupOptions}
                  value={
                    state.competitorsGroup
                      ? {
                          label: '',
                          value: state.competitorsGroup,
                        }
                      : ''
                  }
                  onChange={onSetInsightsCompetitorsGroup}
                  isSearchable={false}
                >
                  Competitors Group:
                </Dropdown>
              </Column>
            )}

            {/* --- COMPETITORS DROPDOWN --- */}
            <Column>
              <Row>
                <Dropdown
                  options={state.competitorOptions}
                  value={state.competitors}
                  onChange={onSetInsightsCompetitors}
                  display="selectionInline"
                  required={isKeywordsMetrics}
                  isSearchable={false}
                  multiple
                >
                  Competitors:
                </Dropdown>
              </Row>
              <Row>
                {isKeywordsMetrics &&
                  (state.competitors.length < 2 ||
                    state.competitors.length > 3) && (
                    <InputError>Please select 2 or 3 competitors.</InputError>
                  )}
              </Row>
            </Column>

            {/* --- KEYWORD GROUPS DROPDOWN --- */}
            {!isKeywordsMetrics && (
              <Column>
                <Dropdown
                  options={state.keywordsGroupOptions}
                  value={
                    state.keywordsGroup
                      ? {
                          label: '',
                          value: state.keywordsGroup,
                        }
                      : ''
                  }
                  onChange={onSetInsightsKeywordsGroup}
                  isSearchable={false}
                >
                  Keywords Group:
                </Dropdown>
              </Column>
            )}

            {/* --- KEYWORDS DROPDOWN --- */}
            <Column>
              <Dropdown
                options={state.keywordOptions}
                value={state.keywords}
                onChange={onSetInsightsKeywords}
                isSearchable={false}
                display="selectionInline"
                multiple
              >
                Keywords:
              </Dropdown>
            </Column>
          </Row>
        </Grid>

        {/* --- SUBMIT BUTTON --- */}
        <div className="insights-filter-button">
          <Button type="submit" disabled={isSubmitDisabled}>
            Apply
          </Button>
        </div>
      </form>
    </div>
  );
}

export default InsightsFilter;
