import React, { useEffect, useState } from 'react';

// CONSTANTS
import { METRICS_TOOLTIPS } from '../../tooltips';

// TYPES
import { ContentAnalysisRow } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface ContentAnalysisTableProps {
    data: ContentAnalysisRow[];
}

// FUNCTIONAL COMPONENT (CONTENT ANALYSIS TABLE)
function ContentAnalysisTable({ data }: ContentAnalysisTableProps) {
    const [provider, setProvider] = useState<any>();

    // SET TABLE PROVIDER ON DATA LOAD/CHANGES
    useEffect(() => {
        setProvider(
            new DataProvider({
                data: data
                    .map((row, index) => ({ ...row, id: index }))
                    .sort((a, b) => {
                        if (a['IMP %'] > b['IMP %']) {
                            return -1;
                        }
                        if (a['IMP %'] < b['IMP %']) {
                            return 1;
                        }
                        return 0;
                    }),
            })
        );
    }, [data]);

    // JSX
    return (
        <DataTable
            className="content-analysis-table no-vertical-scroll"
            data={provider}
            height="tall"
            bindKey="id"
        >
            <ColumnTemplate bind="Brand" type="text" isSortable minWidth>
                Brand
            </ColumnTemplate>
            <ColumnTemplate
                className="static-width"
                bind="Ad Title"
                type="text"
                minWidth
            >
                Ad Title
            </ColumnTemplate>
            <ColumnTemplate
                className="static-width"
                bind="Description"
                type="text"
                minWidth
            >
                Description
            </ColumnTemplate>
            <ColumnTemplate
                bind="IMP %"
                type="percent"
                align="center"
                isSortable
                minWidth
            >
                <CustomTooltip
                    content={
                        <p>
                            <strong>IMP%:</strong>{' '}
                            {METRICS_TOOLTIPS.IMPRESSION_PERCENTAGE}
                        </p>
                    }
                    align={TOOLTIP_ALIGN.RIGHT}
                    maxWidth="300px"
                >
                    IMP %
                </CustomTooltip>
            </ColumnTemplate>
            <ColumnTemplate
                bind="Rank"
                type="text"
                align="center"
                isSortable
                minWidth
            >
                Rank
            </ColumnTemplate>
            <ColumnTemplate bind="First Seen" type="text" minWidth>
                First Seen
            </ColumnTemplate>
            <ColumnTemplate bind="Last Seen" type="text" minWidth>
                Last Seen
            </ColumnTemplate>
            <ColumnTemplate bind="Lifetime" type="text" isSortable minWidth>
                Lifetime
            </ColumnTemplate>
        </DataTable>
    );
}

export default React.memo(ContentAnalysisTable);
