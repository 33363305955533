import { useMemo } from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { DropdownOption, PaidVsOrganicData } from '../../state';

// UI COMPONENTS
import { Column } from '@publicismedia-ds/ui-charts';
import ChartWrapper from './Chart-Wrapper/Chart-Wrapper';

// COMPONENT PROPS
interface PaidVsOrganicChartProps {
  data: PaidVsOrganicData | null;
  competitors: DropdownOption[];
  dateRange: string;
  onChange?: (selection: DropdownOption) => void;
  value?: DropdownOption | null;
}

// FUNCTIONAL COMPONENT
function PaidVsOrganicChart({
  data,
  value,
  onChange,
  dateRange,
  competitors,
}: PaidVsOrganicChartProps) {
  // GET DATES FOR X-AXIS CATEGORIES
  const categories = useMemo(() => {
    const dates: string[] = [];
    const start = dateRange.split('-')[0];
    const end = dateRange.split('-')[1];

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diff =
        (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);

      for (let i = diff; i >= 0; i--) {
        const currentDay = new Date(endDate);
        currentDay.setDate(currentDay.getDate() - i);
        dates.push(
          currentDay.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        );
      }
    }
    return dates;
  }, [dateRange]);

  // FORMAT CHART DATA
  const series = useMemo(() => {
    const chartData: { paid: number[]; organic: number[] } = {
      paid: [],
      organic: [],
    };

    if (data && value && data[value.label]) {
      const brandData = { ...data[value.label] };
      for (const date of categories) {
        const paid = Math.round(parseFloat(brandData[date]?.paid)) || 0;
        chartData.paid.push(paid);
        chartData.organic.push(brandData[date]?.organic ? 100 - paid : 0);
      }
    }

    return [
      { name: 'Paid', data: chartData.paid },
      { name: 'Organic', data: chartData.organic },
    ];
  }, [data, value, categories]);

  // CHART OPTIONS
  const options: ApexOptions = {
    chart: {
      stacked: true,
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2.5rem',
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: (val) => val + '%',
      },
    },
    xaxis: {
      categories,
    },
  };

  // JSX
  return (
    <ChartWrapper
      title="Paid vs Organic"
      tooltipText="A daily breakdown of Paid versus Organic estimated impressions for a given brand in relation to the total estimated impressions."
      options={competitors}
      dateRange={dateRange}
      value={value}
      onChange={onChange}
    >
      <Column data={series} options={options} width="100%" />
    </ChartWrapper>
  );
}

export default PaidVsOrganicChart;
