import produce from 'immer';

// CONSTANTS
import { FEATURE_OPTIONS } from '../../../constants';

// TYPES
import {
    AccountDetailsAction,
    AccountDetailsState,
    ACCOUNT_DETAILS_ACTIONS,
} from '../models/account-details-models';

export const DEFAULT_ACCOUNT_DETAILS_STATE: AccountDetailsState = {
    studies: [],
    alerts: [],
    reports: [],
    accountId: null,
    accountOptions: [],
    feature: FEATURE_OPTIONS[0],
};

export const accountDetailsReducer = produce(
    (
        state: AccountDetailsState,
        action: AccountDetailsAction
    ): AccountDetailsState => {
        switch (action.type) {
            case ACCOUNT_DETAILS_ACTIONS.LOAD_DATA:
                state = action.payload;
                return state;

            case ACCOUNT_DETAILS_ACTIONS.SET_ACCOUNT:
                state.accountId = action.payload;
                return state;

            case ACCOUNT_DETAILS_ACTIONS.SET_FEATURE:
                state.feature = action.payload;
                return state;

            default:
                return state;
        }
    },
    DEFAULT_ACCOUNT_DETAILS_STATE
);
