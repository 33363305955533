import './Page-Container.scss';

interface PageContainerProps {
  title?: string;
  children?: React.ReactNode;
}

function PageContainer({ title, children }: PageContainerProps) {
  return (
    <div className="page-container">
      <h1 className="page-container-title">{title?.toLowerCase()}</h1>
      {children}
    </div>
  );
}

export default PageContainer;
