import './Studies-Activity.scss';

import { useEffect, useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// TYPES
import { StudyActivityLog, StudyListItem } from '../../state';

// API REQUESTS
import { getStudiesActivity, getStudyList } from '../../api';

// UI COMPONENTS
import StudiesActivityTable from './Studies-Activity-Table';
import PageContainer from '../../components/Page-Container/Page-Container';

// FUNCTIONAL COMPONENT (STUDIES ACTIVITY - ADMIN ONLY)
function StudiesActivity() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // ACTIVITY LOGS
    const [activityLogs, setActivityLogs] = useState<StudyActivityLog[]>([]);

    // STUDIES LIST
    const [studyList, setStudyList] = useState<StudyListItem[]>([]);

    // FETCH AND STORE STUDIES & ACTIVITY LOGS
    const loadPageData = wrapper(async () => {
        await Promise.all([getStudiesActivity(), getStudyList()]).then(
            ([{ activityLogs: logs }, studies]) => {
                setActivityLogs(logs);
                setStudyList(studies);
            }
        );
    });

    // LOAD ACTIVITY LOGS ON INITIAL RENDER
    useEffect(() => {
        loadPageData();
    }, []);

    return (
        <PageContainer title="Studies Activity Logs">
            <div className="studies-activity-container">
                <StudiesActivityTable
                    logs={activityLogs}
                    studyList={studyList}
                />
            </div>
        </PageContainer>
    );
}

export default StudiesActivity;
