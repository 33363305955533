import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// CONSTANTS
import { PROVIDER_IDS } from '../../constants';
import { STUDY_TOOLTIPS } from '../../tooltips';

// UTILS
import {
    formatGroupsData,
    generateUpdateStudyBody,
    formatExistingLocaleDetails,
} from '../../utils';

// UI COMPONENT IMPORTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import GroupDetails from '../../components/Group-Details/Group-Details';
import WarningModal from '../../components/Warning-Modal/Warning-Modal';
import CustomTooltip from '../../components/Custom-Tooltip/Custom-Tooltip';
import LocaleDetails from '../../components/Locale-Details/Locale-Details';
import PageContainer from '../../components/Page-Container/Page-Container';
import NewGroupModal from '../../components/New-Group-Modal/New-Group-Modal';
import EditGroupModal from '../../components/Edit-Group-Modal/Edit-Group-Modal';
import NewLocaleModal from '../../components/New-Locale-Modal/New-Locale-Modal';

// TYPES
import {
    GroupUpdates,
    STATUS_OPTIONS,
    NewLocaleState,
    FREQUENCY_OPTIONS,
    WarningModalState,
    defaultNewGroupState,
    defaultLocaleModalState,
    defaultWarningModalState,
    defaultEditGroupModalState,
} from '../../types';
import { DropdownOption, StudyDetails } from '../../state';

// API REQUESTS
import {
    updateStudy,
    getStudyById,
    deleteStudyLocale,
    updateStudyProvider,
    deleteStudyKeywords,
    deleteStudyCompetitors,
    deleteStudyKeywordGroup,
    deleteStudyCompetitorGroup,
} from '../../api';

// COMPONENT PROPS
interface EditStudyLocation {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state?: StudyDetails;
}

// FUNCTIONAL COMPONENT (EDIT STUDY)
function EditStudy() {
    // HOOKS
    const navigate = useNavigate();
    const { study_id } = useParams();
    const wrapper = useAsyncWrapper();
    const { state } = useLocation() as EditStudyLocation;

    // FORM STATE
    const [study, setStudy] = useState(state ? state : null);

    const { user } = useAppSelector(({ auth }) => auth);

    // REDUX ACTIONS
    const { alertError, alertSuccess } = useActions();

    const isAdmin = user && user.role === 'Admin';

    const keywordGroupsData = formatGroupsData({
        keyword_groups: study?.keyword_group_details || [],
    });
    const competitorGroupsData = formatGroupsData({
        competitor_groups: study?.competitor_group_details || [],
    });

    // INITIAL DROPDOWN STATE VALUES
    const initialFrequencyState =
        FREQUENCY_OPTIONS.find((opt) => opt.value === study?.frequency) ||
        FREQUENCY_OPTIONS[0];

    const initialStatusState = study
        ? { label: study.status, value: study.status }
        : null;

    // FORM VALUE STATE
    const [updatedName, setUpdatedName] = useState(study?.name || '');
    const [updatedFrequency, setUpdatedFrequency] = useState<
        (typeof FREQUENCY_OPTIONS)[0]
    >(initialFrequencyState);
    const [updatedStatus, setUpdatedStatus] = useState<
        (typeof STATUS_OPTIONS)[0] | null
    >(initialStatusState || null);

    // MODAL STATES
    const [addLocaleModal, setAddLocaleModal] = useState(
        defaultLocaleModalState
    );
    const [showNewGroupModal, setShowNewGroupModal] =
        useState(defaultNewGroupState);
    const [editGroupModal, setEditGroupModal] = useState(
        defaultEditGroupModalState
    );
    const [warningModal, setWarningModal] = useState<WarningModalState>(
        defaultWarningModalState
    );

    // ENABLE/DISABLE SUBMIT BUTTON
    const updateBtnIsDisabled =
        (study?.name === updatedName &&
            study.frequency === updatedFrequency.value &&
            study.status === updatedStatus?.value) ||
        !updatedName.trim();

    // FETCH AND STORE STUDY DETAILS
    const loadStudyDetails = wrapper(async function (study_id: string) {
        const foundStudy = await getStudyById(study_id);

        if (foundStudy) {
            setStudy(foundStudy);
            const frequency = FREQUENCY_OPTIONS.find(
                (option) => option.value === foundStudy.frequency
            );
            setUpdatedName(foundStudy.name);
            setUpdatedFrequency(frequency || initialFrequencyState);
            setUpdatedStatus({
                label: foundStudy.status,
                value: foundStudy.status,
            });
        }
    });

    // FETCH STUDY DETAILS ON MOUNT
    useEffect(() => {
        if (study || !study_id) {
            return;
        }
        loadStudyDetails(study_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [study]);

    // HANDLE DUPLICATE EXISTING LOCALE
    const onDuplicateExistingLocale = (id: string | number) => {
        const locale = study?.locale_details.find((locale) => locale.id === id);
        const details = locale ? formatExistingLocaleDetails(locale) : null;
        setAddLocaleModal({ display: true, details });
    };

    // HANDLE NEW LOCALE CLICK
    const onNewLocale = () => {
        setAddLocaleModal((prev) => {
            return { ...prev, display: true };
        });
    };

    // HANDLE ADD NEW LOCALE
    const onAddLocaleSubmit = wrapper(async (locale: NewLocaleState) => {
        if (!study) {
            return;
        }
        const body = generateUpdateStudyBody(study, { newLocale: locale });
        const { message } = await updateStudy(study.id, body);

        if (!message) {
            return;
        }

        alertSuccess(message);
        setStudy(null);
    });

    // HANDLE DELETE LOCALE
    const onDeleteLocale = (id: string | number) => {
        const onConfirm = wrapper(async () => {
            if (!study) {
                return;
            }

            const { message } = await deleteStudyLocale(study.id, id);

            if (!message) {
                throw new Error('Unable to delete locale.');
            }

            alertSuccess(message);
            setStudy(null);
        });
        setWarningModal({
            display: true,
            header: 'Delete Local',
            content: 'Are you sure you want to delete this locale?',
            onConfirm,
        });
    };

    // HANDLE ADD NEW KEYWORD GROUP
    const onAddKeywordGroup = wrapper(
        async (groupName: string, keywords: string[]) => {
            if (!study) {
                return;
            }
            const body = generateUpdateStudyBody(study, {
                keyword_group: { [groupName]: keywords },
            });

            const { message } = await updateStudy(study.id, body);

            if (!message) {
                throw new Error('Unable to add keyword group.');
            }

            alertSuccess(message);
            setStudy(null);
        }
    );

    // HANDLE EDIT KEYWORD GROUP CLICK
    const onEditKeywordGroup = (id: string | number) => {
        if (!study) {
            return;
        }
        const index = study.keyword_group_details.findIndex((group) => {
            return group.id === id;
        });
        if (index < 0) {
            return;
        }
        const keywordGroup = study.keyword_group_details[index];
        setEditGroupModal({
            display: true,
            groupType: 'keyword',
            groupName: keywordGroup.name,
            details: keywordGroup.keyword_details,
            onSubmit: onUpdateKeywordGroup,
        });
    };

    // HANDLE UPDATE KEYWORD GROUP
    const onUpdateKeywordGroup = wrapper(
        async (groupName: string, updates: GroupUpdates) => {
            const { deleted = [], added = [] } = updates;
            if (!study) {
                return;
            }
            if (deleted.length) {
                const { message } = await deleteStudyKeywords(
                    study.id,
                    deleted
                );

                if (message) {
                    alertSuccess(message);
                } else {
                    throw new Error('Unable to update study.');
                }
            }
            if (added.length) {
                const body = generateUpdateStudyBody(study, {
                    keyword_group: { [groupName]: added },
                });
                const { message } = await updateStudy(study.id, body);

                if (message) {
                    alertSuccess(message);
                } else {
                    throw new Error('Unable to update study.');
                }
            }
            setStudy(null);
        }
    );

    // HANDLE DELETE KEYWORD GROUP
    const onDeleteKeywordGroup = (id: string | number) => {
        if (!study) {
            return;
        }
        const onConfirm = wrapper(async () => {
            const { message } = await deleteStudyKeywordGroup(study.id, id);

            if (!message) {
                throw new Error('Unable to delete keyword group.');
            }

            alertSuccess(message);
            setStudy(null);
        });
        setWarningModal({
            display: true,
            header: 'Delete Keyword Group:',
            content:
                'Are you sure you want to delete this keyword group and all associated keywords?',
            onConfirm,
        });
    };

    // HANDLE ADD NEW COMPETITOR GROUP
    const onAddCompetitorGroup = wrapper(
        async (groupName: string, competitors: string[]) => {
            if (!study) {
                return;
            }
            const body = generateUpdateStudyBody(study, {
                competitor_group: { [groupName]: competitors },
            });

            const { message } = await updateStudy(study.id, body);

            if (!message) {
                throw new Error('Unable to add competitor group.');
            }

            alertSuccess(message);
            setStudy(null);
        }
    );

    // HANDLE EDIT COMPETITOR GROUP CLICK
    const onEditCompetitorGroup = (id: string | number) => {
        if (!study) {
            return;
        }
        const index = study.competitor_group_details.findIndex((group) => {
            return group.id === id;
        });
        if (index < 0) {
            return;
        }
        const competitorGroup = study.competitor_group_details[index];
        setEditGroupModal({
            display: true,
            groupType: 'keyword',
            groupName: competitorGroup.name,
            details: competitorGroup.competitor_details,
            onSubmit: onUpdateCompetitorGroup,
        });
    };

    // HANDLE UPDATE COMPETITOR GROUP
    const onUpdateCompetitorGroup = wrapper(
        async (groupName: string, updates: GroupUpdates) => {
            const { deleted = [], added = [] } = updates;
            if (!study) {
                return;
            }
            if (deleted.length) {
                const { message } = await deleteStudyCompetitors(
                    study.id,
                    deleted
                );

                if (message) {
                    alertSuccess(message);
                } else {
                    throw new Error('Unable to update study.');
                }
            }
            if (added.length) {
                const body = generateUpdateStudyBody(study, {
                    competitor_group: { [groupName]: added },
                });
                const { message } = await updateStudy(study.id, body);

                if (message) {
                    alertSuccess(message);
                } else {
                    throw new Error('Unable to update study.');
                }
            }
            setStudy(null);
        }
    );

    // HANDLE DELETE COMPETITOR GROUP
    const onDeleteCompetitorGroup = (id: string | number) => {
        const onConfirm = wrapper(async () => {
            if (!study) {
                return;
            }
            const { message } = await deleteStudyCompetitorGroup(study.id, id);

            if (!message) {
                throw new Error('Unable to delete competitor group.');
            }

            alertSuccess(message);
            setStudy(null);
        });
        setWarningModal({
            display: true,
            header: 'Delete Competitor Group:',
            content:
                'Are you sure you want to delete this competitor group and all associated competitors?',
            onConfirm,
        });
    };

    // HANDLE SELECTING PROVIDER (ADMIN ONLY)
    const onSelectProvider = (selected: DropdownOption) => {
        if (!isAdmin || !study) {
            return;
        }

        const header = 'Study Provider:';
        const content = (
            <>
                <p style={{ fontSize: '1rem' }}>
                    Please confirm updating study provider to:
                </p>
                <p style={{ fontSize: '1.1rem' }}>
                    <strong>{selected.label}</strong>
                </p>
            </>
        );
        const onConfirm = wrapper(async () => {
            const data = await updateStudyProvider(
                study.id.toString(),
                parseInt(selected.value.toString())
            );

            if (data?.message) {
                alertSuccess(data.message);
            }

            if (data?.errorMessage) {
                alertError(data?.errorMessage);
            }
        });
        setWarningModal({ header, content, onConfirm, display: true });
    };

    // HANDLE SUBMIT STUDY UPDATE FORM
    const onSubmitUpdate = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!study) {
            return;
        }

        const body = generateUpdateStudyBody(study, {
            frequency: updatedFrequency?.value,
            status: updatedStatus?.value,
            name: updatedName,
        });

        const { message } = await updateStudy(study.id, body);

        if (!message) {
            throw new Error('Unable to update study.');
        }

        alertSuccess(message);
        navigate('/studies/view/' + study_id);
    });

    // JSX
    return (
        <PageContainer title={`edit study`}>
            <form onSubmit={onSubmitUpdate} className="form-update-study">
                <Row>
                    <Column>
                        <Textbox
                            value={updatedName}
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => setUpdatedName(evt.target.value)}
                            required
                        >
                            Study Name:
                        </Textbox>
                    </Column>

                    <Column>
                        <Dropdown
                            options={FREQUENCY_OPTIONS}
                            defaultValue={updatedFrequency}
                            value={updatedFrequency}
                            onChange={(
                                selected: (typeof FREQUENCY_OPTIONS)[0]
                            ) => {
                                setUpdatedFrequency(selected);
                            }}
                            required
                        >
                            <CustomTooltip content={STUDY_TOOLTIPS.FREQUENCY}>
                                Frequency:
                            </CustomTooltip>
                        </Dropdown>
                    </Column>

                    <Column>
                        <Dropdown
                            options={STATUS_OPTIONS}
                            defaultValue={updatedStatus}
                            value={updatedStatus}
                            onChange={(
                                selected: (typeof STATUS_OPTIONS)[0]
                            ) => {
                                setUpdatedStatus(selected);
                            }}
                            required
                        >
                            Status:
                        </Dropdown>
                    </Column>

                    {isAdmin && (
                        <Column>
                            <Dropdown
                                options={[
                                    {
                                        label: 'Oxylabs',
                                        value: PROVIDER_IDS.OXYLAB,
                                    },
                                    {
                                        label: 'SerpWow',
                                        value: PROVIDER_IDS.SERPWOW,
                                    },
                                ]}
                                value={{
                                    label: '',
                                    value: study?.provider_id || '',
                                }}
                                defaultValue={study?.provider_id}
                                onChange={onSelectProvider}
                            >
                                Provider:
                            </Dropdown>
                        </Column>
                    )}
                </Row>

                <Row style={{ marginTop: '2rem' }}>
                    <LocaleDetails
                        locales={study?.locale_details || []}
                        onDelete={onDeleteLocale}
                        onDuplicate={onDuplicateExistingLocale}
                        onAdd={onNewLocale}
                    />
                </Row>

                <Row>
                    <GroupDetails
                        type="keyword"
                        data={keywordGroupsData}
                        onDelete={onDeleteKeywordGroup}
                        onEdit={onEditKeywordGroup}
                        onAdd={() =>
                            setShowNewGroupModal({
                                groupType: 'keyword',
                                onSubmit: onAddKeywordGroup,
                            })
                        }
                    />
                </Row>

                <Row>
                    <GroupDetails
                        type="competitor"
                        data={competitorGroupsData}
                        onDelete={onDeleteCompetitorGroup}
                        onEdit={onEditCompetitorGroup}
                        onAdd={() =>
                            setShowNewGroupModal({
                                groupType: 'competitor',
                                onSubmit: onAddCompetitorGroup,
                            })
                        }
                    />
                </Row>
                <div className="form-buttons">
                    <Button
                        className="btn-cancel"
                        color="red"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                    <Button type="submit" disabled={updateBtnIsDisabled}>
                        Update Study
                    </Button>
                </div>
            </form>

            {/* === MODALS === */}
            {addLocaleModal.display && (
                <NewLocaleModal
                    onClose={() => setAddLocaleModal(defaultLocaleModalState)}
                    onSubmit={onAddLocaleSubmit}
                    details={addLocaleModal.details || null}
                />
            )}
            {warningModal.display && (
                <WarningModal
                    header={warningModal.header}
                    content={warningModal.content}
                    onConfirm={warningModal.onConfirm}
                    onClose={() => setWarningModal(defaultWarningModalState)}
                />
            )}
            {editGroupModal.display && editGroupModal.onSubmit && (
                <EditGroupModal
                    groupType={editGroupModal.groupType || 'keyword'}
                    groupName={editGroupModal.groupName || ''}
                    details={editGroupModal.details || {}}
                    onClose={() =>
                        setEditGroupModal(defaultEditGroupModalState)
                    }
                    onSubmit={editGroupModal.onSubmit}
                />
            )}
            {showNewGroupModal.groupType && showNewGroupModal.onSubmit && (
                <NewGroupModal
                    groupType={showNewGroupModal.groupType}
                    onClose={() => setShowNewGroupModal(defaultNewGroupState)}
                    onSubmit={showNewGroupModal.onSubmit}
                />
            )}
        </PageContainer>
    );
}

export default EditStudy;
