import './Locale-Details.scss';

// TYPES
import { LocaleDetailsState } from '../../state';

// CONSTANTS
import { STUDY_TOOLTIPS } from '../../tooltips';

// UI COMPONENTS
import {
    AccordionControl,
    AccordionContent,
} from '@publicismedia-ds/ui-accordion';
import Button from '@publicismedia-ds/ui-button';
import AccordionGroup from '../Accordion-Group/Accordion-Group';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface LocaleDetailsProps {
    onAdd?: () => void;
    newLocales?: boolean;
    locales: LocaleDetailsState[];
    onDelete?: (id: string | number) => void;
    onDuplicate?: (id: string | number) => void;
}

// FUNCTIONAL COMPONENT (LOCALE DETAILS)
function LocaleDetails({
    onAdd,
    locales,
    onDelete,
    onDuplicate,
}: LocaleDetailsProps) {
    return (
        <AccordionGroup>
            <AccordionControl targetId={'locales'}>
                {onAdd ? (
                    <CustomTooltip
                        content={
                            <div className="tooltip-max-width">
                                {STUDY_TOOLTIPS.LOCALES}
                            </div>
                        }
                        align={TOOLTIP_ALIGN.LEFT}
                    >
                        <h3>Locales</h3>
                    </CustomTooltip>
                ) : (
                    <h3>Locales</h3>
                )}
            </AccordionControl>
            <AccordionContent id={'locales'}>
                <div className="accordion-group-items">
                    {!!onAdd && (
                        <div className="button-add">
                            <Button onClick={onAdd}>Add Locale</Button>
                        </div>
                    )}
                    {locales.length ? (
                        locales.map((locale) => {
                            return (
                                <div key={locale.id}>
                                    <AccordionControl
                                        targetId={'locale-' + locale.id}
                                    >
                                        <div className="accordion-group-item">
                                            <p>
                                                <span className="locale-property">
                                                    Engine:
                                                </span>{' '}
                                                {locale.engine || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Device:
                                                </span>{' '}
                                                {locale.device || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Location:
                                                </span>{' '}
                                                {locale.location || 'N/A'}
                                            </p>
                                        </div>
                                    </AccordionControl>
                                    <AccordionContent
                                        id={'locale-' + locale.id}
                                    >
                                        <div className="accordion-group-item accordion-content">
                                            <h4 className="details-header">
                                                Details:
                                                <div className="action-buttons">
                                                    {!!onDuplicate && (
                                                        <i
                                                            className="icon-copy"
                                                            onClick={() =>
                                                                onDuplicate(
                                                                    locale.id
                                                                )
                                                            }
                                                            title="Duplicate"
                                                        />
                                                    )}
                                                    {!!onDelete && (
                                                        <i
                                                            className="icon-delete"
                                                            onClick={() =>
                                                                onDelete(
                                                                    locale.id
                                                                )
                                                            }
                                                            title="Delete"
                                                        />
                                                    )}
                                                </div>
                                            </h4>
                                            <p>
                                                <span className="locale-property">
                                                    Language:
                                                </span>{' '}
                                                {locale.language || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Latitude:
                                                </span>{' '}
                                                {locale.latitude || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Longitude:
                                                </span>{' '}
                                                {locale.longitude || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    City:
                                                </span>{' '}
                                                {locale.city || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    County:
                                                </span>{' '}
                                                {locale.county || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Radius:
                                                </span>{' '}
                                                {locale.radius || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Region:
                                                </span>{' '}
                                                {locale.region || 'N/A'}
                                            </p>
                                            <p>
                                                <span className="locale-property">
                                                    Zip:
                                                </span>{' '}
                                                {locale.zip || 'N/A'}
                                            </p>
                                        </div>
                                    </AccordionContent>
                                </div>
                            );
                        })
                    ) : (
                        <p style={{ marginLeft: '2rem' }}>No data available.</p>
                    )}
                </div>
            </AccordionContent>
        </AccordionGroup>
    );
}

export default LocaleDetails;
