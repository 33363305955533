import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  ReactNode,
} from 'react';

import { helpers } from '@publicismedia-ds/ui-theme';

const classNameComponent = 'pmds-nav-link';
const classNamePrefix = `${classNameComponent}__`;

const DEFAULT_TAG = 'button';

// COMPONENT PROPS
interface NavLinkProps {
  children?: React.ReactNode;
  className?: string;
  as?: any;
  icon?: string;
  title?: string;
  text?: string | ReactNode;
  hover?: boolean;
  selected?: boolean;
  expanded?: boolean;
  defaultExpanded?: boolean;
  href?: string;
  to?: string;
  onClick?: (...args: any[]) => void;
  onChange?: (...args: any[]) => void;
}

/*
 * NOTE ABOUT SAFARI:
 * Focus works differently in Safari. Clicking a button does not trigger :focus. This also means clicking elsewhere will not trigger onBlur
 * Programmaticly triggering focus() doesn't work either because when the user clicks on something else it fires blur and the user has to click a second time
 * The only solution I can see to simulate "onBlur" in Safari is to register a global listener on the document and onClick see if the element is this :\
 */

const NavLink = forwardRef<HTMLElement, NavLinkProps>(
  (
    {
      children,
      className,
      as,
      icon,
      title,
      text,
      hover,
      selected,
      expanded,
      href,
      to,
      onClick,
      onChange,
      ...props
    }: NavLinkProps,
    ref
  ) => {
    //Is component being controlled?
    const isControlled = typeof expanded !== typeof undefined;

    const refAs =
      ref && typeof ref !== 'function' ? ref : useRef<HTMLElement>(null);

    //State hooks
    const [stateExpanded, setStateExpanded] = useState(
      isControlled ? expanded : false
    );

    //Watch expanded for changes from parent and update state
    if (isControlled) {
      useEffect(
        function expandedUpdated() {
          setStateExpanded(expanded);
        },
        [expanded]
      );
    }

    //Parse params
    const paramIcon = helpers.parseParam(icon);
    const paramHref = helpers.parseParam(href);
    const paramTo = helpers.parseParam(to);

    //If href is passed in without "as" assume its an <a>
    const ParamAs = as || (paramHref ? 'a' : DEFAULT_TAG);
    const paramAsString = helpers.isString(as)
      ? as
      : paramHref || paramTo
      ? 'a'
      : DEFAULT_TAG;

    //Icon
    const iconIsClass = paramIcon && paramIcon.startsWith('icon');
    const renderChildren = helpers.parseChildren(children);
    let showIconArrow = !hover && renderChildren;
    if (isControlled && !onChange) {
      showIconArrow = false;
    }

    return (
      <div
        className={
          [
					classNameComponent, //prettier-ignore
					selected ? `${classNamePrefix}selected` : "",
					stateExpanded ? `${classNamePrefix}expanded` : "",
					hover ? `${classNamePrefix}hover` : `${classNamePrefix}no-hover`,
					renderChildren ? `${classNamePrefix}expandable` : `${classNamePrefix}not-expandable`,
					paramIcon ? `${classNamePrefix}has-icon` : `${classNamePrefix}no-icon`,
					className ? className : "",
				].join(" ").trim().replace(/\s+/g, " ") //prettier-ignore
        }
        onBlur={(evt) => {
          if (!hover) {
            const newStateExpanded = false;

            //Don't expand/contract if onChange is not defined
            if (!isControlled || (isControlled && onChange)) {
              setStateExpanded(newStateExpanded);
            }

            if (onChange) {
              onChange.call(this, evt, newStateExpanded);
            }
          }
        }}
      >
        <ParamAs
          ref={refAs}
          className={`${classNamePrefix}action ${classNamePrefix}as-${paramAsString}`}
          href={paramHref}
          to={paramTo}
          title={title}
          onClick={(evt: React.MouseEvent) => {
            // Is current target a child NavLink
            const isNavLink = evt.currentTarget.classList.contains(
              `${classNamePrefix}as-a`
            );

            if (!hover) {
              const newStateExpanded = !stateExpanded;

              //Don't expand/contract if onChange is not defined
              if (!isControlled || (isControlled && onChange)) {
                if (isNavLink) {
                  // Tigger parent onBlur
                  refAs.current?.blur();
                  /* 
                                     NOTE:
                                    - Do not update state as well if is a child NavLink.  
                                        * onBlur is triggered first and a re-render occurs changing state to false(true -> false)
                                        * after which the state is updated again followed by a re-render changing the state back to true (false -> true)
                                        * this was causing the menu not to close when a child NavLink was clicked
                                    */
                } else {
                  setStateExpanded(newStateExpanded);
                  if (!newStateExpanded) {
                    // If not a child NavLink and new state is false trigger onBlur to close menu
                    refAs?.current?.blur();
                  }
                }
              }

              if (onClick) {
                onClick.call(this, evt);
              }
              if (onChange) {
                onChange.call(this, evt, newStateExpanded);
              }
            }
          }}
          {...props}
        >
          {/* if icon value does not start with "icon" then assume html and write into span */}
          {paramIcon && (
            <i
              className={`${classNamePrefix}icon${
                iconIsClass && paramIcon ? ' ' + paramIcon : ''
              }`}
            >
              {!iconIsClass && <span>{paramIcon}</span>}
            </i>
          )}
          {text && <span className={`${classNamePrefix}text`}>{text}</span>}
          {showIconArrow && (
            <i className={`${classNamePrefix}icon-expand icon-caret-down`}></i>
          )}
        </ParamAs>
        {children && (
          <ul
            className={`${classNamePrefix}children`}
            aria-expanded={stateExpanded}
          >
            {React.Children.map(renderChildren, (child, index) => (
              <li key={`${classNamePrefix}child_${index}`}>{child}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
);

export default NavLink;
