import { CreateStudyState } from '../../../types/create-study-types/create-study-state';

export const initialNewStudyState: CreateStudyState = {
    name: '',
    frequency: null,
    account_id: null,
    client_id: null,
    locale: [],
    competitor_group: [],
    keyword_group: [],
};
