export enum PROVIDER_IDS {
  OXYLAB = 1,
  SERPWOW = 2,
}

export const isProduction =
  process.env.REACT_APP_ENV?.toLowerCase().includes('prod');

export const DEFAULT_QA_STUDY_ID = 163; // Chewy(2392) or Fashion(2864) NAPA(163)

export const BRAND_NAME = isProduction
  ? 'Benchtools'
  : 'Competitive Intelligence';

export const API_ROUTES = {
  USERS: '/users',
  STUDIES: '/studies',
  BRANDS: '/brands',
  ALERT: '/alert',
  ACCOUNT: '/account',
  BATTLEFIELD: '/battlefield',
  CLIENT: '/client',
  CONTENT: '/content',
  DASHBOARD: '/dashboard',
  LOGIN: '/authentication/signin',
  REPORT_SCHEDULAR: '/reportschedular',
  GET_KEYWORD_METRICS: '/keywords/metrics',
  GET_AGENCY_DETAILS:
    'https://qa-spapi.benchtools.net/scenario-planner-1.0/goal/get-agency-details',
  get GET_USER() {
    return this.USERS + '/view'; // .../:id
  },
  get GET_STUDY_BY_ID() {
    return this.STUDIES + '/view';
  },
  get GET_STUDIES() {
    return this.STUDIES + '/studylist';
  },
  get GET_ACCOUNTS() {
    return this.USERS + '/accounts/accountlist';
  },
  get ADD_USER() {
    return this.USERS + '/add';
  },
  get EXPORT_STUDIES() {
    return this.STUDIES + '/exportStudies';
  },
  get EXPORT_USERS() {
    return this.USERS + '/xlsx/exportDetails';
  },
  get EXPORT_SCHEDULED_REPORTS() {
    return this.REPORT_SCHEDULAR + '/export/schedule-reports';
  },
  get GET_STUDIES_ACTIVITY() {
    return this.STUDIES + '/activityLog';
  },
  get UPDATE_STUDY_PROVIDER() {
    return this.STUDIES + '/update/provider';
  },
  get GET_ALL_ALERTS() {
    return this.ALERT + '/allAlerts';
  },
  get GET_ALL_SCHEDULED_REPORTS() {
    return this.REPORT_SCHEDULAR + '/allScheduleReports';
  },
  get ADD_ACCOUNT() {
    return this.ACCOUNT + '/add';
  },
  get ADD_CLIENT() {
    return this.CLIENT + '/add';
  },
  get GET_AVERAGE_POSITION() {
    return this.BRANDS + '/average-position';
  },
  get GET_NEW_ADS_SEEN() {
    return this.BRANDS + '/new-ads';
  },
  get GET_PAID_VS_ORGANIC() {
    return this.CONTENT + '/paid-organic';
  },
  get GET_BRANDS_JSON() {
    return this.BRANDS + '/json';
  },
  get CONTENT_REPORT_XLSX() {
    return this.CONTENT + '/xlsx';
  },
  get GET_TOP_ADS() {
    return this.CONTENT + '/top-ads/';
  },
  get DASHBOARD_KEYWORD() {
    return this.DASHBOARD + '/keyword';
  },
  get GET_SHARE_OF_CLICKS() {
    return this.DASHBOARD_KEYWORD + '/clicks-by-date';
  },
  get GET_SHARE_OF_IMPRESSIONS() {
    return this.DASHBOARD + '/share-of-impressions';
  },
  get GET_BATTLEFIELD_JSON() {
    return this.BATTLEFIELD + '/json';
  },
};

export enum REPORT_TYPE {
  BATTLEFIELD = 'Battlefield',
  BRANDS = 'Brands',
  CONTENT = 'Content',
  KEYWORDS = 'Keywords',
  SERP_VISIBILITY = 'SERPVisibility',
  WORD_ANALYSIS = 'WordAnalysis',
  KEYWORDS_METRICS = 'KeywordsMetrics',
}

export const ALERTS = [
  { label: 'Trademark Market Monitor', value: 'Content' },
  { label: 'Keywords Monitor', value: 'Keywords' },
] as const;
export type AlertsType = (typeof ALERTS)[number];

export const ADS = ['sem', 'seo', 'pla'] as const;
export type AdsType = (typeof ADS)[number];

export const AD_TYPE_OPTIONS = ADS.map((adType) => ({
  label: adType.toUpperCase(),
  value: adType,
}));

// SERP VISIBILITY AD TYPES (SEM/PLA ONLY)
export const SERP_AD_TYPE_OPTIONS = [
  { label: 'SEM', value: 'sem' },
  { label: 'PLA', value: 'pla' },
];

// ELIGIBLE TIME ZONES FOR SCHEDULING REPORTS
export const TIMEZONE_OPTIONS = [
  { value: '-12', label: '(GMT -12:00) Eniwetok, Kwajalein' },
  { value: '-11', label: '(GMT -11:00) Midway Island, Samoa' },
  { value: '-10', label: '(GMT -10:00) Hawaii' },
  { value: '-09:50', label: '(GMT -9:30) Taiohae' },
  { value: '-09', label: '(GMT -9:00) Alaska' },
  { value: '-08', label: '(GMT -8:00) Pacific Time (US & Canada)' },
  { value: '-07', label: '(GMT -7:00) Mountain Time (US & Canada)' },
  {
    value: '-06',
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  },
  {
    value: '-05',
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  { value: '-04:50', label: '(GMT -4:30) Caracas' },
  {
    value: '-04',
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  { value: '-03:50', label: '(GMT -3:30) Newfoundland' },
  { value: '-03', label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
  { value: '-02', label: '(GMT -2:00) Mid-Atlantic' },
  { value: '-01', label: '(GMT -1:00) Azores, Cape Verde Islands' },
  {
    value: 'GMT',
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  { value: '+01', label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: '+02', label: '(GMT +2:00) Kaliningrad, South Africa' },
  {
    value: '+03',
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  { value: '+03:50', label: '(GMT +3:30) Tehran' },
  { value: '+04', label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
  { value: '+04:50', label: '(GMT +4:30) Kabul' },
  {
    value: '+05',
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  {
    value: '+05:50',
    label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  },
  { value: '+05:75', label: '(GMT +5:45) Kathmandu, Pokhara' },
  { value: '+06', label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
  { value: '+06:50', label: '(GMT +6:30) Yangon, Mandalay' },
  { value: '+07', label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
  { value: '+08', label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
  { value: '+08:75', label: '(GMT +8:45) Eucla' },
  {
    value: '+09',
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  { value: '+09:50', label: '(GMT +9:30) Adelaide, Darwin' },
  {
    value: '+10',
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  { value: '+10:50', label: '(GMT +10:30) Lord Howe Island' },
  {
    value: '+11',
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  { value: '+11:50', label: '(GMT +11:30) Norfolk Island' },
  {
    value: '+12',
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
  { value: '+12:75', label: '(GMT +12:45) Chatham Islands' },
  { value: '+13', label: '(GMT +13:00) Apia, Nukualofa' },
  { value: '+14', label: '(GMT +14:00) Line Islands, Tokelau<' },
];

// ROUTE ACCESS DENIED MESSAGE
export const UNAUTHORIZED =
  'You are not authorized to view the requested resource.';

// DEFAULT API REQUEST ERROR MESSAGE
export const DEFAULT_API_ERROR =
  'Unfortunately we are unable to complete your request at this time.';

export const PM_DS_PREFIX = '@publicmedia-ds';

// TABLE VIEW DROPDOWN OPTIONS
export const FEATURE_OPTIONS = [
  { label: 'Studies', value: 'studies' },
  { label: 'Alerts', value: 'alerts' },
  { label: 'Scheduled Reports', value: 'scheduledReports' },
] as const;

export const YESTERDAY = new Date();
YESTERDAY.setDate(YESTERDAY.getDate() - 1);

export const DATA_VIZ_COLORS = [
  '#2764b0',
  '#b48a53',
  '#81cacf',
  '#419fa1',
  '	#e98841',
  '#e3d830',
  '#a6c46f',
  '#57b966',
  '#894c7b',
  '#be5873',
  '#7f7f7f',
  '#c3c3c3',
];
