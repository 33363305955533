import './Create-Scheduled-Report.scss';

import { useActions } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

// CONSTANTS
import { ROUTES } from '../../../router';

// TYPES
import { ReportFiltersState, ReportFormState } from '../../../state';

// API REQUESTS
import { createScheduledReport } from '../../../api';

// UI COMPONENT IMPORTS
import ReportForm from '../../../components/Report-Form/Report-Form';
import PageContainer from '../../../components/Page-Container/Page-Container';

// UTILS
import {
    generateCreateReportBody,
    generateRequestParamsString,
} from '../../../utils';

// FUNCTIONAL COMPONENT (CREATE SCHEDULED REPORT)
function CreateScheduledReport() {
    // HOOKS
    const navigate = useNavigate();

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // HANDLE CREATE SCHEDULED REPORT (NO WRAPPER - IMPLEMENTED IN REPORT_FORM)
    const onFormSubmit = async (
        report: ReportFormState,
        filters: ReportFiltersState
    ) => {
        const requestParams = generateRequestParamsString(filters);
        const body = generateCreateReportBody(report, requestParams);

        if (!body) {
            return;
        }

        const data = await createScheduledReport(body);

        if (data) {
            alertSuccess('Successfully created scheduled report');
            navigate(ROUTES.SCHEDULED_REPORTS);
        }
    };

    // JSX
    return (
        <PageContainer title="schedule report">
            <ReportForm onSubmit={onFormSubmit} />
        </PageContainer>
    );
}

export default CreateScheduledReport;
