import { sortDropdownOptions } from '..';
import {
  DropdownOption,
  LocaleDetailsState,
  KeywordGroupDetails,
  CompetitorGroupDetails,
} from '../../state';

interface UniqueDetails {
  [key: number]: string;
}

// PARSE STUDY KEYWORD GROUPS AND UNIQUE KEYWORDS
export function parseKeywordGroups(keywordGroupDetails: KeywordGroupDetails[]) {
  const keywordGroupOptions: DropdownOption[] = [];
  const keywordOptions: DropdownOption[] = [];
  const keywords: UniqueDetails = {};

  // GET KEYWORD GROUPS
  keywordGroupDetails.forEach((keywordGroup) => {
    const { keyword_details, name, id } = keywordGroup;
    keywordGroupOptions.push({ label: name, value: id });
    // GET UNIQUE KEYWORDS
    for (const keyword in keyword_details) {
      const keywordId = keyword_details[keyword];
      if (keywords[keywordId] === undefined) {
        keywords[keywordId] = keyword;
      }
    }
  });

  for (const [id, keyword] of Object.entries(keywords)) {
    keywordOptions.push({
      label: keyword,
      value: id,
    });
  }

  return [
    sortDropdownOptions(keywordGroupOptions),
    sortDropdownOptions(keywordOptions),
  ];
}

// PARSE STUDY COMPETITOR GROUPS
export function parseCompetitorGroups(
  competitorGroupDetails: CompetitorGroupDetails[]
) {
  const competitorGroupOptions: DropdownOption[] = [];
  const competitorOptions: DropdownOption[] = [];
  const competitors: UniqueDetails = {};

  // GET COMPETITOR GROUPS
  competitorGroupDetails.forEach((competitorGroup) => {
    const { competitor_details, name, id } = competitorGroup;
    competitorGroupOptions.push({ label: name, value: id });
    // GET UNIQUE COMPETITORS
    for (const competitor in competitor_details) {
      const competitorId = competitor_details[competitor];
      if (competitors[competitorId] === undefined) {
        competitors[competitorId] = competitor;
      }
    }
  });

  for (const [id, competitor] of Object.entries(competitors)) {
    competitorOptions.push({
      label: competitor,
      value: id,
    });
  }

  return [
    sortDropdownOptions(competitorGroupOptions),
    sortDropdownOptions(competitorOptions),
  ];
}

// PARSE STUDY TARGETS
export function parseTargets(localeDetails: LocaleDetailsState[]) {
  const targetOptions: DropdownOption[] = localeDetails.map((locale) => {
    const label = `${locale.engine || '-'} / ${locale.device || '-'} / ${
      locale.language || '-'
    } / ${locale.location || '-'} / ${locale.region || '-'} / ${
      locale.city || '-'
    }`;
    return { label, value: locale.id };
  });

  return [sortDropdownOptions(targetOptions)];
}

export const getCompsBySelectedGroups = (
  selectedGroups: DropdownOption[],
  groupDetails: CompetitorGroupDetails[]
) => {
  // Include all by default
  let details = [...groupDetails];

  // Create selections obj {[key: id]: label} (avoids looping within a loop when filtering)
  const selections = selectedGroups.reduce(
    (obj: { [key: string]: string }, { label, value }) => {
      obj[value.toString()] = label;
      return obj;
    },
    {}
  );

  // Filter Competitors by selected Groups (if applicable)
  if (selectedGroups.length) {
    details = details.filter(({ id }) => selections[id]);
  }

  // RETURN DROPDOWN OPTIONS
  return parseCompetitorGroups(details)[1];
};
