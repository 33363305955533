import './Account-Details.scss';

import { useAsyncWrapper } from '../../hooks';
import { useEffect, useReducer } from 'react';

// TYPES
import {
    StatusType,
    FeatureOption,
    DropdownOption,
    AlertsTableItem,
    accountDetailsReducer,
    ACCOUNT_DETAILS_ACTIONS,
} from '../../state';

// CONSTANTS
import { FEATURE_OPTIONS } from '../../constants';
import { DEFAULT_ACCOUNT_DETAILS_STATE } from '../../state';

// API REQUESTS
import {
    getAllAlerts,
    getStudyList,
    getAccountList,
    getAllScheduledReports,
} from '../../api';

// UTILS
import { sortDropDowns } from '../../utils';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import AccountReportsTable from './Account-Reports-Table';
import AlertsTable from '../Brand-Monitoring/Alerts-Table';
import StudiesTable from '../../components/Studies-Table/Studies-Table';
import PageContainer from '../../components/Page-Container/Page-Container';

// FUNCTIONAL COMPONENT (ACCOUNT DETAILS)
function AccountDetails() {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // PAGE STATE
    const [state, dispatch] = useReducer(
        accountDetailsReducer,
        DEFAULT_ACCOUNT_DETAILS_STATE
    );

    // FETCH AND STORE ACCOUNT OPTIONS
    const loadAccounts = async () => {
        // FETCH ACCOUNTS LIST
        const accounts = await getAccountList();

        // CREATE DROPDOWN OPTIONS
        return accounts
            .map(({ name, id }) => ({
                label: `${name} (ID#: ${id})`,
                value: id,
            }))
            .sort(sortDropDowns.ascending);
    };

    // FETCH AND STORE STUDY LIST
    const loadStudies = async () => {
        return await getStudyList();
    };

    // FETCH AND STORE ALL BRAND ALERTS
    const loadBrandAlerts = async () => {
        // FETCH BRAND ALERTS
        const { alertsReportDetails } = await getAllAlerts();
        return alertsReportDetails;
    };

    // FETCH AND STORE ALL SCHEDULED REPORTS
    const loadScheduledReports = async () => {
        const { scheduledReportDetails } = await getAllScheduledReports();
        return scheduledReportDetails;
    };

    // LOAD PAGE DATA
    const loadPageData = wrapper(async () => {
        // LOAD ACCOUNT LIST AND STUDY LIST
        await Promise.all([
            loadStudies(),
            loadAccounts(),
            loadBrandAlerts(),
            loadScheduledReports(),
        ])
            .then(
                ([
                    studies = [],
                    accountOptions = [],
                    alerts = [],
                    reports = [],
                ]) => {
                    const studiesStatus = studies.reduce(
                        (obj: { [key: string]: StatusType }, studyData) => {
                            obj[studyData.id] = studyData.status;
                            return obj;
                        },
                        {}
                    );

                    // FORMAT ALERTS DATA FOR TABLE
                    const formattedAlerts = alerts.map(
                        (alertData): AlertsTableItem => ({
                            start_date: alertData.startDate,
                            status: alertData.status,
                            study_id: alertData.studyId,
                            study_name: alertData.studyName,
                            id: alertData.id,
                            report_type: alertData.reportType,
                            created_by: alertData.createdBy,
                            account_id: alertData.accountId,
                            account_name: alertData.accountName,
                            study_status: studiesStatus[alertData.studyId],
                            client_name: alertData.clientName,
                        })
                    );

                    dispatch({
                        type: ACCOUNT_DETAILS_ACTIONS.LOAD_DATA,
                        payload: {
                            studies,
                            alerts: formattedAlerts,
                            reports,
                            accountOptions,
                            accountId: null,
                            feature: FEATURE_OPTIONS[0],
                        },
                    });
                }
            )
            .catch((error) => error);
    });

    // LOAD REPQUIRED DATA ON INITIAL RENDER
    useEffect(() => {
        loadPageData();
    }, []);

    // HANDLE ACCOUNT SELECTION
    const onSelectAccount = (selected: DropdownOption) => {
        // STORE ACCOUNT DETAILS
        dispatch({
            type: ACCOUNT_DETAILS_ACTIONS.SET_ACCOUNT,
            payload: selected.value as number,
        });
    };

    // HANDLE SELECT VIEW TABLE
    const onSetFeatureOption = (selected: FeatureOption) => {
        dispatch({
            type: ACCOUNT_DETAILS_ACTIONS.SET_FEATURE,
            payload: selected,
        });
    };

    return (
        <PageContainer title="account details">
            <div className="account-details-container">
                <div className="account-details-content">
                    <Row cols={3}>
                        <Column>
                            <Dropdown
                                options={state.accountOptions}
                                onChange={onSelectAccount}
                                value={state.accountId || ''}
                                required
                            >
                                Account:
                            </Dropdown>
                        </Column>
                        <Column> </Column>
                        <Column>
                            <Dropdown
                                options={FEATURE_OPTIONS}
                                onChange={onSetFeatureOption}
                                value={state.feature || ''}
                                isSearchable={false}
                            >
                                Feature:
                            </Dropdown>
                        </Column>
                    </Row>
                    {state.accountId ? (
                        <Row className="account-details-row">
                            <div className="account-details-data">
                                {/* --- STUDIES --- */}
                                {state.feature?.value === 'studies' && (
                                    <StudiesTable
                                        data={state.studies.filter(
                                            ({ account_id }) =>
                                                account_id === state.accountId
                                        )}
                                    />
                                )}

                                {/* --- ALERTS ---  */}
                                {state.feature?.value === 'alerts' && (
                                    <AlertsTable
                                        data={state.alerts.filter(
                                            ({ account_id }) =>
                                                account_id === state.accountId
                                        )}
                                        showButtons={false}
                                        isEditable={false}
                                    />
                                )}

                                {/* --- SCHEDULED REPORTS --- */}
                                {state.feature?.value ===
                                    'scheduledReports' && (
                                    <AccountReportsTable
                                        data={state.reports.filter(
                                            ({ accountId }) =>
                                                accountId === state.accountId
                                        )}
                                    />
                                )}
                            </div>
                        </Row>
                    ) : null}
                </div>
            </div>
        </PageContainer>
    );
}

export default AccountDetails;
