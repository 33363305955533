export enum NewLocaleProps {
    ENGINE = 'engine',
    DEVICE = 'device',
    LANGUAGE = 'language',
    LOCATION = 'location',
}

export enum NewLocaleLocationProps {
    CITY = 'city',
    COUNTRY = 'country',
    COUNTY = 'county',
    LATITUDE = 'latitude',
    LONGITUDE = 'longitude',
    RADIUS = 'radius',
    REGION = 'region',
    ZIP = 'zip',
}

export enum NewLocaleActionTypes {
    UPDATE_ENGINE = 'update_engine',
    UPDATE_DEVICE = 'update_device',
    UPDATE_LANGUAGE = 'update_language',
    UPDATE_CITY = 'update_city',
    UPDATE_COUNTRY = 'update_contry',
    UPDATE_COUNTY = 'update_county',
    UPDATE_LATITUDE = 'update_latitude',
    UPDATE_LONGITUDE = 'update_longitude',
    UPDATE_RADIUS = 'update_radius',
    UPDATE_REGION = 'update_region',
    UPDATE_ZIP = 'update_zip',
}

export interface NewLocaleState {
    id?: number | string;
    engine: string;
    language: string;
    device: string;
    location: {
        city: string;
        country: string;
        county: string;
        latitude: string;
        longitude: string;
        radius: string;
        region: string;
        zip: string;
    };
}

export interface NewLocaleDropdown {
    label: string;
    value: string;
}
