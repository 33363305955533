import produce from 'immer';

import {
    NewLocaleState,
    NewLocaleAction,
    NewLocaleActionTypes,
} from '../../../types/edit-study-types';
import { initialNewLocaleState } from './models';

export const newLocaleReducer = produce(
    (state: NewLocaleState, action: NewLocaleAction) => {
        const { value, type } = action;
        switch (type) {
            case NewLocaleActionTypes.UPDATE_CITY:
                state.location.city = value;
                return state;

            case NewLocaleActionTypes.UPDATE_COUNTRY:
                state.location.country = value;
                return state;

            case NewLocaleActionTypes.UPDATE_COUNTY:
                state.location.county = value;
                return state;

            case NewLocaleActionTypes.UPDATE_DEVICE:
                state.device = value;
                return state;

            case NewLocaleActionTypes.UPDATE_ENGINE:
                state.engine = value;
                return state;

            case NewLocaleActionTypes.UPDATE_LANGUAGE:
                state.language = value;
                return state;

            case NewLocaleActionTypes.UPDATE_LATITUDE:
                state.location.latitude = value;
                return state;

            case NewLocaleActionTypes.UPDATE_LONGITUDE:
                state.location.longitude = value;
                return state;

            case NewLocaleActionTypes.UPDATE_RADIUS:
                state.location.radius = value;
                return state;

            case NewLocaleActionTypes.UPDATE_REGION:
                state.location.region = value;
                return state;

            case NewLocaleActionTypes.UPDATE_ZIP:
                state.location.zip = value;
                return state;

            default:
                return state;
        }
    },
    initialNewLocaleState
);
