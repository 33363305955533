import { authReducer } from './auth-reducer';
import { alertsReducer } from './alerts-reducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from './loading-reducer';
import { tooltipsReducer } from './tooltips-reducer';
import { insightsReducer } from './insights-filter-reducer';

const reducer = combineReducers({
  auth: authReducer,
  alerts: alertsReducer,
  loading: loadingReducer,
  tooltips: tooltipsReducer,
  insights: insightsReducer,
});

export default reducer;
