import './Export-Charts-Button.scss';

import { useAsyncWrapper } from '../../../hooks';

// UTILS
import JSZip from 'jszip';
import {
    convertSvgToPng,
    triggerDownload,
    getSelectionDetails,
    addLabelToWordCloudSVG,
} from '../../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';

// TYPES
import { InsightsState } from '../../../state';

// COMPONENT PROPS
interface ExportChartsBtnProps {
    studyName: string;
    filters: InsightsState | null;
}

// FUNCTIONAL COMPONENT (EXPORT CHARTS BUTTON)
function ExportChartsBtn({ studyName, filters }: ExportChartsBtnProps) {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // HANDLE EXPORTING CHARTS AS PNGS
    const onExportCharts = wrapper(async () => {
        // GET ALL CHART SVGS
        const svgNodes: NodeListOf<SVGElement> =
            document.querySelectorAll('.apexcharts-svg');

        // INSTANTIATE ZIP INSTANCE
        const zip = new JSZip();

        const folderName = `bt-visuals_${studyName}`;

        // CREATE FILTER SELECTIONS SUMMARY STRING
        const selectionDetails = getSelectionDetails(filters, studyName);

        // SAVE FILTER SELECTIONS SUMMARY AS TXT FILE
        zip.file(`${folderName}/Selection_Details.txt`, selectionDetails);

        // CREATE PROMISES ARRAY TO CONVERT EACH SVG TO PNG
        const promises = Array.from(svgNodes).map(async (svg, index) => {
            let svgToConvert: SVGElement = svg;

            const svgId =
                svg.parentElement?.parentElement?.id || `visual_${index}`;

            if (svgId === 'word-cloud') {
                const labelText =
                    document.querySelector('.word-cloud-label')?.textContent ||
                    '';

                const svgWithLabel = addLabelToWordCloudSVG(svg, labelText);

                svgToConvert = svgWithLabel;
            }

            const { blob } = await convertSvgToPng(svgToConvert);

            return { pngBlob: blob, fileName: `${studyName}__${svgId}` };
        });

        // CONVERT SVGs TO PNGs
        const pngBlobs = await Promise.all(promises);

        // INCLUDE EACH PNG WITHIN ZIP FILE
        pngBlobs.forEach(({ pngBlob, fileName }) => {
            if (pngBlob) {
                zip.file(`${folderName}/${fileName}.png`, pngBlob, {
                    base64: true,
                });
            }
        });

        // DOWNLOAD ZIP FILE
        zip.generateAsync({ type: 'blob' }).then((blob) => {
            // CREATE URL
            const url = URL.createObjectURL(blob);

            // DOWNLOAD
            triggerDownload(url, `bt-visuals_${studyName}`, 'zip');

            // REMOVE URL FROM MEMORY
            URL.revokeObjectURL(url);
        });
    });

    return (
        <div className="export-charts-button-container">
            <Button onClick={onExportCharts} color="brand-2">
                Export All
            </Button>
        </div>
    );
}

export default ExportChartsBtn;
