import './Alert-Messages.scss';

import { useEffect } from 'react';
import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import Modal from '../Modal/Modal';

// AUTO CLOSE MODAL AFTER N SECONDS
const ALERT_DISPLAY_TIME = 8000; // milliseconds

function AlertMessages() {
    const { successMessages, errorMessages } = useAppSelector(
        ({ alerts }) => alerts
    );
    const { clearSuccessAlert, clearErrorAlert, clearAllAlerts } = useActions();

    // AUTO CLEAR ALERTS AFTER N SECONDS
    useEffect(() => {
        if (!successMessages.length && !errorMessages.length) {
            return;
        }

        const timer = setTimeout(() => {
            clearAllAlerts();
        }, ALERT_DISPLAY_TIME);

        // CLEARS PRIOR TIMER IF ANOTHER ALERT OCCURS
        return () => {
            clearTimeout(timer);
        };
    }, [successMessages, errorMessages]);

    const messages = !!successMessages.length || !!errorMessages.length;

    return messages ? (
        <>
            {!!successMessages.length && (
                <Modal
                    id="alertSuccess"
                    alertType="success"
                    onClose={clearSuccessAlert}
                    content={
                        <div className="alert-content">
                            {successMessages.map((msg, index) => (
                                <p
                                    className="alert-message alert-message-success"
                                    key={index}
                                >
                                    {msg}
                                </p>
                            ))}
                        </div>
                    }
                />
            )}

            {!!errorMessages.length && (
                <Modal
                    id="alertError"
                    alertType="error"
                    onClose={clearErrorAlert}
                    content={
                        <div className="alert-content">
                            {errorMessages.map((msg, index) => (
                                <p
                                    className="alert-message alert-message-error"
                                    key={index}
                                >
                                    {msg}
                                </p>
                            ))}
                        </div>
                    }
                />
            )}
        </>
    ) : null;
}

export default AlertMessages;
