import './Radial-Bar-Compare.scss';

// TYPES
import { ApexOptions } from 'apexcharts';

// UI COMPONENTS
import RadialBar from '../Radial-Bar';

// CONPONENT PROPS
interface RadialBarCompareProps {
  className?: string;
  current: number | null;
  prior: number | null;
  min?: number;
  title?: string;
}

// FUNCTIONAL COMPONENT (RADIAL BAR COMPARE DATA)
function RadialBarCompare({
  className,
  current,
  prior,
  min,
  title,
}: RadialBarCompareProps) {
  /* NOTE: Radial bar values can only range from 0-100 (percentage)
    Since the values for "Average Position" is not percentage based
    the value passed in "series" is adjusted to a percentage.
  */
  let ratio: number;
  let diff: number | null = null; // no change

  let actualValue = current || 0;

  if (current !== null && prior !== null) {
    // lower current value than prior is desired (ie pos. 1 > pos. 5)
    diff = current - prior;
  }

  if (min !== undefined) {
    ratio = 100 / min;
    actualValue =
      current !== null && current < min
        ? Math.floor((min - current) * ratio)
        : 0;

    // Adjust value if greater than half min (currently 1 === 90, where 100 is desired)
    if (current !== null && current < min / 2) actualValue += ratio;

    if (diff) diff = diff * -1;
  }

  // CHART OPTIONS
  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2.5rem',
      },
    },
    title: {
      style: {
        fontSize: '0.9rem',
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#f1f1f1',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 4,
            left: 0,
            color: '#d1d1d1',
            opacity: 1,
            blur: 4,
          },
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: -10,
            color: '#000',
            fontSize: '2.25rem',
            fontWeight: '100',
          },
          value: {
            show: false,
            offsetY: 0,
            fontSize: '22px',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: ['#2764b0'],
    },
    labels: [`${current || 'N/A'}${min !== undefined || !current ? '' : '%'}`],
  };

  const markerStyles: React.CSSProperties = {
    position: 'absolute',
    bottom: '20px',
    fontSize: '0.9rem',
    fontWeight: '500',
  };

  const diffColor = diff === null ? 'black' : diff >= 0 ? '#6aa428' : '#f41a43';

  // JSX
  return (
    <div className="radial-bar-compare-container">
      <RadialBar
        className={className}
        data={[actualValue]}
        options={{ ...options }}
        title={title}
        height={300}
        width="100%"
      />
      {min !== undefined ? (
        <>
          <span style={{ ...markerStyles, left: '-5px' }}>{min}</span>
          <span style={{ ...markerStyles, right: '0px' }}>1</span>
        </>
      ) : null}
      <span
        style={{
          position: 'absolute',
          fontSize: '.9rem',
          left: '50%',
          translate: '-50%',
          bottom: '62px',
          color: diffColor,
        }}
      >
        {diff !== null ? (
          <>
            <i style={{ color: diffColor, fontSize: '.70rem' }} />
            {diff >= 0 ? (
              <span style={{ fontSize: '.75rem' }}>&#9650;</span>
            ) : (
              <span style={{ fontSize: '.75rem' }}>&#9660;</span>
            )}
            {Math.abs(diff) + `${min !== undefined ? '' : '%'}`}
          </>
        ) : (
          'N/A'
        )}
      </span>
    </div>
  );
}

export default RadialBarCompare;
