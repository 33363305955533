import './Brand-Benchmarking.scss';

import { useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getKeywordMetrics } from '../../api';

// TYPES
import {
    StudyDetails,
    InsightsState,
    DropdownOption,
    KeywordsOverlap,
    GETKeywordsMetrics,
    KeywordsMetricsByBrand,
} from '../../state';

// CONSTANTS
import { REPORT_TYPE } from '../../constants';

// UI COMPONENTS
import KeywordsMetricsTable from './Keywords-Metrics-Table';
import KeywordsOverlapChart from './Keywords-Overlap-Chart';
import InsightsFilter from '../Insights-Filter/Insights-Filter';

// COMPONENT PROPS
interface BrandBenchmarkingProps {
    study: StudyDetails;
}

// FUNCTIONAL COMPONENT (BRAND BENCHMARKING)
function BrandBenchmarking({ study }: BrandBenchmarkingProps) {
    const wrapper = useAsyncWrapper();

    // KEYWORDS METRICS DATA
    const [metricsData, setMetricsData] =
        useState<KeywordsMetricsByBrand | null>(null);
    const [overlapData, setOverlapData] = useState<KeywordsOverlap | null>(
        null
    );

    // GROUP KEYWORDS METRICS DATA BY BRAND
    const getMetricsByBrand = (
        data: GETKeywordsMetrics,
        brands: DropdownOption[]
    ) => {
        const output = brands.reduce(
            (acc: KeywordsMetricsByBrand, { label }) => {
                acc[label] = {
                    topRate: [],
                    outrankRate: [],
                    aboveRate: [],
                };

                return acc;
            },
            {}
        );

        for (const brands of Object.values(data.top_of_page_rate)) {
            for (const [brandName, brandData] of Object.entries(brands)) {
                output[brandName].topRate.push(...brandData);
            }
        }

        for (const brands of Object.values(data.outrank_share_rate)) {
            for (const [brandName, brandData] of Object.entries(brands)) {
                output[brandName].outrankRate.push(...brandData);
            }
        }

        for (const brands of Object.values(data.position_above_rate)) {
            for (const [brandName, brandData] of Object.entries(brands)) {
                output[brandName].aboveRate.push(...brandData);
            }
        }

        return output;
    };

    // HANDLE FORM SUBMISSION
    const onSubmit = wrapper(async (filterState: InsightsState) => {
        const data = await getKeywordMetrics(study.id, filterState);

        setMetricsData(getMetricsByBrand(data, filterState.competitors));
        setOverlapData(data.overlaps);
    });

    return (
        <div className="brand-benchmarking-container">
            <div>
                <InsightsFilter
                    study={study}
                    onSubmit={onSubmit}
                    reportType={REPORT_TYPE.KEYWORDS_METRICS}
                />
            </div>
            <div>
                {metricsData ? (
                    <KeywordsMetricsTable data={metricsData} />
                ) : null}
                {overlapData ? (
                    <KeywordsOverlapChart data={overlapData} />
                ) : null}
            </div>
        </div>
    );
}

export default BrandBenchmarking;
