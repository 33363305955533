import './Reset-Password.scss';

import { useState } from 'react';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import AlertMessage from '../../components/Alert-Message/Alert-Message';
import PageContainer from '../../components/Page-Container/Page-Container';

// API REQUESTS
import { submitResetPassword } from '../../api';

// FUNCTIONAL COMPONENT
function ResetPassword() {
    // HOOKS
    const { uuid } = useParams();
    const navigate = useNavigate();
    const wrapper = useAsyncWrapper();
    const { user } = useAppSelector(({ auth }) => auth);

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // FORM INPUT STATES
    const [newPassword, setNewPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const isSubmitDisabled = user?.token
        ? !confirmPassword || !newPassword || !currentPassword
        : !newPassword || !confirmPassword;

    // HANDLE SUBMIT PASSWORD RESET
    const onSubmit = wrapper(async (evt: React.FormEvent) => {
        evt.preventDefault();
        if (!uuid) {
            return;
        }
        if (newPassword !== confirmPassword) {
            setPasswordsMatch(false);
            return;
        }
        const successMessage = await submitResetPassword(uuid, newPassword);

        alertSuccess(successMessage);
        navigate('/login');
    });

    return (
        <PageContainer title="password-reset">
            <div className="password-reset-container">
                {!passwordsMatch && (
                    <AlertMessage
                        type="error"
                        message="Passwords must match."
                    />
                )}
                <form onSubmit={onSubmit}>
                    {/* CURRENT PASSWORD INPUT */}
                    {user?.token && (
                        <div className="password-reset-input">
                            <Textbox
                                type="password"
                                value={currentPassword}
                                onChange={(
                                    evt: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setCurrentPassword(evt.target.value);
                                }}
                                required
                                invert={true}
                                error="Please enter current password"
                            >
                                Current Password
                            </Textbox>
                        </div>
                    )}

                    {/* NEW PASSWORD INPUT */}
                    <div className="password-reset-input">
                        <Textbox
                            type="password"
                            value={newPassword}
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setNewPassword(evt.target.value);
                            }}
                            required
                            invert={true}
                            error="Please enter new password"
                        >
                            New Password
                        </Textbox>
                    </div>

                    {/* CONFIRM PASSWORD INPUT */}
                    <div className="password-reset-input">
                        <Textbox
                            type="password"
                            value={confirmPassword}
                            onChange={(
                                evt: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setConfirmPassword(evt.target.value);
                            }}
                            required
                            invert={true}
                            error="Please confirm new password."
                        >
                            Confirm New Password
                        </Textbox>
                    </div>
                    <div className="custom-modal-footer password-reset-buttons">
                        <Button
                            className="btn-cancel"
                            color="red"
                            onClick={() => navigate('/')}
                            style={{ color: 'white' }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitDisabled}>
                            Confirm
                        </Button>
                    </div>
                </form>
            </div>
        </PageContainer>
    );
}

export default ResetPassword;
