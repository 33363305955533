import './Accordion-Group.scss';

interface AccordionGroupProps {
    children: React.ReactNode;
}

function AccordionGroup({ children }: AccordionGroupProps) {
    return <div className="accordion-group">{children}</div>;
}

export default AccordionGroup;
