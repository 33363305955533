import { convertDateToString } from '../index';
import { ReportFormState, ReportFiltersState } from '../../state';

interface Params {
  [key: string]: string | number | true;
}

// PARSE DOWNLOAD URL AND PARAMS FOR REPORTS
export function getDownloadUrlAndParams(
  reportSelections: ReportFormState,
  filterSelections: ReportFiltersState
): [string, Params] | [null, null] {
  const { report_type, file_type, study, start_date, end_date } =
    reportSelections;

  if (!study || !start_date || !end_date || !report_type) {
    return [null, null];
  }

  const url = `/${report_type.toLowerCase()}/${file_type}/${study?.id}`;

  const params: Params = {
    start_date: convertDateToString(start_date),
    end_date: convertDateToString(end_date),
  };

  // PARSE PARAMS
  for (const key in filterSelections) {
    if (key.includes('Options')) {
      continue;
    }
    const filterKey = key as keyof ReportFiltersState;
    let filterValue = filterSelections[filterKey];
    filterValue = Array.isArray(filterValue)
      ? filterValue.map((opt) => opt.value).join(',')
      : filterValue;

    if (filterValue) {
      params[filterKey] = filterValue;
    }
  }

  return [url, params];
}

// GENERATES FILE NAME FOR STUDY REPORT DOWNLOAD
export const generateFileName = (reportSelections: ReportFormState) => {
  const { study, report_type, file_type } = reportSelections;
  const now = new Date();
  const year = now.getFullYear(),
    month = now.getMonth() + 1,
    date = now.getDate(),
    hours = now.getHours(),
    minutes = now.getMinutes(),
    seconds = now.getSeconds();

  const fileName = `${study?.id}_${report_type}_${year}_${
    month < 10 ? '0' + month : month
  }_${date < 10 ? '0' + date : date}_${hours < 10 ? '0' + hours : hours}_${
    minutes < 10 ? '0' + minutes : minutes
  }_${seconds < 10 ? '0' + seconds : seconds}.${file_type}`;

  return fileName;
};
