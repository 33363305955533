import './Custom-Tooltip.scss';

import Tooltip from '@publicismedia-ds/ui-tooltip';
import { useAppSelector } from '../../hooks';

interface CustomTooltipProps {
    maxWidth?: string | number; // default '400'
    icon?: 'info' | 'support' | 'hidden';
    align?: string;
    content: string | React.ReactNode;
    position?: string;
    children?: string | React.ReactNode;
    className?: string;
    displayAlways?: boolean;
    displayOnFocus?: boolean;
}

export enum TOOLTIP_ALIGN {
    CENTER = 'center',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
}

export enum TOOLTIP_POSITION {
    ABOVE = 'above',
    BELOW = 'below',
    LEFT = 'left',
    RIGHT = 'right',
}

function CustomTooltip({
    icon = 'info',
    align,
    content,
    children,
    position,
    maxWidth,
    className,
    displayAlways,
    displayOnFocus,
}: CustomTooltipProps) {
    const tooltipsEnabled = useAppSelector(({ tooltips }) => tooltips.enabled);

    const styles: React.CSSProperties = {};

    if (maxWidth !== undefined) {
        const width =
            typeof maxWidth === 'string'
                ? parseInt(maxWidth || '400')
                : maxWidth;
        styles.width = `${width}px`;
        styles.whiteSpace = 'normal';
    }

    return (
        <div
            className={`custom-tooltip-container${
                className ? ` ${className}` : ''
            }`}
        >
            {tooltipsEnabled || displayAlways ? (
                <>
                    {children}
                    <Tooltip
                        className="custom-tooltip"
                        title={<div style={styles}>{content}</div>}
                        align={align || TOOLTIP_ALIGN.CENTER}
                        position={position || TOOLTIP_POSITION.BELOW}
                        displayOnFocus={
                            displayOnFocus !== undefined ? displayOnFocus : true
                        }
                    >
                        <div className="custom-tooltip-label">
                            {icon !== 'hidden' && (
                                <i className={`icon-${icon} tooltip-icon`} />
                            )}
                        </div>
                    </Tooltip>
                </>
            ) : (
                children
            )}
        </div>
    );
}

export default CustomTooltip;
