import { DropdownOption } from '../../state';
import { NewLocaleState } from '../edit-study-types';
import { GroupData } from '../../components/Group-Content/Group-Content';

export const FREQUENCY_VALUES = [
    1, 3, 4, 6, 8, 12, 24, 48, 96, 144, 288,
] as const;

export const FREQUENCY_OPTIONS = FREQUENCY_VALUES.map((value) => {
    return {
        label: value + 'x',
        value: value as FrequencyValueType,
    };
});

export type FrequencyValueType = typeof FREQUENCY_VALUES[number];

export interface FrequecyOptionType {
    label: string;
    value: FrequencyValueType;
}

export interface CreateStudyState {
    name: string;
    frequency: FrequecyOptionType | null;
    account_id: DropdownOption | null;
    client_id: DropdownOption | null;
    locale: NewLocaleState[];
    competitor_group: GroupData[];
    keyword_group: GroupData[];
}
