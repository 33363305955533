import './Top-Ad-Item.scss';

// COMPONENT PROPS
interface TopAdProps {
  brand?: string;
  title?: string;
  displayURL?: string;
  description?: string;
}

// FUNCTIONAL COMPONENT (TOP AD)
function TopAd({ brand, title, displayURL, description }: TopAdProps) {
  return (
    <div className="top-ad-item-container position-relative">
      <div className="link-wrapper">
        <span className="top-ad-header-text sponsored">Sponsored</span>
      </div>
      <div className="top-ad-header">
        <span className="container">
          <span className="inner-1">
            {/* <span className="inner-2">
              <div className="inner-div">
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path>
                </svg>
              </div>
            </span> */}
            <span className="link-wrapper">
              <span className="link-item-2-wrapper">
                {displayURL ? displayURL : brand ? `www.${brand}` : ''}
              </span>
              <span className="svg-kebob">
                <svg
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                </svg>
              </span>
            </span>
          </span>
          <span></span>
        </span>
        <span className="ad-title">{title}</span>
      </div>
      <div className="top-ad-description">
        {description ? (
          <span className="description">
            <span className="ad-marker">Ad</span>
            {description}
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default TopAd;
