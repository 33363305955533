// CONSTANTS
import { BENCHMARKING_TOOLTIPS } from '../../tooltips';

// TYPES
import { ApexOptions } from 'apexcharts';
import { KeywordsOverlap } from '../../state';

// UI COMPONENTS
import CustomTooltip, {
  TOOLTIP_POSITION,
} from '../Custom-Tooltip/Custom-Tooltip';
import { Bar, TOOLTIP } from '@publicismedia-ds/ui-charts';

// COMPONENT PROPS
interface KeywordsOverlapChartProps {
  data: KeywordsOverlap | null;
}

// CHART DATA TYPE
interface ChartData {
  categories: string[];
  data: number[];
}

// FUNCTIONAL COMPONENT (KEYWORDS OVERLAP BAR CHART)
function KeywordsOverlapChart({ data }: KeywordsOverlapChartProps) {
  const tempData = [];

  // AVERAGE OVERLAP VALUE FOR ALL TARGETS FOR EACH KEYWORD
  for (const [keyword, targets] of Object.entries(data || {})) {
    const value = Math.round(
      targets.reduce((acc, target) => {
        return (acc += target.overlap);
      }, 0) / targets.length
    );
    tempData.push({
      keyword,
      value,
    });
  }

  // SORT KEYWORDS BY VALUE (DESC)
  tempData.sort((a, b) => {
    if (a.value > b.value) {
      return -1;
    }
    if (a.value < b.value) {
      return 1;
    }
    return 0;
  });

  // FORMAT CHART DATA
  const chartData = tempData.reduce(
    (acc: ChartData, { keyword, value }) => {
      acc.categories.push(keyword);
      acc.data.push(value);
      return acc;
    },
    { categories: [], data: [] }
  );

  // ADJUST CHART HEIGHT BY NUMBER OF KEYWORD ROWS
  const chartHeight = Math.max(350, chartData.categories.length * 50);

  // CHART OPTIONS
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: chartHeight,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2rem',
      },
    },
    xaxis: {
      min: 0,
      max: 100,
      tickAmount: 4,
      categories: chartData.categories,
      labels: {
        show: true,
      },
      title: {
        text: 'Overlap (%)',
      },
    },
    yaxis: {
      labels: {
        show: chartData.data.length > 0,
        style: {
          fontWeight: 'bold',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div className="keyword-overlap-chart-container">
      <div className="keyword-overlap-chart-header">
        <CustomTooltip
          content={BENCHMARKING_TOOLTIPS.KEYWORD_OVERLAP}
          position={TOOLTIP_POSITION.ABOVE}
          maxWidth="350px"
        >
          <span className="chart-title">Keyword Overlap%:</span>
        </CustomTooltip>
      </div>
      <div className="keyword-overlap-chart-content">
        <Bar
          data={[chartData]}
          options={options}
          barSingle={true}
          width="100%"
          tooltipPos={TOOLTIP.BAR}
        />
      </div>
    </div>
  );
}

export default KeywordsOverlapChart;
