export interface StatusModalState {
    display: boolean;
    action: string | null;
    onConfirm: ((...args: any[]) => Promise<void>) | null;
}

export const DEFAULT_STATUS_MODAL_STATE: StatusModalState = {
    display: false,
    action: null,
    onConfirm: null,
};
