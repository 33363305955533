import './Dashboard.scss';

import { ROUTES } from '../../router';
import { Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
  getStudyById,
  getStudyList,
  getTopAdsData,
  getWordAnalysis,
  getBrandsReport,
  getPaidVsOrganic,
  getNewAdsLast7Days,
  getAveragePosition,
  getShareOfImpressions,
  getDailyBattlefieldData,
} from '../../api';

// CONSTANTS
import { DEFAULT_QA_STUDY_ID, isProduction } from '../../constants';

// TYPES
import {
  DashboardData,
  DashboardState,
  DropdownOption,
  AdhocReportState,
} from '../../state';

// UI COMPONENTS
import { AdMessagingChart } from '../../components/Charts';
import Grid, { Row, Column } from '@publicismedia-ds/ui-grid';
import PageContainer from '../../components/Page-Container/Page-Container';
import ShareOfClicksChart from '../../components/Charts/Share-Clicks-Chart';
import NewAdsLastSevenDays from '../../components/Charts/New-Ads-Seven-Days';
import TopAdsChart from '../../components/Charts/Top-Ads-Chart/Top-Ads-Chart';
import PaidVsOrganicChart from '../../components/Charts/Paid-Vs-Organic-Chart';
import TopCompetitorsChart from '../../components/Charts/Top-Competitors-Chart';
import DashboardFilter from '../../components/Dashboard-Filter/Dashboard-Filter';

// UTILS
import {
  getDateRangeString,
  sortDropdownOptions,
  getCompsBySelectedGroups,
} from '../../utils';
import CompetitorRadials from '../../components/Charts/Competitor-Radials/Competitor-Radials';
import DailyBattlefieldChart from '../../components/Charts/Daily-Battlefiled-Chart';

// DEFAULT START DATE (PRIOR 7)
const defaultStart = new Date();
defaultStart.setDate(defaultStart.getDate() - 7);

// DEFAULT END DATE (YESTERDAY)
const defaultEnd = new Date();
defaultEnd.setDate(defaultEnd.getDate() - 1);

// DEFAULT PRIOR END
const defaultPriorEnd = new Date(defaultStart);
defaultPriorEnd.setDate(defaultPriorEnd.getDate() - 1);

const defaultPriorStart = new Date(defaultPriorEnd);
defaultPriorStart.setDate(defaultPriorStart.getDate() - 6);

const DEFAULT_DATA: DashboardData = {
  wordAnalysisBrandsData: null,
  averagePosition: null,
  newAdsLast7Days: null,
  brandsReport: null,
  topAds: null,
  paidVsOrganic: null,
  shareOfClicksLast7Days: null,
  shareOfImpressions: null,
  dailyBattlefieldData: null,
};

// DEFAULT DASHBOARD STATE
const DEFAULT_STATE: DashboardState = {
  activeStudies: [],
  appliedFilters: null,
  selectedBrand: null,
  ...DEFAULT_DATA,
};

// DEFAULT APPLIED FILTERS STATE
const defaultFilters: AdhocReportState = {
  study: null,
  start_date: defaultStart.toDateString(),
  end_date: defaultEnd.toDateString(),
  prior_start_date: defaultPriorStart.toDateString(),
  prior_end_date: defaultPriorEnd.toDateString(),
};

// FUNCTIONAL COMPONENT (DASHBOARD)
function Dashboard() {
  const wrapper = useAsyncWrapper();

  // REDUX STATE
  const user = useAppSelector(({ auth }) => auth.user);

  // CURRENTLY APPLIED FILTERS
  const [state, setState] = useState<DashboardState>(DEFAULT_STATE);

  // HANDLE FETCHING OF REPORTS
  const getReportsData = async (filters: AdhocReportState | null) => {
    // Ensure required values are present and valid
    if (!filters?.study || !filters.start_date || !filters.end_date) {
      throw new Error('Please enter a value for all required fields.');
    }
    // Fetch reports/data
    const fetchedData = await Promise.allSettled([
      getDailyBattlefieldData(filters),
      getShareOfImpressions(filters),
      getPaidVsOrganic(filters),
      getTopAdsData(filters),
      getWordAnalysis(
        { ...filters, competitors: [competitorOptions[0]] },
        { focus: 'brands' }
      ),
      getAveragePosition(filters),
      getNewAdsLast7Days(filters.study.id),
      getBrandsReport(filters),
    ]).then(
      ([
        dailyBattlefieldData,
        impressions,
        paidVsOrganic,
        topAds,
        wordAnalysis,
        avgPos,
        newAds,
        brandsReport,
      ]): DashboardData => {
        // Return successfully fetched data
        return {
          ...DEFAULT_DATA,
          dailyBattlefieldData:
            dailyBattlefieldData.status === 'fulfilled'
              ? dailyBattlefieldData.value
              : null,
          shareOfImpressions:
            impressions.status === 'fulfilled' ? impressions.value : null,
          wordAnalysisBrandsData:
            wordAnalysis.status === 'fulfilled' ? wordAnalysis.value : null,
          averagePosition: avgPos.status === 'fulfilled' ? avgPos.value : null,
          newAdsLast7Days: newAds.status === 'fulfilled' ? newAds.value : null,
          brandsReport:
            brandsReport.status === 'fulfilled' ? brandsReport.value : null,
          topAds: topAds.status === 'fulfilled' ? topAds.value : null,
          paidVsOrganic:
            paidVsOrganic.status === 'fulfilled' ? paidVsOrganic.value : null,
        };
      }
    );
    return fetchedData;
  };

  // FETCH AND STORE INITIAL DATA
  const loadInitialData = wrapper(async () => {
    const studies = await getStudyList();

    const activeStudies = studies.filter(({ status }) => status === 'Active');
    let defaultStudy = activeStudies[0] ? activeStudies[0] : null;

    if (!isProduction) {
      // Set default study to Chewy (if eligible)
      defaultStudy =
        activeStudies.find(({ id }) => id === DEFAULT_QA_STUDY_ID) ||
        defaultStudy;
    }

    // FETCH STUDY
    const studyDetails = defaultStudy
      ? await getStudyById(defaultStudy.id)
      : null;

    // SET DEFAULT STUDY
    defaultFilters.study = studyDetails;

    const data = await getReportsData(defaultFilters);
    // const data = { ...DEFAULT_DATA };

    setState({
      activeStudies,
      appliedFilters: { ...defaultFilters },
      selectedBrand: null,
      ...data,
    });
  });

  // LOAD PAGE DATA ON INITIAL RENDER
  useEffect(() => {
    if (isProduction) return;
    loadInitialData();
  }, []);

  // FORMAT CURRENT DATE RANGE TO BE DISPLAYED IN CHART COMPONENTS
  const currentRange = useMemo(() => {
    return getDateRangeString(
      state.appliedFilters?.start_date,
      state.appliedFilters?.end_date
    );
  }, [state.appliedFilters?.start_date, state.appliedFilters?.end_date]);

  // FORMAT PRIOR DATE RANGE TO BE DISPLAYED IN CHART COMPONENTS
  const priorRange = useMemo(() => {
    return getDateRangeString(
      state.appliedFilters?.prior_start_date,
      state.appliedFilters?.prior_end_date
    );
  }, [
    state.appliedFilters?.prior_start_date,
    state.appliedFilters?.prior_end_date,
  ]);

  // HANDLE APPLY SELECTED FILTERS SUBMISSION
  const onApplyFilters = async (filters: AdhocReportState) => {
    const data = await getReportsData(filters);
    setState((prev) => ({ ...prev, appliedFilters: { ...filters }, ...data }));
  };

  // GET COMPETITOR OPTIONS (AD MESSAGING)
  const competitorOptions = useMemo(() => {
    const options = getCompsBySelectedGroups(
      state.appliedFilters?.competitor_group || [],
      state.appliedFilters?.study?.competitor_group_details || []
    );
    setState((prev) => ({ ...prev, selectedBrand: options[0] }));
    return options;
  }, [state.appliedFilters?.study, state.appliedFilters?.competitor_group]);

  const agencyOptions = useMemo(() => {
    const options =
      user?.agenciesInfo?.agencies?.map(
        ({ agencyName, agencyId }): DropdownOption => ({
          label: agencyName,
          value: agencyId,
        })
      ) || [];
    return sortDropdownOptions(options);
  }, [user]);

  // HANDLE COMPETITOR SELECTION
  const onSelectCompetitor = (selected: DropdownOption) => {
    setState((prev) => ({ ...prev, selectedBrand: selected }));
  };

  // JSX
  return isProduction ? (
    <Navigate to={ROUTES.HOME} />
  ) : (
    <PageContainer>
      <div className="dashboard-content">
        <DashboardFilter
          studies={state.activeStudies}
          onApply={onApplyFilters}
          defaultState={state.appliedFilters}
        />
        <Grid>
          {/* --- NEW ADS LAST 7 DAYS --- */}
          <Row>
            <Column>
              <NewAdsLastSevenDays data={state.newAdsLast7Days || null} />
            </Column>
          </Row>

          {/* --- TOP COMPETITORS --- */}
          <Row>
            <Column>
              <TopCompetitorsChart
                data={state.brandsReport || null}
                dateRange={currentRange}
              />
            </Column>
          </Row>

          {/* --- TOP ADS --- */}
          <Row>
            <Column>
              <TopAdsChart
                data={state.topAds || null}
                competitors={competitorOptions}
                dateRange={currentRange}
                onChange={onSelectCompetitor}
                value={state.selectedBrand}
              />
            </Column>
          </Row>

          {/* --- AD MESSAGING --- */}
          <Row>
            <Column>
              <AdMessagingChart
                data={state.wordAnalysisBrandsData || null}
                competitors={competitorOptions}
                filters={state.appliedFilters}
                dateRange={currentRange}
                onChange={onSelectCompetitor}
                value={state.selectedBrand}
              />
            </Column>
          </Row>

          {/* --- PAID VS ORGANIC --- */}
          <Row>
            <Column>
              <PaidVsOrganicChart
                data={state.paidVsOrganic || null}
                competitors={competitorOptions}
                dateRange={currentRange}
                onChange={onSelectCompetitor}
                value={state.selectedBrand}
              />
            </Column>
          </Row>

          {/* --- SHARE OF CLICKS LAST 7 DAYS --- */}
          {state.activeStudies.length && user?.agenciesInfo ? (
            <>
              <Row>
                <Column>
                  <ShareOfClicksChart agencyOptions={agencyOptions} />
                </Column>
              </Row>
            </>
          ) : null}

          {/* --- Brand Ownership (Pos, Imps, Clicks(pending), Spend(pending)) --- */}
          <Row>
            <Column>
              <CompetitorRadials
                positionData={state.averagePosition}
                impressionsData={state.shareOfImpressions}
                competitors={competitorOptions}
                dateRange={`${currentRange} vs ${priorRange}`}
                value={state.selectedBrand}
                onChange={onSelectCompetitor}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <DailyBattlefieldChart
                data={state.dailyBattlefieldData}
                competitors={competitorOptions}
                onChange={onSelectCompetitor}
                value={state.selectedBrand}
                dateRange={currentRange}
              />
            </Column>
          </Row>
        </Grid>
      </div>
    </PageContainer>
  );
}

export default Dashboard;
