import './Study-View.scss';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions, useAsyncWrapper, useUserPermissions } from '../../hooks';

// CONSTANTS
import { ROUTES } from '../../router';

// TYPES
import { StudyDetails } from '../../state';

// API REQUESTS
import { getStudyById } from '../../api';

// UI COMPONENT IMPORTS
import TabGroup from '@publicismedia-ds/ui-tabs';
import PageContainer from '../../components/Page-Container/Page-Container';
import ContentAnalysis from '../../components/Content-Analysis/Content-Analysis';
import StudyDetailsView from '../../components/Study-Details-View/Study-Details-View';
import BrandBenchmarking from '../../components/Brand-Benchmarking/Brand-Benchmarking';
import CompetitiveInsights from '../../components/Competitive-Insights/Competitive-Insights';

// FUNCTIONAL COMPONENT (STUDY VIEW)
function StudyView() {
    // HOOKS
    const navigate = useNavigate();
    const { study_id } = useParams();
    const wrapper = useAsyncWrapper();
    const { isPrivileged } = useUserPermissions();

    // REDUX ACTIONS
    const { resetInsights } = useActions();

    // CURRENTLY SELECTED TAB
    const [selectedTab, setSelectedTab] = useState(0);

    // STUDY DETAILS
    const [study, setStudy] = useState<StudyDetails | null>(null);

    // FETCH AND STORE STUDY DETAILS
    const loadStudyDetails = wrapper(async () => {
        if (!study_id) {
            return;
        }

        const studyDetails = (await getStudyById(study_id)) || null;

        if (!studyDetails) {
            navigate(ROUTES.STUDIES);
        }

        setStudy(studyDetails);
    });

    // LOAD STUDY DETAILS ON INITIAL RENDER AND STUDY_ID CHANGES
    useEffect(() => {
        // LOAD DETAILS
        loadStudyDetails();

        // RESET FILTER SELECTIONS
        resetInsights();
    }, [study_id]);

    const onEditStudyClick = () => {
        navigate(`/studies/edit/${study_id}`, { state: study });
    };

    return (
        <PageContainer>
            <div className="study-view-container">
                <h1 className="study-view-header">
                    {study?.name}{' '}
                    {isPrivileged && (
                        <i className="icon-edit" onClick={onEditStudyClick} />
                    )}
                </h1>
                <TabGroup
                    className="study-view-tab-group"
                    tabs={[
                        {
                            label: 'Details',
                            disabled: false,
                            panelId: 'study-details',
                        },
                        {
                            label: 'Competitive Insights',
                            disabled: study?.status !== 'Active',
                            panelId: 'competitive-insights',
                        },
                        {
                            label: 'Content Analysis',
                            disabled: study?.status !== 'Active',
                            panelId: 'content-analysis',
                        },
                        {
                            label: 'Brand Benchmarking',
                            disabled: study?.status !== 'Active',
                            panelId: 'brand-benchmarking',
                        },
                    ]}
                    onSelect={(tabIndex: number) => {
                        setSelectedTab(tabIndex);
                    }}
                    selectedTab={selectedTab}
                    invert={true}
                >
                    {/* --- DETAILS TAB --- */}
                    <div
                        id="study-details"
                        role="tabpanel"
                        aria-labelledby="Study Details"
                    >
                        <div>
                            {!!study && <StudyDetailsView study={study} />}
                        </div>
                    </div>

                    {/* --- COMPETITIVE INSIGHTS TAB --- */}
                    {selectedTab === 1 && (
                        <div
                            id="competitive-insights"
                            role="tabpanel"
                            aria-labelledby="Competitive Insights"
                        >
                            <div className="competitive-insights-tab">
                                {!!study && (
                                    <div>
                                        <CompetitiveInsights
                                            study={study}
                                            active={selectedTab === 1}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {/* --- CONTENT ANALYSIS --- */}
                    {selectedTab === 2 && (
                        <div
                            id="content-analysis"
                            role="tabpanel"
                            aria-labelledby="Content Analysis"
                        >
                            <div className="content-analysis-tab">
                                {!!study && <ContentAnalysis study={study} />}
                            </div>
                        </div>
                    )}
                    {selectedTab === 3 && (
                        <div
                            id="brand-benchmarking"
                            role="tabpanel"
                            aria-labelledby="Brand Benchmarking"
                        >
                            <div className="brand-benchmarking-tab">
                                {!!study && <BrandBenchmarking study={study} />}
                            </div>
                        </div>
                    )}
                </TabGroup>
            </div>
        </PageContainer>
    );
}

export default StudyView;
