import { LoadingAction } from '../actions';
import { LOADING_ACTION_TYPES } from '../action-types';

export const setIsLoading = (isLoading: boolean): LoadingAction => {
    if (isLoading) {
        return {
            type: LOADING_ACTION_TYPES.START_LOADING,
        };
    } else {
        return {
            type: LOADING_ACTION_TYPES.STOP_LOADING,
        };
    }
};
