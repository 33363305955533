import './New-Group-Modal.scss';

import { useActions } from '../../hooks';
import React, { useState, useMemo } from 'react';

// UTILS
import { isValidRootDomain } from '../../utils';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import ModalFooterButtons from '../Modal-Footer-Buttons/Modal-Footer-Buttons';

interface NewGroupModalProps {
    groupType: 'keyword' | 'competitor';
    onClose: () => void;
    onSubmit:
        | ((groupName: string, items: string[]) => Promise<void>)
        | ((groupName: string, items: string[]) => void);
}

function NewGroupModal({ groupType, onClose, onSubmit }: NewGroupModalProps) {
    // FORM STATE
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupItem, setNewGroupItem] = useState('');
    const [addedGroupItems, setAddedGroupItems] = useState<string[]>([]);

    // REDUX ACTIONS
    const { alertError } = useActions();

    const onAddGroupItem = () => {
        if (!newGroupItem) {
            return;
        }

        const trimmedItem = newGroupItem.trim();

        const foundDuplicateItem = addedGroupItems.indexOf(trimmedItem) > -1;
        if (foundDuplicateItem) {
            alertError(`Duplicate ${groupType}s are not allowed.`);
            return;
        }

        if (groupType === 'competitor') {
            const isValid = isValidRootDomain(trimmedItem);
            if (!isValid) {
                alertError(
                    'Please enter the root domain for all competitors (ex: Chewy.com, harvard.edu)'
                );
                return;
            }
        }

        setAddedGroupItems((prev) => [...prev, trimmedItem]);
        setNewGroupItem('');
    };

    const renderedAddedGroupItems = useMemo(() => {
        return addedGroupItems.map((item, index) => {
            return (
                <p key={index} className="new-group-item">
                    <span
                        data-index={index}
                        className="remove-new-group-item"
                        onClick={() => {
                            setAddedGroupItems((prev) =>
                                prev.filter((text, i) => i !== index)
                            );
                        }}
                    >
                        X
                    </span>
                    {item}
                </p>
            );
        });
    }, [addedGroupItems]);

    return (
        <Modal onClose={onClose} userClosable={false}>
            <div className="new-group-container custom-modal-container">
                <div className="new-group-header custom-modal-header">
                    <h2>New {groupType} group:</h2>
                    <Textbox
                        value={newGroupName}
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setNewGroupName(evt.target.value);
                        }}
                        required
                    >
                        Group Name:
                    </Textbox>
                </div>
                <div className="new-group-content custom-modal-content">
                    <form
                        onSubmit={(evt: React.FormEvent) =>
                            evt.preventDefault()
                        }
                    >
                        <div className="new-group-item-field-container">
                            <h3>Add {groupType}s:*</h3>
                            <div className="new-group-item-field">
                                <div className="added-group-items-content">
                                    {renderedAddedGroupItems}
                                </div>
                            </div>
                            <div className="add-new-group-item-inputs">
                                <Textbox
                                    className="new-group-item-input"
                                    hideLabel={true}
                                    value={newGroupItem}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setNewGroupItem(evt.target.value);
                                    }}
                                    onKeyUp={(evt: React.KeyboardEvent) => {
                                        if (evt.key === 'Enter') {
                                            onAddGroupItem();
                                        }
                                    }}
                                >
                                    ''
                                </Textbox>
                                <Button onClick={onAddGroupItem}>Add</Button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="custom-modal-footer">
                    <ModalFooterButtons
                        onCancel={onClose}
                        onConfirm={async () => {
                            const trimmedName = newGroupName.trim();
                            if (!trimmedName) {
                                alertError('Group name is required.');
                                return;
                            }
                            await onSubmit(trimmedName, addedGroupItems);
                            onClose();
                        }}
                        disabled={
                            !newGroupName.trim() || !addedGroupItems.length
                        }
                        confirmText="Add Group"
                    />
                </div>
            </div>
        </Modal>
    );
}

export default NewGroupModal;
