import produce from 'immer';
import { formatReportFormData } from '../../../utils/reports-utils/load-existing-report';
import {
    ReportFormAction,
    ReportFormState,
    REPORT_FORM_ACTION_TYPES,
    DEFAULT_REPORT_FORM_STATE,
} from '../models';

export const reportReducer = produce(
    (state: ReportFormState, action: ReportFormAction) => {
        switch (action.type) {
            case REPORT_FORM_ACTION_TYPES.SET_REPORT_TYPE:
                state.report_type = action.value;
                return state;

            case REPORT_FORM_ACTION_TYPES.SET_STUDY:
                state.study = action.value;
                return state;

            case REPORT_FORM_ACTION_TYPES.ADD_EMAIL_RECIPIENT:
                state.email_recipients.push(action.value);
                return state;

            case REPORT_FORM_ACTION_TYPES.REMOVE_EMAIL_RECIPIENT:
                state.email_recipients = state.email_recipients.filter(
                    (email) => email !== action.value
                );
                return state;

            case REPORT_FORM_ACTION_TYPES.SET_START_DATE:
                state.start_date = action.value;

                if (state.end_date && state.end_date < action.value) {
                    const date = new Date(action.value);
                    date.setDate(date.getDate() + 1);
                    state.end_date = date;
                }

                return state;

            case REPORT_FORM_ACTION_TYPES.SET_END_DATE:
                state.end_date = action.value;
                return state;

            case REPORT_FORM_ACTION_TYPES.SET_FILE_TYPE:
                state.file_type = action.value;
                return state;

            case REPORT_FORM_ACTION_TYPES.SET_OCCURRENCE:
                state.occurrence = action.value;
                return state;

            case REPORT_FORM_ACTION_TYPES.LOAD_EXISTING_REPORT: {
                const { report, study } = action.value;
                state = formatReportFormData(report);
                state.study = study;
                return state;
            }

            default:
                return state;
        }
    },
    DEFAULT_REPORT_FORM_STATE
);
