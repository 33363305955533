import { StatusType, StudyListItem } from '../state';
import { ReportType, ScheduledReport, ScheduledReportRow } from '../types';

export const getScheduledReportsURL = () => {
    const protocol = 'https://';
    const hostname = window.location.hostname;
    return protocol + hostname + '/resportschedular/export/schedule-reports';
};

// GENERATES FILE NAME FOR REPORTS DOWNLOAD
export const generateScheduledReportFileName = () => {
    const now = new Date();
    const year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hours = now.getHours(),
        minutes = now.getMinutes(),
        seconds = now.getSeconds();

    const fileName = `Scheduled_Reports_Details_${year}_${
        month < 10 ? '0' + month : month
    }_${date < 10 ? '0' + date : date}_${hours < 10 ? '0' + hours : hours}_${
        minutes < 10 ? '0' + minutes : minutes
    }_${seconds < 10 ? '0' + seconds : seconds}.xlsx`;

    return fileName;
};

export const formatReportRowData = (
    reportList: ScheduledReport[],
    studyList: StudyListItem[]
) => {
    const studies = studyList.reduce(
        (prev: { [key: number]: StudyListItem }, study) => {
            prev[study.id] = study;
            return prev;
        },
        {}
    );

    const formatedRowData: ScheduledReportRow[] = reportList.map((report) => {
        const {
            report_id,
            study_id,
            report_type,
            schedule,
            status,
            csv_email_report_recipients,
        } = report;

        return {
            id: report_id,
            report_id: report_id.toString(),
            study_id: study_id.toString(),
            study_name: studies[study_id]?.name || 'N/A',
            report_type: report_type as ReportType,
            occurrence: schedule.occurrence,
            start_date: schedule.start_date,
            end_date: schedule.end_date,
            recipients: csv_email_report_recipients,
            status: status as StatusType,
            study_status: studies[study_id]?.status || 'Suspended',
        };
    });

    return formatedRowData;
};
