import './Alert-Message.scss';

import { useActions, useAppSelector } from '../../hooks';
import { useEffect } from 'react';

const ALERT_DISPLAY_TIME = 10000; // miliseconds

// COMPONENT PROPS
interface AlertMessageProps {
    type?: 'success' | 'error';
    message?: string;
    onDismiss?: () => void;
}

function AlertMessage({ type, message, onDismiss }: AlertMessageProps) {
    // REDUX ALERTS STATE
    const { successMessages, errorMessages } = useAppSelector(
        ({ alerts }) => alerts
    );

    // REDUX ALERTS ACTIONS
    const { clearSuccessAlert, clearErrorAlert, clearAllAlerts } = useActions();

    // AUTO CLEAR ALERTS AFTER N SECONDS
    useEffect(() => {
        if (!successMessages.length && !errorMessages.length) {
            return;
        }

        const timer = setTimeout(() => {
            clearAllAlerts();
        }, ALERT_DISPLAY_TIME);

        // AUTO SCROLL TO TOP OF PAGE
        window.scroll(0, 0);

        // CLEARS PRIOR TIMER IF ANOTHER ALERT OCCURS
        return () => {
            clearTimeout(timer);
        };
    }, [successMessages, errorMessages, clearAllAlerts]);

    // HANDLE DISMISS SUCCESS ALERT
    const onDismissSuccess = () => {
        if (onDismiss) {
            onDismiss();
        } else {
            clearSuccessAlert();
        }
    };

    // HANDLE DISMISS ERROR ALERT
    const onDismissError = () => {
        if (onDismiss) {
            onDismiss();
        } else {
            clearErrorAlert();
        }
    };

    return (
        <>
            {(!!successMessages.length || type === 'success') && (
                <div className="alert-message-container display-alert alert-message-success">
                    {/* DISMISS ICON  */}
                    {type && !onDismiss ? (
                        ''
                    ) : (
                        <div
                            className="alert-message-dismiss"
                            onClick={onDismissSuccess}
                        >
                            X
                        </div>
                    )}
                    {type === 'success' && !!message && (
                        <div className="alert-message-content">{message}</div>
                    )}
                    {successMessages.map((msg, index) => (
                        <div
                            key={'success-' + index}
                            className="alert-message-content"
                        >
                            {msg}
                        </div>
                    ))}
                </div>
            )}
            {(!!errorMessages.length || type === 'error') && (
                <div className="alert-message-container display-alert alert-message-error">
                    {/* DISMISS ICON  */}
                    {type && !onDismiss ? (
                        ''
                    ) : (
                        <div
                            className="alert-message-dismiss"
                            onClick={onDismissError}
                        >
                            X
                        </div>
                    )}
                    {type === 'error' && !!message && (
                        <div className="alert-message-content">{message}</div>
                    )}
                    {errorMessages.map((msg, index) => (
                        <div
                            key={'error-' + index}
                            className="alert-message-content"
                        >
                            {msg}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default AlertMessage;
