import { POSTCreateStudy } from '../../types/create-study-types';
import { CreateStudyState } from '../../types/create-study-types/create-study-state';

export const generateCreateStudyBody = (
    newStudyValues: CreateStudyState
): POSTCreateStudy => {
    const {
        name,
        frequency,
        account_id,
        client_id,
        locale,
        keyword_group,
        competitor_group,
    } = newStudyValues;
    const newLocales = locale.map((newLocale) => {
        const copy = { ...newLocale };
        delete copy.id;
        return copy;
    });
    const formatted_keyword_groups = keyword_group.reduce(
        (prev: { [key: string]: string[] }, group) => {
            prev[group.name] = Object.keys(group.details);
            return prev;
        },
        {}
    );
    const formatted_competitor_groups = competitor_group.reduce(
        (prev: { [key: string]: string[] }, group) => {
            prev[group.name] = Object.keys(group.details);
            return prev;
        },
        {}
    );

    const body: POSTCreateStudy = {
        name: name.trim(),
        locale: newLocales,
        type: 'Market Analysis',
        clientId: client_id?.value || '',
        frequency: frequency?.value || 3,
        accountId: account_id?.value || '',
        keyword_group: formatted_keyword_groups,
        competitor_group: formatted_competitor_groups,
    };
    return body;
};
