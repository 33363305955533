import './Scheduled-Reports-Table.scss';

import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAsyncWrapper, useUserPermissions } from '../../../hooks';

// CONSTANTS
import { ROUTES } from '../../../router';
import { API_ROUTES } from '../../../constants';

// UI COMPONENT IMPORTS
import DataTable, {
    TD_TYPE,
    TD_ALIGN,
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import Button from '@publicismedia-ds/ui-button';
import Tooltip from '@publicismedia-ds/ui-tooltip';
import SearchFilter from '@publicismedia-ds/ui-search-filter';
import Status, { STATUS_TYPE } from '@publicismedia-ds/ui-status';
import { TOOLTIP_ALIGN } from '../../../components/Custom-Tooltip/Custom-Tooltip';

// UTILIS
import {
    saveDownloadFile,
    formatReportRowData,
    generateScheduledReportFileName,
} from '../../../utils';

// API REQUESTS
import { axios } from '../../../api';

// TYPES
import { StatusType, StudyListItem } from '../../../state';
import { ScheduledReport, ScheduledReportRow } from '../../../types';

// COMPONENT PROPS
interface ScheduledReportsTableProps {
    data: ScheduledReport[];
    studies: StudyListItem[];
    isViewable?: boolean;
    isEditable?: boolean;
    onUpdateStatus?: (reportData: ScheduledReportRow) => void;
}

// FUNCTION COMPONENT (SCHEDULED REPORTS TABLE)
function ScheduledReportsTable({
    data,
    studies,
    isViewable = true,
    isEditable = true,
    onUpdateStatus,
}: ScheduledReportsTableProps) {
    // HOOKS
    const wrapper = useAsyncWrapper();
    const { isPrivileged, isAdmin } = useUserPermissions();

    // SCHEDULED REPORTS TABLE DATA PROVIDER
    const [reportsDataProvider, setReportsDataProvider] = useState<any>([]);

    // UPDATE TABLE DATA PROVIDER FOR FETCHED SCHEDULED REPORTS
    useEffect(() => {
        const formattedData = formatReportRowData(data, studies);
        const updatedProvider = new DataProvider({
            data: [...formattedData],
        });
        setReportsDataProvider(updatedProvider);
    }, [data]);

    // HANDLE DOWNLOAD SCHEDULED REPORTS
    const onDownloadScheduledReports = wrapper(async () => {
        const url = API_ROUTES.EXPORT_SCHEDULED_REPORTS;

        try {
            const fileName = generateScheduledReportFileName();
            const response = await axios.get(url, {
                responseType: 'blob',
            });

            saveDownloadFile(response, fileName);
        } catch (error: any) {
            throw new Error('Unable to download file at this time.');
        }
    });

    // HANDLE SEARCH SCHEDULED REPORTS
    const onSearchChange = (text: string) => {
        reportsDataProvider.search(text);
    };

    // JSX
    return (
        <>
            <div className="scheduled-reports-table">
                <DataTable
                    data={reportsDataProvider}
                    bindKey="id"
                    emptyState={<h2>No scheduled reports.</h2>}
                    above={
                        <div className="data-table-above">
                            <div className="data-table-action-buttons">
                                <Link to={ROUTES.CREATE_SCHEDULED_REPORT}>
                                    <Button>Create Scheduled Report</Button>
                                </Link>
                                {isAdmin && (
                                    <div className="download-reports-btn-container">
                                        <Button
                                            color="brand-2"
                                            onClick={onDownloadScheduledReports}
                                        >
                                            Download Scheduled Reports
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <SearchFilter onSearchChange={onSearchChange} />
                        </div>
                    }
                    hasVirtualScrolling={false}
                >
                    {/* --- REPORT ID --- */}
                    <ColumnTemplate bind="report_id" isSortable minWidth>
                        Report ID
                    </ColumnTemplate>

                    {/* --- STUDY ID --- */}
                    <ColumnTemplate bind="study_id" isSortable minWidth>
                        Study ID
                    </ColumnTemplate>

                    {/* --- STUDY NAME --- */}
                    <ColumnTemplate
                        className="visible-actions"
                        bind="study_name"
                        type="actions"
                        onRender={(
                            text: string,
                            rowData: ScheduledReportRow
                        ) => {
                            return isPrivileged ? (
                                <Link to={`/studies/view/${rowData.study_id}`}>
                                    {text}
                                </Link>
                            ) : (
                                <span>{text}</span>
                            );
                        }}
                        isSortable
                        minWidth
                    >
                        Study Name
                    </ColumnTemplate>

                    {/* --- REPORT TYPE --- */}
                    <ColumnTemplate bind="report_type" isSortable minWidth>
                        Type
                    </ColumnTemplate>

                    {/* --- REPORT OCCURRENCE --- */}
                    <ColumnTemplate
                        bind="occurrence"
                        isSortable
                        align={TD_ALIGN.CENTER}
                        minWidth
                    >
                        Frequency
                    </ColumnTemplate>

                    {/* --- START DATE --- */}
                    <ColumnTemplate bind="start_date" isSortable minWidth>
                        Start Date
                    </ColumnTemplate>

                    {/* --- END DATE --- */}
                    <ColumnTemplate
                        bind="end_date"
                        isSortable
                        align={TD_ALIGN.CENTER}
                        minWidth
                    >
                        End Date
                    </ColumnTemplate>

                    {/* --- NUMBER OF RECIPIENTS --- */}
                    <ColumnTemplate
                        bind="recipients"
                        align={TD_ALIGN.CENTER}
                        onRender={(recipients: string) => {
                            const numRecipients = recipients
                                ? recipients.split(',').length
                                : 0;

                            return (
                                <span className="recipients-column">
                                    {numRecipients} Recipient(s)
                                </span>
                            );
                        }}
                        minWidth
                    >
                        Recipients
                    </ColumnTemplate>

                    {/* --- REPORT STATUS --- */}
                    <ColumnTemplate
                        align={TD_ALIGN.CENTER}
                        className="status-column"
                        bind="status"
                        isSortable
                        onRender={(
                            status: StatusType,
                            data: ScheduledReportRow
                        ) => {
                            const isStudySuspended =
                                data.study_status === 'Suspended';

                            return (
                                <>
                                    {status === 'Active' ? (
                                        <Status
                                            type={STATUS_TYPE.SUCCESS}
                                            title={status}
                                        />
                                    ) : (
                                        <Status
                                            type={
                                                isStudySuspended
                                                    ? STATUS_TYPE.ERROR
                                                    : STATUS_TYPE.NOTELIGIBLETOSYNC
                                            }
                                            title={
                                                isStudySuspended
                                                    ? 'Study Suspended'
                                                    : status
                                            }
                                        />
                                    )}
                                </>
                            );
                        }}
                        minWidth
                    >
                        Status
                    </ColumnTemplate>

                    {/* --- REPORT ACTIONS --- */}
                    {isEditable || onUpdateStatus ? (
                        <ColumnTemplate
                            bind="report_id"
                            type={TD_TYPE.ACTIONS}
                            align="center"
                            onRender={(
                                id: string,
                                rowData: ScheduledReportRow
                            ) => {
                                const reportData = data.find(
                                    (report) =>
                                        report.report_id === parseInt(id)
                                );
                                const isStudySuspended =
                                    rowData.study_status === 'Suspended';
                                const isAlertActive =
                                    rowData.status === 'Active';

                                return (
                                    <>
                                        {/* --- VIEW REPORT --- */}
                                        {isViewable && (
                                            <Link
                                                to={`/scheduled-reports/view/${id}`}
                                                state={{
                                                    isEditing: false,
                                                    reportData,
                                                }}
                                            >
                                                <Tooltip title="View">
                                                    <i className="icon-view" />
                                                </Tooltip>
                                            </Link>
                                        )}

                                        {/* --- EDIT REPORT --- */}
                                        {isEditable && (
                                            <Link
                                                to={`/scheduled-reports/edit/${id}`}
                                                state={{
                                                    isEditing: true,
                                                    reportData,
                                                }}
                                            >
                                                <Tooltip title="Edit">
                                                    <i className="icon-edit" />
                                                </Tooltip>
                                            </Link>
                                        )}

                                        {/* --- SUSPEND/ACTIVATE REPORT --- */}
                                        {!isStudySuspended &&
                                            !!onUpdateStatus && (
                                                <Tooltip
                                                    title={
                                                        rowData.status ===
                                                        'Active'
                                                            ? 'Suspend'
                                                            : 'Activate'
                                                    }
                                                    align={TOOLTIP_ALIGN.RIGHT}
                                                >
                                                    <i
                                                        className={
                                                            isAlertActive
                                                                ? 'icon-cancel-20'
                                                                : 'icon-progress'
                                                        }
                                                        onClick={() =>
                                                            onUpdateStatus(
                                                                rowData
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            )}
                                    </>
                                );
                            }}
                            minWidth
                        >
                            Actions
                        </ColumnTemplate>
                    ) : null}
                </DataTable>
            </div>
        </>
    );
}

export default ScheduledReportsTable;
