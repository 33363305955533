import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getAgencyDetails, getShareOfClicks } from '../../api';

// TYPES
import { ApexOptions } from 'apexcharts';
import { DropdownOption, ShareOfClicksData } from '../../state';

// UI COMPONENTS
import { Line } from '@publicismedia-ds/ui-charts';
import ChartWrapper from './Chart-Wrapper/Chart-Wrapper';

// UTILS
import { getDateCategories } from '../../utils';

// COMPONENT PROPS
interface ShareOfClicksChartProps {
  agencyOptions: DropdownOption[];
}

interface ComponentState {
  data: ShareOfClicksData | null;
  agency: DropdownOption | null;
}

const DEFAULT_SELECTIONS: ComponentState = {
  data: null,
  agency: null,
};

// FUNCTIONAL COMPONENT (SHARE OF CLICKS LAST 7 DAYS)
function ShareOfClicksChart({ agencyOptions = [] }: ShareOfClicksChartProps) {
  const wrapper = useAsyncWrapper();

  const userId = useAppSelector(({ auth }) => auth.user?.agenciesInfo?.userId);

  const [state, setState] = useState<ComponentState>(DEFAULT_SELECTIONS);

  // DEFAULT DATE RANGE OF LAST 7 DAYS UPTO PRIOR DAY
  const end_date = new Date();
  end_date.setDate(end_date.getDate() - 1);

  const start_date = new Date(end_date);
  start_date.setDate(start_date.getDate() - 6);

  const categories = useMemo(() => {
    return getDateCategories(start_date, end_date);
  }, []);

  // HANDLE AGENCY SELECTION
  const onSelectAgency = wrapper(async (selected: DropdownOption) => {
    if (!userId) return;
    const { agencyDetails } = await getAgencyDetails(selected.value, userId);
    const engine_ids = agencyDetails.advertisers.reduce(
      (options: DropdownOption[], advertiser) => {
        options.push(
          ...advertiser.engines.map(({ engineId, engineName }) => ({
            label: engineName,
            value: engineId,
          }))
        );
        return options;
      },
      []
    );

    const data = await getShareOfClicks({
      start_date: start_date.toLocaleDateString('en-CA'),
      end_date: end_date.toLocaleDateString('en-CA'),
      agency_ids: [selected],
      engine_ids,
    });
    setState((prev) => ({ ...prev, agency: selected, data }));
  });
  // SET DEFAULT SELECTIONS
  useEffect(() => {
    if (agencyOptions.length) {
      onSelectAgency(agencyOptions[0]);
    }
  }, [agencyOptions]);

  interface FormatedClicksData {
    desktop?: {
      [key: string]: number;
    };
    mobile?: {
      [key: string]: number;
    };
    tablet?: {
      [key: string]: number;
    };
  }

  const formatShareOfClicks = (data: ShareOfClicksData | null) => {
    const formatted: FormatedClicksData = {};
    if (data?.desktop) {
      formatted.desktop = data.desktop.reduce(
        (details: { [key: string]: number }, foo) => {
          details[
            new Date(foo.date.replaceAll('-', '/')).toLocaleDateString(
              'en-US',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )
          ] = Math.round(foo.click_percentage);
          return details;
        },
        {}
      );
    }
    if (data?.mobile) {
      formatted.mobile = data.mobile.reduce(
        (details: { [key: string]: number }, foo) => {
          details[
            new Date(foo.date.replaceAll('-', '/')).toLocaleDateString(
              'en-US',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )
          ] = Math.round(foo.click_percentage);
          return details;
        },
        {}
      );
    }
    if (data?.tablet) {
      formatted.tablet = data.tablet.reduce(
        (details: { [key: string]: number }, foo) => {
          details[
            new Date(foo.date.replaceAll('-', '/')).toLocaleDateString(
              'en-US',
              {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }
            )
          ] = Math.round(foo.click_percentage);
          return details;
        },
        {}
      );
    }
    return formatted;
  };

  const series = useMemo(() => {
    const formattedData = formatShareOfClicks(state.data);
    return [
      {
        name: 'Desktop',
        data: categories.map((date) => formattedData?.desktop?.[date] || null),
      },
      {
        name: 'Mobile',
        data: categories.map((date) => formattedData?.mobile?.[date] || null),
      },
      {
        name: 'Tablet',
        data: categories.map((date) => formattedData?.tablet?.[date] || null),
      },
    ];
  }, [state.data]);

  const options: ApexOptions = {
    chart: {
      toolbar: {
        tools: {
          zoom: false,
          pan: false,
          reset: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2.5rem',
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val + '%',
      },
    },
    xaxis: {
      categories,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  // JSX
  return (
    <ChartWrapper
      title="Share of clicks last 7 days"
      dateRange={`${start_date.toLocaleDateString(
        'en-US'
      )} - ${end_date.toLocaleDateString('en-US')}`}
      options={agencyOptions}
      value={state.agency}
      onChange={onSelectAgency}
      tooltipText="coming soon..."
    >
      <Line data={series} options={options} width="100%" />
    </ChartWrapper>
  );
}

export default ShareOfClicksChart;
