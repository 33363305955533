import './Ad-Messaging-Chart.scss';

import { useAsyncWrapper } from '../../../hooks';
import { memo, useEffect, useMemo, useState } from 'react';

// API REQUESTS
import { getWordAnalysis } from '../../../api';

// TYPES
import {
  DropdownOption,
  AdhocReportState,
  WordAnalysisBrandsData,
} from '../../../state';
import { ApexOptions } from 'apexcharts';

// UI COMPONENTS
import ChartWrapper from '../Chart-Wrapper/Chart-Wrapper';
import { Column } from '@publicismedia-ds/ui-charts';

// UTILS
import { getTotalCountByWord } from '../../../utils';

// COMPONENT PROPS
interface AdMessagingChartProps {
  data: WordAnalysisBrandsData | null;
  competitors: DropdownOption[];
  filters: AdhocReportState | null;
  onChange: (selection: DropdownOption) => void;
  value: DropdownOption | null;
  dateRange?: string;
}

// FUNCTIONAL COMPONET (AD MESSAGING) # Top 10 keywords seen by brand
function AdMessagingChart({
  data,
  filters,
  onChange,
  value,
  dateRange,
  competitors,
}: AdMessagingChartProps) {
  const wrapper = useAsyncWrapper();

  // SELECTED BRAND & DATA
  const [brandData, setBrandData] = useState<WordAnalysisBrandsData | null>(
    null
  );

  // CLEAR SELECTED BRAND ON NEWLY APPLIED FILTERS
  useEffect(() => {
    setBrandData(null);
  }, [filters]);

  useEffect(() => {
    setBrandData(data);
  }, [data]);

  // GET COUNT TOTAL FOR EACH WORD
  const wordsData = useMemo(() => {
    return getTotalCountByWord(brandData).slice(0, 10);
  }, [brandData]);

  // CREATE DATA LABELS FOR CHART (WORD)
  const categories = wordsData.map(({ text }) => text);
  // CREATE CHART DATA VALUES (COUNT)
  const values = wordsData.map(({ value }) => value);

  // FORMAT CHART DATA
  const chartData = [
    {
      name: 'Messaging',
      data: values,
    },
  ];

  // CHART OPTIONS
  const options: ApexOptions = {
    xaxis: { categories },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2.5rem',
      },
    },
  };

  // HANDLE BRAND SELECTION
  const onSelectBrand = wrapper(async () => {
    // FETCH SELECTED BRAND WORD ANALYSIS REPORT
    if (value && filters?.study && filters.start_date && filters.end_date) {
      const response = await getWordAnalysis(
        {
          study: filters.study,
          start_date: filters.start_date,
          end_date: filters.end_date,
          competitors: [value],
        },
        { focus: 'brands' }
      );
      setBrandData(response);
    }
  });

  useEffect(() => {
    if (!value) return;
    onSelectBrand();
  }, [value]);

  const TOOLTIP_TEXT =
    'The top ten most common words seen accross a selected brand or all brands ad messaging.';

  // JSX
  return (
    <div className="ad-messaging-container">
      <ChartWrapper
        title="Ad messaging"
        tooltipText={TOOLTIP_TEXT}
        dateRange={dateRange || 'N/A'}
        options={competitors}
        onChange={onChange}
        value={value}
      >
        <div className="ad-messaging-content">
          <Column
            data={chartData}
            options={options}
            width="100%"
            tooltipPos="column"
          />
        </div>
      </ChartWrapper>
    </div>
  );
}

export default memo(AdMessagingChart);
