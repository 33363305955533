import './Create-Alert.scss';

import { useActions, useAsyncWrapper } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

// CONSTANTS
import { ROUTES } from '../../../router';

// FORM STATE
import { AlertFormState, StudyListItem } from '../../../state';

// API REQUESTS
import { createAlert, getStudyList } from '../../../api';

// UI COMPONENTS
import AlertForm from '../../../components/Alert-Form/Alert-Form';
import PageContainer from '../../../components/Page-Container/Page-Container';
import { useEffect, useState } from 'react';

// FUNCTIONAL COMPONENT (CREATE NEW ALERT)
function CreateAlert() {
    // HOOKS
    const navigate = useNavigate();
    const wrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { alertSuccess } = useActions();

    // STUDY LIST
    const [studyList, setStudyList] = useState<StudyListItem[]>([]);

    // FETCH STUDY LIST ON INITIAL RENDER
    useEffect(() => {
        wrapper(async () => {
            const studies = await getStudyList();
            setStudyList(studies);
        })();
    }, []);

    // HANDLE CREATE ALERT SUBMIT (NO ASYNC WRAPPER - IMPLEMENTED IN ALERT_FORM)
    const onCreateAlert = async (formState: AlertFormState) => {
        const { message } = await createAlert(formState);

        if (!message) {
            return;
        }

        alertSuccess(message);
        navigate(ROUTES.BRAND_MONITORING);
    };

    // JSX
    return (
        <PageContainer title={`create alert`}>
            <div className="create-alert-container">
                <AlertForm studyList={studyList} onSubmit={onCreateAlert} />
            </div>
        </PageContainer>
    );
}

export default CreateAlert;
