import { Dispatch } from 'redux';

// API REQUESTS
import { getUserAccountDetails, userLogin } from '../../api';

// TYPES
import {
  AuthAction,
  AlertAction,
  LogoutAction,
  LoadingAction,
  LoginFailureAction,
  LoginSuccessAction,
  SetUserAgenciesAction,
  // SetUserAgenciesAction,
} from '../actions';
import { UserAgencyData } from '../../types';
import { AUTH_ACTION_TYPES } from '../action-types';
import { STORED_USER, UserDetails } from '../models';

// UTILS
import { formatUserDetails } from '../../utils';

import { alertError } from './alerts-creators';
import { setIsLoading } from './loading-creators';

const loginSuccess = (user: UserDetails): LoginSuccessAction => {
  return {
    type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
    payload: user,
  };
};

const loginFailure = (): LoginFailureAction => {
  return {
    type: AUTH_ACTION_TYPES.LOGIN_FAILURE,
  };
};

const setUserAgencies = (agencyData: UserAgencyData): SetUserAgenciesAction => {
  return {
    type: AUTH_ACTION_TYPES.SET_USER_AGENCIES,
    payload: agencyData || null,
  };
};

export const loginUser = (email: string, password: string) => {
  return async (
    dispatch: Dispatch<AuthAction | LoadingAction | AlertAction>
  ) => {
    dispatch(setIsLoading(true));
    try {
      const responseData = await userLogin(email, password);
      const { agenciesInfo } = await getUserAccountDetails(
        responseData.accessToken
      );

      const user = formatUserDetails(responseData, agenciesInfo);

      if (!user) {
        dispatch(loginFailure());
      } else {
        // SET USER DETAILS IN LOCAL STORAGE
        localStorage.setItem(STORED_USER, JSON.stringify(user));

        dispatch(loginSuccess(user));
      }
    } catch (error: any) {
      dispatch(loginFailure());
      dispatch(
        alertError(
          error.response?.data?.message || 'Invalid username or password.'
        )
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const logoutUser = (): LogoutAction => {
  return {
    type: AUTH_ACTION_TYPES.LOGOUT,
  };
};

export const loadUserAgencies = (user: UserDetails) => {
  return async (dispatch: Dispatch<AuthAction | LoadingAction>) => {
    if (user.agenciesInfo === undefined) {
      dispatch(setIsLoading(true));
      try {
        const { agenciesInfo } = await getUserAccountDetails();
        dispatch(setUserAgencies(agenciesInfo || null));
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  };
};
