import { useMemo } from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { NewAdDetails } from '../../state';

// UI COMPONENTS
import ChartWrapper from './Chart-Wrapper/Chart-Wrapper';
import { Column } from '@publicismedia-ds/ui-charts';

// COMPONENT PROPS
interface NewAdsLastSevenDaysProps {
  data: NewAdDetails[] | null;
}

// DEFAULT DATE RANGE OF LAST 7 DAYS UPTO PRIOR DAY
const end_date = new Date();
end_date.setDate(end_date.getDate() - 1);

const start_date = new Date(end_date);
start_date.setDate(start_date.getDate() - 6);

// FUNCTIONAL COMPONENT (NEW ADS SEEN OVER LAST 7 DAYS)
function NewAdsLastSevenDays({ data }: NewAdsLastSevenDaysProps) {
  const chartData = useMemo(() => {
    const sortedData = data
      ? [...data]
          .sort((a, b) => {
            if (a.new_ads_count > b.new_ads_count) return -1;
            if (a.new_ads_count < b.new_ads_count) return 1;
            return 0;
          })
          .slice(0, 5)
      : [];

    return sortedData.reduce(
      (chartObj: { data: number[]; categories: string[] }, details) => {
        chartObj.data.push(details.new_ads_count);
        chartObj.categories.push(details.brand_name);
        return chartObj;
      },
      { data: [], categories: [] }
    );
  }, [data]);

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
    },
  };

  const TOOLTIP_TEXT =
    'The top five brands with the most new ads seen over the last seven days.';

  // JSX
  return (
    <div className="new-ads-container">
      <ChartWrapper
        title="New ads seen last 7 days"
        tooltipText={TOOLTIP_TEXT}
        dateRange={`${start_date.toLocaleDateString(
          'en-US'
        )} - ${end_date.toLocaleDateString('en-US')}`}
      >
        <div className="new-ads-content">
          <Column
            data={[
              { name: 'New ads seen in last 7 days', data: chartData.data },
            ]}
            options={options}
            width="100%"
          />
        </div>
      </ChartWrapper>
    </div>
  );
}

export default NewAdsLastSevenDays;
