import Login from '../pages/Login/Login';
import Reports from '../pages/Reports/Reports';
import Studies from '../pages/Studies/Studies';
import Landing from '../pages/Landing/Landing';
import Dashboard from '../pages/Dashboard/Dashboard';
import StudyView from '../pages/Study-View/Study-View';
import EditStudy from '../pages/Edit-Study/Edit.Study';
import Onboarding from '../pages/Onboarding/Onboarding';
import CreateStudy from '../pages/Create-Study/Create-Study';
import PageNotFound from '../pages/Page-Not-Found/Page-Not-Found';
import ResetPassword from '../pages/Reset-Password/Reset-Password';
import AccountDetails from '../pages/Account-Details/Account-Details';
import ForgotPassword from '../pages/Forgot-Password/Forgot-Password';
import ViewAlert from '../pages/Brand-Monitoring/View-Alert/View-Alert';
import BrandMonitoring from '../pages/Brand-Monitoring/Brand-Monitoring';
import StudiesActivity from '../pages/Studies-Activity/Studies-Activity';
import CreateAlert from '../pages/Brand-Monitoring/Create-Alert/Create-Alert';
import ViewScheduledReport from '../pages/Scheduled-Reports/View-Scheduled-Report/View-Scheduled-Report';
import ListScheduledReports from '../pages/Scheduled-Reports/List-Scheduled-Reports/List-Scheduled-Reports';
import CreateScheduledReport from '../pages/Scheduled-Reports/Create-Scheduled-Report/Create-Scheduled-Report';

export enum ROUTES {
    HOME = '/',
    LOGIN = '/login',
    REPORTS = '/reports',
    SCHEDULED_REPORTS = '/scheduled-reports',
    CREATE_SCHEDULED_REPORT = '/scheduled-reports/create',
    VIEW_SCHEDULED_REPORT = '/scheduled-reports/view/:report_id',
    EDIT_SCHEDULED_REPORT = '/scheduled-reports/edit/:report_id',
    STUDIES = '/studies',
    BRAND_MONITORING = '/brand-monitoring',
    FORGOT_PASSWORD = '/forgot-password',
    RESET_PASSWORD = '/reset-password/:uuid',
    STUDY_VIEW = '/studies/view/:study_id',
    EDIT_STUDY = '/studies/edit/:study_id',
    CREATE_STUDY = '/studies/create',
    CREATE_ALERT = '/alerts/create',
    EDIT_ALERT = '/alerts/edit/:alert_id',
    VIEW_ALERT = '/alerts/view/:alert_id',
    ONBOARDING = 'admin/onboarding',
    STUDIES_ACTIVITY = '/admin/studies/activity-logs',
    ACCOUNT_DETAILS = '/admin/accounts/account-details',
    DASHBOARD = '/dashboard',
}

export const userRoutes = [
    {
        path: ROUTES.HOME,
        element: <Landing />,
    },
    {
        path: ROUTES.REPORTS,
        element: <Reports />,
    },
    {
        path: ROUTES.SCHEDULED_REPORTS,
        element: <ListScheduledReports />,
    },
    {
        path: ROUTES.CREATE_SCHEDULED_REPORT,
        element: <CreateScheduledReport />,
    },
    {
        path: ROUTES.VIEW_SCHEDULED_REPORT,
        element: <ViewScheduledReport />,
    },
    {
        path: ROUTES.EDIT_SCHEDULED_REPORT,
        element: <ViewScheduledReport isEditing={true} />,
    },
    {
        path: ROUTES.STUDIES,
        element: <Studies />,
    },
    {
        path: ROUTES.STUDY_VIEW,
        element: <StudyView />,
    },
    {
        path: ROUTES.DASHBOARD,
        element: <Dashboard />,
    },
];

export const privlagedRoutes = [
    {
        path: ROUTES.CREATE_STUDY,
        element: <CreateStudy />,
    },
    {
        path: ROUTES.EDIT_STUDY,
        element: <EditStudy />,
    },
    {
        path: ROUTES.BRAND_MONITORING,
        element: <BrandMonitoring />,
    },
    {
        path: ROUTES.CREATE_ALERT,
        element: <CreateAlert />,
    },
    {
        path: ROUTES.EDIT_ALERT,
        element: <ViewAlert />,
    },
    {
        path: ROUTES.VIEW_ALERT,
        element: <ViewAlert isEditing={false} />,
    },
];

export const adminRoutes = [
    {
        path: ROUTES.ONBOARDING,
        element: <Onboarding />,
    },
    {
        path: ROUTES.STUDIES_ACTIVITY,
        element: <StudiesActivity />,
    },
    {
        path: ROUTES.ACCOUNT_DETAILS,
        element: <AccountDetails />,
    },
    {
        path: ROUTES.STUDIES_ACTIVITY,
        element: <StudiesActivity />,
    },
    {
        path: ROUTES.ACCOUNT_DETAILS,
        element: <AccountDetails />,
    },
];

export const publicRoutes = [
    {
        path: ROUTES.LOGIN,
        element: <Login />,
    },
    {
        path: ROUTES.FORGOT_PASSWORD,
        element: <ForgotPassword />,
    },
    {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPassword />,
    },
    {
        path: '*',
        element: <PageNotFound />,
    },
];
