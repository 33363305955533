import { axios } from '../axios';

// UTILS
import { parseFile, getInsightsParams, getAdhocParams } from '../../utils';

// TYPES
import {
  StatusType,
  InsightsState,
  AdhocReportState,
  PUTEditReportRes,
  GETKeywordsReport,
  GETKeywordsMetrics,
  GETWordAnalysisWords,
  POSTCreateReportState,
  GETWordAnalysisBrands,
  SERPVisibilityDataItem,
  GETAllScheduledReports,
} from '../../state';
import { POSTScheduledReportsResponse, PUTEditReportBody } from '../../types';

// CONSTANTS
import { API_ROUTES, REPORT_TYPE } from '../../constants';

// GET SERP VISIBILITY REPORT DATA
export const getSERPVisibilityReport = async (
  study_id: number,
  filters: InsightsState
) => {
  const params = getInsightsParams(filters);

  const url = `serpvisibility/xlsx/${study_id}`;

  if (params.type === 'seo') {
    return [];
  }

  const response = await axios.get(url, {
    params: {
      ...params,
      daily: true,
    },
    responseType: 'blob',
  });

  const data =
    ((await parseFile(response.data)) as SERPVisibilityDataItem[]) || [];

  return data;
};

// GET KEYWORD REPORT DATA
export const getKeywordsReport = async (
  study_id: number,
  filters: InsightsState
) => {
  const params = getInsightsParams(filters);

  const url = `/keywords/json/${study_id}`;

  const { data } = await axios.get<GETKeywordsReport>(url, { params });

  return data;
};

// GET WORD ANALYSIS REPORT DATA (FOCUS = WORDS)
export const getWordAnalysisWords = async (
  study_id: number,
  filters: InsightsState,
  top_words = 100
) => {
  const params = getInsightsParams(filters);

  const url = `/wordanalysis/json/${study_id}`;

  const { data } = await axios.get<GETWordAnalysisWords>(url, {
    params: {
      ...params,
      top_words,
    },
  });

  return data;
};

// GET WORD ANALYSIS REPORT DATA (FOCUS = BRANDS)
export const getWordAnalysisBrands = async (
  study_id: number,
  filters: InsightsState,
  top_words = 100
) => {
  const params = getInsightsParams(filters);

  const url = `/wordanalysis/json/${study_id}`;

  const { data } = await axios.get<GETWordAnalysisBrands>(url, {
    params: {
      ...params,
      top_words,
      focus: 'brands',
    },
  });

  return data;
};

interface GetWordAnalysisOptions {
  top_words?: number; // 1-100;
  focus?: 'brands' | 'words';
}
// GET WORD ANALYSIS REPORT DATA (FOCUS = BRANDS)
export const getWordAnalysis = async (
  state: AdhocReportState,
  options: GetWordAnalysisOptions = {}
) => {
  if (!state.study?.id) {
    throw new Error('Please select a study.');
  }
  const params = getAdhocParams(state);

  const url = `/wordanalysis/json/${state.study.id}`;

  const { data } = await axios.get<GETWordAnalysisBrands>(url, {
    params: {
      ...params,
      ...options,
    },
  });

  return data.data;
};

// GET CONENT REPORT DATA
export const getContentReport = async (
  study_id: number,
  filters: InsightsState
) => {
  const params = getInsightsParams(filters);

  const url = `${API_ROUTES.CONTENT_REPORT_XLSX}/${study_id}`;

  const blob = await axios.get(url, { params, responseType: 'blob' });

  return await parseFile(blob.data);
};

// CREATE NEW SCHEDULED REPORT
export const createScheduledReport = async (
  requestBody: POSTCreateReportState
) => {
  const url = '/reportschedular/add';
  const { data } = await axios.post(url, requestBody);

  return data;
};

// FETCH SCHEDULED REPORTS
export const getScheduledReports = async (email: string) => {
  const { data } = await axios.post<POSTScheduledReportsResponse>(
    '/reportschedular/view',
    { email }
  );
  return data;
};

// UPDATE SCHEDULED REPORT
export const editScheduledReport = async (requestBody: PUTEditReportBody) => {
  const url = '/reportschedular/edit';

  const { data } = await axios.put<PUTEditReportRes>(url, requestBody);
  return data;
};

// UPDATE SCHEDULED REPORT STATUS
export const updateReportStatus = async (
  id: number | string,
  status: StatusType
) => {
  const url = `/reportschedular/update/status/${id}`;

  // RETURNS "SUCCESSFULLY SUSPENDED" EITHER WAY
  const { data } = await axios.put<{ message: string }>(url, { status });

  return data;
};

// FETCH ALL SCHEDULED REPORTS
export const getAllScheduledReports = async () => {
  const { data } = await axios.get<GETAllScheduledReports>(
    API_ROUTES.GET_ALL_SCHEDULED_REPORTS
  );

  return data;
};

// FETCH STUDY'S KEYWORD METRICS
export const getKeywordMetrics = async (
  study_id: number | string,
  filters: InsightsState
) => {
  try {
    const url = API_ROUTES.GET_KEYWORD_METRICS + `/${study_id}`;

    const params = getInsightsParams(filters, REPORT_TYPE.KEYWORDS_METRICS);
    const { data } = await axios.get<GETKeywordsMetrics>(url, { params });

    return data;
  } catch {
    throw new Error('Data unavailable based on current selections.');
  }
};
