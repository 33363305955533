// CONSTANTS
import { FEATURE_OPTIONS } from '../../../constants';

// TYPES
import {
    StudyListItem,
    DropdownOption,
    AlertsTableItem,
    ScheduledReportListItem,
} from '../../models';

export type FeatureOption = (typeof FEATURE_OPTIONS)[number];

export interface AccountDetailsState {
    studies: StudyListItem[];
    alerts: AlertsTableItem[];
    reports: ScheduledReportListItem[];
    accountId: number | null;
    accountOptions: DropdownOption[];
    feature: FeatureOption;
}

export enum ACCOUNT_DETAILS_ACTIONS {
    LOAD_DATA = 'load_data',
    SET_ACCOUNT = 'set_account',
    SET_FEATURE = 'set_feature',
}

export interface LoadAccountDetailsDataAction {
    type: ACCOUNT_DETAILS_ACTIONS.LOAD_DATA;
    payload: AccountDetailsState;
}

export interface SetAccountSelectionAction {
    type: ACCOUNT_DETAILS_ACTIONS.SET_ACCOUNT;
    payload: number | null;
}

export interface SetFeatureSelectionAction {
    type: ACCOUNT_DETAILS_ACTIONS.SET_FEATURE;
    payload: FeatureOption;
}

export type AccountDetailsAction =
    | LoadAccountDetailsDataAction
    | SetAccountSelectionAction
    | SetFeatureSelectionAction;
