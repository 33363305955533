export enum REPORT_TYPE_TOOL_TIPS {
  BATTLEFIELD = 'Analyze competitor ranking and the percentage of time seen in a position.',
  BRANDS = 'Provides Coverage, Rank, and Creative Count information per brand for the dates, keywords, and targets selected.',
  CONTENT = 'Analyze key messaging and themes used in competitor ads. Drill down to the specifics to see what’s included in the ad title, description, price, landing page, and more.',
  KEYWORDS = `Analyze what keywords competitors are bidding on and which ones overlap with your client's strategy. `,
  SERP_VISIBILITY = 'Compare and contrast brand presence on the SERP.',
  WORD_ANALYSIS = 'Analyze the most relevant and frequently used words on the SERP.',
}

export const REPORT_TYPE = (
  <>
    <p>
      <strong>Battlefield:</strong> {REPORT_TYPE_TOOL_TIPS.BATTLEFIELD}
    </p>
    <p>
      <strong>Brands:</strong> {REPORT_TYPE_TOOL_TIPS.BRANDS}
    </p>
    <p>
      <strong>Content:</strong> {REPORT_TYPE_TOOL_TIPS.CONTENT}
    </p>
    <p>
      <strong>Keywords:</strong> {REPORT_TYPE_TOOL_TIPS.KEYWORDS}
    </p>
    <p>
      <strong>SERP Visibility:</strong> {REPORT_TYPE_TOOL_TIPS.SERP_VISIBILITY}
    </p>
    <p>
      <strong>Word Analysis:</strong> {REPORT_TYPE_TOOL_TIPS.WORD_ANALYSIS}
    </p>
  </>
);

export enum METRICS_TOOLTIPS {
  COVERAGE = 'The proportion of time a brand appeared on the SERP divided by the number of opportunities it had to appear.',
  RANK = 'Average of all positions for a given brand/ total number of positions possible when seen.',
  IMPRESSION_PERCENTAGE = `The frequency of the times both you and your competitor's ad received impressions — your impression percentage is the number of impressions you received vs. what you were eligible to receive`,
  CREATIVE_COUNT = 'Total number of advertisements seen on the SERP when a keyword is searched.',
}
const METRICS_TOOLTIP = (
  <>
    <p>
      <strong>Coverage:</strong> {METRICS_TOOLTIPS.COVERAGE}
    </p>
    <p>
      <strong>Rank:</strong> {METRICS_TOOLTIPS.RANK}
    </p>
    <p>
      <strong>Creative Count:</strong> {METRICS_TOOLTIPS.CREATIVE_COUNT}
    </p>
  </>
);

export const REPORT_TOOLTIPS = {
  REPORT_TYPE,
  FILE_TYPE: 'Preferred report format.',
  KEYWORDS: 'List of words and/or phrases used for search.',
  KEYWORD_GROUPS: 'Collection of keywords.',
  COMPETITORS: 'List of brands against whom data is compared.',
  COMPETITOR_GROUPS: 'Collection of competitors.',
  TARGETS: 'Target systems to be considered for results.',
  METRICS: METRICS_TOOLTIP,
  AD_TYPE: 'Type of search result to be considered.',
  FOCUS: 'Choose word analysis focus.',
  TIMEZONE: 'Select timezone.',
  COMPETITORS_LIMIT: 'Number of auto competitors to be included (1 - 10).',
  TOP_WORDS: 'Number of top words to be included (1-100).',
  REPORT_OCCURRENCE: 'Choose the sample frequency.',
  ZERO_COVERAGE: 'Include data with zero values.',
  COMPARE_PREVIOUS: 'Compare results with previous period of data.',
  AUTO_COMPETITOR_GROUP: 'Include auto competitor data.',
  DAILY: (
    <div>
      <p>Group report data by day.</p>
      <p>Max date range of one month.</p>
    </div>
  ),
  HOURLY: (
    <div>
      <p>Group report data by hour.</p>
      <p>Max date range of one day.</p>
    </div>
  ),
};

export const STUDY_TOOLTIPS = {
  FREQUENCY: 'Select the number of times a study needs to be sampled.',
  LOCALES:
    'A locale is the combination of individual search platforms, locations, languages, and devices on which your keywords will be monitored',
  KEYWORD_GROUPS:
    'Categorize your keywords into multiple Keyword Groups to facilitate reporting. Keywords are monitored exactly as they are entered. Please do not add symbols to differentiate by match types.',
  COMPETITOR_GROUPS: 'Identify your core competitors.',
};

enum ALERT_TYPE_TOOLTIP {
  TRADEMARDK = 'Set notifications to get alerted for trademark infringements on the SERP.',
  KEYWORD = 'Set notifications to get alerted for fluctuations in keyword performance on the SERP.',
}

export const ALERTS_TOOLTIPS = {
  ALERT_TYPE: (
    <>
      <p>
        <strong>Trademark:</strong> {ALERT_TYPE_TOOLTIP.TRADEMARDK}
      </p>
      <p>
        <strong>Keyword:</strong> {ALERT_TYPE_TOOLTIP.KEYWORD}
      </p>
    </>
  ),
  LOOKBACK_TOOLTIP: 'Define the lookback period to compare results.',
};

export const TOP_ADS_TOOLTIP = (
  <p>
    <strong>Top Ads:</strong> Shows paid desktop and mobile advertisement that
    have been shown by you and your competitors when any of your search terms
    were searched.
  </p>
);

export const BENCHMARKING_TOOLTIPS = {
  TOP_OF_PAGE:
    'The average rate a brand or competitor was seen at the top of the search engines results page.',
  POSITION_ABOVE_RATE:
    'The average amount of times a competitors ad was shown at a higher position than yours when both ads were shown simultaneously',
  OUTRANK_SHARE:
    'The amount of times your ad was seen in a higher rank than a competitor ad.',
  KEYWORD_OVERLAP:
    'An overview of commonly used keywords among competitors. Identify which keywords your competitors are ranking for and where there may be opportunities for you to outrank them.',
};
