import './Modal.scss';

import { createPortal } from 'react-dom';

interface ModalProps {
    onClose: () => void;
    id?: string;
    className?: string;
    userClosable?: boolean;
    header?: string;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    children?: React.ReactNode;
    alertType?: 'success' | 'error';
}

function Modal({
    onClose,
    id,
    className = '',
    header,
    content,
    footer,
    userClosable = true,
    children,
    alertType,
}: ModalProps) {
    const modalPortal = document.getElementById('modal');
    if (!modalPortal) {
        return null;
    }

    const onBackgroundClick = () => {
        if (userClosable) {
            onClose();
        }
    };

    const borderColor = alertType || 'modal-top-border-brand-1';

    return createPortal(
        <div
            className={`modal-container${className ? ' ' + className : ''}`}
            id={id}
        >
            <div className="modal-background" onClick={onBackgroundClick} />
            {children ? (
                <>{children}</>
            ) : (
                <div
                    className="modal-frame"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={`modal-top-border ${borderColor}`}></div>
                    {userClosable && (
                        <button className="modal-close" onClick={onClose} />
                    )}
                    <div className="modal-content-box">
                        {header && <h1 className="modal-heading">{header}</h1>}
                        <div className="modal-content modal-content-prop">
                            {content}
                        </div>
                        <div className="modal-footer">{footer}</div>
                    </div>
                </div>
            )}
        </div>,
        modalPortal
    );
}

export default Modal;
