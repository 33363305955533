import { AccountRoleDetails, DropdownOption, UserRole } from '../../models';

export interface UserOnboardingState {
    first_name: string;
    last_name: string;
    email: string;
    role: UserRole | '';
    password: string;
    confirmPassword: string;
    accounts: DropdownOption[];
    accountOptions: DropdownOption[];
    clients: DropdownOption[];
    clientOptions: DropdownOption[];
    clientOptionsByAccountId: { [key: string]: DropdownOption[] };
}

export enum USER_ONBOARDING_ACTION_TYPES {
    LOAD_ACCOUNT_OPTIONS = 'load_account_options',
    SET_FIRST_NAME = 'set_first_name',
    SET_LAST_NAME = 'set_last_name',
    SET_EMAIL = 'set_email',
    SET_ROLE = 'set_role',
    SET_PASSWORD = 'set_password',
    SET_CONFIRM_PASSWORD = 'set_confirm_password',
    SET_ACCOUNTS = 'set_accounts',
    SET_CLIENTS = 'set_clients',
    RESET_FORM = 'reset_form',
}

interface LoadAccountOptionsAction {
    type: USER_ONBOARDING_ACTION_TYPES.LOAD_ACCOUNT_OPTIONS;
    payload: AccountDetails[];
}

interface SetFirstNameAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_FIRST_NAME;
    payload: string;
}

interface SetLastNameAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_LAST_NAME;
    payload: string;
}

interface SetEmailAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_EMAIL;
    payload: string;
}

interface SetRoleAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_ROLE;
    payload: UserRole;
}

interface SetPasswordAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_PASSWORD;
    payload: string;
}

interface SetConfirmPasswordAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_CONFIRM_PASSWORD;
    payload: string;
}

interface SetUserAccountsAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_ACCOUNTS;
    payload: DropdownOption[];
}

interface SetUserClientsAction {
    type: USER_ONBOARDING_ACTION_TYPES.SET_CLIENTS;
    payload: DropdownOption[];
}

interface ResetFormAction {
    type: USER_ONBOARDING_ACTION_TYPES.RESET_FORM;
}

export type UserOnboardingAction =
    | LoadAccountOptionsAction
    | SetFirstNameAction
    | SetLastNameAction
    | SetEmailAction
    | SetRoleAction
    | SetPasswordAction
    | SetConfirmPasswordAction
    | SetUserAccountsAction
    | SetUserClientsAction
    | ResetFormAction;

export interface ClientDetails {
    id: number;
    name: string;
}

export interface AccountDetails {
    id: number;
    name: string;
    clients: ClientDetails[];
}

export interface GETAccounts {
    accounts: AccountDetails[];
    error_code: string;
    error_message: string;
    message: string;
}

export interface POSTAddUserRequest {
    account_role_details: AccountRoleDetails[];
    client_id: number[];
    email: string;
    first_name: string;
    last_name: string;
    password: string;
}

export interface POSTAddUserResponse {
    error_code: string;
    error_message: string;
    message: string;
    user_details: {
        account_role_details: AccountRoleDetails[];
        client_id: number[];
        email: string;
        first_name: string;
        last_name: string;
        status: string;
    };
}
