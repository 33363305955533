import produce from 'immer';

// TYPES
import { InsightsState } from '../models';
import { InsightsAction } from '../actions';
import { INSIGHTS_ACTION_TYPES } from '../action-types';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const DEFAULT_INSIGHTS_STATE: InsightsState = {
    startDate: yesterday.toDateString(),
    endDate: yesterday.toDateString(),
    targetOptions: [],
    targets: [],
    competitorsGroupOptions: [],
    competitorsGroup: null,
    competitorOptions: [],
    competitors: [],
    keywordsGroupOptions: [],
    keywordsGroup: null,
    keywordOptions: [],
    keywords: [],
    adType: 'sem',
};

export const insightsReducer = produce(
    (state: InsightsState, action: InsightsAction): InsightsState => {
        switch (action.type) {
            case INSIGHTS_ACTION_TYPES.LOAD_OPTIONS: {
                const { payload } = action;

                state.competitorsGroupOptions = payload.competitorsGroupOptions;
                state.competitorOptions = payload.competitorOptions;
                state.keywordsGroupOptions = payload.keywordsGroupOptions;
                state.keywordOptions = payload.keywordOptions;
                state.targetOptions = payload.targetOptions;

                return state;
            }

            case INSIGHTS_ACTION_TYPES.SET_START_DATE:
                state.startDate = action.payload;

                // ADJUST END DATE IF NEW START DAY IS AFTER CURRENT END DATE
                if (new Date(state.endDate) < new Date(action.payload)) {
                    state.endDate = action.payload;
                }
                return state;

            case INSIGHTS_ACTION_TYPES.SET_END_DATE:
                state.endDate = action.payload;
                return state;

            case INSIGHTS_ACTION_TYPES.SET_COMPETITORS_GROUP:
                if (state.competitorsGroup === action.payload) {
                    state.competitorsGroup = null;
                } else {
                    state.competitorsGroup = action.payload;
                }

                if (state.competitors.length) {
                    state.competitors = [];
                }

                return state;

            case INSIGHTS_ACTION_TYPES.SET_COMPETITORS:
                state.competitors = action.payload;

                if (typeof state.competitorsGroup === 'number') {
                    state.competitorsGroup = null;
                }

                return state;

            case INSIGHTS_ACTION_TYPES.SET_KEYWORDS_GROUP:
                if (state.keywordsGroup === action.payload) {
                    state.keywordsGroup = null;
                } else {
                    state.keywordsGroup = action.payload;
                }

                if (state.keywords.length) {
                    state.keywords = [];
                }

                return state;

            case INSIGHTS_ACTION_TYPES.SET_KEYWORDS:
                state.keywords = action.payload;

                if (state.keywordsGroup) {
                    state.keywordsGroup = null;
                }

                return state;

            case INSIGHTS_ACTION_TYPES.SET_AD_TYPE:
                state.adType = action.payload;
                return state;

            case INSIGHTS_ACTION_TYPES.SET_TARGETS:
                state.targets = action.payload;
                return state;

            case INSIGHTS_ACTION_TYPES.RESET_INSIGHTS:
                state = { ...DEFAULT_INSIGHTS_STATE };
                return state;

            default:
                return state;
        }
    },
    DEFAULT_INSIGHTS_STATE
);
