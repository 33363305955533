import './Landing.scss';
import { axios, getUserAccountDetails } from '../../api';
import { useState, useEffect } from 'react';
import { getFileNameDate, saveDownloadFile } from '../../utils';
import { UserAccountDetails } from '../../types/acount-details-summary';
import { useActions, useAppSelector, useAsyncWrapper } from '../../hooks';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import PageContainer from '../../components/Page-Container/Page-Container';

// API REQUESTS

function Landing() {
  // HOOKS
  const wrapper = useAsyncWrapper();

  // REDUX ACTIONS
  const { setIsLoading, alertError } = useActions();

  // REDUX STATE
  const { user } = useAppSelector(({ auth }) => auth);

  const userIsAdmin = user?.role === 'Admin';

  const [accountDetails, setAccountDetails] = useState<UserAccountDetails>();

  const loadAccountDetails = wrapper(async function () {
    if (!user?.token || !user?.account_id || accountDetails) {
      return;
    }
    const data = await getUserAccountDetails();

    setAccountDetails(data.accountDetails);
  });

  // FETCH ACCOUNT DETAILS
  useEffect(() => {
    loadAccountDetails();
  }, []);

  const appEnv = process.env.REACT_APP_ENV;
  console.log('APP_ENV:', appEnv);

  // DOWNLOAD ACCOUNTS DETAILS (ADMIN ONLY)
  const downloadAccount = async () => {
    if (!user?.token) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get('/account/export/accounts', {
        responseType: 'blob',
      });
      const fileName = `Account_details_${getFileNameDate()}`;
      saveDownloadFile(response, fileName);
    } catch (error: any) {
      alertError(
        error?.response?.data?.error_message ||
          error?.response?.data?.message ||
          error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <div className="landing-container">
        <h1>Welcome</h1>
        <p>Please select any of the options from the menu above.</p>
        <div className="account-details-content">
          <h2>
            Account Details:
            {userIsAdmin && <Button onClick={downloadAccount}>Download</Button>}
          </h2>
          <table className="account-details-table">
            <tbody>
              <tr>
                <td># of active studies:</td>
                <td>{accountDetails?.active_studies || 'n/a'}</td>
              </tr>
              <tr>
                <td># of suspended studies:</td>
                <td>{accountDetails?.suspended_studies || 'n/a'}</td>
              </tr>
              <tr>
                <td># of clients:</td>
                <td>{accountDetails?.clients || 'n/a'}</td>
              </tr>
              <tr>
                <td># of users:</td>
                <td>{accountDetails?.user || 'n/a'}</td>
              </tr>
              <tr>
                <td># of current searches per month:</td>
                <td>{accountDetails?.searches_per_month || 'n/a'}</td>
              </tr>
              <tr>
                <td>Search volume by account:</td>
                <td>{accountDetails?.account_search_volume || 'n/a'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </PageContainer>
  );
}

export default Landing;
