import './Chart-Wrapper.scss';

// TYPES
import React from 'react';

// TYPES
import { DropdownOption } from '../../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import CustomTooltip, {
  TOOLTIP_ALIGN,
} from '../../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface ChartWrapeprProps {
  title: string;
  tooltipText?: string;
  options?: DropdownOption[];
  dateRange: string;
  onChange?: (selection: DropdownOption) => void;
  value?: DropdownOption | null;
  children: React.ReactNode;
}

function ChartWrapper({
  title,
  tooltipText,
  options,
  dateRange,
  onChange,
  value,
  children,
}: ChartWrapeprProps) {
  // JSX
  return (
    <div className="chart-wrapper-container">
      <div className="chart-wrapper-header">
        <div className="chart-wrapper-header-text">
          <CustomTooltip
            content={tooltipText || undefined}
            align={TOOLTIP_ALIGN.LEFT}
            maxWidth={300}
          >
            {title}
          </CustomTooltip>
          <span className="chart-wrapper-header-sub">
            ({dateRange || 'N/A'})
          </span>
        </div>
        {options ? (
          <div className="chart-wrapper-header-dd">
            <Dropdown
              options={options}
              value={value}
              placeholder="Select a brand"
              onChange={onChange}
              hideLabel={true}
            >
              {' '}
            </Dropdown>
          </div>
        ) : null}
      </div>
      <div className="brand-chart-wrapper-content">{children}</div>
    </div>
  );
}

export default ChartWrapper;
