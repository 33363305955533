import { useAsyncWrapper } from '../../hooks';
import React, { useEffect, useReducer } from 'react';

// TYPES
import {
  UserRole,
  USER_ROLES,
  AccountDetails,
  DropdownOption,
  UserOnboardingState,
  userOnboardingReducer,
  DEFAULT_USER_FORM_STATE,
  USER_ONBOARDING_ACTION_TYPES,
} from '../../state';

// UITILS
import { onPreventEnterKeySubmit } from '../../utils';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';

// COMPONENT PROPS
interface UserOnboardingFormProps {
  accounts: AccountDetails[];
  onSubmit: (formState: UserOnboardingState) => Promise<void>;
}

// FUNCTIONAL COMPONENT
function UserOnboardingForm({ accounts, onSubmit }: UserOnboardingFormProps) {
  const wrapper = useAsyncWrapper();

  // FORM STATE
  const [state, dispatch] = useReducer(
    userOnboardingReducer,
    DEFAULT_USER_FORM_STATE
  );

  // PASSWORD & CONFIRM PASSWORD MATCHING
  const passwordsMatch = state.password === state.confirmPassword;

  // ENABLE/DISABLE SUBMIT BUTTON
  const isSubmitDisabled =
    !state.role ||
    !state.email ||
    !state.password ||
    !state.last_name ||
    !state.first_name ||
    !state.accounts.length ||
    !state.confirmPassword ||
    !passwordsMatch;

  // LOAD ACCOUNT OPTIONS ON INITIAL RENDER
  useEffect(() => {
    if (accounts.length) {
      dispatch({
        type: USER_ONBOARDING_ACTION_TYPES.LOAD_ACCOUNT_OPTIONS,
        payload: accounts,
      });
    }
  }, [accounts]);

  // HANDLE SETTING FIRST NAME
  const onSetFirstName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_FIRST_NAME,
      payload: evt.target.value,
    });
  };

  // HANDLE SETTING LAST NAME
  const onSetLastName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_LAST_NAME,
      payload: evt.target.value,
    });
  };

  // HANDLE SETTING EMAIL
  const onSetEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_EMAIL,
      payload: evt.target.value,
    });
  };

  // HANDLE SETTING FIRST NAME
  const onSelectRole = (selected: DropdownOption) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_ROLE,
      payload: selected.value as UserRole,
    });
  };

  // HANDLE SELECTING ACCOUNTS
  const onSelectAccount = (selections: DropdownOption[]) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_ACCOUNTS,
      payload: selections,
    });
  };

  // HANDLE SELECTING CLIENTS
  const onSelectClients = (selections: DropdownOption[]) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_CLIENTS,
      payload: selections,
    });
  };

  // HANDLE SETTING PASSWORD
  const onSetPassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_PASSWORD,
      payload: evt.target.value,
    });
  };
  // HANDLE SETTING CONFIRM PASSWORD
  const onSetConfirmPassword = (evt: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.SET_CONFIRM_PASSWORD,
      payload: evt.target.value,
    });
  };

  // HANDLE FORM SUBMISSION
  const onSubmitForm = wrapper(async (evt: React.FormEvent) => {
    evt.preventDefault();

    await onSubmit(state);
    dispatch({
      type: USER_ONBOARDING_ACTION_TYPES.RESET_FORM,
    });
  });

  return (
    <div className="user-onboarding-form-container">
      <form onSubmit={onSubmitForm} onKeyDown={onPreventEnterKeySubmit}>
        <Row>
          {/* FIRST NAME */}
          <Column>
            <Textbox
              onChange={onSetFirstName}
              value={state.first_name}
              required
            >
              First Name:
            </Textbox>
          </Column>

          {/* LAST NAME */}
          <Column>
            <Textbox onChange={onSetLastName} value={state.last_name} required>
              Last Name:
            </Textbox>
          </Column>

          {/* EMAIL */}
          <Column>
            <Textbox
              type="email"
              onChange={onSetEmail}
              value={state.email}
              required
            >
              Email:
            </Textbox>
          </Column>

          {/* ROLE */}
          <Column>
            <Dropdown
              options={USER_ROLES.map((role) => ({
                label: role,
                value: role,
              }))}
              onChange={onSelectRole}
              value={{ label: '', value: state.role }}
              required
            >
              Role:
            </Dropdown>
          </Column>
        </Row>

        <Row>
          {/* ACCOUNTS */}
          <Column>
            <Dropdown
              options={state.accountOptions}
              onChange={onSelectAccount}
              value={state.accounts}
              display="selectionInline"
              multiple
              required
            >
              Account(s):
            </Dropdown>
          </Column>

          {/* CLIENTS */}
          <Column>
            <Dropdown
              options={state.clientOptions}
              onChange={onSelectClients}
              display="selectionInline"
              value={state.clients}
              multiple
            >
              Client(s):
            </Dropdown>
          </Column>

          {/* PASSWORD */}
          <Column>
            <Textbox
              onChange={onSetPassword}
              value={state.password}
              type="password"
              required
            >
              Password
            </Textbox>
          </Column>

          {/* CONFIRM PASSWORD */}
          <Column>
            <>
              <Textbox
                className="confirm-password"
                onChange={onSetConfirmPassword}
                value={state.confirmPassword}
                type="password"
                required
              >
                Confirm Password
              </Textbox>
              <p
                style={{
                  color: 'red',
                  fontSize: '.8rem',
                  visibility: passwordsMatch ? 'hidden' : 'visible',
                }}
              >
                Passwords must match
              </p>
            </>
          </Column>
        </Row>

        {/* SUBMIT BUTTON */}
        <div className="form-buttons">
          <Button type="submit" disabled={isSubmitDisabled}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default UserOnboardingForm;
