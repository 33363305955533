import { axios } from '../axios';

// CONSTANTS
import { API_ROUTES } from '../../constants';

// TYPES
import {
  GETAccounts,
  POSTAddClient,
  POSTAddAccount,
  ClientFormState,
  AccountFormState,
  POSTAddUserResponse,
  UserOnboardingState,
} from '../../state';

// UTILS
import { saveDownloadFile, formatAddUserBody } from '../../utils';

// ADD NEW ACCOUNT
export const addAccount = async (state: AccountFormState) => {
  const body: POSTAddAccount = {
    account_name: state.name,
    search_volume: state.volume,
    status: state.status.value,
  };

  const { data } = await axios.post<{
    message?: string;
    errorMessage?: string;
  }>(API_ROUTES.ADD_ACCOUNT, body);

  return data;
};

// ADD NEW CLIENT
export const addClient = async (state: ClientFormState) => {
  if (!state.account) {
    throw new Error('Please select an account.');
  }
  const body: POSTAddClient = {
    account_id: parseInt(state.account.value.toString()),
    client_name: state.name,
    search_volume: parseInt(state.volume),
    status: state.status.value,
    is_new_business: state.isNewBusiness ? 1 : 0,
  };

  const { data } = await axios.post<{
    message?: string;
    errorMessage?: string;
  }>(API_ROUTES.ADD_CLIENT, body);

  return data;
};

// ADD NEW USER
export const addUser = async (state: UserOnboardingState) => {
  const body = formatAddUserBody(state);
  const { data } = await axios.post<POSTAddUserResponse>(
    API_ROUTES.ADD_USER,
    body
  );
  return data;
};

// DOWNLOAD ACTIVE USERS
export const exportActiveUsers = async () => {
  const url = API_ROUTES.EXPORT_USERS;
  const fileName = 'Active_Users_' + new Date().toDateString();

  const blob = await axios.get(url, { responseType: 'blob' });

  saveDownloadFile(blob, fileName);
};

// GET ACCOUNTS LIST
export const getAccounts = async () => {
  const url = API_ROUTES.GET_ACCOUNTS;

  const { data } = await axios.get<GETAccounts>(url);

  return data;
};
