import './Navbar.scss';

import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useActions, useAppSelector } from '../../hooks';

// CONSTANTS
import { ROUTES } from '../../router';
import { STORED_USER } from '../../state';
import logo from '../../assets/img/benchtools-logo.png';
import { BRAND_NAME, isProduction } from '../../constants';
// UI COMPONENTS
import NavLink from './Nav-Link';
import { SecondaryNav } from '@publicismedia-ds/ui-navigation';

// TYPES
interface LinkType {
  path: string | LinkType[];
  text: string;
}

const ALL_LINKS: LinkType[] = [
  {
    path: [
      { path: ROUTES.REPORTS, text: 'ad hoc' },
      { path: ROUTES.SCHEDULED_REPORTS, text: 'scheduled' },
    ],
    text: 'reports',
  },
  { path: ROUTES.STUDIES, text: 'studies' },
  { path: ROUTES.BRAND_MONITORING, text: 'brand monitoring' },
  { path: ROUTES.DASHBOARD, text: 'dashboard' },
  {
    path: [
      { path: ROUTES.ONBOARDING, text: 'onboarding' },
      { path: ROUTES.STUDIES_ACTIVITY, text: 'studies activity' },
      { path: ROUTES.ACCOUNT_DETAILS, text: 'account details' },
    ],
    text: 'admin',
  },
].filter(({ text }) => {
  if (!isProduction) return true;
  if (text === 'dashboard') return false;
  return true;
});

// FUNCTIONAL COMPONENT (NAVBAR)
const Navbar = () => {
  const { logoutUser } = useActions();

  // REDUX STATE
  const { user } = useAppSelector(({ auth }) => auth);

  const isAdmin = user?.role === 'Admin';
  const isPrivileged = isAdmin || user?.role === 'Account Manager';

  const location = useLocation();

  const handleLogout = async () => {
    localStorage.removeItem(STORED_USER);
    logoutUser();
    window.location.reload();
  };

  const renderNavLink = (
    link: LinkType,
    indexKey: number,
    isNested = false
  ) => {
    if (Array.isArray(link.path)) {
      return (
        <NavLink
          text={link.text}
          selected={location.pathname.includes(link.text)}
          key={indexKey}
        >
          {link.path.map((nestedLink, index) =>
            renderNavLink(nestedLink, index, true)
          )}
        </NavLink>
      );
    }
    return (
      <NavLink
        as={Link}
        selected={location.pathname === link.path && !isNested}
        text={link.text}
        to={link.path}
        key={indexKey}
      />
    );
  };

  const renderedLinks = useMemo(() => {
    const allowedLinks: LinkType[] = [];

    if (user) {
      for (const link of ALL_LINKS) {
        // FILTER OUT ADMIN LINKS FOR NON-ADMINS
        if (!isAdmin && link.text === 'admin') {
          continue;
        }

        // FILTER OUT PRIVILEGED LINKS FOR NON-PRIVILEGED USERS
        if (!isPrivileged && link.path === ROUTES.BRAND_MONITORING) {
          continue;
        }
        allowedLinks.push(link);
      }
    }

    return allowedLinks.map((link, index) => renderNavLink(link, index));
  }, [user, location.pathname]);

  // JSX
  return (
    <div className="nav-container">
      <SecondaryNav
        heading={
          <NavLink
            className="logo"
            as={Link}
            text={
              isProduction ? (
                <img src={logo} alt="Benchtools logo" height="25" width="200" />
              ) : (
                BRAND_NAME
              )
            }
            to="/"
          />
        }
      >
        {renderedLinks}
        {user && (
          <NavLink text={`Hi, ${user.first_name}`}>
            <NavLink text="logout" onClick={handleLogout} />
          </NavLink>
        )}
      </SecondaryNav>
    </div>
  );
};

export default Navbar;
