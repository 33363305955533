import './Studies.scss';

import { useEffect, useState } from 'react';
import { useActions, useAsyncWrapper, useUserPermissions } from '../../hooks';

// TYPE IMPORTS
import { WarningModalState } from '../../types';
import { StatusType, StudyListItem } from '../../state';

// UI COMPONENTS IMPORTS
import PageContainer from '../../components/Page-Container/Page-Container';
import StudiesTable from '../../components/Studies-Table/Studies-Table';
import WarningModal from '../../components/Warning-Modal/Warning-Modal';

// API REQUESTS
import { getStudyList, updateStudyStatus } from '../../api';

// FUNCTIONAL COMPONENT
function Studies() {
    const wrapper = useAsyncWrapper();
    const { isPrivileged, isAdmin } = useUserPermissions();

    // REDUX ACTIONS
    const { alertError, alertSuccess } = useActions();

    // TABLE STATE
    const [studyList, setStudyList] = useState<StudyListItem[]>([]);

    // MODAL STATE
    const [showWarningModal, setShowWarningModal] =
        useState<WarningModalState | null>(null);

    // FETCH AND STORE STUDIES
    const loadStudyList = wrapper(async () => {
        // FETCH STUDIES
        const studies = await getStudyList();

        // STORE STUDIES
        setStudyList(studies);
    });

    // LOAD STUDIES ON INITIAL RENDER
    useEffect(() => {
        loadStudyList();
    }, []);

    // HANDLE UPDATING STUDY STATUS
    const onUpdateStatus = (studyId: string, isActive: boolean) => {
        const newStatus: StatusType = isActive ? 'Suspended' : 'Active';

        const header = 'Study status:';

        const content = (
            <>
                <p style={{ fontSize: '1rem' }}>
                    Please confirm updating study status to:
                </p>
                <p style={{ fontSize: '1.1rem' }}>
                    <strong>{newStatus}</strong>
                </p>
            </>
        );

        const onConfirm = wrapper(async () => {
            const data = await updateStudyStatus(studyId, newStatus);

            if (data) {
                alertSuccess('Successfully updated study status.');

                // UPDATE STATUS IN STUDY LIST
                loadStudyList();
            } else {
                alertError('Unable to update study status.');
            }
        });

        setShowWarningModal({ display: true, header, content, onConfirm });
    };

    // JSX
    return (
        <PageContainer title="studies">
            <div className="studies-data-table">
                <StudiesTable
                    data={studyList}
                    isAdmin={isAdmin}
                    isPrivileged={isPrivileged}
                    onUpdateStatus={onUpdateStatus}
                />
            </div>
            {!!showWarningModal && (
                <WarningModal
                    header={showWarningModal.header}
                    content={showWarningModal.content}
                    onClose={() => setShowWarningModal(null)}
                    onConfirm={showWarningModal.onConfirm}
                />
            )}
        </PageContainer>
    );
}

export default Studies;
