import { AdhocReportState, DropdownOption, StudyDetails } from '../models';
import { ADHOC_REPORT_ACTION_TYPES } from '../action-types';

// ACTIONS
import {
  SetAdhocFocusAction,
  SetAdhocStudyAction,
  LoadAdhocStateAction,
  ResetAdhocStateAction,
  SetAdhocAdTypesAction,
  SetAdhocEndDateAction,
  SetAdhocKeywordsAction,
  SetAdhocMetricsAction,
  SetAdhocTargetsAction,
  SetAdhocTimeZoneAction,
  SetAdhocTopWordsAction,
  SetAdhocStartDateAction,
  SetAdhocCompetitorsAction,
  SetAdhocZeroCoverageAction,
  SetAdhocPriorEndDateAction,
  SetAdhocKeywordGroupsAction,
  SetAdhocAutoCompetitorAction,
  SetAdhocDailyReportingAction,
  SetAdhocPriorStartDateAction,
  SetAdhocComparePreviousAction,
  SetAdhocHourlyReportingAction,
  SetAdhocCompetitorGroupsAction,
  SetAdhocAutoCompetitorLimitAction,
} from '../actions';
import { ReportFocus } from '../forms';

export const setAdhocStudy = (study: StudyDetails): SetAdhocStudyAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_STUDY,
    payload: study,
  };
};

export const setAdhocStartDate = (startDate: Date): SetAdhocStartDateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_START_DATE,
    payload: startDate.toDateString(),
  };
};

export const setAdhocEndDate = (endDate: Date): SetAdhocEndDateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_END_DATE,
    payload: endDate.toDateString(),
  };
};

export const setAdhocPriorStartDate = (
  startDate: Date
): SetAdhocPriorStartDateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_PRIOR_START_DATE,
    payload: startDate.toDateString(),
  };
};

export const setAdhocPriorEndDate = (
  endDate: Date
): SetAdhocPriorEndDateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_PRIOR_END_DATE,
    payload: endDate.toDateString(),
  };
};

export const setAdhocZeroCoverage = (
  isChecked: boolean
): SetAdhocZeroCoverageAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_ZERO_COVERAGE,
    payload: isChecked,
  };
};

export const setAdhocComparePrevious = (
  isChecked: boolean
): SetAdhocComparePreviousAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_COMPARE_PREVIOUS,
    payload: isChecked,
  };
};

export const setAdhocAutoCompetitor = (
  isChecked: boolean
): SetAdhocAutoCompetitorAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_AUTO_COMPETITOR_GROUP,
    payload: isChecked,
  };
};

export const setAdhocDailyReporting = (
  isChecked: boolean
): SetAdhocDailyReportingAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_DAILY_REPORTING,
    payload: isChecked,
  };
};

export const setAdhocHourlyReporting = (
  isChecked: boolean
): SetAdhocHourlyReportingAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_HOURLY_REPORTING,
    payload: isChecked,
  };
};

export const setAdhocCompetitorGroups = (
  selections: DropdownOption[]
): SetAdhocCompetitorGroupsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_COMPETITOR_GROUPS,
    payload: selections,
  };
};

export const setAdhocCompetitors = (
  selections: DropdownOption[]
): SetAdhocCompetitorsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_COMPETITORS,
    payload: selections,
  };
};

export const setAdhocKeywordGroups = (
  selections: DropdownOption[]
): SetAdhocKeywordGroupsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_KEYWORD_GROUPS,
    payload: selections,
  };
};

export const setAdhocKeywords = (
  selections: DropdownOption[]
): SetAdhocKeywordsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_KEYWORDS,
    payload: selections,
  };
};

export const setAdhocTargets = (
  selections: DropdownOption[]
): SetAdhocTargetsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_TARGETS,
    payload: selections,
  };
};

export const setAdhocMetrics = (
  selections: DropdownOption[]
): SetAdhocMetricsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_METRICS,
    payload: selections,
  };
};

export const setAdhocAdTypes = (
  selections: DropdownOption[]
): SetAdhocAdTypesAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_AD_TYPES,
    payload: selections,
  };
};

export const setAdhocTimeZone = (
  selected: DropdownOption
): SetAdhocTimeZoneAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_TIME_ZONE,
    payload: selected,
  };
};

export const setAdhocFocus = (
  selectedValue: ReportFocus
): SetAdhocFocusAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_FOCUS,
    payload: selectedValue,
  };
};

export const setAdhocAutoCompetitorLimit = (
  value: number
): SetAdhocAutoCompetitorLimitAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_AUTO_COMPETITOR_LIMIT,
    payload: value,
  };
};

export const setAdhocTopWords = (value: number): SetAdhocTopWordsAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.SET_TOP_WORDS,
    payload: value,
  };
};

export const loadAdhocState = (
  state: AdhocReportState
): LoadAdhocStateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.LOAD_STATE,
    payload: state,
  };
};

export const resetAdhocState = (): ResetAdhocStateAction => {
  return {
    type: ADHOC_REPORT_ACTION_TYPES.RESET_STATE,
  };
};
