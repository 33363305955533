import './Study-Details-View.scss';

import { StudyDetails } from '../../state';
import { useAppSelector } from '../../hooks';
import { formatGroupsData } from '../../utils';

// UI COMPONENTS
import { Row, Column } from '@publicismedia-ds/ui-grid';
import StudyDetail from '../../components/Study-Detail/Study-Detail';
import GroupDetails from '../../components/Group-Details/Group-Details';
import LocaleDetails from '../../components/Locale-Details/Locale-Details';

// COMPONENT PROPS
interface StudyDetailsViewProps {
    study: StudyDetails;
}

function StudyDetailsView({ study }: StudyDetailsViewProps) {
    const isAdmin = useAppSelector(({ auth }) => auth.user?.role === 'Admin');

    return (
        <div className="study-details-container">
            <Row>
                {/* --- STUDY ID --- */}
                <Column>
                    <StudyDetail header="study ID" content={study.id} />
                </Column>

                {/* --- SEARCH TYPE ---
                <Column>
                    <StudyDetail header="search type" content="Search" />
                </Column> */}

                {/* --- SAMPLE FREQUENCY --- */}
                <Column>
                    <StudyDetail
                        header="frequency"
                        content={study.frequency + 'x'}
                    />
                </Column>

                {/* --- SEARCH VOLUME --- */}
                <Column>
                    <StudyDetail
                        header="search volume"
                        content={study.monthly_search_volume}
                    />
                </Column>

                {/* --- CLIENTS --- */}
                <Column>
                    <StudyDetail
                        header="client"
                        content={study.client_name || 'N/A'}
                    />
                </Column>

                {/* --- STUDY STATUS --- */}
                <Column>
                    <StudyDetail header="status" content={study.status} />
                </Column>

                {isAdmin && study.provider_id && (
                    <Column>
                        <StudyDetail
                            header="Provider"
                            content={
                                study.provider_id === 1 ? 'Oxylab' : 'SERPWow'
                            }
                        />
                    </Column>
                )}
            </Row>

            <div className="study-details-row accordion-groups">
                <LocaleDetails locales={study.locale_details || []} />
                <GroupDetails
                    type="keyword"
                    data={formatGroupsData({
                        keyword_groups: study.keyword_group_details,
                    })}
                />
                <GroupDetails
                    type="competitor"
                    data={formatGroupsData({
                        competitor_groups: study.competitor_group_details,
                    })}
                />
            </div>
        </div>
    );
}

export default StudyDetailsView;
