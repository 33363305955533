import { REPORT_TOOLTIPS } from '../../tooltips';
import React, { memo, useEffect, useMemo } from 'react';

// TYPES
import {
  REPORT_FOCUS,
  DropdownOption,
  REPORT_METRICS,
  ReportFormState,
  ReportFilterProps,
  ReportFiltersState,
  FocusDropdownOption,
  ReportFiltersAction,
  REPORT_FILTER_ACTION_TYPES,
} from '../../state';
import { ScheduledReport } from '../../types';

// CONSTANTS
import {
  AD_TYPE_OPTIONS,
  TIMEZONE_OPTIONS,
  SERP_AD_TYPE_OPTIONS,
} from '../../constants';

// UI COMPONENT IMPORTS
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import Grid, { Row, Column } from '@publicismedia-ds/ui-grid';
import ReportFilterCheckboxes from './Report-Filters-Checkboxes';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// UTILITIES
import {
  snakeCaseToSpaces,
  parseNumberInputValue,
  parseCompetitorGroups,
  enabledFiltersByChoice,
  getCompsBySelectedGroups,
} from '../../utils';

// COMPONENT PROPS
interface ReportFiltersProps {
  onDemand?: boolean;
  report?: ScheduledReport;
  state: ReportFiltersState;
  baseState: ReportFormState;
  dispatch: React.Dispatch<ReportFiltersAction>;
  readOnly?: boolean;
}

// FUNCTIONAL COMPONENT
function ReportFilters({
  state,
  report,
  dispatch,
  onDemand,
  baseState,
  readOnly = false,
}: ReportFiltersProps) {
  // RESET FORM ON REPORT TYPE OR STUDY SELECTION
  useEffect(() => {
    dispatch({
      type: REPORT_FILTER_ACTION_TYPES.RESET_FILTERS,
    });
  }, [baseState.report_type, dispatch]);

  // LOAD FILTER OPTIONS BASED ON STUDY
  useEffect(() => {
    if (!baseState.study) {
      return;
    }

    dispatch({
      type: REPORT_FILTER_ACTION_TYPES.LOAD_STUDY_OPTIONS,
      value: baseState.study,
    });
  }, [baseState.study, dispatch]);

  // LOAD EXISITNG REPORT DATA ON INITIAL LOAD (IF PRESENT)
  useEffect(() => {
    if (!report || !baseState.study) {
      return;
    }
    dispatch({
      type: REPORT_FILTER_ACTION_TYPES.LOAD_REPORT_FILTERS,
      value: { report, study: baseState.study },
    });
  }, [report, baseState.study, dispatch]);

  // DISABLE / ENABLE FILTERS BY REPORT CHOICE
  const enabledFilters = useMemo(() => {
    return enabledFiltersByChoice(baseState.report_type || 'Battlefield');
  }, [baseState.report_type]);

  // AD TYPE OPTIONS
  const adTypeOptions =
    baseState.report_type === 'SERPVisibility'
      ? SERP_AD_TYPE_OPTIONS
      : AD_TYPE_OPTIONS;

  // HANDLE COMPETITOR LIMIT CHANGE
  const handleCompetitorLimitChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseNumberInputValue(evt);
    dispatch({
      type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_LIMIT,
      value: value,
    });
  };

  // COMPETITOR GROUP DROPDOWN OPTIONS
  const competitorGroupOptions = useMemo((): DropdownOption[] => {
    if (!baseState.study) {
      return [];
    }

    // RETURN DROPDOWN OPTIONS
    return parseCompetitorGroups(baseState.study.competitor_group_details)[0];
  }, [baseState.study]);

  // COMPETITOR DROPDOWN OPTIONS
  const competitorOptions = useMemo(() => {
    return getCompsBySelectedGroups(
      state.competitor_group || [],
      baseState.study?.competitor_group_details || []
    );
  }, [baseState.study, state.competitor_group]);

  // JSX
  return (
    <div className="report-filters-container">
      <hr className="hr-line" />
      <h3 className="filters-title">
        Filters:
        <span className="text-muted text-small">(optional)</span>
      </h3>

      <div className="filter-content">
        <Grid>
          {/* --- CHECKBOXES --- */}
          <Row className="cols-5">
            <ReportFilterCheckboxes
              state={state}
              dispatch={dispatch}
              baseState={baseState}
              onDemand={onDemand}
              enabledFilters={enabledFilters}
              disabled={readOnly}
            />
          </Row>

          <Row className="cols-4">
            {/* --- KEYWORD GROUPS --- */}
            {enabledFilters[ReportFilterProps.KEYWORD_GROUPS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Keyword Groups (${state.keywordGroupOptions.length})`
                  }
                  options={state.keywordGroupOptions}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_KEYWORD_GROUPS,
                      value: selected,
                    });
                  }}
                  value={state.keyword_groups}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  disabled={readOnly}
                  multiple
                  invert
                >
                  <CustomTooltip content={REPORT_TOOLTIPS.KEYWORD_GROUPS}>
                    Keyword Groups:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- KEYWORDS --- */}
            {enabledFilters[ReportFilterProps.KEYWORDS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Keywords (${state.keywordOptions.length})`
                  }
                  options={state.keywordOptions}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_KEYWORDS,
                      value: selected,
                    });
                  }}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  value={state.keywords}
                  disabled={readOnly}
                  multiple
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.KEYWORDS}
                    align={TOOLTIP_ALIGN.LEFT}
                  >
                    Keywords:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- COMPETITORS GROUP --- */}
            {enabledFilters[ReportFilterProps.COMPETITOR_GROUP] !==
              undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Competitor Groups (${competitorGroupOptions.length})`
                  }
                  options={competitorGroupOptions}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_GROUP,
                      value: selected,
                    });
                  }}
                  disabled={
                    state.auto_competitor_group ||
                    state.competitors?.length ||
                    readOnly
                  }
                  value={state.competitor_group}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  multiple
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.COMPETITOR_GROUPS}
                    align={TOOLTIP_ALIGN.RIGHT}
                  >
                    Competitor Groups:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- COMPETITORS --- */}
            {enabledFilters[ReportFilterProps.COMPETITORS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Competitors (${competitorOptions.length})`
                  }
                  options={competitorOptions}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_COMPETITORS,
                      value: selected,
                    });
                  }}
                  disabled={state.auto_competitor_group || readOnly}
                  value={state.competitors}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  multiple
                  invert
                >
                  <CustomTooltip content={REPORT_TOOLTIPS.COMPETITORS}>
                    Competitors:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- TARGETS --- */}
            {enabledFilters[ReportFilterProps.TARGETS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Targets (${state.targetOptions.length})`
                  }
                  options={state.targetOptions}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_TARGETS,
                      value: selected,
                    });
                  }}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  value={state.targets}
                  disabled={readOnly}
                  multiple
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.TARGETS}
                    align={TOOLTIP_ALIGN.LEFT}
                  >
                    Targets:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- METRICS --- */}
            {enabledFilters[ReportFilterProps.METRICS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Metrics (${REPORT_METRICS.length})`
                  }
                  options={REPORT_METRICS.map((metric) => {
                    return {
                      label: snakeCaseToSpaces(metric),
                      value: metric,
                    };
                  })}
                  onChange={(selected: DropdownOption[]) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_METRICS,
                      value: selected,
                    });
                  }}
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  value={state.metrics}
                  isSearchable={false}
                  disabled={readOnly}
                  multiple
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.METRICS}
                    align={TOOLTIP_ALIGN.LEFT}
                    maxWidth="350px"
                  >
                    Metrics:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- TYPES --- */}
            {enabledFilters[ReportFilterProps.TYPE] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Type (${adTypeOptions.length})`
                  }
                  options={adTypeOptions}
                  onChange={(selected: DropdownOption[] | DropdownOption) => {
                    if (!Array.isArray(selected)) {
                      selected = [selected];
                    }
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_TYPE,
                      value: selected,
                    });
                  }}
                  multiple={
                    baseState.report_type !== 'Keywords' &&
                    baseState.report_type !== 'SERPVisibility'
                  }
                  display={readOnly ? 'pillBelow' : 'selectionInline'}
                  isSearchable={false}
                  disabled={readOnly}
                  value={state.type}
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.AD_TYPE}
                    align={TOOLTIP_ALIGN.LEFT}
                  >
                    Ad Type:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- TIME ZONES --- */}
            {enabledFilters[ReportFilterProps.TIME_ZONE] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder={
                    readOnly
                      ? `0 Selected`
                      : `Select Timezone (${TIMEZONE_OPTIONS.length})`
                  }
                  options={TIMEZONE_OPTIONS}
                  onChange={(selected: DropdownOption) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_TIME_ZONE,
                      value: selected.value as string,
                    });
                  }}
                  value={state.time_zone}
                  isSearchable={false}
                  disabled={readOnly}
                  invert
                >
                  <CustomTooltip content={REPORT_TOOLTIPS.TIMEZONE}>
                    Timezone:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}

            {/* --- COMPETITOR LIMIT --- */}
            {state.auto_competitor_group && (
              <Column>
                <Textbox
                  className="filter-input input-number"
                  onChange={handleCompetitorLimitChange}
                  value={state.competitor_limit || ''}
                  placeholder={'Competitor Limit'}
                  type={'number'}
                  required
                  max={10}
                  min={1}
                  disabled={readOnly}
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.COMPETITORS_LIMIT}
                    align={TOOLTIP_ALIGN.LEFT}
                  >
                    Top Competitors:
                  </CustomTooltip>
                </Textbox>
                {!state.competitor_limit && (
                  <p className="input-error">Please enter a value 1-10</p>
                )}
              </Column>
            )}
            {/* --- TOP WORDS --- */}
            {enabledFilters[ReportFilterProps.TOP_WORDS] !== undefined && (
              <Column>
                <Textbox
                  className="filter-input input-number"
                  placeholder="Top Words"
                  type="number"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const value = parseNumberInputValue(evt);
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_TOP_WORDS,
                      value,
                    });
                  }}
                  value={state.top_words || ''}
                  max={100}
                  min={0}
                  disabled={readOnly}
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.TOP_WORDS}
                    align={TOOLTIP_ALIGN.LEFT}
                  >
                    Top Words:
                  </CustomTooltip>
                </Textbox>
              </Column>
            )}

            {/* --- WORD FOCUS --- */}
            {enabledFilters[ReportFilterProps.FOCUS] !== undefined && (
              <Column>
                <Dropdown
                  className="filter-input"
                  placeholder="Focus"
                  options={REPORT_FOCUS.map((focus) => {
                    return {
                      label: focus,
                      value: focus,
                    };
                  })}
                  onChange={(option: FocusDropdownOption) => {
                    dispatch({
                      type: REPORT_FILTER_ACTION_TYPES.SET_FOCUS,
                      value: option.value,
                    });
                  }}
                  value={{ label: '', value: state.focus || '' }}
                  disabled={readOnly}
                  invert
                >
                  <CustomTooltip content={REPORT_TOOLTIPS.FOCUS}>
                    Focus:
                  </CustomTooltip>
                </Dropdown>
              </Column>
            )}
          </Row>
        </Grid>
      </div>
    </div>
  );
}

export default memo(ReportFilters);
