import produce from 'immer';
import { LoadingAction } from '../actions';
import { LoadingState } from '../models/loading-model';
import { LOADING_ACTION_TYPES } from '../action-types';

const DEFAULT_LOADING_STATE: LoadingState = {
    isLoading: false,
};

export const loadingReducer = produce(
    (state: LoadingState, action: LoadingAction) => {
        switch (action.type) {
            case LOADING_ACTION_TYPES.START_LOADING:
                state.isLoading = true;
                return state;

            case LOADING_ACTION_TYPES.STOP_LOADING:
                state.isLoading = false;
                return state;

            default:
                return state;
        }
    },
    DEFAULT_LOADING_STATE
);
