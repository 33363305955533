import './Radial-Bar-Group.scss';

import { useMemo } from 'react';
import { useAsyncWrapper } from '../../../../hooks';

// TYPES
import { DropdownOption } from '../../../../state';

// UI COMPONENTS
import RadialBar from '../Radial-Bar';
import ChartMenu from '../../Chart-Menu/Chart-Menu';
import RadialBarCompare from '../Radial-Bar-Compare/Radial-Bar-Compare';

// UTILS
import domToImage from 'dom-to-image';
import { triggerDownload } from '../../../../utils';

export interface RadialBarGroupData {
  name: string;
  current: number | null;
  prior?: number | null;
}

// COMPONENT PROPS
interface RadialBarGroupProps {
  data: RadialBarGroupData[];
  title?: string;
  onChange?: (selected: DropdownOption) => void;
  min?: number;
}

// FUNCTIONAL COMPONENT (RADIAL BAR GROUP)
function RadialBarGroup({ data, title, min }: RadialBarGroupProps) {
  const wrapper = useAsyncWrapper();

  // unique class required for selecting group for download
  const uniqueClassName =
    'radial-bar-group__' + Math.floor(Math.random() * 10000);
  const renderedCharts = useMemo(() => {
    return data.map(({ name, current, prior }, index) => (
      <div className="radial-bar-group-item" key={index}>
        {prior !== undefined ? (
          <RadialBarCompare
            current={current}
            prior={prior}
            title={name}
            min={min}
          />
        ) : (
          <RadialBar
            data={[current || 0]}
            labels={[current !== null ? current + '%' : 'N/A']}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
              },
              title: {
                text: name,
                style: {
                  fontWeight: 600,
                  fontSize: '0.9rem',
                },
                offsetY: 35,
              },
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    name: {
                      show: true,
                      offsetY: -10,
                      color: '#000',
                      fontSize: '2.25rem',
                      fontWeight: '100',
                    },
                    value: {
                      show: false,
                      offsetY: 0,
                      fontSize: '22px',
                    },
                  },
                },
              },
            }}
            height={300}
            width="100%"
          />
        )}
      </div>
    ));
  }, [data]);

  // HANDLE EXPORTING SVG TO PNG
  const onExport = wrapper(async () => {
    // SELECT RADIAL BAR GROUP BY UNIQUE CLASS NAME
    const node = document.querySelector(`.${uniqueClassName}`);

    domToImage
      .toPng(node, {
        filter: (node: HTMLElement) => {
          // filter out open "Download PNG" menu
          if (node.className === 'apexcharts-toolbar') {
            return false;
          }
          return true;
        },
        // bgcolor default === transparent
        bgcolor: 'white',
      })
      .then((dataURL: string) => {
        triggerDownload(dataURL, title?.replaceAll(' ', '-') || 'chart');
      })
      .catch(() => {
        throw new Error('Unable to export PNG.');
      });
  });

  return (
    <div className={`radial-bar-group-container + ${uniqueClassName}`}>
      <div className="radial-bar-group-header">
        <span className="radial-bar-group-header-text">{title}</span>
        <ChartMenu onExportPNG={onExport} />
      </div>
      <div className="radial-bar-group-content">{renderedCharts}</div>
    </div>
  );
}

export default RadialBarGroup;
