import './New-Locale-Modal.scss';

import React, { useReducer } from 'react';

// UI COMPONENTS
import Modal from '../Modal/Modal';
import Button from '@publicismedia-ds/ui-button';
import Textbox from '@publicismedia-ds/ui-textbox';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import InputError from '../Input-Error-Message/Input-Error-Message';

// TYPES
import {
  NewLocaleActionTypes,
  NewLocaleDropdown,
  NewLocaleState,
} from '../../types/edit-study-types';

// UTILS
import { LOCALE_OPTIONS } from '../../utils';

// FORM STATE
import { initialNewLocaleState, newLocaleReducer } from './new-local-state';

// COMPONENT PROPS
interface NewLocaleModalProps {
  details: NewLocaleState | null;
  onClose: () => void;
  onSubmit: (locale: NewLocaleState) => void;
}

// FUNCTIONAL COMPONENT (ADD LOCALE MODAL FORM)
function NewLocaleModal({ details, onClose, onSubmit }: NewLocaleModalProps) {
  const initialState = details || initialNewLocaleState;
  const [newLocale, dispatch] = useReducer(newLocaleReducer, initialState);

  const { engine, device, language, location } = newLocale;
  const { city, country, county, latitude, longitude, radius, region, zip } =
    location;

  const onAddLocaleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(newLocale);
    onClose();
  };

  const isRegionRequired = !!city && !region;
  const isCityRequired = !!region && !city;

  const isSubmitDisabled =
    !engine ||
    !device ||
    !language ||
    !location.country ||
    isRegionRequired ||
    isCityRequired;

  // JSX
  return (
    <Modal onClose={onClose} userClosable={false}>
      <div className="custom-modal-container">
        <div className="custom-modal-header">
          <h2>Add Locale:</h2>
        </div>
        <div className="custom-modal-content">
          <form
            id="form-add-locale"
            className="custom-modal-form"
            onSubmit={onAddLocaleSubmit}
          >
            <div className="custom-modal-form-content">
              <Row>
                {/* ENGINE */}
                <Column>
                  <Dropdown
                    options={LOCALE_OPTIONS.ENGINES}
                    onChange={(selected: NewLocaleDropdown) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_ENGINE,
                        value: selected.value,
                      });
                    }}
                    defaultValue={{
                      label: engine,
                      value: engine,
                    }}
                    value={{ label: engine, value: engine }}
                    required
                  >
                    Engine
                  </Dropdown>
                </Column>
                <Column>
                  {/* DEVICE */}
                  <Dropdown
                    options={LOCALE_OPTIONS.DEVICES}
                    defaultValue={{
                      label: device,
                      value: device,
                    }}
                    value={{ label: device, value: device }}
                    onChange={(selected: NewLocaleDropdown) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_DEVICE,
                        value: selected.value,
                      });
                    }}
                    required
                  >
                    Device
                  </Dropdown>
                </Column>

                {/* COUNTRY */}
                <Column>
                  <Dropdown
                    options={LOCALE_OPTIONS.COUNTRIES}
                    defaultValue={{
                      label: country,
                      value: country,
                    }}
                    value={{
                      label: country,
                      value: country,
                    }}
                    onChange={(selected: NewLocaleDropdown) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_COUNTRY,
                        value: selected.value,
                      });
                    }}
                    required
                  >
                    Country
                  </Dropdown>
                </Column>
                <Column>
                  {/* LANGUAGE */}
                  <Dropdown
                    options={LOCALE_OPTIONS.LANGUAGES}
                    defaultValue={{
                      label: language,
                      value: language,
                    }}
                    value={{
                      label: language,
                      value: language,
                    }}
                    onChange={(selected: NewLocaleDropdown) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_LANGUAGE,
                        value: selected.value,
                      });
                    }}
                    required
                  >
                    Language
                  </Dropdown>
                </Column>
              </Row>
              <Row>
                <Column>
                  {/* REGION */}
                  <Textbox
                    value={region}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_REGION,
                        value: evt.target.value,
                      });
                    }}
                    required={isRegionRequired}
                  >
                    State or Region
                  </Textbox>
                  <InputError display={isRegionRequired}>
                    Please enter a region
                  </InputError>
                </Column>
                <Column>
                  {/* CITY */}
                  <Textbox
                    value={city}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_CITY,
                        value: evt.target.value,
                      });
                    }}
                    required={isCityRequired}
                  >
                    City
                  </Textbox>
                  <InputError display={isCityRequired}>
                    Please enter a city
                  </InputError>
                </Column>
                <Column>
                  {/* COUNTY */}
                  <Textbox
                    value={county}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_COUNTY,
                        value: evt.target.value,
                      });
                    }}
                  >
                    County
                  </Textbox>
                </Column>
                <Column>
                  {/* ZIP */}
                  <Textbox
                    value={zip}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_ZIP,
                        value: evt.target.value,
                      });
                    }}
                  >
                    Zip
                  </Textbox>
                </Column>
              </Row>
              <Row>
                <Column>
                  {/* LATITUDE */}
                  <Textbox
                    value={latitude}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_LATITUDE,
                        value: evt.target.value,
                      });
                    }}
                  >
                    Latitude
                  </Textbox>
                </Column>
                <Column>
                  {/* LONGITUDE */}
                  <Textbox
                    value={longitude}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_LONGITUDE,
                        value: evt.target.value,
                      });
                    }}
                  >
                    Longitude
                  </Textbox>
                </Column>
                <Column>
                  {/* RADIUS */}
                  <Textbox
                    type="number"
                    value={radius}
                    min={0}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = evt.target;
                      const actualValue = Math.max(
                        parseInt(value),
                        0
                      ).toString();
                      dispatch({
                        type: NewLocaleActionTypes.UPDATE_RADIUS,
                        value: actualValue,
                      });
                    }}
                  >
                    Radius{' '}
                    <span className="text-muted text-small">(meters)</span>
                  </Textbox>
                </Column>
              </Row>
            </div>
          </form>
        </div>
        <div className="custom-modal-footer">
          <div className="form-buttons">
            <Button
              className="btn-cancel"
              color="red"
              onClick={onClose}
              style={{ color: 'white' }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              form="form-add-locale"
              disabled={isSubmitDisabled}
            >
              Add Locale
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default NewLocaleModal;
