import './Loading-Modal.scss';

import Modal from '../Modal/Modal';
import CircleLoader from 'react-spinners/CircleLoader';
import { useActions, useAppSelector } from '../../hooks';

interface LoadingModalProps {
    header?: string;
    onClose?: () => void;
}

function LoadingModal({ header, onClose }: LoadingModalProps) {
    const { isLoading } = useAppSelector(({ loading }) => loading);
    const { setIsLoading } = useActions();

    return (
        <>
            {isLoading && (
                <Modal
                    userClosable={false}
                    onClose={() => {
                        if (onClose) {
                            onClose();
                        } else {
                            setIsLoading(false);
                        }
                    }}
                >
                    <div className="loading-modal-content">
                        <h1 className="loading-modal-header">{header}</h1>
                        <CircleLoader color="#36d7b7" />
                    </div>
                </Modal>
            )}
        </>
    );
}

export default LoadingModal;
