import { AdhocReportParams, AdhocReportState, DarwinFilters } from '../state';
import { dropdownsToString, getDateDiff } from './misc-utils';

interface GetAdhocParamsOptions {
  includePrior: boolean;
}

// FORMAT REPORT PARAMS (KEYWORD, SERP VISIBILITY, WORD ANALYSIS)
export const getAdhocParams = (
  state: AdhocReportState,
  options?: GetAdhocParamsOptions
) => {
  if (!state.start_date) {
    throw new Error('Please select a valid start date.');
  }
  if (!state.end_date) {
    throw new Error('Please select a valid end date.');
  }

  const params: AdhocReportParams = {
    start_date: new Date(state.start_date).toLocaleDateString('en-CA'),
    end_date: new Date(state.end_date).toLocaleDateString('en-CA'),
  };

  // Don't include "prior" dates for BT reports
  if (options?.includePrior) {
    if (state.prior_start_date) {
      params.prior_start_date = new Date(
        state.prior_start_date
      ).toLocaleDateString('en-CA');
    }

    if (state.prior_end_date) {
      params.prior_end_date = new Date(state.prior_end_date).toLocaleDateString(
        'en-CA'
      );
    }
  }

  if (state.auto_competitor_group) {
    params.auto_competitor_group = true;
  }

  if (state.compare_previous) {
    params.compare_previous = true;
  }

  if (state.competitor_group?.length) {
    params.competitor_group = dropdownsToString(state.competitor_group);
  }

  if (state.competitors?.length) {
    params.competitors = dropdownsToString(state.competitors);
  }

  if (state.competitor_limit) {
    params.competitor_limit = Math.min(state.competitor_limit, 100);
  }

  if (state.daily) {
    params.daily = true;
  }

  if (state.focus) {
    params.focus = state.focus;
  }

  if (state.hourly) {
    params.hourly = true;
  }

  if (state.keyword_groups?.length) {
    params.keyword_groups = dropdownsToString(state.keyword_groups);
  }

  if (state.keywords?.length) {
    params.keywords = dropdownsToString(state.keywords);
  }

  if (state.metrics?.length) {
    params.metrics = dropdownsToString(state.metrics);
  }

  if (state.targets?.length) {
    params.targets = dropdownsToString(state.targets);
  }

  if (state.time_zone) {
    params.time_zone = state.time_zone.value.toString();
  }

  if (state.top_words) {
    params.top_words = Math.min(state.top_words, 100);
  }

  if (state.type?.length) {
    params.type = dropdownsToString(state.type);
  }

  if (state.zero_coverage) {
    params.zero_coverage = true;
  }

  return params;
};

// UPDATE PRIOR END DATE
export const getPriorEndDate = (
  currentStart: string | null,
  currentEnd: string | null,
  priorStart: string | null
) => {
  if (!priorStart) return null;
  const priorEnd = new Date(priorStart);
  /* 
  Update end to be equal number of days from prior start as current
  start is from current end
   */
  if (currentStart && currentEnd) {
    const diff = getDateDiff(currentStart, currentEnd);
    priorEnd.setDate(priorEnd.getDate() + diff);
  }

  return priorEnd.toDateString();
};

export const getDateRangeString = (
  startDate: string | null | undefined,
  endDate: string | null | undefined
) => {
  const start = startDate ? new Date(startDate) : null;
  const end = endDate ? new Date(endDate) : null;

  return start || end
    ? `${start?.toLocaleDateString('en-US') || 'N/A'} - ${
        end?.toLocaleDateString('en-US') || 'N/A'
      }`
    : 'N/A';
};

export const getDarwinParams = (filters: DarwinFilters) => {
  return {
    agency_ids: filters.agency_ids.map(({ value }) => value).join(','),
    engine_ids: filters.engine_ids.map(({ value }) => value).join(','),
    start_date: new Date(filters.start_date).toLocaleDateString('en-CA'),
    end_date: new Date(filters.end_date).toLocaleDateString('en-CA'),
  };
};

export const getDateCategories = (
  startDate: string | Date,
  endDate: string | Date
) => {
  const categories: string[] = [];
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const end = typeof endDate === 'string' ? new Date(endDate) : endDate;

  if (start && end) {
    const diff = (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000);

    for (let i = diff; i >= 0; i--) {
      const currentDay = new Date(endDate);
      currentDay.setDate(currentDay.getDate() - i);
      categories.push(
        currentDay.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      );
    }
  }
  return categories;
};
