import React, { useMemo } from 'react';

// TYPES
import {
    DropdownOption,
    AlertFormState,
    AlertFormAction,
    AlertRecurrence,
    ALERT_RECURRENCE,
    KeywordsAlertOperator,
    ALERT_FORM_ACTION_TYPES,
    KEYWORDS_ALERT_OPERATORS,
} from '../../state';

// UI COMPONENTS
import Radio from '@publicismedia-ds/ui-radio';
import { ALERTS_TOOLTIPS } from '../../tooltips';
import Textbox from '@publicismedia-ds/ui-textbox';
import Fieldset from '@publicismedia-ds/ui-fieldset';
import Dropdown from '@publicismedia-ds/ui-dropdown';
import { Row, Column } from '@publicismedia-ds/ui-grid';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface KeywordsAlertProps {
    readOnly?: boolean;
    state: AlertFormState;
    dispatch: React.Dispatch<AlertFormAction>;
}

// FUNCTIONAL COMPONENT
const keywordsAlertOperatorOptions = KEYWORDS_ALERT_OPERATORS.reduce(
    (options: DropdownOption[], operator) => {
        options.push({
            label: operator,
            value: operator,
        });
        return options;
    },
    [{ label: ' ', value: '' }]
);

function KeywordsAlert({
    state,
    dispatch,
    readOnly = false,
}: KeywordsAlertProps) {
    // HANDLE ALERT OCCURRENCE SELECTION
    const onSetAlertOccurrence = (evt: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_ALERT_RECURRENCE,
            payload: evt.target.value as AlertRecurrence,
        });
    };

    // HANDLE COVERAGE OPERATOR SELCTION
    const onSetCoverageOperator = (selected: DropdownOption) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_OPERATOR,
            payload: selected.value as KeywordsAlertOperator,
        });
    };

    // HANDLE COVERAGE THRESHOLD INPUT
    const onSetCoverageThreshold = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_THRESHOLD,
            payload: isNaN(percentage) ? null : Math.min(percentage, 100),
        });
    };

    // HANDLE COVERAGE BASE VALUE INPUT
    const onSetCoverageBaseValue = (
        evt: React.ChangeEvent<HTMLInputElement>
    ) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_BASE_VALUE,
            payload: isNaN(percentage) ? null : Math.min(percentage, 100),
        });
    };

    // HANDLE COVERAGE CHANGE INPUT
    const onSetCoverageChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_COVERAGE_CHANGE,
            payload: isNaN(percentage) ? null : Math.min(percentage, 100),
        });
    };

    // HANDLE RANK OPERATOR SELCTION
    const onSetRankOperator = (selected: DropdownOption) => {
        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_RANK_OPERATOR,
            payload: selected.value as KeywordsAlertOperator,
        });
    };

    // HANDLE RANK THRESHOLD INPUT
    const onSetRankThreshold = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_RANK_THRESHOLD,
            payload: isNaN(percentage) ? null : Math.min(percentage, 10),
        });
    };

    // HANDLE RANK BASE VALUE INPUT
    const onSetRankBaseValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_RANK_BASE_VALUE,
            payload: isNaN(percentage) ? null : Math.min(percentage, 10),
        });
    };

    // HANDLE RANK CHANGE INPUT
    const onSetRankChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const percentage = parseInt(evt.target.value);

        dispatch({
            type: ALERT_FORM_ACTION_TYPES.SET_RANK_CHANGE,
            payload: isNaN(percentage) ? null : Math.min(percentage, 10),
        });
    };

    // CREATE ALERT
    const recurenceRadios = useMemo(() => {
        return ALERT_RECURRENCE.map((recurrence, index) => {
            return (
                <Radio
                    key={`keywords-alert-occurrence-${index}`}
                    name="time-period"
                    value={recurrence}
                    defaultChecked={state.recurrence === recurrence}
                    disabled={readOnly}
                    invert
                >
                    {recurrence[0].toUpperCase() + recurrence.slice(1)}
                </Radio>
            );
        });
    }, [state.recurrence]);

    return (
        <>
            <div className="keywords-alert-header">
                <h3>
                    Alert Coverage/Rank Changes{' '}
                    <span className="text-muted">(optional)</span>
                </h3>
            </div>
            <div className="form-row">
                <Fieldset
                    className="keywords-alert-time-period"
                    legend={
                        <CustomTooltip
                            content={ALERTS_TOOLTIPS.LOOKBACK_TOOLTIP}
                            align={TOOLTIP_ALIGN.LEFT}
                        >
                            Lookback Timeframe:
                        </CustomTooltip>
                    }
                    onChange={onSetAlertOccurrence}
                    layout="horizontal"
                    invert
                >
                    {recurenceRadios}
                </Fieldset>
            </div>
            <Row cols={3}>
                <Column>
                    <Dropdown
                        options={keywordsAlertOperatorOptions}
                        onChange={onSetCoverageOperator}
                        value={
                            state.coverage_operator
                                ? { label: '', value: state.coverage_operator }
                                : ''
                        }
                        required={
                            !!state.coverage_base_value ||
                            !!state.coverage_change ||
                            !!state.coverage_threshold
                        }
                        placeholder={readOnly ? 'N/A' : ''}
                        isSearchable={false}
                        disabled={readOnly}
                        invert
                    >
                        Coverage Operator:
                    </Dropdown>
                </Column>
                {state.coverage_operator === 'is at or higher than' ||
                state.coverage_operator === 'is lower than' ? (
                    <>
                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="100"
                                className="input-percentage"
                                onChange={onSetCoverageThreshold}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.coverage_threshold || ''}
                                error="Please enter a percentage 1 to 100"
                                disabled={readOnly}
                                required
                            >
                                Threshold:
                            </Textbox>
                        </Column>
                    </>
                ) : (
                    <>
                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="100"
                                className="input-percentage"
                                onChange={onSetCoverageBaseValue}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.coverage_base_value || ''}
                                required={
                                    !!state.coverage_operator ||
                                    !!state.coverage_change
                                }
                                error="Please enter a percentage 1 to 100"
                                disabled={readOnly}
                                invert
                            >
                                Base Value:
                            </Textbox>
                        </Column>

                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="100"
                                className="input-percentage"
                                onChange={onSetCoverageChange}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.coverage_change || ''}
                                required={
                                    !!state.coverage_operator ||
                                    !!state.coverage_base_value
                                }
                                error="Please enter a percentage 1 to 100"
                                disabled={readOnly}
                                invert
                            >
                                Changes:
                            </Textbox>
                        </Column>
                    </>
                )}
            </Row>
            <Row cols={3} className="border-bottom">
                <Column>
                    <Dropdown
                        placeholder={readOnly ? 'N/A' : ''}
                        options={keywordsAlertOperatorOptions}
                        onChange={onSetRankOperator}
                        value={
                            state.rank_operator
                                ? { label: '', value: state.rank_operator }
                                : ''
                        }
                        required={
                            !!state.rank_base_value ||
                            !!state.rank_change ||
                            !!state.rank_threshold
                        }
                        isSearchable={false}
                        disabled={readOnly}
                        invert
                    >
                        Rank Operator:
                    </Dropdown>
                </Column>
                {state.rank_operator === 'is at or higher than' ||
                state.rank_operator === 'is lower than' ? (
                    <>
                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="10"
                                onChange={onSetRankThreshold}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.rank_threshold || ''}
                                required
                                error="Please enter a value 1 to 10"
                                disabled={readOnly}
                                invert
                            >
                                Threshold:
                            </Textbox>
                        </Column>
                    </>
                ) : (
                    <>
                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="10"
                                onChange={onSetRankBaseValue}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.rank_base_value || ''}
                                required={
                                    !!state.rank_operator || !!state.rank_change
                                }
                                error="Please enter a value 1 to 10"
                                disabled={readOnly}
                                invert
                            >
                                Base Value:
                            </Textbox>
                        </Column>

                        <Column>
                            <Textbox
                                type="number"
                                min="1"
                                max="10"
                                onChange={onSetRankChange}
                                placeholder={readOnly ? 'N/A' : ''}
                                value={state.rank_change || ''}
                                required={
                                    !!state.rank_operator ||
                                    !!state.rank_base_value
                                }
                                error="Please enter a value 1 to 10"
                                disabled={readOnly}
                                invert
                            >
                                Changes:
                            </Textbox>
                        </Column>
                    </>
                )}
            </Row>
        </>
    );
}

export default KeywordsAlert;
