import './Brand-Monitoring.scss';

import { useEffect, useState } from 'react';
import { useActions, useAsyncWrapper } from '../../hooks';

// CONSTANTS
import { ADS } from '../../constants';

// API REQUESTS
import {
    getStudyList,
    getAlertById,
    getAlertList,
    updateAlertStatus,
} from '../../api';

// TYPES
import {
    StatusType,
    AlertsTableItem,
    UpdateAlertStatusBody,
} from '../../state';
import { DEFAULT_STATUS_MODAL_STATE } from '../../types';

// UI COMPONENTS IMPORTS
import AlertsTable from './Alerts-Table';
import WarningModal from '../../components/Warning-Modal/Warning-Modal';
import PageContainer from '../../components/Page-Container/Page-Container';

// DEFAULT AD TYPES
const DEFAULT_AD_TYPES = [...ADS];

// FUNCTION COMPONENT (BRAND MONITORING)
function BrandMonitoring() {
    const wrapper = useAsyncWrapper();

    // REDUX ACTIONS
    const { setIsLoading, alertError, alertSuccess } = useActions();

    // TABLE STATE
    const [alertsList, setAlertsList] = useState<AlertsTableItem[]>([]);

    // MODAL STATE
    const [showStatusWarningModal, setShowStatusWarningModal] = useState(
        DEFAULT_STATUS_MODAL_STATE
    );

    // FETCH AND STORE USER'S ALERTS LIST
    const loadAlerts = async () => {
        const alerts = await getAlertList();

        return alerts || [];
    };

    // FETCH AND STORE STUDIES
    const loadStudyList = async () => {
        const studies = await getStudyList();
        return studies;
    };

    // LOAD PAGE DATA
    const loadPageData = wrapper(async () => {
        await Promise.all([loadStudyList(), loadAlerts()]).then(
            ([studies, alerts]) => {
                // FORMAT ALERTS FOR TABLE
                const formattedAlerts: AlertsTableItem[] = alerts.map(
                    (alert) => {
                        const studyIndex = studies.findIndex(
                            (study) => study.id === alert.study_id
                        );
                        return {
                            ...alert,
                            ad_type:
                                alert?.ad_type?.split(',') || DEFAULT_AD_TYPES,
                            additional_email_ids: alert.additional_email_ids
                                ? alert.additional_email_ids.split(',')
                                : [],
                            study_name: studies[studyIndex]?.name || '',
                        };
                    }
                );

                setAlertsList(formattedAlerts);
            }
        );
    });

    // LOAD STUDIES ON INITIAL PAGE LOAD
    useEffect(() => {
        loadPageData();
    }, []);

    // HANDLE UPDATING ALERT STATUS
    const onUpdateStatus = (id: number, currentStatus: StatusType) => {
        const newStatus = currentStatus === 'Active' ? 'Suspended' : 'Active';
        const statusAction = newStatus === 'Active' ? 'Activate' : 'Suspend';

        const onConfirm = async () => {
            setIsLoading(true);
            try {
                // GET CURRENT ALERT DETAILS
                const alertDetails = await getAlertById(id.toString());

                const body: UpdateAlertStatusBody = {
                    studyId: alertDetails.study_id,
                    reportType: alertDetails.report_type,
                    status: newStatus,
                };

                if (alertDetails.report_type === 'Keywords') {
                    body.keywords = alertDetails.keywords?.split(',');
                }

                // SEND PUT REQUEST
                const data = await updateAlertStatus(id, body);

                if (data.message) {
                    // UPDATE ALERT IN ALERTS LIST IF SUCCESSFULL
                    const alertIndex = alertsList.findIndex(
                        (alert) => alert.id === id
                    );

                    const updatedAlert = { ...alertsList[alertIndex] };
                    updatedAlert.status = newStatus;

                    // UPDATE STORED ALERT
                    setAlertsList((prev) => {
                        prev[alertIndex] = updatedAlert;
                        return [...prev];
                    });

                    alertSuccess(data.message);
                }
            } catch (error: any) {
                alertError(
                    error.response?.data?.message ||
                        error.response?.data?.errorMessage ||
                        error.response?.data?.error_message ||
                        error.message
                );
            } finally {
                setIsLoading(false);
            }
        };

        setShowStatusWarningModal({
            display: true,
            action: statusAction,
            onConfirm,
        });
    };

    // JSX
    return (
        <PageContainer title="brand monitoring">
            <div className="alerts-data-table">
                <AlertsTable
                    data={alertsList}
                    onUpdateStatus={onUpdateStatus}
                />
            </div>
            {/* === MODALS === */}
            {showStatusWarningModal.display &&
                showStatusWarningModal.onConfirm && (
                    <WarningModal
                        header={`${showStatusWarningModal.action} alert:`}
                        content="Are you sure want to continue?"
                        onClose={() =>
                            setShowStatusWarningModal(
                                DEFAULT_STATUS_MODAL_STATE
                            )
                        }
                        onConfirm={showStatusWarningModal.onConfirm}
                    />
                )}
        </PageContainer>
    );
}

export default BrandMonitoring;
