import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useActions, useAppSelector } from '../hooks/redux-hooks';

// CONSTANTS
import { ROUTES } from '../router';

// CONSTANTS
import { UNAUTHORIZED } from '../constants';

// FUNCTIONAL COMPONENT (ADMIN ROUTES)
function AdminRoutes() {
    const { alertError } = useActions();
    const isAdmin = useAppSelector(({ auth }) => auth.user?.role === 'Admin');

    // DIPLAY ERROR MESSAGE FOR UNAUTHORIZED USER
    useEffect(() => {
        if (isAdmin) {
            return;
        }
        alertError(UNAUTHORIZED);
    }, [isAdmin, alertError]);

    // DISPLAY RESOUCE FOR AUTHORIZED USER OR NAVIGATE TO HOME
    return isAdmin ? <Outlet /> : <Navigate to={ROUTES.HOME} />;
}

export default AdminRoutes;
