import produce from 'immer';
import {
    parseCompetitorGroups,
    parseKeywordGroups,
    parseTargets,
} from '../../../utils';
import { formatReportFilters } from '../../../utils/reports-utils/load-existing-report';
import {
    DEFAULT_REPORT_FILTERS_STATE,
    ReportFiltersAction,
    ReportFiltersState,
    REPORT_FILTER_ACTION_TYPES,
} from '../models';

export const reportFiltersReducer = produce(
    (state: ReportFiltersState, action: ReportFiltersAction) => {
        switch (action.type) {
            case REPORT_FILTER_ACTION_TYPES.LOAD_STUDY_OPTIONS: {
                const {
                    keyword_group_details,
                    competitor_group_details,
                    locale_details,
                } = action.value;
                state = { ...DEFAULT_REPORT_FILTERS_STATE };
                const [keywordGroupOptions, keywordOptions] =
                    parseKeywordGroups(keyword_group_details);
                const [competitorGroupOptions, competitorOptions] =
                    parseCompetitorGroups(competitor_group_details);
                // CREATE STUDY'S KEYWORD GROUP DROPDOWN OPTIONS
                state.keywordGroupOptions = [...keywordGroupOptions];

                // CREATE STUDY'S KEYWORD DROPDOWN OPTIONS
                state.keywordOptions = [...keywordOptions];

                // CREATE STUDY'S COMPETITOR GROUP DROPDOWN OPTIONS
                state.competitorGroupOptions = [...competitorGroupOptions];

                // CREATE STUDY'S COMPETITOR DROPDOWN OPTIONS
                state.competitorOptions = [...competitorOptions];

                // CREATE STUDY'S TARGET DROPDOWN OPTIONS
                state.targetOptions = parseTargets(locale_details)[0];

                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.SET_KEYWORDS:
                state.keywords = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_KEYWORD_GROUPS:
                state.keyword_groups = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_COMPETITORS:
                state.competitors = action.value;

                if (state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_GROUP:
                state.competitor_group = action.value;

                if (state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_TARGETS:
                state.targets = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_METRICS:
                state.metrics = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_TYPE:
                state.type = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_TIME_ZONE:
                state.time_zone = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.TOGGLE_ZERO_COVERAGE: {
                const isSelected = !state.zero_coverage;
                state.zero_coverage = isSelected;

                if (isSelected && state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.TOGGLE_AUTO_COMPETITOR_GROUP: {
                const isSelected = !state.auto_competitor_group;
                state.auto_competitor_group = isSelected;

                state.competitor_limit = null;
                state.competitor_group = [];
                state.competitors = [];
                state.zero_coverage = false;
                state.hourly = false;
                state.daily = false;
                state.compare_previous = false;

                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.TOGGLE_COMPARE_PREVIOUS: {
                const isSelected = !state.compare_previous;
                state.compare_previous = isSelected;

                if (isSelected && state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.TOGGLE_DAILY: {
                const isSelected = !state.daily;
                state.daily = isSelected;

                if (isSelected && state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.TOGGLE_HOURLY: {
                const isSelected = !state.hourly;
                state.hourly = isSelected;

                if (isSelected && state.auto_competitor_group) {
                    state.auto_competitor_group = false;
                }
                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.SET_COMPETITOR_LIMIT:
                state.competitor_limit = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_TOP_WORDS:
                state.top_words = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.SET_FOCUS:
                state.focus = action.value;
                return state;

            case REPORT_FILTER_ACTION_TYPES.RESET_FILTERS: {
                const keywordOptions = state.keywordOptions
                    ? [...state.keywordOptions]
                    : [];
                const keywordGroupOtions = state.keywordGroupOptions
                    ? [...state.keywordGroupOptions]
                    : [];
                const competitorOptions = state.competitorOptions
                    ? [...state.competitorOptions]
                    : [];
                const competitorGroupOptions = state.competitorGroupOptions
                    ? [...state.competitorGroupOptions]
                    : [];
                const targetOptions = state.targetOptions
                    ? [...state.targetOptions]
                    : [];

                state = { ...DEFAULT_REPORT_FILTERS_STATE };
                state.keywordOptions = keywordOptions;
                state.keywordGroupOptions = keywordGroupOtions;
                state.competitorOptions = competitorOptions;
                state.competitorGroupOptions = competitorGroupOptions;
                state.targetOptions = targetOptions;
                return state;
            }

            case REPORT_FILTER_ACTION_TYPES.LOAD_REPORT_FILTERS: {
                const { report, study } = action.value;
                state = formatReportFilters(report, study);

                return state;
            }

            default:
                return state;
        }
    },
    DEFAULT_REPORT_FILTERS_STATE
);
