import AXIOS from 'axios';
import { store } from '../state';

type AppEnv =
    | 'devgcp'
    | 'prodgcp'
    | 'devaws'
    | 'prodaws'
    | 'development'
    | 'production';

enum API_HOSTNAMES {
    DEV_AWS = 'devapi.benchtools.net',
    PROD_AWS = 'api.benchtools.net',
    DEV_GCP = 'dev.pfx-bt-api.com',
    PROD_GCP = 'pfx-bt-api.com',
}

const getHostname = () => {
    const appEnv = process.env.REACT_APP_ENV as AppEnv;

    switch (appEnv) {
        case 'development':
            return API_HOSTNAMES.DEV_GCP;

        case 'production':
            return API_HOSTNAMES.PROD_GCP;

        case 'devaws':
            return API_HOSTNAMES.DEV_AWS;

        case 'prodaws':
            return API_HOSTNAMES.PROD_AWS;

        case 'devgcp':
            return API_HOSTNAMES.DEV_GCP;

        case 'prodgcp':
            return API_HOSTNAMES.PROD_GCP;

        default:
            return API_HOSTNAMES.DEV_GCP;
    }
};

const hostname = getHostname();

const axios = AXIOS.create({
    baseURL: `https://${hostname}/`,
});

axios.interceptors.request.use((config) => {
    const userToken = store.getState().auth.user?.token;
    if (userToken) {
        config.headers = {
            Authorization: `Bearer ${userToken}`,
        };
    }
    return config;
});

export { axios };
