import { useEffect, useMemo, useState } from 'react';

// TYPES
import { StudyActivityLog, StudyListItem } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import SearchFilter from '@publicismedia-ds/ui-search-filter';

// COMPONENT PROPS
interface StudiesActivityTableProps {
    logs: StudyActivityLog[];
    studyList: StudyListItem[];
}

// FUNCTIONAL COMPONENT (STUDIES ACTIVITY LOGS DATA TABLE - ADMIN ONLY)
function StudiesActivityTable({ logs, studyList }: StudiesActivityTableProps) {
    // ACTIVITY LOGS DATA PROVIDER
    const [logsProvider, setLogsProvider] = useState<any>([]);

    // CONVERT STUDY LIST ARRAY TO OBJECT (MEMOIZE FOR PERFORMANCE)
    const studies = useMemo(() => {
        return studyList.reduce(
            (allStudies: { [key: string]: StudyListItem }, study) => {
                allStudies[study.id] = { ...study };
                return allStudies;
            },
            {}
        );
    }, [studyList]);

    // STORE/UPDATE DATA PROVIDER ON LOG CHANGES
    useEffect(() => {
        // CONVERT STUDY_IDS TO STRINGS (NOT SEARCHABLE AS NUMBERS)
        const data = logs.map(({ studyId, ...rest }) => ({
            ...rest,
            studyId: studyId.toString(),
            studyName: studies[studyId]?.name || 'N/A',
        }));

        // SET DATA PROVIDER
        setLogsProvider(new DataProvider({ data }));
    }, [logs, studies]);

    // HANDLE SEARCH FILTER CHANGES
    const onSearchChange = (text: string) => {
        logsProvider.search(text, ['id']);
    };

    return (
        <DataTable
            data={logsProvider}
            bindKey="id"
            above={
                <div>
                    <SearchFilter
                        className="search-studies-input"
                        onSearchChange={onSearchChange}
                    />
                </div>
            }
        >
            <ColumnTemplate bind="studyId" isSortable>
                Study ID
            </ColumnTemplate>
            <ColumnTemplate bind="studyName" isSortable>
                Study Name
            </ColumnTemplate>
            <ColumnTemplate bind="updatedBy" isSortable>
                Updated By
            </ColumnTemplate>
            <ColumnTemplate bind="eventType" isSortable>
                Event Type
            </ColumnTemplate>
            <ColumnTemplate bind="eventDate" isSortable>
                Event Date
            </ColumnTemplate>
            <ColumnTemplate bind="columnName" align="left" isSortable>
                Column Name
            </ColumnTemplate>
            <ColumnTemplate bind="oldValue" align="left">
                Prior Value
            </ColumnTemplate>
            <ColumnTemplate bind="newValue" align="left">
                Updated Value
            </ColumnTemplate>
        </DataTable>
    );
}

export default StudiesActivityTable;
