import './Tooltips-Button.scss';

import { useActions, useAppSelector } from '../../hooks';

// UI COMPONENTS
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// TOOLTIPS DISABLED MESSAGE
const TOOLTIPS_DISABLED_TITLE = 'Display available tooltip icons.';

// TOOLTIS ENABLED MESSAGE
const TOOLTIPS_ENABLED_TITLE = 'Disable available tooltips.';

function TooltipsButton() {
    // REDUX STATE
    const { enabled } = useAppSelector(({ tooltips }) => tooltips);
    const { user } = useAppSelector(({ auth }) => auth);

    // REDUX ACTIONS
    const { toggleTooltips } = useActions();

    const tooltipTitle = enabled
        ? TOOLTIPS_ENABLED_TITLE
        : TOOLTIPS_DISABLED_TITLE;

    return user ? (
        <div className="tooltips-button-container">
            <div className="tooltips-button">
                <CustomTooltip
                    content={tooltipTitle}
                    displayAlways={true}
                    align={TOOLTIP_ALIGN.RIGHT}
                >
                    <button onClick={() => toggleTooltips()}>
                        {enabled ? 'Hide' : 'Show'} Tooltips
                    </button>
                </CustomTooltip>
            </div>
        </div>
    ) : null;
}

export default TooltipsButton;
