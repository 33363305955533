import './Onboarding.scss';

import { useEffect, useState } from 'react';
import { useActions, useAsyncWrapper } from '../../hooks';

// API REQUESTS
import {
  addUser,
  addClient,
  addAccount,
  getAccounts,
  exportActiveUsers,
} from '../../api';

// TYPES
import {
  AccountDetails,
  ClientFormState,
  AccountFormState,
  UserOnboardingState,
} from '../../state';

// UI COMPONENTS
import Button from '@publicismedia-ds/ui-button';
import TabGroup from '@publicismedia-ds/ui-tabs';
import PageContainer from '../../components/Page-Container/Page-Container';
import ClientOnboardingForm from '../../components/Onboarding-Forms/Client-Onboarding-Form';
import AccountOnboardingForm from '../../components/Onboarding-Forms/Account-Onboarding-Form';
import UserOnboardingForm from '../../components/Onboarding-Forms/User-Onboarding-Form';

// FUNCTIONAL COMPONENT (ONBOARDING: USER, ACCOUNT, AND/OR CLIENT)
function Onboarding() {
  const wrapper = useAsyncWrapper();

  // REDUX ACTIONS
  const { alertSuccess } = useActions();

  // CURRENT SELECTED TAB STATE
  const [selectedTab, setSelectedTab] = useState(0);
  const [accounts, setAccounts] = useState<AccountDetails[]>([]);

  // FETCH AND STORE ACCOUNT OPTIONS ON INITIAL RENDER
  const loadAccountOptions = async function () {
    const data = await getAccounts();
    if (data.error_message) {
      throw new Error(data.error_message);
    }
    setAccounts(data.accounts);
  };

  // FETCH AND STORE ACCOUNTS LIST ON INITIAL RENDER
  useEffect(() => {
    wrapper(loadAccountOptions)();
  }, []);

  // HANDLE SUBMIT NEW ACCOUNT
  const onSubmitAccount = async (formState: AccountFormState) => {
    const data = await addAccount(formState);
    if (data.errorMessage) {
      throw new Error(data.errorMessage);
    }
    alertSuccess(data.message || 'Successfully added account.');
    await loadAccountOptions();
  };

  // HANDLE SUBMIT NEW CLIENT
  const onSubmitClient = async (formState: ClientFormState) => {
    const data = await addClient(formState);
    if (data.errorMessage) {
      throw new Error(data.errorMessage);
    }
    alertSuccess(data.message || 'Successfully added client');
    await loadAccountOptions();
  };

  // HANDLE SUBMIT NEW USER
  const onSubmitUser = async (formState: UserOnboardingState) => {
    const data = await addUser(formState);
    if (data.error_message) {
      throw new Error(data.error_message);
    }
    alertSuccess(data.message || 'Successfully added user');
    await loadAccountOptions();
  };

  // HANDLE EXPORTING ACTIVE USERS
  const onExportActiveUsers = wrapper(async () => {
    try {
      // FETCH AND SAVE ACTIVE USERS XLSX FILE
      await exportActiveUsers();
    } catch (error: any) {
      throw new Error('Unable to export active users at this time.');
    }
  });

  // JSX
  return (
    <PageContainer title="onboarding">
      <div className="onboarding-container">
        <TabGroup
          className="onboarding-tab-group"
          tabs={[
            {
              label: 'Account',
              disabled: false,
              panelId: 'account-onboarding',
            },
            {
              label: 'Client',
              disabled: false,
              panelId: 'client-onboarding',
            },
            {
              label: 'User',
              disabled: false,
              panelId: 'user-onboarding',
            },
          ]}
          onSelect={(tabIndex: number) => {
            setSelectedTab(tabIndex);
          }}
          selectedTab={selectedTab}
          invert={true}
        >
          {/* --- ACCOUNT ONBOARDING --- */}
          <div
            id="account-onboarding"
            role="tabpanel"
            aria-labelledby="Account Onboarding"
          >
            <AccountOnboardingForm onSubmit={onSubmitAccount} />
          </div>

          {/* --- CLIENT ONBOARDING --- */}
          <div
            id="client-onboarding"
            role="tabpanel"
            aria-labelledby="Client Onboarding"
          >
            <ClientOnboardingForm
              accounts={accounts}
              onSubmit={onSubmitClient}
            />
          </div>

          {/* --- USER ONBOARDING --- */}
          <div
            id="user-onboarding"
            role="tabpanel"
            aria-labelledby="User Onboarding"
          >
            <div className="user-onboarding-container">
              <div>
                <Button
                  color="brand-2"
                  onClick={onExportActiveUsers}
                  style={{
                    float: 'right',
                    marginTop: '-3rem',
                  }}
                >
                  Export Users
                </Button>
              </div>
              <UserOnboardingForm accounts={accounts} onSubmit={onSubmitUser} />
            </div>
          </div>
        </TabGroup>
      </div>
    </PageContainer>
  );
}

export default Onboarding;
