import { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useActions, useUserPermissions } from '../hooks';

// CONSTANTS
import { UNAUTHORIZED } from '../constants';

// FUNCTIONAL COMPONENT
function PrivlagedRoutes() {
    const { alertError } = useActions();
    const { isPrivileged } = useUserPermissions();

    // DISPLAY ERROR MESSAGE IF UNAUTHORIZED USER
    useEffect(() => {
        if (isPrivileged) {
            return;
        }
        alertError(UNAUTHORIZED);
    }, [isPrivileged, alertError]);

    // DISPLAY RESOURCE IF AUTHORIZED OR REDIRECT TO HOME
    return isPrivileged ? <Outlet /> : <Navigate to="/" />;
}

export default PrivlagedRoutes;
