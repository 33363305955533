import _ from 'lodash';

// TYPES
import { ApexOptions } from 'apexcharts';

// UTIL

// UI COMPONENTS
import React from 'react';
import ReactApexChart from 'react-apexcharts';

// COMPONENT PROPS
interface RadialBarProps {
  className?: string;
  data: ApexNonAxisChartSeries;
  labels?: string[];
  options?: ApexOptions;
  height?: string | number;
  width?: string | number;
  title?: string;
}

export const DEFAULT_COLORS = [
  '#2764b0',
  '#b48a53',
  '#81cacf',
  '#419fa1',
  '#e98841',
  '#e3d830',
  '#a6c46f',
  '#57b966',
  '#894c7b',
  '#be5873',
  '#7f7f7f',
  '#c3c3c3',
];

// FUNCTIONAL COMPONENT (RADIAL BAR CHART)
function RadialBar({
  className,
  data,
  options,
  labels = [],
  height,
  width,
  title,
}: RadialBarProps) {
  const defaultOptions: ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },
    title: {
      text: title,
      style: {
        fontWeight: 600,
        fontSize: '1rem',
      },
      offsetY: 35,
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '2.5rem',
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#f1f1f1',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 4,
            left: 0,
            color: '#d1d1d1',
            opacity: 1,
            blur: 4,
          },
        },
        dataLabels: {
          name: {
            show: labels.length > 0,
            offsetY: -10,
            color: '#000',
            fontSize: '1rem',
            fontWeight: '100',
          },
          value: {
            show: false,
            offsetY: 0,
            fontSize: '22px',
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: [...DEFAULT_COLORS],
    },
    labels,
  };

  return (
    <ReactApexChart
      className={className}
      series={data}
      options={_.merge(defaultOptions, options)}
      type="radialBar"
      height={height || 350}
      width={width}
    />
  );
}

export default RadialBar;
