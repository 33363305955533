import { AdhocReportState, DropdownOption, StudyDetails } from '../../models';

export enum DASHBOARD_FILTER_ACTION_TYPES {
  SET_STUDY = 'set_study',
  SET_START_DATE = 'set_start_date',
  SET_END_DATE = 'set_end_date',
  SET_PRIOR_START = 'set_prior_start',
  SET_PRIOR_END = 'set_prior_end',
  SET_COMPETITOR_GROUPS = 'set_competitor_groups',
  SET_COMPETITORS = 'set_competitors',
  SET_KEYWORD_GROUPS = 'set_keyword_groups',
  SET_KEYWORDS = 'set_keywords',
  LOAD_STATE = 'load_state',
  RESET_STATE = 'reset_state',
}

export interface SetDashboardStudyAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_STUDY;
  payload: StudyDetails;
}

export interface SetDashboardStartDateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_START_DATE;
  payload: string; // DateString
}

export interface SetDashboardEndDateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_END_DATE;
  payload: string; // DateString
}

export interface SetDashboardPriorStartDateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_PRIOR_START;
  payload: string; // DateString
}

export interface SetDashboardPriorEndDateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_PRIOR_END;
  payload: string; // DateString
}

export interface SetDashboardCompetitorGroupsAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_COMPETITOR_GROUPS;
  payload: DropdownOption[];
}

export interface SetDashboardCompetitorsAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_COMPETITORS;
  payload: DropdownOption[];
}

export interface SetDashboardKeywordGroupsAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_KEYWORD_GROUPS;
  payload: DropdownOption[];
}

export interface SetDashboardKeywordsAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.SET_KEYWORDS;
  payload: DropdownOption[];
}

export interface LoadDashboardStateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.LOAD_STATE;
  payload: AdhocReportState;
}

export interface ResetDashboardStateAction {
  type: DASHBOARD_FILTER_ACTION_TYPES.RESET_STATE;
}

export type DashboardReportAction =
  | SetDashboardStudyAction
  | SetDashboardStartDateAction
  | SetDashboardEndDateAction
  | SetDashboardCompetitorGroupsAction
  | SetDashboardCompetitorsAction
  | SetDashboardKeywordGroupsAction
  | SetDashboardKeywordsAction
  | LoadDashboardStateAction
  | ResetDashboardStateAction;
