import { useActions } from './redux-hooks';

// CONSTANTS
import { DEFAULT_API_ERROR } from '../constants';

export const useAsyncWrapper = () => {
    // REDUX ACTIONS
    const { setIsLoading, alertError } = useActions();

    return (func: (...args: any[]) => Promise<void>, userLoader = true) => {
        return async (...args: any[]) => {
            // REDUX ACTIONS

            if (userLoader) {
                setIsLoading(true);
            }

            try {
                await func(...args);
            } catch (error: any) {
                alertError(
                    error.response?.data?.errorMessage ||
                        error.response?.data?.error_message ||
                        error.response?.data?.msg ||
                        error.response?.data?.message ||
                        error.message ||
                        DEFAULT_API_ERROR
                );
            } finally {
                if (userLoader) {
                    setIsLoading(false);
                }
            }
        };
    };
};
