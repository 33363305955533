import { useAsyncWrapper } from '../../hooks';
import React, { memo, useEffect, useState } from 'react';

// CONSTANTS
import { REPORT_TOOLTIPS } from '../../tooltips';

// API REQUESTS
import { getStudyById, getStudyList } from '../../api';

// TYPES
import {
  ReportType,
  ScheduledReport,
  FILE_TYPE_OPTIONS,
  ReportDownloadType,
  REPORT_TYPE_OPTIONS,
} from '../../types';
import {
  DropdownOption,
  ReportFormState,
  ReportOccurrence,
  ReportFormAction,
  REPORT_OCURRENCE,
  REPORT_FORM_ACTION_TYPES,
} from '../../state';

// UI COMPONENT IMPORTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import TextList from '../Pmds-Text-List/Pmds-Text-List';
import DatePicker from '@publicismedia-ds/ui-datepicker';
import Grid, { Row, Column } from '@publicismedia-ds/ui-grid';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// PREVIOUS DAY
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

// COMPONENT PROPS
interface ReportFormBaseProps {
  report?: ScheduledReport;
  onDemand?: boolean;
  state: ReportFormState;
  dispatch: React.Dispatch<ReportFormAction>;
  isAutoCompetitor?: boolean;
  readOnly?: boolean;
}

// FUNCTIONAL COMPONENT (REPORT BASE FORM)
function ReportFormBase({
  state,
  report,
  dispatch,
  onDemand,
  isAutoCompetitor,
  readOnly = false,
}: ReportFormBaseProps) {
  // HOOKS
  const wrapper = useAsyncWrapper();

  const [studyOptions, setStudyOptions] = useState<DropdownOption[]>([]);

  // MIN START DATE
  const minStartDate = state.study?.created
    ? new Date(state.study.created)
    : new Date();

  // MAX END DATE
  const maxEndDate = onDemand ? yesterday : '';

  // FETCH AND STORE REPORT DATA
  const loadReport = async function (reportData: ScheduledReport) {
    const study = await getStudyById(reportData.study_id);

    if (!study) {
      return;
    }

    dispatch({
      type: REPORT_FORM_ACTION_TYPES.LOAD_EXISTING_REPORT,
      value: { report: reportData, study },
    });
  };

  const loadStudyOptions = async () => {
    const studies = await getStudyList();

    const options = [];

    // DOWNLOADING REPORT ON-DEMAND OR VIEWING (ALL STUDIES)
    if (onDemand || readOnly) {
      for (const study of studies) {
        options.push({
          label: `${study.id} - ${study.name}`,
          value: study.id,
        });
      }
    } else {
      // SCHEDULING REPORT (ONLY ACTIVE STUDIES)
      for (const study of studies) {
        if (study.status === 'Active') {
          options.push({
            label: `${study.id} - ${study.name}`,
            value: study.id,
          });
        }
      }
    }

    setStudyOptions(options);
  };

  // FETCH AND STORE STUDIES
  const loadPageData = wrapper(async () => {
    if (report) {
      await Promise.all([loadStudyOptions(), loadReport(report)]);
    } else {
      await loadStudyOptions();
    }
  });

  useEffect(() => {
    loadPageData();
  }, [onDemand]);

  // LOAD REPORT DATA ON INITIAL PAGE LOAD (IF PRESENT)
  useEffect(() => {
    if (!report?.study_id) {
      return;
    }
    loadReport(report);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.study_id]);

  // SET DEFAULT START/END DATES ON INITIAL
  useEffect(() => {
    if (onDemand) {
      dispatch({
        type: REPORT_FORM_ACTION_TYPES.SET_START_DATE,
        value: new Date(yesterday.getTime()),
      });
      dispatch({
        type: REPORT_FORM_ACTION_TYPES.SET_END_DATE,
        value: new Date(yesterday.getTime()),
      });
    } else {
      dispatch({
        type: REPORT_FORM_ACTION_TYPES.SET_START_DATE,
        value: new Date(),
      });
    }
  }, [dispatch, onDemand]);

  // SET EXPORTED FILE TYPE OPTION TO XLSX IF AUTO_COMPETITOR_GROUP SELECETED
  useEffect(() => {
    if (isAutoCompetitor) {
      dispatch({
        type: REPORT_FORM_ACTION_TYPES.SET_FILE_TYPE,
        value: 'xlsx',
      });
    }
  }, [isAutoCompetitor]);

  // HANDLE REPORT TYPE SELECTION
  const onSelectReportType = (selected: DropdownOption) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.SET_REPORT_TYPE,
      value: selected.value as ReportType,
    });
  };

  // HANDLE STUDY SELECTION
  const onSelectStudy = wrapper(async (selected: DropdownOption) => {
    const studyId = selected.value;

    const foundStudy = await getStudyById(studyId);

    if (!foundStudy) {
      return;
    }

    // UPDATE CURRENTLY SELECTED STUDY
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.SET_STUDY,
      value: foundStudy,
    });
  });

  // HANDLE START DATE SELECTION
  const onSelectStartDate = (selectedDate: Date) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.SET_START_DATE,
      value: selectedDate,
    });
  };

  // HANDLE END DATE SELECTION
  const onSelectEndDate = (selectedDate: Date) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.SET_END_DATE,
      value: selectedDate,
    });
  };

  // HANDLE REPORT OCCURRENCE SELECTION
  const onSelectOccurrence = (selected: DropdownOption) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.SET_OCCURRENCE,
      value: selected.value as ReportOccurrence,
    });
  };

  // HANDLE ADD EMAIL RECIPIENT
  const onAddEmailRecipient = (email: string) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.ADD_EMAIL_RECIPIENT,
      value: email,
    });
  };

  // HANDLE REMOVE EMAIL RECIPIENT
  const onRemoveEmailRecipient = (email: string) => {
    dispatch({
      type: REPORT_FORM_ACTION_TYPES.REMOVE_EMAIL_RECIPIENT,
      value: email,
    });
  };

  // EXPORTED FILE TYPE OPTIONS
  const fileTypeOptions = isAutoCompetitor
    ? FILE_TYPE_OPTIONS.filter((fileType) => fileType.value === 'xlsx')
    : FILE_TYPE_OPTIONS;

  // JSX
  return (
    <div className="reports-content">
      {/* <===== REQUIRED =====> */}
      <Grid>
        <Row className="cols-3">
          <Column>
            {/* --- REPORT TYPE --- */}
            <Dropdown
              options={REPORT_TYPE_OPTIONS}
              value={
                REPORT_TYPE_OPTIONS.find(
                  ({ value }) => value === state.report_type
                ) || {
                  label: state.report_type,
                  value: state.report_type,
                }
              }
              onChange={onSelectReportType}
              className="report-choice"
              isSearchable={false}
              error="Please select report type."
              disabled={!!report}
              required
              invert
            >
              <CustomTooltip
                content={REPORT_TOOLTIPS.REPORT_TYPE}
                align={TOOLTIP_ALIGN.LEFT}
                maxWidth="400px"
              >
                Report Type:
              </CustomTooltip>
            </Dropdown>
          </Column>
          <Column>
            {/* --- STUDY --- */}
            <Dropdown
              className="dropdown-study"
              options={studyOptions}
              value={{ label: '', value: state.study?.id || '' }}
              onChange={onSelectStudy}
              placeholder="Search for or select a study"
              error="Please select a study"
              disabled={!!report}
              required
              invert
            >
              Study:
            </Dropdown>
          </Column>
          <Column>
            {/* --- FILE TYPE --- */}
            <Dropdown
              className="download-file-type"
              options={fileTypeOptions}
              value={{ label: '', value: state.file_type }}
              onChange={(option: ReportDownloadType) => {
                dispatch({
                  type: REPORT_FORM_ACTION_TYPES.SET_FILE_TYPE,
                  value: option.value,
                });
              }}
              error="Please select a file type"
              isSearchable={false}
              disabled={readOnly}
              required
              invert
            >
              <CustomTooltip
                content={REPORT_TOOLTIPS.FILE_TYPE}
                align={TOOLTIP_ALIGN.CENTER}
              >
                File Type:
              </CustomTooltip>
            </Dropdown>
          </Column>
          <Column>
            {/* --- DATE --- */}
            <DatePicker
              value={state.start_date}
              onChange={onSelectStartDate}
              minDate={minStartDate}
              maxDate={maxEndDate}
              error="Please select a date"
              disabled={!!report}
              required
              invert
            >
              Start Date:
            </DatePicker>
          </Column>
          <Column>
            <DatePicker
              value={state.end_date}
              onChange={onSelectEndDate}
              minDate={state.start_date}
              maxDate={maxEndDate}
              error="Please select a date"
              disabled={readOnly}
              required={onDemand}
              invert
            >
              End Date:
            </DatePicker>
          </Column>
          <Column>
            {/* REPORT OCURRENCE - SCHEDULED REPORTS ONLY */}
            {!onDemand && (
              <>
                <Dropdown
                  className="dropdown-occurrence"
                  options={REPORT_OCURRENCE.map((option) => {
                    return {
                      label: option,
                      value: option,
                    };
                  })}
                  value={{
                    label: '',
                    value: state.occurrence,
                  }}
                  onChange={onSelectOccurrence}
                  error="Please select report recurrence"
                  placeholder="Report Occurrence"
                  disabled={readOnly}
                  required
                  invert
                >
                  <CustomTooltip
                    content={REPORT_TOOLTIPS.REPORT_OCCURRENCE}
                    align={TOOLTIP_ALIGN.RIGHT}
                  >
                    Report Occurrence:
                  </CustomTooltip>
                </Dropdown>
              </>
            )}
          </Column>
        </Row>
        {/* ADDITIONAL EMAIL RECIPIENTS */}
        {!onDemand && (
          <Row cols={3}>
            <Column>
              <TextList
                onAdd={onAddEmailRecipient}
                onRemove={onRemoveEmailRecipient}
                values={state.email_recipients}
                label="Additional Email Recipients:"
                disabled={readOnly}
                invert
              />
            </Column>
          </Row>
        )}
      </Grid>
    </div>
  );
}

export default memo(ReportFormBase);
