import { AdsType } from '../constants';
import {
    AlertBody,
    StudyDetails,
    AlertFormState,
    DropdownOption,
    ContentAlertField,
    FetchedAlertDetails,
} from '../state';

// FORMAT FETCHED ALERT DATA INTO FORM STATE
export const formatFetchedAlert = (
    alertData: FetchedAlertDetails,
    studyData: StudyDetails
): AlertFormState => {
    const keywords = alertData.keywords
        ? alertData?.keywords?.split(',').map((keyword): DropdownOption => {
              return {
                  label: keyword,
                  value: keyword,
              };
          })
        : [];

    const phrases =
        alertData.phrases && alertData.phrases !== 'NA'
            ? alertData?.phrases?.split(',')
            : [];

    const competitors = alertData.competitors
        ? alertData?.competitors
              ?.split(',')
              .map((competitor): DropdownOption => {
                  return {
                      label: competitor,
                      value: competitor,
                  };
              })
        : [];

    const fields: ContentAlertField[] = alertData.field
        ? (alertData?.field?.split(',') as ContentAlertField[])
        : [];

    const adsType: AdsType[] = alertData.ad_type
        ? (alertData?.ad_type?.split(',') as AdsType[])
        : ['seo', 'sem', 'pla'];

    const recipients = alertData.additional_email_ids
        ? alertData?.additional_email_ids?.split(',')
        : [];

    const formState: AlertFormState = {
        alertType: alertData.report_type,
        adsType: adsType.map((adType) => ({
            label: adType.toUpperCase(),
            value: adType,
        })),
        recipients,
        keywords,
        competitorOperator: alertData.competitor_operator,
        competitors,
        recurrence: alertData.alert_recurrence,
        study: studyData,
        phrases,
        fields,
        operator: alertData.operator || null,
        savePng: alertData.save_png,
        coverage_operator: alertData.coverage_operator || null,
        coverage_base_value: alertData.coverage_base_value || null,
        coverage_change: alertData.coverage_change || null,
        coverage_threshold: alertData.coverage_threshold || null,
        rank_operator: alertData.rank_operator || null,
        rank_base_value: alertData.rank_base_value || null,
        rank_change: alertData.rank_change || null,
        rank_threshold: alertData.rank_threshold || null,
    };

    return formState;
};

export const generateAlertBody = (state: AlertFormState) => {
    if (!state.study) {
        return;
    }
    if (!state.alertType) {
        return;
    }

    const body: AlertBody = {
        studyId: parseInt(state.study.id.toString()),
        adType: state.adsType.map(({ value }) => value as AdsType),
        reportType: state.alertType,
        alert_recurrence: state.recurrence,
        additionalEmailIds: state.recipients,
        savePng: state.alertType === 'Content' ? state.savePng : 0,
    };

    if (state.competitors.length) {
        body.competitorOperator = state.competitorOperator;
        body.competitors = state.competitors.map(
            ({ value }) => value as string
        );
    }

    if (state.keywords.length) {
        body.keywords = state.keywords.map(({ value }) => value as string);
    }

    if (state.alertType === 'Keywords') {
        body.rank_operator = state.rank_operator;
        body.rank_change = state.rank_change;
        body.rank_threshold = state.rank_threshold;
        body.rank_base_value = state.rank_base_value;
        body.coverage_operator = state.coverage_operator;
        body.coverage_change = state.coverage_change;
        body.coverage_threshold = state.coverage_threshold;
        body.coverage_base_value = state.coverage_base_value;
    }

    if (state.alertType === 'Content') {
        body.phrases = state.phrases;
        body.operator = state.operator || '';
        body.field = state.fields;
    }

    return body;
};
