import './Content-Analysis.scss';

import { useState } from 'react';
import { useAsyncWrapper } from '../../hooks';

// API REQUESTS
import { getContentReport } from '../../api';

// CONSTANTS
import { TOP_ADS_TOOLTIP } from '../../tooltips';

// TYPES
import { StudyDetails, ContentAnalysisRow, InsightsState } from '../../state';

// UI COMPONENTS
import ContentAnalysisTable from './Content-Analysis-Table';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';
import InsightsFilter from '../Insights-Filter/Insights-Filter';

// UTILS
import { getTopAds } from '../../utils';

// COMPONENT PROPS
interface ContentAnalysisProps {
    study: StudyDetails;
}

// FUNCTIONAL COMPONENT (CONTENT ANALYSIS)
function ContentAnalysis({ study }: ContentAnalysisProps) {
    const wrapper = useAsyncWrapper();

    // CONTENT REPORT DATA
    const [topAds, setTopAds] = useState<ContentAnalysisRow[]>([]);

    // HANDLE FORM SUBMISSION
    const onSubmit = wrapper(async (filterState: InsightsState) => {
        if (study.id) {
            const study_id =
                typeof study.id === 'number' ? study.id : parseInt(study.id);
            const data = await getContentReport(study_id, filterState);

            setTopAds(getTopAds(data));
        }
    });

    // JSX
    return (
        <div className="content-analysis-container">
            <InsightsFilter study={study} onSubmit={onSubmit} />

            <div className="content-analysis-content">
                <span className="content-analysis-header">
                    <CustomTooltip
                        content={TOP_ADS_TOOLTIP}
                        align={TOOLTIP_ALIGN.LEFT}
                        maxWidth="330px"
                    >
                        Top Ads:
                    </CustomTooltip>
                </span>
                <ContentAnalysisTable data={topAds} />
            </div>
        </div>
    );
}

export default ContentAnalysis;
