import './Report-Form.scss';

import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// TYPES
import {
    reportReducer,
    ReportFormState,
    ReportFiltersState,
    reportFiltersReducer,
    DEFAULT_REPORT_FORM_STATE,
    DEFAULT_REPORT_FILTERS_STATE,
} from '../../state';
import { ScheduledReport } from '../../types';

// UI COMPONENTS
import ReportFilters from './Report-Filters';
import ReportFormBase from './Report-Form-Base';
import Button from '@publicismedia-ds/ui-button';

// COMPONENT PROPS
interface ReportFormProps {
    report?: ScheduledReport;
    onDemand?: boolean;
    onCancel?: () => void;
    onSubmit: (
        report: ReportFormState,
        filters: ReportFiltersState
    ) => Promise<void>;
    readOnly?: boolean;
}

// FUNCTIONAL COMPONENT (REPORT FORM)
function ReportForm({ report, onDemand, onSubmit, readOnly }: ReportFormProps) {
    const navigate = useNavigate();

    // BASE FORM STATE
    const [reportState, dispatchReport] = useReducer(
        reportReducer,
        DEFAULT_REPORT_FORM_STATE
    );

    // FILTERS STATE
    const [filtersState, dispatchFilters] = useReducer(
        reportFiltersReducer,
        DEFAULT_REPORT_FILTERS_STATE
    );

    // HANDLE REPORT FORM SUBMISSION
    const onSubmitReport = (evt: React.FormEvent) => {
        evt.preventDefault();

        onSubmit(reportState, filtersState);
    };

    // HANDLE CANCEL EDITING
    const onCancelEdit = () => {
        navigate(-1);
    };

    const isSubmitDisabled =
        !reportState.study ||
        !reportState.file_type ||
        !reportState.start_date ||
        !reportState.report_type ||
        (filtersState.auto_competitor_group && !filtersState.competitor_limit);

    // JSX
    return (
        <div className="report-form-container">
            <form onSubmit={onSubmitReport}>
                <ReportFormBase
                    state={reportState}
                    dispatch={dispatchReport}
                    onDemand={onDemand}
                    report={report}
                    isAutoCompetitor={filtersState.auto_competitor_group}
                    readOnly={readOnly}
                />
                <ReportFilters
                    state={filtersState}
                    dispatch={dispatchFilters}
                    baseState={reportState}
                    onDemand={onDemand}
                    report={report}
                    readOnly={readOnly}
                />

                <div className="form-buttons">
                    {readOnly ? (
                        <Button
                            display="secondary"
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </Button>
                    ) : (
                        <>
                            {!onDemand && (
                                <Button color="red" onClick={onCancelEdit}>
                                    Cancel
                                </Button>
                            )}
                            <Button type="submit" disabled={isSubmitDisabled}>
                                {onDemand ? 'Download' : 'Submit'}
                            </Button>
                        </>
                    )}
                </div>
            </form>
        </div>
    );
}

export default ReportForm;
