import { LoginResponse, UserDetails } from '../state';
import { UserAgencyData } from '../types';

export const formatUserDetails = (
  userData: LoginResponse,
  agencyData: UserAgencyData
) => {
  const { user_details, accessToken } = userData;
  if (!user_details || !accessToken) {
    return;
  }
  const user: UserDetails = {
    email: user_details.email,
    first_name: user_details.first_name,
    last_name: user_details.last_name,
    token: accessToken,
    role: user_details.account_role_details[0].role,
    status: user_details.status,
    account_id: user_details.account_role_details[0].account_id,
    expires: Date.now() + 7 * 24 * 60 * 60 * 1000,
    agenciesInfo: { ...agencyData },
  };

  return user;
};
