import './Page-Not-Found.scss';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useActions } from '../../hooks';

function PageNotFound() {
    const { alertError } = useActions();

    const NOT_FOUND_MESSAGE = `Unfortunately we coudn't find the page you were looking for. [404 error]`;

    useEffect(() => {
        alertError(NOT_FOUND_MESSAGE);
    });

    return <Navigate to="/" />;
}

export default PageNotFound;
