import produce from 'immer';

// TYPES
import { AuthAction } from '../actions';
import { AuthState, STORED_USER } from '../models';
import { AUTH_ACTION_TYPES } from '../action-types';

const storedUser = localStorage.getItem(STORED_USER);

export const INITIAL_AUTH_STATE: AuthState = {
  isAuthenticated: !!storedUser,
  user: storedUser ? JSON.parse(storedUser) : null,
  authenticationFailed: false,
};

export const authReducer = produce((state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_FAILURE:
      state.authenticationFailed = true;
      return state;

    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      state.isAuthenticated = true;
      state.user = action.payload;
      return state;

    case AUTH_ACTION_TYPES.LOGOUT:
      state.isAuthenticated = false;
      state.user = null;
      return state;

    case AUTH_ACTION_TYPES.SET_USER_AGENCIES:
      if (state.user) {
        state.user.agenciesInfo = action.payload;
        // UPDATE USER DETAILS IN LOCAL STORAGE
        localStorage.setItem(STORED_USER, JSON.stringify(state.user));
      }
      return state;

    default:
      return state;
  }
}, INITIAL_AUTH_STATE);
