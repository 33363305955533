// TYPES
import { AdsType } from '../../constants';
import { DropdownOption } from '../models';
import { INSIGHTS_ACTION_TYPES } from '../action-types';
import { LoadInsightsOptionsAction, ResetInsightsAction } from '../actions';

// INSIGHTS OPTIONS PARAMS
export interface LoadInsightsOptionsParams {
    competitorsGroupOptions: DropdownOption[];
    competitorOptions: DropdownOption[];
    keywordsGroupOptions: DropdownOption[];
    keywordOptions: DropdownOption[];
    targetOptions: DropdownOption[];
}

// LOAD INSIGHTS FILTER DROPDOWN OPTIONS
export const loadInsightsOptions = (
    options: LoadInsightsOptionsParams
): LoadInsightsOptionsAction => {
    return {
        type: INSIGHTS_ACTION_TYPES.LOAD_OPTIONS,
        payload: {
            competitorsGroupOptions: options.competitorsGroupOptions,
            competitorOptions: options.competitorOptions,
            keywordsGroupOptions: options.keywordsGroupOptions,
            keywordOptions: options.keywordOptions,
            targetOptions: options.targetOptions,
        },
    };
};

// SET SELECTED START DATE
export const onSetInsightsStartDate = (selected: Date) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_START_DATE,
        payload: selected.toDateString(),
    };
};

// SET SELECTED END DATE
export const onSetInsightsEndDate = (selected: Date) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_END_DATE,
        payload: selected.toDateString(),
    };
};

// SET SELECTED COMPETITORS GROUP
export const onSetInsightsCompetitorsGroup = (selected: DropdownOption) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_COMPETITORS_GROUP,
        payload: selected.value as number,
    };
};

// SET SELECTED COMPETITORS
export const onSetInsightsCompetitors = (selected: DropdownOption[]) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_COMPETITORS,
        payload: selected,
    };
};

// SET SELECTED KEYWORDS GROUP
export const onSetInsightsKeywordsGroup = (selected: DropdownOption) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_KEYWORDS_GROUP,
        payload: selected.value as number,
    };
};

// SET SELECTED KEYWORDS
export const onSetInsightsKeywords = (selected: DropdownOption[]) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_KEYWORDS,
        payload: selected,
    };
};

// SET SELECTED AD TYPE
export const onSelectInsightsAdType = (selected: DropdownOption) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_AD_TYPE,
        payload: selected.value as AdsType,
    };
};

// SET SELECTED TARGETS
export const onSelectInsightsTargets = (selected: DropdownOption[]) => {
    return {
        type: INSIGHTS_ACTION_TYPES.SET_TARGETS,
        payload: selected,
    };
};

// RESET INSIGHTS SELECTIONS
export const resetInsights = (): ResetInsightsAction => {
    return {
        type: INSIGHTS_ACTION_TYPES.RESET_INSIGHTS,
    };
};
