import { POSTAddUserRequest, UserOnboardingState } from '../state';

export const formatAddUserBody = (state: UserOnboardingState) => {
  const {
    first_name,
    last_name,
    email,
    role,
    accounts,
    clients,
    password,
    confirmPassword,
  } = state;

  // CHECK FOR REQUIRED INPUT VALUES
  if (
    !first_name ||
    !last_name ||
    !email ||
    !role ||
    !accounts.length ||
    !password ||
    !confirmPassword ||
    password !== confirmPassword
  ) {
    throw new Error('Please fill out all required fields.');
  }

  const body: POSTAddUserRequest = {
    account_role_details: accounts.map((account) => ({
      account_id: account.value.toString(),
      role,
    })),
    client_id: clients.map((client) => parseInt(client.value.toString())),
    email,
    first_name,
    last_name,
    password,
  };

  return body;
};
