import { useMemo } from 'react';
import { DATA_VIZ_COLORS } from '../../../constants';
import './Custom-Apex-Legend.scss';

// COMPONENT PROPS
interface CustomApexLegendProps {
  options: string[];
  value: string;
  colors?: string[];
  onClick: (selected: string) => void;
}

// FUNCTIONAL COMPONENT (CUSTOM APEX CHARTS LEGEND)
function CustomApexLegend({
  options,
  value,
  onClick,
  colors,
}: CustomApexLegendProps) {
  const colorOptions = colors || DATA_VIZ_COLORS;
  const numColors = colorOptions.length;

  // LEGEND OPTIONS
  const items = useMemo(() => {
    return options.map((option, index) => {
      return (
        <li
          className="custom-apex-legend-item"
          data-active={value === option}
          key={index}
          onClick={() => onClick(option)}
        >
          <span
            className="custom-apex-legend-item__before"
            style={{
              backgroundColor: `${
                index < numColors
                  ? colorOptions[index]
                  : colorOptions[index % numColors]
              }`,
            }}
          />
          {option}
        </li>
      );
    });
  }, [options, onClick]);
  return (
    <div className="custom-apex-legend-container">
      <ul className="custom-apex-legend-content">{items}</ul>
    </div>
  );
}

export default CustomApexLegend;
