import produce from 'immer';

import { AdhocReportState } from '../models';
import { AdhocReportAction } from '../actions';
import { ADHOC_REPORT_ACTION_TYPES } from '../action-types';
import { getPriorEndDate } from '../../utils';

export const DEFAULT_ADHOC_STATE: AdhocReportState = {
  study: null,
  start_date: null,
  end_date: null,
  prior_start_date: null,
  prior_end_date: null,
  zero_coverage: false,
  compare_previous: false,
  auto_competitor_group: false,
  daily: false,
  hourly: false,
  keyword_groups: [],
  keywords: [],
  competitor_group: [],
  competitors: [],
  competitor_limit: null,
  targets: [],
  metrics: [],
  type: [],
  time_zone: null,
  top_words: null,
  focus: null,
};

// VALIDATE CURRENT & PRIOR DATE RANGES

export const adhocReportReducer = produce(
  (state: AdhocReportState, action: AdhocReportAction): AdhocReportState => {
    switch (action.type) {
      case ADHOC_REPORT_ACTION_TYPES.SET_STUDY:
        state = {
          ...DEFAULT_ADHOC_STATE,
          study: action.payload,
          end_date: state.end_date,
          start_date: state.start_date,
          prior_end_date: state.prior_end_date,
          prior_start_date: state.prior_start_date,
        };
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_START_DATE:
        state.start_date = action.payload;
        state.prior_start_date = null;
        state.prior_end_date = null;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_END_DATE:
        state.end_date = action.payload;
        state.prior_start_date = null;
        state.prior_end_date = null;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_PRIOR_START_DATE: {
        state.prior_start_date = action.payload;
        state.prior_end_date = getPriorEndDate(
          state.start_date,
          state.end_date,
          action.payload
        );
        return state;
      }

      case ADHOC_REPORT_ACTION_TYPES.SET_PRIOR_END_DATE:
        state.prior_end_date = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_ZERO_COVERAGE:
        state.zero_coverage = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_COMPARE_PREVIOUS:
        state.compare_previous = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_AUTO_COMPETITOR_GROUP:
        state.auto_competitor_group = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_DAILY_REPORTING:
        state.daily = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_HOURLY_REPORTING:
        state.hourly = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_COMPETITOR_GROUPS:
        state.competitor_group = action.payload;
        state.competitors = [];
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_COMPETITORS:
        state.competitors = action.payload;
        state.competitor_group = [];
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_KEYWORD_GROUPS:
        state.keyword_groups = action.payload;
        state.keywords = [];
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_KEYWORDS:
        state.keywords = action.payload;
        state.keyword_groups = [];
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_TARGETS:
        state.targets = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_METRICS:
        state.metrics = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_AD_TYPES:
        state.type = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_TIME_ZONE:
        state.time_zone = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_FOCUS:
        state.focus = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_AUTO_COMPETITOR_LIMIT:
        state.competitor_limit = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.SET_TOP_WORDS:
        state.top_words = action.payload;
        return state;

      case ADHOC_REPORT_ACTION_TYPES.LOAD_STATE:
        state = { ...DEFAULT_ADHOC_STATE, ...action.payload };
        return state;

      case ADHOC_REPORT_ACTION_TYPES.RESET_STATE:
        state = { ...DEFAULT_ADHOC_STATE };
        return state;

      default:
        return state;
    }
  },
  DEFAULT_ADHOC_STATE
);
