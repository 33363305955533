import './Word-Cloud.scss';

import { useAsyncWrapper } from '../../../hooks';
import React, { useEffect, useRef, useState } from 'react';

// TYPES
import { DropdownOption, WordAnalysisBrandsData } from '../../../state';

// UI COMPONENTS
import Dropdown from '@publicismedia-ds/ui-dropdown';
import ReactWordcloud, {
    Word,
    Options,
    Optional,
    Callbacks,
} from 'react-wordcloud';

// UITILS
import {
    convertSvgToPng,
    triggerDownload,
    getTotalCountByWord,
    addLabelToWordCloudSVG,
} from '../../../utils';

// REACT-WORD_CLOUD TOOLTIP STYLES
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import ChartMenu from '../Chart-Menu/Chart-Menu';

// WORD CLOUD FONT SIZES
const MIN_FONT_SIZE = 10 as const;
const MAX_FONT_SIZE = 100 as const;

// WORD CLOUD PADDING
const INNER_WIDTH_BREAK = 1500 as const;
const MIN_PADDING = 5 as const;
const MAX_PADDING = 10 as const;

// COMPONENT PROPS
interface WordCloudProps {
    data: WordAnalysisBrandsData;
    studyName: string;
}

// FUNCTIONAL COMPONENT (WORD CLOUD)
function WordCloud({ data, studyName }: WordCloudProps) {
    // HOOKS
    const wrapper = useAsyncWrapper();

    // TOTAL WORD COUNT
    const [wordsTotal, setWordsTotal] = useState<Word[]>([]);

    // SELECTED BRAND
    const [selectedBrand, setSelectedBrand] = useState<DropdownOption | null>(
        null
    );

    // WORD CLOUD PADDING VALUE
    const [padding, setPadding] = useState<
        typeof MIN_PADDING | typeof MAX_PADDING
    >(MIN_PADDING);

    // REFS
    const cloudCoverRef = useRef<HTMLDivElement | null>(null);

    // RESET BRAND SELECTION ON DATA CHANGE
    useEffect(() => {
        // TOTAL OCCURRENCE FOR EACH WORD (INCLUDES ALL BRANDS - DEFAULT)
        const totalWordCount = getTotalCountByWord(data);

        setWordsTotal(totalWordCount);
        setSelectedBrand(null);
    }, [data]);

    // WORD COUNT BY SELECTED BRAND
    const wordsByBrand: Word[] = selectedBrand
        ? data[selectedBrand.value].map(({ word, count }) => ({
              text: word,
              value: count,
          }))
        : [];

    // WORD CLOUD DATA
    const words = selectedBrand ? wordsByBrand : wordsTotal;

    // WORD CLOUD LABEL
    const wordCloudLabel =
        `Top ${words.length} Words ` +
        `${selectedBrand ? `(${selectedBrand.value})` : ''}`;

    // DISPLAY TOOLTIP
    const callbacks: Optional<Callbacks> = {
        getWordTooltip: (word) =>
            `The word "${word.text}" appears ${word.value} times.`,
    };

    // WORD CLOUD OPTIONS
    const options: Optional<Options> = {
        fontSizes: [MIN_FONT_SIZE, MAX_FONT_SIZE],
        scale: 'linear',
        rotations: 0,
        padding,
        svgAttributes: {
            id: 'word-cloud-svg',
            class: 'apexcharts-svg',
            xmlns: 'http://www.w3.org/2000/svg',
            'xmlns:xlink': 'http://www.w3.org/1999/xlink',
            fill: 'white',
        },
    };

    // GET BRANDS DROPDOWN OPTIONS
    const brandOptions: DropdownOption[] = Object.keys(data).map((brand) => ({
        label: brand,
        value: brand,
    }));

    const onExportToPNG = wrapper(async () => {
        const svgWordCloud: SVGSVGElement | null =
            document.querySelector('#word-cloud-svg');

        if (!svgWordCloud) {
            throw new Error('Unable to download png');
        }

        const svgWithLabel = addLabelToWordCloudSVG(
            svgWordCloud,
            wordCloudLabel
        );

        // EXPORT SVG TO PNG
        const { imgURI } = await convertSvgToPng(svgWithLabel);

        triggerDownload(imgURI, `${studyName}__word-cloud`, 'png');
    });

    // ADD EVENT LISTENER TO DETECT SCREEN SIZE CHANGE AND ADJUST PADDING
    useEffect(() => {
        // ADJUST WORD PADDING BASED ON SCREEN SIZE
        const handleResize = () => {
            if (
                window.innerWidth < INNER_WIDTH_BREAK &&
                padding !== MIN_PADDING
            ) {
                setPadding(MIN_PADDING);
            }

            if (
                window.innerWidth >= INNER_WIDTH_BREAK &&
                padding !== MAX_PADDING
            ) {
                setPadding(MAX_PADDING);
            }
        };

        // ADD RESIZE EVENT LISTENER TO ADJUST PADDING
        window.addEventListener('resize', handleResize);

        // REMOVE EVENT LISTENER
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [padding]);

    // HANDLE BRAND SELECTION
    const onSelectBrand = async (selected: DropdownOption) => {
        if (cloudCoverRef.current) {
            cloudCoverRef.current.style.display = 'block';
        }
        setTimeout(() => {
            if (cloudCoverRef.current) {
                cloudCoverRef.current.style.display = 'none';
            }
        }, 1000);
        const selection =
            selected.value !== selectedBrand?.value ? selected : null;
        setSelectedBrand(selection);
    };

    return (
        <div className="word-cloud-container" style={{ position: 'relative' }}>
            {/* - used to cover the word cloud once a selection is made from the dropdown */}
            {/* - prevents tooltip displaying when the menu closes, followed by the word no longer appearing */}
            <div className="word-cloud-cover" ref={cloudCoverRef} />

            <div className="word-cloud-header">
                <p className="word-cloud-label">{wordCloudLabel}</p>
                <Dropdown
                    className="word-cloud-dd"
                    options={brandOptions}
                    placeholder="Select a competitor"
                    value={selectedBrand}
                    onChange={onSelectBrand}
                    isSearchable={false}
                    hideLabel={true}
                >
                    Competitor:
                </Dropdown>
            </div>
            <div
                id="word-cloud"
                className="word-cloud-chart"
                style={{ height: '350px' }}
            >
                {words.length > 0 ? (
                    <ReactWordcloud
                        words={words}
                        options={options}
                        callbacks={callbacks}
                    />
                ) : (
                    <h1 className="data-not-found">No data found</h1>
                )}
            </div>
            <ChartMenu onExportPNG={onExportToPNG} />
        </div>
    );
}

export default React.memo(WordCloud);
