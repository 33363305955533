export const getTopAds = (data: any[]) => {
    // MAX 100 - MIN 20
    let highestIMP = 20;

    // MIN 0 - MAX 80
    let lowestIMP = 0;

    for (const row of data) {
        // ROUND IMP% UP TO NEAREST INT
        const imprInt = Math.ceil(row['IMP %']);

        // ROUND INT UP TO NEAREST 10
        const nearestTen = Math.ceil(imprInt / 10) * 10;

        // DON'T ALLOW PERCENTAGE HIGH THAN 100
        highestIMP =
            nearestTen > highestIMP ? Math.min(nearestTen, 100) : highestIMP;

        // SET LOWEST IMP TO N LESS THEN HIGHEST, BUT NOT LESS THAN 0
        lowestIMP = Math.max(highestIMP - 20, 0);
    }

    // RETURN ROWS WITHIN HIGHTEST & LOWEST RANGE
    return data.filter(
        (row) => row['IMP %'] <= highestIMP && row['IMP %'] >= lowestIMP
    );
};
