import { GroupData } from '../components/Group-Content/Group-Content';
import { GroupDetails } from '../types';
import { CompetitorGroupDetails, KeywordGroupDetails } from '../state';
interface Groups {
    keyword_groups?: KeywordGroupDetails[];
    competitor_groups?: CompetitorGroupDetails[];
}

export const formatGroupsData = ({
    keyword_groups,
    competitor_groups,
}: Groups): GroupData[] => {
    if (keyword_groups) {
        return keyword_groups.map((group) => {
            return {
                id: group.id,
                name: group.name,
                details: group.keyword_details,
            };
        });
    }

    if (competitor_groups) {
        return competitor_groups.map((group) => {
            return {
                id: group.id,
                name: group.name,
                details: group.competitor_details,
            };
        });
    }

    return [];
};

export const swapGroupKeyValues = (details: GroupDetails) => {
    const output: { [key: number]: string } = {};
    for (const [key, value] of Object.entries(details)) {
        output[value] = key;
    }
    return output;
};

export const swapGroupKeyValuesBack = (swappedDetails: {
    [key: number]: string;
}) => {
    const output: GroupDetails = {};
    for (const [key, value] of Object.entries(swappedDetails)) {
        output[value] = parseInt(key);
    }
    return output;
};
