import { axios } from '../axios';
import { generateAlertBody, saveDownloadFile } from '../../utils';

import {
    GETAlertById,
    GETAlertList,
    GETAllAlerts,
    AlertFormState,
    UpdateAlertStatusBody,
} from '../../state';

// CONSTANTS
import { API_ROUTES } from '../../constants';

// GET USER'S ALERTS
export const getAlertList = async () => {
    const url = '/alert/alertlist';
    const response = await axios.get<GETAlertList>(url);

    return response.data.alerts;
};

// CREATE NEW ALERT
export const createAlert = async (formState: AlertFormState) => {
    const url = '/alert/';

    const body = generateAlertBody(formState);

    const { data } = await axios.post<{ message: string }>(url, body);

    return data;
};

// UPDATE ALERT
export const updateAlertById = async (
    alert_id: string | number,
    formState: AlertFormState
) => {
    const url = `/alert/${alert_id}`;

    const body = generateAlertBody(formState);

    const { data } = await axios.put<{ message: string }>(url, body);

    return data;
};

// UPDATE ALERT STATUS
export const updateAlertStatus = async (
    alert_id: string | number,
    body: UpdateAlertStatusBody
) => {
    const url = `/alert/${alert_id}`;

    const { data } = await axios.put<{ message: string }>(url, body);

    return data;
};

// GET ALERT DETAILS BY ID
export const getAlertById = async (alert_id: string) => {
    const url = `/alert/${alert_id}`;

    const { data: alert } = await axios.get<GETAlertById>(url);
    return alert.data;
};

// DOWNLOAD ALERT DETAILS CSV
export const onDownloadAlertDetails = async () => {
    const url = '/alert/export/alert-reports';
    const fileName = `Alerts-Summary_${new Date().toISOString()}.xlsx`;

    const blob = await axios.get(url, {
        responseType: 'blob',
    });

    saveDownloadFile(blob, fileName);
};

// FETCH ALL ALERTS
export const getAllAlerts = async () => {
    const { data } = await axios.get<GETAllAlerts>(API_ROUTES.GET_ALL_ALERTS);

    return data;
};
