import './Study-Detail.scss';

interface StudyDetailProps {
    header: string;
    content?: string | number | null;
    children?: React.ReactNode;
}

function StudyDetail({ header, content, children }: StudyDetailProps) {
    const shouldRenderChildren = !content && children;
    const shouldRenderContent = content || !children;
    return (
        <div className="study-detail-container">
            <h3 className="study-detail-header">{header}:</h3>
            {shouldRenderContent && (
                <p className="study-detail-content">{content || 'N/A'}</p>
            )}
            {shouldRenderChildren && (
                <div className="study-detail-children">{children}</div>
            )}
        </div>
    );
}

export default StudyDetail;
