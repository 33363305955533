import { read, utils } from 'xlsx';

// FUNCTION TO PARSE XLSX FILES
export const parseFile = (blob: Blob): Promise<unknown[]> => {
    return new Promise((resolve) => {
        // CREATE NEW READER INSTANCE
        const reader = new FileReader();

        // RUNS FUNCTION ONCE READER HAS FINISHED READING FILE
        reader.onloadend = (evt) => {
            if (!evt.target?.result) {
                throw new Error('Unable to parse file.');
            }

            // READ ARRAY BUFFER AND CONVERT TO WORKBOOK
            const wb = read(evt.target.result);

            // GET WORKBOOK SHEET NAME
            const sheetName = wb.SheetNames[1];

            // CONVERT WORKBOOK SHEET TO JSON DATA
            const data = utils.sheet_to_json(wb.Sheets[sheetName]);

            resolve(data);
        };

        // START READER
        reader.readAsArrayBuffer(blob);
    });
};
