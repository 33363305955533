import { useEffect, useState } from 'react';

// CONSTANTS
import { BENCHMARKING_TOOLTIPS } from '../../tooltips';

// TYPES
import { KeywordsMetricsByBrand } from '../../state';

// UI COMPONENTS
import DataTable, {
    DataProvider,
    ColumnTemplate,
} from '@publicismedia-ds/ui-datatable';
import CustomTooltip, { TOOLTIP_ALIGN } from '../Custom-Tooltip/Custom-Tooltip';

// COMPONENT PROPS
interface KeywordsMetricsTableProps {
    data: KeywordsMetricsByBrand | null;
}

// FUNCTIONAL COMPONENT (KEYWORDS METRICS TABLE)
function KeywordsMetricsTable({ data }: KeywordsMetricsTableProps) {
    const [provider, setProvider] = useState<any>();

    // SET OR UPDATE TABLE DATA PROVIDER
    useEffect(() => {
        const formattedData = data
            ? Object.entries(data).map(([brand, metrics]) => {
                  // CALC AVERAGE ABOVE RATE
                  const aboveRate = Math.round(
                      metrics.aboveRate.reduce((acc, rateData) => {
                          return (acc += rateData.position_above);
                      }, 0) / metrics.aboveRate.length
                  );

                  // CALC AVERAGE OUTRANK RATE
                  const outrankRate = Math.round(
                      metrics.outrankRate.reduce((acc, rateData) => {
                          return (acc += rateData.outrank_share);
                      }, 0) / metrics.outrankRate.length
                  );

                  // CALC AVERAGE TOP OF PAGE RATE
                  const topRate = Math.round(
                      metrics.topRate.reduce((acc, rateData) => {
                          return (acc += rateData.top_rank);
                      }, 0) / metrics.topRate.length
                  );

                  return {
                      brand,
                      aboveRate: isNaN(aboveRate) ? 0 : aboveRate,
                      outrankRate: isNaN(outrankRate) ? 0 : outrankRate,
                      topRate: isNaN(topRate) ? 0 : topRate,
                  };
              })
            : [];

        setProvider(
            // SORT BRANDS ALPHABETICALLY (ASC)
            new DataProvider({
                data: formattedData.sort((a, b) => {
                    if (a.brand < b.brand) {
                        return -1;
                    }
                    if (a.brand > b.brand) {
                        return 1;
                    }
                    return 0;
                }),
            })
        );
    }, [data]);

    // JSX
    return (
        <DataTable bindKey="brand" data={provider}>
            {/* --- BRAND --- */}
            <ColumnTemplate bind="brand" isSortable minWidth>
                Brand
            </ColumnTemplate>

            {/* --- AVERAGE TOP OF PAGE RATE -- */}
            <ColumnTemplate
                type="percent"
                align="center"
                bind="topRate"
                isSortable
                minWidth
            >
                <CustomTooltip
                    content={BENCHMARKING_TOOLTIPS.TOP_OF_PAGE}
                    maxWidth="250px"
                >
                    Top of Page
                </CustomTooltip>
            </ColumnTemplate>

            {/* --- AVERAGE OUTRANK SHARE RATE --- */}
            <ColumnTemplate
                type="percent"
                align="center"
                bind="outrankRate"
                isSortable
                minWidth
            >
                <CustomTooltip
                    content={BENCHMARKING_TOOLTIPS.OUTRANK_SHARE}
                    maxWidth="250px"
                >
                    Outrank Share
                </CustomTooltip>
            </ColumnTemplate>

            {/* --- AVERAGE POSITION ABOVE RATE */}
            <ColumnTemplate
                type="percent"
                align="center"
                bind="aboveRate"
                isSortable
                minWidth
            >
                <CustomTooltip
                    content={BENCHMARKING_TOOLTIPS.POSITION_ABOVE_RATE}
                    align={TOOLTIP_ALIGN.RIGHT}
                    maxWidth="300px"
                >
                    Position Above
                </CustomTooltip>
            </ColumnTemplate>
        </DataTable>
    );
}

export default KeywordsMetricsTable;
