import produce from 'immer';

// CONSTANTS
import { AD_TYPE_OPTIONS } from '../../../constants';

// UTILS
import { formatFetchedAlert } from '../../../utils';

// TYPES
import {
    ALERT_TYPES,
    AlertFormState,
    AlertFormAction,
    ALERT_RECURRENCE,
    PHRASES_OPERATORS,
    CONTENT_ALERT_FIELDS,
    COMPETITORS_OPERATORS,
    ALERT_FORM_ACTION_TYPES,
} from '../models/alert-form-models';

// DEFAULT ALERT === 'CONTENT'
export const DEFAULT_ALERT_FORM_STATE: AlertFormState = {
    study: null,
    alertType: ALERT_TYPES[0],
    adsType: AD_TYPE_OPTIONS,
    phrases: [],
    operator: PHRASES_OPERATORS[0],
    keywords: [],
    competitors: [],
    competitorOperator: COMPETITORS_OPERATORS[0], // default 'equal'
    recipients: [],
    fields: [...CONTENT_ALERT_FIELDS],
    savePng: 0,
    recurrence: ALERT_RECURRENCE[0], // default === daily
    coverage_operator: null,
    coverage_base_value: null,
    coverage_change: null,
    coverage_threshold: null,
    rank_operator: null,
    rank_base_value: null,
    rank_change: null,
    rank_threshold: null,
};

export const alertFormReducer = produce(
    (state: AlertFormState, action: AlertFormAction) => {
        switch (action.type) {
            case ALERT_FORM_ACTION_TYPES.SET_ALERT_TYPE: {
                const tempStudy = state.study;
                state = { ...DEFAULT_ALERT_FORM_STATE };
                state.study = tempStudy;
                state.alertType = action.payload;

                if (action.payload === 'Keywords') {
                    state.adsType = [state.adsType[0]];
                }
                return state;
            }

            case ALERT_FORM_ACTION_TYPES.SET_STUDY: {
                const tempAlertType = state.alertType;
                const tempAdsType = state.adsType;

                state = { ...DEFAULT_ALERT_FORM_STATE };

                state.study = action.payload;
                state.alertType = tempAlertType;
                state.adsType = tempAdsType;

                return state;
            }

            case ALERT_FORM_ACTION_TYPES.SET_ALERT_RECURRENCE:
                state.recurrence = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_ADS_TYPE:
                state.adsType = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_KEYWORDS:
                state.keywords = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_COMPETITORS:
                state.competitors = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_COMPETITOR_OPERATOR:
                state.competitorOperator = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.ADD_RECIPIENT:
                state.recipients.push(action.payload);
                return state;

            case ALERT_FORM_ACTION_TYPES.REMOVE_RECIPIENT:
                state.recipients = state.recipients.filter(
                    (recipient) => recipient !== action.payload
                );
                return state;

            case ALERT_FORM_ACTION_TYPES.RESET_ALERT_FORM:
                state = { ...DEFAULT_ALERT_FORM_STATE };
                return state;

            case ALERT_FORM_ACTION_TYPES.LOAD_EXISTING_ALERT: {
                const { alert, study } = action.payload;
                state = formatFetchedAlert(alert, study);
                return state;
            }

            // CONTENT ALERT ONLY
            case ALERT_FORM_ACTION_TYPES.ADD_PHRASE:
                state.phrases.push(action.payload);
                return state;

            case ALERT_FORM_ACTION_TYPES.REMOVE_PHRASE:
                state.phrases = state.phrases.filter(
                    (phrase) => phrase !== action.payload
                );
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_PHRASES_OPERATOR:
                state.operator = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.ADD_FIELD:
                state.fields.push(action.payload);
                return state;

            case ALERT_FORM_ACTION_TYPES.REMOVE_FIELD:
                state.fields = state.fields.filter(
                    (field) => field !== action.payload
                );
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_SAVE_PNG:
                state.savePng = action.payload;
                return state;

            // KEYWORDS ALERT ONLY
            case ALERT_FORM_ACTION_TYPES.SET_COVERAGE_OPERATOR:
                // CLEAR ALL VALUES IF NO COVERAGE OPERATOR SELECTED
                if (!action.payload) {
                    state.coverage_change = null;
                    state.coverage_threshold = null;
                    state.coverage_base_value = null;
                }

                // CLEAR THRESHOLD VALUE ONLY
                if (
                    action.payload === 'decreased by' ||
                    action.payload === 'increased by'
                ) {
                    state.coverage_threshold = null;
                }

                // CLEAR BASE & CHANGE VALUES
                if (
                    action.payload === 'is at or higher than' ||
                    action.payload === 'is lower than'
                ) {
                    state.coverage_base_value = null;
                    state.coverage_change = null;
                }

                // SET UPDATE COVERAGE OPERATOR
                state.coverage_operator = action.payload || null;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_COVERAGE_BASE_VALUE:
                state.coverage_base_value = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_COVERAGE_CHANGE:
                state.coverage_change = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_COVERAGE_THRESHOLD:
                state.coverage_threshold = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_RANK_OPERATOR:
                // CLEAR ALL VALUES IF NO RANK OPERATOR SELECTED
                if (!action.payload) {
                    state.rank_change = null;
                    state.rank_threshold = null;
                    state.rank_base_value = null;
                }

                // CLEAR THRESHOLD VALUE ONLY
                if (
                    action.payload === 'decreased by' ||
                    action.payload === 'increased by'
                ) {
                    state.rank_threshold = null;
                }

                // CLEAR BASE & CHANGE VALUES
                if (
                    action.payload === 'is at or higher than' ||
                    action.payload === 'is lower than'
                ) {
                    state.rank_base_value = null;
                    state.rank_change = null;
                }

                // SET UPDATE COVERAGE OPERATOR
                state.rank_operator = action.payload || null;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_RANK_BASE_VALUE:
                state.rank_base_value = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_RANK_CHANGE:
                state.rank_change = action.payload;
                return state;

            case ALERT_FORM_ACTION_TYPES.SET_RANK_THRESHOLD:
                state.rank_threshold = action.payload;
                return state;

            default:
                return state;
        }
    },
    DEFAULT_ALERT_FORM_STATE
);
