import { axios } from '../axios';
import { API_ROUTES } from '../../constants';
import { LoginCredentials, LoginResponse } from '../../state';

export const userLogin = async (email: string, password: string) => {
    const url = API_ROUTES.LOGIN;
    const body: LoginCredentials = { email, password };

    // ATTEMPT USER LOGIN
    const { data } = await axios.post<LoginResponse>(url, body);

    // RETURN REPONSE
    return data;
};

// SEND FORGOT PASSWORD REQUEST
export const submitForgotPassword = async (email: string) => {
    const { data } = await axios.post<{ message: string }>(
        '/users/resetpasswordlink',
        {
            email,
        }
    );
    return data.message;
};

// RESET PASSWORD
export const submitResetPassword = async (
    uuid: string,
    new_password: string
) => {
    const { data } = await axios.post<{ message: string }>(
        '/users/resetpassword',
        {
            uuid,
            new_password,
        }
    );
    return data.message;
};
