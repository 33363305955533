import { NewLocaleState } from '../../../types/edit-study-types';

export const initialNewLocaleState: NewLocaleState = {
    engine: '',
    language: '',
    device: '',
    location: {
        country: '',
        region: '',
        county: '',
        city: '',
        zip: '',
        latitude: '',
        longitude: '',
        radius: '',
    },
};
