import React from 'react';
import { NewLocaleState } from './edit-study-types';

export interface AddLocaleModalState {
    display: boolean;
    details: NewLocaleState | null;
}

export interface WarningModalState {
    display: boolean;
    header: string;
    content: string | React.ReactNode;
    onConfirm: () => void;
}

export interface EditGroupModalState {
    display: boolean;
    groupType: 'keyword' | 'competitor' | null;
    groupName: string | null;
    details: GroupDetails | null;
    onSubmit: ((groupName: string, updates: GroupUpdates) => void) | null;
}

export interface NewGroupModalState {
    groupType: 'keyword' | 'competitor' | null;
    onSubmit: ((groupName: string, items: string[]) => void) | null;
}

export interface GroupDetails {
    [key: string]: number;
}

export interface GroupUpdates {
    deleted?: (string | number)[];
    added?: string[];
}

export interface EditGroupModalProps {
    groupType: 'keyword' | 'competitor';
    groupName: string;
    details: GroupDetails;
    onClose: () => void;
    onSubmit:
        | ((name: string, updates: GroupUpdates) => Promise<void>)
        | ((name: string, updates: GroupUpdates) => void);
}

// DEFAULT MODAL STATES
export const defaultLocaleModalState: AddLocaleModalState = {
    display: false,
    details: null,
};

export const defaultWarningModalState: WarningModalState = {
    display: false,
    header: '',
    content: '',
    onConfirm: () => undefined,
};

export const defaultEditGroupModalState: EditGroupModalState = {
    display: false,
    details: null,
    groupType: null,
    groupName: null,
    onSubmit: null,
};

export const defaultNewGroupState: NewGroupModalState = {
    groupType: null,
    onSubmit: null,
};
