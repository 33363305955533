import { ReportType } from '../../types';
import { ReportFilters } from '../../state';

// RETURNS AVAILABLE FILTERS BASED ON REPORT CHOICE
// (all filters are disabled by default)
export function enabledFiltersByChoice(choice: ReportType): ReportFilters {
    switch (choice) {
        case 'Battlefield':
            return {
                type: [],
                daily: false,
                hourly: false,
                time_zone: '',
            };

        case 'Brands':
            return {
                keywords: [],
                keyword_groups: [],
                competitors: [],
                competitor_group: [],
                targets: [],
                metrics: [],
                type: [],
                auto_competitor_group: false,
                compare_previous: false,
                daily: false,
                hourly: false,
                time_zone: '',
            };

        case 'Content':
            return {
                keywords: [],
                keyword_groups: [],
                competitors: [],
                competitor_group: [],
                targets: [],
                type: [],
                daily: false,
                hourly: false,
                time_zone: '',
            };

        case 'Keywords':
            return {
                keywords: [],
                keyword_groups: [],
                competitors: [],
                competitor_group: [],
                targets: [],
                metrics: [],
                type: [],
                zero_coverage: false,
                auto_competitor_group: false,
                compare_previous: false,
                daily: false,
                hourly: false,
                time_zone: '',
            };

        case 'SERPVisibility':
            return {
                keywords: [],
                keyword_groups: [],
                competitors: [],
                competitor_group: [],
                time_zone: '',
                targets: [],
                metrics: [],
                type: [],
                daily: false,
                hourly: false,
            };

        case 'WordAnalysis':
            return {
                keywords: [],
                keyword_groups: [],
                competitors: [],
                competitor_group: [],
                targets: [],
                metrics: [],
                type: [],
                daily: false,
                hourly: false,
                time_zone: '',
                focus: 'words',
            };

        default:
            return {};
    }
}
