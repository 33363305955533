import { useMemo } from 'react';

// TYPES
import { ApexOptions } from 'apexcharts';
import { BrandsReportData } from '../../state';

// UI COMPONENTS
import { Bar } from '@publicismedia-ds/ui-charts';
import ChartWrapper from './Chart-Wrapper/Chart-Wrapper';

// COMPONENT PROPS
interface TopCompetitorsChartProps {
  data: BrandsReportData | null;
  dateRange?: string;
}

const getTopCompetitors = (data: BrandsReportData) => {
  const formatted = Object.keys(data).map((brandName) => {
    return {
      brand: brandName,
      // Calculate average coverage for each brand including all locales
      avgCoverage: Math.round(
        (data[brandName].reduce((acc: number, { coverage }) => {
          return (acc += parseFloat(coverage));
        }, 0) /
          data[brandName].length) *
          100
      ),
    };
  });

  return formatted
    .sort((a, b) => {
      if (a.avgCoverage > b.avgCoverage) return -1;
      if (a.avgCoverage < b.avgCoverage) return 1;
      return 0;
    })
    .slice(0, 10);
};

const TOOLTIP_TEXT =
  'The top ten brands based on coverage over a given timeframe.';

// FUNCTIONAL COMPONENT (TOP 10 COMPETITORS CHART)
function TopCompetitorsChart({ data, dateRange }: TopCompetitorsChartProps) {
  // CHART FORMATED DATA
  const { series, categories } = useMemo(() => {
    if (!data) return { series: [], categories: [] };

    const topCompetitors = getTopCompetitors(data);
    return {
      series: [{ data: topCompetitors.map(({ avgCoverage }) => avgCoverage) }],
      categories: topCompetitors.map(({ brand }) => brand),
    };
  }, [data]);

  const chartHeight = Math.max(350, categories.length * 50);

  // CHART OPTIONS
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: chartHeight + 'px',
    },
    noData: {
      text: 'No data',
      style: {
        fontSize: '2.5rem',
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      },
    },
    xaxis: {
      min: 0,
      max: 100,
      tickAmount: 10,
      categories,
      title: {
        text: 'Coverage',
        style: {
          fontWeight: 'bold',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontWeight: 'bold',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val + '%',
      textAnchor: 'start',
      offsetX: 15,
      style: {
        colors: ['black'],
      },
    },
  };

  // JSX
  return (
    <div className="top-competitors-container">
      <ChartWrapper
        title="Top competitors"
        tooltipText={TOOLTIP_TEXT}
        dateRange={dateRange || 'N/A'}
      >
        <div className="top-competitors-content">
          <Bar data={series || []} options={options} width="100%" />
        </div>
      </ChartWrapper>
    </div>
  );
}

export default TopCompetitorsChart;
